import React, {useEffect, useState} from 'react';
import { Col, Form,  Row, Select, Spin} from "antd";
import clientAPI from "../../../../api/api";
import style from "./CreateFolder.module.css";

const SelectCardPackage = ({setSizeId,setSizePrice}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedItem, setSelectedItem] = useState([]);


    const handleItemSelect = (selectedItem) => {
        const size_id= data.find((item) => item.name === selectedItem)?.id || null;
        setSizeId(size_id);
        setSelectedItem(selectedItem);
    };
    const fetchSizesAndDetails = async () => {
        setLoading(true);
        try {
            let productID = 1; // Assuming "Flat Cards" have an ID of 1
            switch (selectedType) {
                case 'Fold Over': productID = 3; break;
                case 'Flat Cards': productID = 1; break;
                case 'Photo Cards': productID = 4; break;
                // Add more cases as necessary
            }
            const sizesRes = await clientAPI.getProductObjectSizes(2, 'SIZE');
            const defSizes = await clientAPI.getStudioCardDefailtByType(productID);

            if (defSizes.data && defSizes.data.item_ids && Array.isArray(defSizes.data.item_ids) && defSizes.data.item_ids.length > 0) {
                const sizesBody = sizesRes.data[selectedType];
                const sizesMap = sizesBody.reduce((acc, size) => {
                    acc[size.ID] = size;
                    return acc;
                }, {});

                const combinedData = defSizes.data.item_ids.reduce((acc, item) => {
                    const size = sizesMap[item];
                    if (size) {
                        acc.push({
                            id: item,
                            name: `${size.CAT_TEXT}`,
                            width: `${size.width}`,
                            height: ` ${size.height}`,
                            price: `${size.price}`,
                        });
                    }
                    return acc;
                }, []);
                setData(combinedData);
                setLoading(false);
                return defSizes.data;
            } else {
                console.error('defSizes.data.item_ids is not an array or is empty');
            }

        } catch (error) {
            setLoading(false);
        }

    };

    useEffect(() => {
        console.log(selectedType)
        if (selectedType) {
            fetchSizesAndDetails();
        }
    }, [selectedType]);


    return (
        <Row justify="space-between">
            <Col span={24} className='mb10'>
                <Form.Item name="selectcard_type" label="Card Type" rules={[{required: true}]}>
                    <Select
                        className={style.selectedCard}
                        onChange={setSelectedType}
                        placeholder="Select Card Type"
                        value={selectedType}
                    >
                        {[
                            {
                                label: "Fold Over",
                                value: "Fold Over",
                            },
                            {
                                label: "Flat Cards",
                                value: "Flat Cards",
                            },
                            {
                                label: "Photo Cards",
                                value: "Photo Cards",
                            },
                        ].map((option) => (
                            <Select.Option key={option.value} value={option.value}
                                           className={style.selectedOption}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24} className='mb10'>
                <Form.Item name="selected_size" label="Card Size" rules={[{required: true}]}>
                    <Select
                      className={style.selectedCard}
                      onChange={handleItemSelect}
                      placeholder="Search your card size"
                      value={selectedItem}
                    >
                        {loading && selectedType ? (
                          <Select.Option className={style.selectedOption} key="loading" value="loading">
                              <Spin/>
                          </Select.Option>
                        ) : (
                          data.map((item) => (
                            <Select.Option key={item.id} value={item.name} className={style.selectedOption}>
                                {item.name}
                            </Select.Option>
                          ))
                        )}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
};
export default SelectCardPackage;