import React, {useEffect, useState,useRef} from 'react';
import {Button, Radio, Input, DatePicker, Space, Divider, Row, Col, Select, Tour} from 'antd';
import dayjs from "dayjs";
import { MinusCircleOutlined } from '@ant-design/icons';
import styles from "./Contract.module.css"
import {useHelperTab} from "../../../../../context/HelperTabContext";


const PaymentSchedule = React.forwardRef((props,ref) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const { helperTab } = useHelperTab();
    const [open, setOpen] = useState(helperTab);
    useEffect(() => {
        if (typeof props.setHelperShow === 'function') {
            if (!open) { props.setHelperShow(false); }
        }
    }, [open]);

    useEffect(() => {
        if (props.helperShow) { setOpen(true);  }
    }, [props.helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    const ref6 = useRef(null);
    const ref7= useRef(null);
    useEffect(() => {
        props.setRefPrevious(ref6);
        props.setRefNext(ref7);
    }, [ref6,ref7,props]);
    const steps= [
        {
            title: 'Contract  Sub Total',
            description: 'The "Contract Sub Total" field is a key financial element in the contract creation and management process. It represents the total cost of all the services or products selected before any additional charges, discounts, or taxes are applied. This figure provides a clear and concise summary of the base cost associated with the contract',
            target: () => ref1.current,
        },{
            title: 'Choosing the Discount Type',
            description: 'You have two options for the type of discount:\n' +
                '\n' +
                'Percentage (%) Discount: Select this option if you want to apply a discount that is a percentage of the total cost. For example, choosing 10% would mean the discount amount is 10% of the subtotal.\n' +
                'Fixed Dollar Amount ($) Discount: Choose this option to apply a specific dollar amount off the total cost. For instance, entering $10 would reduce the subtotal by a flat rate of 10 dollars',
            target: () => ref2.current,
        },{
            title: 'Tax 8.625%:',
            description: 'By checking this checkbox, you enable the system to automatically calculate and add a tax at the rate of 8.625% to the subtotal (after any discounts are applied). This ensures that the tax is accurately factored into the final cost.',
            target: () => ref3.current,
        },{
            title: 'Automate or Manual',
            description: 'This section provides two distinct methods for setting up payment schedules through the use of radio buttons labeled "Automate" and "Manual". These options cater to different needs and preferences for managing payment schedules',
            target: () => ref4.current,
        },{
            title: 'Add Automate ',
            description: 'Automated Payment Schedule:\n' +
                '\n' +
                'When you select the "Automate" radio button, the system takes over the task of creating a payment schedule for you.\n' +
                'Upon clicking the "Add Automate" button, it automatically calculates the total price, including any taxes or discounts, and then generates a payment schedule based on predefined criteria or rules.\n' +
                'This option is ideal for standard contracts or services where payment terms follow a regular pattern or percentage breakdown.\n' +
                'Manual Payment Schedule:\n' +
                '\n' +
                'Selecting the "Manual" radio button allows for personalized control over the payment schedule.\n' +
                'In this mode, you are required to manually input the payment amounts and their respective due dates.\n' +
                'This method provides flexibility and is suitable for custom or unique payment arrangements that don\'t fit a standard mold.\n' +
                'The manual option is particularly useful when dealing with special agreements, variable payment amounts, or irregular payment timelines.',
            target: () => ref5.current,
        },{
            title: 'Previous to Terms and Conditions',
            description: '',
            target: () => ref6.current,
        },
        {
            title: 'Next',
            description: 'See Result and Save Contract',
            target: () => ref7.current,
        },
    ];

    const contractTotal = localStorage.getItem('contract_total');
    const [subTotalPackages, setSubTotalPackages] = useState(contractTotal);
    const [payments, setPayments] = useState([]);
    const [paymentMode, setPaymentMode] = useState('automate');
    const [discountType, setDiscountType] = useState("$");
    const [discountValue, setDiscountValue] = useState('0.00');
    const [subTotal, setSubTotal] = useState(0);
    const [includeTax, setIncludeTax] = useState(false);

    const {Option} = Select;
    const dateFormat = "MM-DD-YYYY";
    const taxRate = 0.08625;
    const updatedTaxAmount = includeTax ? taxRate : 0;
    const taxAmount =subTotal * updatedTaxAmount;
    const totalWithTax = (subTotal + taxAmount);
    const paymentAmount = parseFloat(payments[0]?.amount) || 0;
    const isValidPaymentAmount = !isNaN(paymentAmount) && paymentAmount !== null;
    const balance = isValidPaymentAmount ? (totalWithTax - paymentAmount).toFixed(2) : totalWithTax.toFixed(2);


    const handleModeChange = e => {
        setPaymentMode(e.target.value);
    };


    const calculateTotalPayments = (paymentsArray) => {
        return paymentsArray.reduce((sum, payment) => sum + parseFloat(payment.amount || 0), 0);
    };

    const handlePaymentChange = (e, index) => {
        const newAmountStr = e.target.value;
        const regex = /^\d*\.?\d*$/;

        if (regex.test(newAmountStr)) {
            let updatedPayments = payments.map((payment, idx) => {
                if (idx === index) {
                    return {
                        ...payment,
                        amount: newAmountStr.trim() === '' || !regex.test(newAmountStr) ? 0 : newAmountStr
                    };
                }
                return payment;
            });

            if (calculateTotalPayments(updatedPayments) > contractTotal) {
                return;
            }

            setPayments(updatedPayments);
        }
    };
    const handleBlur = (index) => {
        let updatedPayments = payments.map((payment, idx) => {
            if (idx === index) {
                const formattedAmount = parseFloat(payment.amount).toFixed(2);
                return { ...payment, amount: formattedAmount };
            }
            return payment;
        });

        if (calculateTotalPayments(updatedPayments) > contractTotal) {
            return;
        }

        setPayments(updatedPayments);
        localStorage.setItem('paymentDeposits', JSON.stringify(updatedPayments));

    };


    const addPayment = () => {
        let updatedPayments = [];
        if (paymentMode === 'automate') {
            const newPaymentCount = payments.length + 1;
            const totalCents = Math.round(totalWithTax * 100); // Convert total to cents
            let remainingCents = totalCents;

            updatedPayments = Array.from({ length: newPaymentCount }, (_, idx) => {
                if (idx < payments.length && payments[idx].date) {
                    const existingPaymentCents = Math.round(payments[idx].amount * 100);
                    remainingCents -= existingPaymentCents;
                    return { amount: (existingPaymentCents / 100).toFixed(2), date: payments[idx].date };
                } else {
                    // Distribute the remaining amount
                    const equalShare = Math.floor(remainingCents / (newPaymentCount - idx));
                    remainingCents -= equalShare;
                    return { amount: (equalShare / 100).toFixed(2), date: '' };
                }
            });

            setPayments(updatedPayments);
            props.setStepFourData({ ...props.stepFourData, payments: updatedPayments });
        }else {
            const remainingAmount = (subTotalPackages - calculateTotalPayments(payments)).toFixed(2);
            let updatedPayments = [...payments];
            if (remainingAmount > 0) {
                const newPayment = {
                    amount: 0,
                };
                updatedPayments = [...payments, newPayment];

            }
            setPayments(updatedPayments);
            props.setStepFourData({ ...props.stepFourData, payments: updatedPayments });
        }
    };

    const removePayment = (index) => {
        if (paymentMode === 'automate') {
            const updatedPayments = payments.filter((_, idx) => idx !== index);
            const newPaymentAmount = totalWithTax / updatedPayments.length;
            const recalculatedPayments = updatedPayments.map(payment => ({
                ...payment,
                amount: newPaymentAmount.toFixed(2)
            }));
            setPayments(recalculatedPayments);
            props.setStepFourData({ ...props.stepFourData, payments: recalculatedPayments });
        } else {
            const updatedPayments = payments.filter((_, idx) => idx !== index);
            setPayments(updatedPayments);
            props.setStepFourData({ ...props.stepFourData, payments: updatedPayments });
        }
    };

    useEffect(() => {
        if (subTotalPackages !== null) {
            let numberValue = parseFloat(subTotalPackages).toFixed(2);
            setSubTotalPackages(numberValue);
        }

        const storedPayments = JSON.parse(localStorage.getItem('paymentPackagesData'));
        if (storedPayments) {
            try {
                // setSubTotalPackages(storedPayments.subTotalPackages || '0.00')
                setPayments(storedPayments.payments || []);
                setDiscountType(storedPayments.discountType || "$");
                setDiscountValue(storedPayments.discountValue || '0.00');
                setIncludeTax(storedPayments.includeTax || false);
            } catch (error) {
                console.error('Error parsing stored payments data:', error);
            }
        }
    }, []);

    const handleDateChange = (dates, dateStrings, index) => {
        const updatedPayments = payments.map((payment, idx) => {
            if (idx === index) {
                return {
                    ...payment,
                    date:  dayjs(dateStrings, dateFormat).format(),
                };
            }
            return payment;
        });

        setPayments(updatedPayments);
    };


    const handleDiscountBlur = () => {
        const formattedValue = parseFloat(discountValue).toFixed(2);
        setDiscountValue(formattedValue);
    };
    const handleSubTotalBlur = () => {
        const formattedValue = parseFloat(subTotalPackages).toFixed(2);
        setSubTotalPackages(formattedValue);
    };
    const handleDiscountValueChange = (value) => {
        const numericValue =parseFloat(value) || 0.00;
        if (!isNaN(numericValue)) {
            setDiscountValue(numericValue);
            let subTotalValue = subTotalPackages - (discountType ? numericValue : (subTotalPackages * (numericValue / 100)));
            setSubTotal(subTotalValue);
        } else {
            console.error("Invalid discount value:", value);
        }
    };

    const handleDiscountTypeChange = (value) => {
        setDiscountType(value);
    };




    useEffect(() => {
            let effectiveDiscountValue;
            if (discountType==="$") {
                effectiveDiscountValue = discountValue;
            } else {
                effectiveDiscountValue = (subTotalPackages * (discountValue / 100));
            }

            const subTotalValue = subTotalPackages - effectiveDiscountValue;

            setSubTotal(subTotalValue);

    }, [ discountValue, discountType]);


    const handlePackageSubTotalChange = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            let subTotalValue = newValue - (discountType ? discountValue : (newValue * (discountValue / 100)));
            setSubTotal(subTotalValue);
            setSubTotalPackages(newValue);
        }
    };


    useEffect(() => {
        const paymentPackagesData = {
            subTotalPackages,
            discountType,
            discountValue,
            subTotal,
            taxAmount,
            includeTax,
            totalWithTax,
            balance,
            payments
        };
        localStorage.setItem('paymentPackagesData', JSON.stringify(paymentPackagesData));
    }, [subTotalPackages, discountType, discountValue, subTotal, taxAmount,includeTax, totalWithTax, balance, payments]);


    return (
      <div className={styles.PaymentSchedule} ref={ref}>
      <Row  justify='space-around'>
          <Col span={24} className='mt-4'>
              <Row justify='space-around'>
                  <Col sm={8} xs={24}>
                      <h2 >Contract Payment</h2>
                  </Col>
                  <Col sm={13} xs={24} className='mt-2' >
                      <Radio.Group defaultValue="automate" onChange={handleModeChange} ref={ref4}>
                          <Radio value="automate">Automate</Radio>
                          <Radio value="manual">Manual</Radio>
                      </Radio.Group>
                  </Col>
                  <Divider />
              </Row>
          </Col>
          <Col lg={8} span={22}>
              <Space direction="vertical"  className={styles.textSpace}>
                  <Row justify='space-between' ref={ref1}>
                      <Col span={11}><strong className={styles.itemStrong}>Contract  Sub Total</strong></Col>
                      <Col span={11} className={styles.itemP} >
                          <Input
                            className={styles.itemP}
                            type="number"
                            value={subTotalPackages }
                            onChange={handlePackageSubTotalChange}
                            onBlur={handleSubTotalBlur}
                          />
                      </Col>
                  </Row>
                  <Row justify='space-between' ref={ref2}>
                      <Col span={11}>
                          <strong className={styles.itemStrong}>Discount
                              <Select
                                style={{marginLeft:"8px"}}
                                value={discountType}
                                onChange={handleDiscountTypeChange}
                              >
                                  <Option value={"$"}>$</Option>
                                  <Option value={"%"}>%</Option>
                              </Select>
                          </strong>
                      </Col>
                      <Col span={11}>
                          <Input
                            className={styles.itemP}
                            value={discountValue}
                            onChange={(e) => handleDiscountValueChange( e.target.value)}
                            onBlur={handleDiscountBlur}
                          />
                      </Col>
                  </Row>
                  <div className={styles.pdfPackage}>
                      <strong className={styles.itemStrong}>Discount in $</strong>
                      <p className={styles.itemD}>$ {(subTotal - subTotalPackages).toFixed(2)}</p>
                  </div>
                  <div className={styles.pdfPackage}>
                      <strong className={styles.itemStrong}>Sub Total</strong>
                      <p className={styles.itemP}>$ {subTotal.toFixed(2)}</p>
                  </div>

                  <div className={styles.pdfPackage} ref={ref3}>
                      <strong className={styles.itemStrong}>Tax 8.625%:
                          <input
                            style={{marginLeft:'20px'}}
                            type="checkbox"
                            checked={includeTax}
                            onChange={() => setIncludeTax(!includeTax)}
                          />
                      </strong>
                      <p className={styles.itemP}>
                          $ {taxAmount.toFixed(2)}
                      </p>
                  </div>
                  <div className={styles.pdfList}>
                      <strong className={styles.itemStrong}>Total </strong>
                      <p className={styles.itemP}>$ {totalWithTax.toFixed(2)}</p>
                  </div>
              </Space>
          </Col>
          <Col lg={13} span={22}>
              <Row  justify='center'>
                    <Col span={24}>
                      <div>Total amount: {totalWithTax.toFixed(2)}</div>
                    </Col>
                    <Col lg={14} span={20} className='mt-4' >
                      {payments.map((payment, index) => (
                        <Space key={index} style={{ marginBottom: '10px' }}>
                            <Input
                              prefix={`${index + 1}${index === 0 ? 'st' : index === 1 ? 'nd' : 'rd'} Payment: $`}
                              value={payment.amount}
                              readOnly={paymentMode === 'automate'}
                              onChange={paymentMode === 'manual' ? (e) => handlePaymentChange(e, index) : undefined}
                              onBlur={() => handleBlur(index)}
                            />
                            <DatePicker
                              format={dateFormat}
                              value={payment.date ? dayjs(payment.date) : null}
                              onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings, index)}
                            />
                            <MinusCircleOutlined className={styles.DepositSvg} onClick={() => removePayment(index)} />
                        </Space>
                      ))}

                    </Col>
                    <Col span={24} ref={ref5}>
                        <Button type="dashed" onClick={addPayment} className='mt-4'  ref={ref5}>
                            + Add Deposit Automate
                        </Button>
                    </Col>
                 </Row>
          </Col>
          <Col span={24} className='mt-4 mb-4'>
            <Row justify='space-around'>
                <Divider />
                <Col md={8} sm={13} xs={20}>
                    <div className={styles.pdfBalance}>
                        <strong className={styles.itemBalance}>Balance</strong>
                        <b className={styles.itemP}> $ {balance} </b>
                    </div>
                </Col>
                <Col md={13} sm={8} xs={24}>
                    <div>It remains to pay: {(totalWithTax.toFixed(2) - payments.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0)).toFixed(2)}</div>
                </Col>
            </Row>
        </Col>
      </Row>
          {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
      </div>
    );
});

export default PaymentSchedule;