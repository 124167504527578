import React from 'react';
import AddCardsStudio from "../AddCardsStudio";

const AddFlatCardsStudio = ({setHelperShow,helperShow}) => {

  const nameCards = "Flat Cards";
  const navigate_link = '/Studio/flat_cards_list';
  const mainCardType = 1;

  return (
    <div>
      <AddCardsStudio nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} setHelperShow={setHelperShow} helperShow={helperShow}/>
    </div>
  );
};



export default AddFlatCardsStudio;


