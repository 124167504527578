import React from 'react';
import css from './ProductType.module.css';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from "antd";

export const ProductType = ({ setProductType,productType }) => {
    const navigate = useNavigate();
    const handleClickPrint = () => {
        setProductType('Print');
        navigate('/prints');
    };
    const handleClickCard = () => {
        setProductType('Card');
        navigate('/carddesign');
    };
    const handleClickAlbum = () => {
        setProductType('Album');
        navigate('/albumdesign');
    };

    const selectedProducts = [
        { name: 'Print', onClick: handleClickPrint, image: 'https://th.bing.com/th/id/R.d3929cf6a8bf405dca32b52d75ae7aaa?rik=wGdVDAmznAW46g&pid=ImgRaw&r=0&sres=1&sresct=1' },
        { name: 'Card', onClick: handleClickCard, image: 'https://th.bing.com/th/id/OIP.Q27osNFNwACOeWc98ypOcgHaE8?w=1140&h=761&rs=1&pid=ImgDetMain' },
        { name: 'Album', onClick: handleClickAlbum, image: 'https://th.bing.com/th/id/R.bc0eb3e28ebb1224706ecf5b41e7e05d?rik=itT0VodWgvS1Ng&pid=ImgRaw&r=0' },
        { name: 'Unit', image: 'https://th.bing.com/th/id/OIP.ZFFM01wPfmjnXHHPsgib-wHaHa?rs=1&pid=ImgDetMain' },
    ];

    return (
      <Row className={css.selectTypeDiv} justify='space-between'>
          {selectedProducts.map((product, index) => (
            <Col
              key={index}
              xs={5}
              sm={5}
              md={productType === 'Card' ? 5 : 11}
              lg={productType === 'Card' ? 5 : 11}
              xl={11}
              className={css.selectedImage_selectType}
              style={{ backgroundImage: `url(${product.image})` }}
              onClick={product.onClick}
            >
                <p>{product.name}</p>
            </Col>
          ))}
      </Row>
    );
};
