const textForTemplate =[
  {
    id: 1,
    text:`Thank you for sharing in this joyous occasion. We are so grateful for your warm wishes and your generous gift.
      
         Love,
         Adam & Deanne
      `
  },

  {
    id:2,
    text:` We sincerely thank you for your love and support.   We could not be more blessed having friends and   family like you in our lives and sharing in this  beautiful time in our lives.
  
        Love, 
        Danielle & John
      `
  },
  {
    id:3,
    text:` From the bottom of our hearts we would  like to thank you for celebrating our  special day with us.  From the Food, Booze,  and the Bad Dance Moves, we hope you enjoyed the night!  Thank you for creating memories with us that will last a lifetime!
  
        Love,
        Veronica & Vincent
      `
  },
  {
    id:4,
    text:`Our wedding would not have  been complete without the   support and love of our family and friends.  We sincerely thank you for celebrating our joy and for being part of our lives!
   
         Love, 
         Pete & Sam
      `
  },
  {
    id: 5,
    text: `Our wedding would not have been complete without the support of our family and friends. Thank you for sharing our day.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 6,
    text: `Thank you for joining us  as we began our new life together. Your generosity and thoughtfulness were very much appreciated.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 7,
    text: `Thank you for adding to The joyful spirit of our wedding  With your presence  And lovely gift.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 8,
    text: `One thank you for the gift, and another for the thought. A dozen thank yous couldn’t tell you all the happiness they brought.  
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 9,
    text: `You brought joy to our day. Thank you for your thoughtful gift!
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 10,
    text: `Just a note to thank you and a line to tell you, too, nothing was more welcomed than the lovely gift from you.
    
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 11,
    text: `Words cannot express the joy that we feel remembering that you shared the beginning of our new life together.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 12,
    text: `Our wedding would not have been complete without the support and love of our family  and friends. We sincerely thank you  for celebrating our joy and for being a part of our lives!
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 13,
    text: `With sincere appreciation, we both send thanks for  your lovely gift and your thoughtful wishes.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 14,
    text: `We sincerely thank you for your love and support. We couldn’t be more blessed having friends and family like you in our lives and sharing in this beautiful time in our lives.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 15,
    text: `A loving note can barely express how you have added to our happiness. We would like to send our sincerest thanks for your thoughts, your prayers, your wonderful gifts & your presence in our lives.
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 16,
    text: `A grateful note we send to you, for your thoughtful gift and wishes too. Thank you for being there and for your support as we begin married life together. We love you very much!
      
          Love,
      Mr. And Mrs.
    `
  },
  {
    id: 17,
    text: `Mere words cannot express enough, the thanks we wish to say for sharing with us all the joy experienced on our wedding day. A perfect day because it was spent with those who really care, a thousand thank you’s for your gift and being there. 
      
         Love,
      Mr. And Mrs.
    `
  },
  {
    id: 18,
    text: `Being a part of our wedding celebration, embracing our joy, supporting us and the wonderful gift you gave means so much to us. With all our love, we thank you!
    
        Love,
      Mr. And Mrs.
    `
  },
  {
    id: 19,
    text: `Whether you were with us on our wedding day or in spirit with our heart, we felt so much love from all of you. Thank you for your generous thoughts and wishes, words and gifts as we became 
      
      Love,
      Mr. And Mrs.
    `
  },
];

export default  textForTemplate