import {
  CameraOutlined,
  ReadOutlined,
  HomeOutlined,
  PictureOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";

export const phItems = [
  {
    key: 'gallery_list',
    icon: <HomeOutlined />,
    label: 'Home',
  }, {
    key: 'studio_ph',
    icon: <CameraOutlined />,
    label: 'Photographers',
    children: [
      {
        key: 'add_ph',
        label: 'Add',
        icon: <PlusCircleOutlined />,
    },
      {
        key: 'list_ph',
        label: 'List',
        icon: <UnorderedListOutlined />,
      },
    ]
  },
  {
      key: 'album_list',
      icon: <ReadOutlined />,
      label: 'Albums',
  },
  {
      key: 'card_list',
      icon: <PictureOutlined />,
      label: 'Cards',
  },
]