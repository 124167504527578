import React, {useEffect, useState} from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {Button, Col, Form, Row, Space, Table} from "antd";
import CardPreview from "../CardPreview";
import Quantity from "../../Utils/Quantity";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import ImageRenderer from "../../Utils/ImageRenderer";
import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
import styles from "../../Studios/ClientSidebar/Contract/ContractAdd/Contract.module.css";
import Order from "./Order";
const GeneratePDFButton = ({props,orderNumber}) => {
    console.log(props,'propsdetal')

    const [data, setData] = useState([]);
    const [shoppingCartInfo, setShoppingCartInfo] = useState({
        user_id: localStorage.getItem('user_id'),
        user_type: localStorage.getItem('user_type'),
        current_user_id: localStorage.getItem('current_user_id'),
        event_id: localStorage.getItem('event_id'),
        client_id: localStorage.getItem('client_id'),
        studio_id: localStorage.getItem('studio_id'),
        event_name: localStorage.getItem('eventName'),
    });
    const generatePDF = async () => {
        const inputElement = document.getElementById('element-to-capture');
        const canvas = await html2canvas(inputElement, {
            useCORS: true,
            allowTaint: false,
            scale: 2, // Increase scale for better resolution
            scrollY: -window.scrollY,
            windowWidth: inputElement.offsetWidth,
            windowHeight: inputElement.offsetHeight
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'pt',
            format: 'a4'
        });

        // Calculate the aspect ratio of the canvas to fit into the PDF
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('download.pdf');
    };



    const [getShoppingCart, cartGetLoading, cartGetError] = useFetching(async (shoppingCardInfo) => {
        try {
            if(shoppingCardInfo){
                const { data: res } = await clientAPI.getShoppingCartOrdered(shoppingCardInfo);
                if(res.data.shopping_cart){
                    const fetchedData = JSON.parse(res.data.shopping_cart);
                    setData(fetchedData);
                }
            }
        } catch (error) {
            console.error(error);
        }
    });


    useEffect(() => {
        getShoppingCart(shoppingCartInfo)
    }, []);




    return (
        <>
            <div id="element-to-capture" style={{ width: '210mm', padding: '10mm', marginLeft: 'auto', marginRight: 'auto' }}>
                <Row justify="center" style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <div className={'text-center'}>
                            <h3 className={'text-uppercase mb-4 header-title'}>{orderNumber}</h3>
                        </div>
                            {props.checkout &&
                                <Space direction="vertical" className='textSpace'>
                                    <Col><span>{props.shippingAddress.firstName} {props.shippingAddress.firstName}</span></Col>
                                    <Col> <span>{props.shippingAddress.address1}, {props.shippingAddress.appNumber}</span></Col>
                                    <Col> <span> {props.shippingAddress.city}, {props.shippingAddress.state}</span></Col>
                                    <Col> <span>{props.shippingAddress.zipCode}, {props.shippingAddress.county}</span></Col>
                                    <Col> <span> Phone: {props.shippingAddress.phone}</span></Col>
                                    <Col>
                                        Email:{props.shippingAddress.email && props.shippingAddress.email.map((email, emailIndex) => (
                                        <span key={emailIndex}> {email},</span>
                                    ))}
                                    </Col>
                                    <hr/>
                                    <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Sub Total: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.subtotal}</p>
                                    </div>
                                    <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Tax 8.625%: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.tax}</p>
                                    </div>
                                    <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Shipping and Handling: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.shippingHandling}</p>
                                    </div>
                                    {props.checkout.couponValue> 0 && <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Coupon pay: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.couponValue}</p>
                                    </div>
                                    }
                                    {props.orderDataForCreditCard.productBalance> 0 && <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Coupon pay: </strong>
                                        <p className={styles.itemP}>$ {props.orderDataForCreditCard.productBalance/100}</p>
                                    </div>
                                    }
                                    <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Applied credit: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.creditUsed}</p>
                                    </div>
                                    <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Total: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.total}</p>
                                    </div>

                                    <div className={styles.pdfPackage}>
                                        <strong className={styles.itemStrong}>Balance: </strong>
                                        <p className={styles.itemP}>$ {props.checkout.balance || 0}</p>
                                    </div>
                                </Space>
                            }
                    </Col>
                </Row>
        </div>
            <Order  props={props}></Order>
            <Button onClick={()=> generatePDF()} type="primary" size="default">
                Generate PDF
            </Button>
        </>
    );
};

export default GeneratePDFButton;