import React, {useEffect, useState} from "react";
import {createDefaultImageReader, createDefaultImageWriter, processImage} from "@pqina/pintura";

const ImageRenderer = ({ image, record }) => {
    const [processedImageSrc, setProcessedImageSrc] = useState(null);
    useEffect(() => {
        const process = async () => {
            if (record.processedData?.crop) {
                try {
                    const res = await processImage(record.original_image, {
                        imageReader: createDefaultImageReader(),
                        imageWriter: createDefaultImageWriter(),
                        imageCrop: record.processedData.crop,
                    });

                    if (res) {
                        setProcessedImageSrc(URL.createObjectURL(res.dest));
                    }
                } catch (error) {
                    setProcessedImageSrc(record.original_image);
                }
            } else {
                setProcessedImageSrc(record.original_image);
            }
        };
        process();
    }, [image, record]);

    const baseSize = 100;
    const aspectRatio = record.w / record.h;

    let style = {};
    if (!record.switcher) {
        // Maintain aspect ratio for both dimensions, scaling based on the orientation
        if (aspectRatio >= 1) {
            // Landscape or square: Set width to baseSize, scale height
            style = { width: `${baseSize}px`, height: `${baseSize / aspectRatio}px` };
        } else {
            // Portrait: Set height to baseSize, scale width
            style = { height: `${baseSize}px`, width: `${baseSize * aspectRatio}px` };
        }
    } else {
        style = { height: `${baseSize}px` }; // If switcher is true, keep height fixed (you might want to adjust this logic)
    }

    return (
        <div className='imgCart'>
            <div className="forBg"
                 style={{
                     backgroundImage: record.selectedBg ? `url(${record.selectedBg})` : 'none',
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                 }}
            >
                <img src={processedImageSrc} style={style} alt="Product Image" />
            </div>
        </div>
    );
};

export default ImageRenderer;