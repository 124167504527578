import React, { useEffect, useState, useContext,useRef } from 'react';
import './SelectSize.css';
import {Col, Row, message, Checkbox, Menu, Space, Button} from 'antd';
import { CartContext } from '../../../../context/CartContext';
import clientAPI from "../../../../api/api";
import LoadingSpin from "../../../Utils/LoadingSpin";
import AddButton from "../../AddButton/AddButton";

const SelectSize = ({
                      updateCartData,
                      currentSize,
                      setCurrentSize,
                      processedData,
                      printImage,
                      quantity,
                      setQuantity,
                      isEditorCartOpen,
                      isSwitchChecked,
                      imageID,
                      isAddedToCart,
                      setIsAddedToCart,
                      productType,
                      selectedBg,
                      ref1,
                      ref4,
                      updateStoredMessages,
                      textValues
                    }) => {


  const [sizes, setSizes] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(false);
  const { addToCart} = useContext(CartContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [userSelectedSize, setUserSelectedSize] = useState(false);
  const [checkedValues, setCheckedValues] = useState([])
  const [retouchPrice, setRetouchPrice] = useState(0)
  const [retouch, setRetouch] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const visibleSizeCount = sizes.length;

  const handleSizeClick = (size) => {
    setCurrentSize(size);
    setUserSelectedSize(true);
  };

  const key = 'updatable';

  const openMessage = () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Adding to cart...',
      style: { marginTop: '20vh', },
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: 'success',
        content: 'Added to cart!',
        duration: 2,
        style: { marginTop: '20vh', },
      });
      setIsAddedToCart(false);
      setQuantity(1);
    }, 1000);
  };

  const handleAddItem = () => {
    const newItem = {
      productType: 'Print',
      image:printImage,
      imageID:imageID,
      selectedBg,
      original_image:printImage,
      currentSize: {
        ...currentSize,
        width: currentSize.width,
        height: currentSize.height,
      },
      price: subPrice,
      processedData,
      quantity,
      switcher:isSwitchChecked,
      textValues,
      retouch,
    };

    setSelectedIcon(true);
    setIsAddedToCart(true);
    setCheckedValues([]);
    setRetouch([]);
    addToCart(newItem);
    openMessage();
    localStorage.removeItem('ImagesForOrder');
    updateStoredMessages();
  };

  const fetchSizesAndDetails = async () => {
    try {
        let headerRes2;
        const {data: sizesRes} = await clientAPI.getProductObjectSizes(5, 'SIZE');
        const studio_mode = localStorage.getItem('studio_mode');
        if(studio_mode !== 'true'){
            headerRes2 = await clientAPI.getPrintPriceStudioDefault();
        }else{
            headerRes2 = await clientAPI.getPrintPriceLabDefault();
        }
      if (sizesRes &&  headerRes2.data) {
        const  detailsRes2 = await clientAPI.getPrintPriceDetails(headerRes2.data.id);
        if (detailsRes2.data && headerRes2) {
          setLoading(false);
          const sizesBody = sizesRes.Print;
           setSizes([...sizesBody]);

          const nonEmptyDetails2 = detailsRes2.data.filter((detail) => detail.price);
          const combinedData = nonEmptyDetails2.map((detail2) => {

            const size = sizesBody.find((size) => size.ID === detail2.item_id);
            return {
              id: detail2.item_id,
              width:size.width,
              height:size.height,
              price: parseFloat(detail2.price).toFixed(2),
            };
          });

          setSizes(combinedData);
          return detailsRes2.data;
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching sizes and details', error);
    }
  };

  useEffect(() => {
    fetchSizesAndDetails()
  }, [productType]);

  const getSizeString = (size) => {
    if (isSwitchChecked) {
      return `${size.height} x ${size.width}`;
    } else {
      return `${size.width} x ${size.height}`;
    }
  };

  useEffect(() => {
    if (currentSize && currentSize?.width && currentSize?.height){
      setCurrentSize(currentSize)
    } else if (sizes && sizes.length > 0 && !userSelectedSize) {
      setCurrentSize(sizes[0]);
    }
  }, [sizes, userSelectedSize]);

  if (loading) {
    return (
        <LoadingSpin>
          <div>Loading...</div>
        </LoadingSpin>
    );
  }

  const options = [
    {id: 1, typeRetouch: 'Retouch',nameRetouch: 'Remove pimples', priceRetouch: 0.3 },
    {id: 2, typeRetouch: 'Retouch',nameRetouch: 'Remove under eye bags', priceRetouch: 0.5 },
    {id: 3, typeRetouch: 'Retouch',nameRetouch: 'Remove small defects', priceRetouch: 0.7 },
    {id: 4, typeRetouch: 'Retouch',nameRetouch: 'Color correction', priceRetouch: 1 },
    {id: 5, typeRetouch: 'Retouch',nameRetouch: 'Skin smoothing', priceRetouch: 0.8 },
    {id: 6, typeRetouch: 'Retouch',nameRetouch: 'Teeth whitening', priceRetouch: 0.6 },
    {id: 7, typeRetouch: 'Retouch',nameRetouch: 'Red eye removal', priceRetouch: 0.4 },
    {id: 8, typeRetouch: 'Retouch',nameRetouch: 'Blemish removal', priceRetouch: 0.5 },
    {id: 9, typeRetouch: 'Retouch',nameRetouch: 'Wrinkle reduction', priceRetouch: 0.7 },
    {id: 10, typeRetouch: 'Retouch',nameRetouch: 'Other', priceRetouch: 1 },

  ];

  const onChangeChecked = checkedValues => {
    setCheckedValues(checkedValues)
    const retouchPrice = checkedValues.reduce((total, option) => {
      const selectedOption = options.find(item => item.id === option);
      return total + selectedOption.priceRetouch;
    }, 0);
    const retouch = checkedValues.map(( option) => {
      const selectedOption = options.find(item => item.id === option);
      return {
        id: selectedOption.id,
        typeRetouch: selectedOption.typeRetouch,
        nameRetouch: selectedOption.nameRetouch,
        priceRetouch: selectedOption.priceRetouch,
      };
    });
    setRetouch(retouch);
    setRetouchPrice(retouchPrice)
  };
  const subPrice = parseFloat(parseFloat(currentSize.price) + parseFloat(retouchPrice)).toFixed(2);

  return (
      <div className="selectSizeContainer"  >
        {contextHolder}
        <div className="textDiv">
          <span className="text1"> {productType} Size <span style={{ color: 'gray', padding:'0 2px' }}> ( {getSizeString(currentSize)} ) </span></span>
          <span className="text1"> Price: $ {subPrice}</span>
        </div>
        <Row justify='center'>
          <Col xl={24} lg={14} md={14} sm={24}>
            <Row className="sizeContainer" ref={ref1}>
              {sizes.slice(0, visibleSizeCount).map((obj) => {
                return (
                  <Col className="flexCenterClass" key={obj.id} xl={12}  lg={8} md={8} xs={8}>
                    <div
                      key={obj.id}
                      className="size"
                      style={currentSize.id === obj.id ? { outline: 'solid 2px #223d8f' } : null}
                      onClick={() => handleSizeClick(obj)}
                    >
                      {getSizeString(obj)}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col xl={24} lg={10} md={10} sm={24} >
            <Row justify='center' className='mt-4'>
              <Button className='byBut mb-2' onClick={()=> setDropdownOpen(!dropdownOpen)}>Choose your Retouch</Button>
              <Col lg={24} md={24} span={24}>
                <Checkbox.Group
                  className={dropdownOpen ? "DropdownOpenBlock": "DropdownOpenNone"}
                  value={checkedValues}
                  onChange={onChangeChecked}
                  style={{ width: "100%",maxHeight: '200px', overflowY: 'auto', scrollbarWidth: 'thin' }}
                >
                  <Space  className='w100' direction="vertical">
                    {options.map(option => (
                      <div key={option.id}>
                        <Checkbox value={option.id}>{option.nameRetouch} -Price: $ {parseFloat(option.priceRetouch).toFixed(2)} </Checkbox>
                      </div>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Col>
            </Row>
            <span  ref={ref4}>
              <AddButton
                setQuantity={setQuantity}
                quantity={quantity}
                isEditorCartOpen={isEditorCartOpen}
                selectedIcon={selectedIcon}
                isAddedToCart={isAddedToCart}
                updateCartData={updateCartData}
                handleAddItem={handleAddItem}
              />
            </span>
          </Col>
        </Row>

      </div>
  );
};

export default SelectSize;
