import React, {useEffect, useState} from 'react';
import {Radio, Input, Form, Row, Col, Button} from 'antd';
import {useContractContext} from "../../../../context/ContactContext";

const Downloads = ({form}) => {
    const [inputType, setInputType] = useState('input');
    const [countOfImages, setCountOfImages] = useState('');
    const {formData, setFormData} = useContractContext();
    const [lastId, setLastId] = useState(5000);
    const handleRadioChange = (e) => {
        setInputType(e.target.value);
        if (e.target.value === 'all') {
            setCountOfImages('All'); // This sets the state to 0
            form.setFieldsValue({ countofimages: 0 }); // This updates the form value
        } else {
            setCountOfImages(e.target.value);
        }
    };

    const handleInputChange = (e) => {
        if (inputType === 'input') {
            setCountOfImages(e.target.value);
        } else {
            setCountOfImages('All');
            form.setFieldsValue({ inputType: 0});
            e.preventDefault();
        }
    };

    const handleAddClick = () => {
        let title_name = "Downloads"
        let newId = 1;
        let newItemId = lastId + 1;
        const existingIds = new Set(formData?.size_data?.map(item => item.item_id));
        while (existingIds.has(newItemId)) {
            newItemId++;
        }
        setLastId(newItemId);
        if (formData.size_data && Array.isArray(formData.size_data)) {
            newId = formData.size_data.length > 0 ? Math.max(...formData.size_data.map(item => item.id)) + 1 : 1;
        }
        const data = {
            id: newId,
            title_name: title_name,
            name: `number of images   "${countOfImages}" `,
            item_id: newItemId,
            quantity: 1,
            initialCost: 0,
            initialSellingPrice: 0,
            sellingPrice: 0,
            cost: "0",
            type: 0,
            NumberOfImages: countOfImages
        };
        const updatedSizeData = [...(formData.size_data || []), data];
        const updatedFormData = {...formData, size_data: updatedSizeData};
        setFormData(updatedFormData);
    };

    return (
        <div>
            <Row justify="space-between" className="mt10">
                <Col span={24}>
                    <Form.Item label="Choose Download Type:" name="inputType">
                        <Radio.Group onChange={handleRadioChange} value={inputType}>
                            <Radio value="input">Write a number</Radio>
                            <Radio value="all">All</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="space-between" className="mt10">
                <Col span={24}>
                    <Form.Item label="Number of download images:" name="countofimages">
                        <Input
                            type="number"
                            onChange={handleInputChange}
                            value={countOfImages}
                            disabled={inputType === 'all'}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Button type="primary" onClick={handleAddClick} className="mt10">
                Add
            </Button>
        </div>
    );
};

export default Downloads;
