import {
  UsergroupAddOutlined,
  ReadOutlined,
  HomeOutlined,
  PictureOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";

export const staffItems = [
  {
    key: 'gallery_list',
    icon: <HomeOutlined />,
    label: 'Home',
  }, {
    key: 'sub2',
    icon: <UsergroupAddOutlined />,
    label: 'Staff',
    children: [
      {
      key: 'staff_add',
      label: 'Add',
      icon: <PlusCircleOutlined />,
      },
      {
      key: 'staff_list',
      label: 'List',
      icon: <UnorderedListOutlined />,
      },
      ],
    },
  {
    key: 'album_list',
    icon: <ReadOutlined />,
    label: 'Albums',
  },
  {
    key: 'card_list',
    icon: <PictureOutlined />,
    label: 'Cards',
  },
]