import React, { useEffect } from "react";
import IsAuth from "../../hoc/IsAuth";
import Login from "../Login/Login";
import StudioDashboard from "../Studios/StudioDashboard";
import LabDashboard from "../Lab/LabDashboard";
import { Space, Spin } from "antd";
import ClientDashboard from "../Clients/ClientDashboard";
import StaffDashboard from "../Staff/StaffDashboard";
import PhDashboard from "../Photographers/PhDashboard";
import { useNavigate } from "react-router-dom";

const ADMIN = "ADMIN";
const LAB = "LAB";
const STUDIO = "STUDIO";
const CLIENT = "CLIENT";
const STAFF = "STAFF";
const PHOTOGRAPHERS = "PHOTOGRAPHERS";

const DashboardContainer = () => {
    const isUserAuthenticated = IsAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const rememberMeChecked = localStorage.getItem("remember_me") === "true";
        const user_type = localStorage.getItem("user_type");

        if (!isUserAuthenticated && !rememberMeChecked && !user_type) {
            navigate("/");
        }
    }, [isUserAuthenticated, navigate]);

    if (isUserAuthenticated) {
        const user_type = localStorage.getItem("user_type");

        switch (user_type) {
            case ADMIN:
                return <LabDashboard page="studilist" />;
            case LAB:
                return <LabDashboard page="studio_list" />;
            case STUDIO:
                return <StudioDashboard page="list_client" />;
            case CLIENT:
                return <ClientDashboard page="gallery_list" />;
            case STAFF:
                return <StaffDashboard page="staff_list" />;
            case PHOTOGRAPHERS:
                return <PhDashboard page="list_ph" />;
            default:
                return <Login />;
        }
    } else {
        return (
            <Space>
                <Spin tip="Loading" size="large">
                    <div className="content" />
                </Spin>
            </Space>
        );
    }
};

export default DashboardContainer;
