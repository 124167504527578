import {

    FileImageOutlined,
    HomeOutlined,
    CreditCardOutlined,
    PercentageOutlined,
    BellOutlined,
    UnlockOutlined,
    TeamOutlined,
    UserOutlined,
    QrcodeOutlined,
} from "@ant-design/icons";

export const studioItems = [
    {
        key: 'gallery_list',
        icon: <HomeOutlined />,
        label: 'Galleries',
    },
    {
        key: 'paymentAndCredits',
        icon: <CreditCardOutlined />,
        label: 'Payments & Credits',
        children: [
            {
                key: 'clientCredit',
                label: 'Client Credit',
            },
            {
                key: 'guestStudentCredit',
                label: 'Guest / Student credit',
            },

        ],
    },
    {
        key: 'discounts',
        icon: <PercentageOutlined />,
        label: 'Discounts',
        children: [
            {
                key: 'clientDiscount',
                label: 'Client discount',
            },
            {
                key: 'guestDiscount',
                label: 'Guest discount',
            },
        ],
    },
    {
        key: 'notifications',
        icon: <BellOutlined />,
        label: 'Notifications',
        children: [
            {
                key: 'album',
                label: 'Album',
            },
        ]
    },
    {
        key: 'permissions',
        icon: <UnlockOutlined />,
        label: 'Permissions',
        children: [
            {
                key: 'clientPackages',
                label: 'Client Packages',
            },
            {
                key: 'clientCards',
                label: 'Client Cards',
            },
            {
                key: 'clientOrder',
                label: 'Client Order',
            },
            {
                key: 'guestOrder',
                label: 'Guest Order',
            },
            {
                key: 'permissionsImage',
                label: 'Image',
            },
            {
                key: 'imageOptions',
                label: 'Image Options',
            },
        ]
    },
    {
        key: 'gallery',
        icon: <FileImageOutlined />,
        label: 'Gallery',
        children: [
            {
                key: 'gallerylist',
                label: 'Show Gallery',
            },
            {
                key: 'albums',
                label: 'Albums',
            },
            {
                key: 'cards',
                label: 'Cards',
            },
            {
                key: 'watermark',
                label: 'Watermark',
            },
            {
                key: 'yearBook',
                label: 'Year Book',
            },
            {
                key: 'backgrounds',
                label: 'Backgrounds',
            },
        ]
    },

    {
        key: 'other',
        icon: <TeamOutlined />,
        label: 'Other',
        children: [
            {
                key: 'defaultPriceList',
                label: 'Default Price List',
            },
            {
                key: 'clientCode',
                label: 'Client Code',
            },
            {
                key: 'defaultProduct',
                label: 'Default Product',
            },
            {
                key: 'guestLoginPage',
                label: 'Guest Login Page',
            },
            {
                key: 'imagesDownloadPrice',
                label: 'Images Download Price',
            },
            {
                key: 'shipping',
                label: 'Shipping',
            },
            {
                key: 'clientCoupon',
                label: 'Client Coupon',
            },
        ]
    },
    {
        key: 'clientInfo',
        icon: <UserOutlined />,
        label: 'Client Info',
        children: [
            {
                key: 'changePassword',
                label: 'Chane password',
            },
        ]
    },
    {
        key: 'qr',
        icon: <QrcodeOutlined />,
        label: 'QR',
        children: [
            {
                key: 'qrList',
                label: 'List',
            },
            {
                key: 'studentExcel',
                label: 'Student Exel',
            },
        ]
    },
];