import React,{useState} from 'react';

import { ShareAltOutlined } from '@ant-design/icons';


const ShareButton = ({ initialImageUrl }) => {
  const [imageUrl, setImageUrl] = useState(initialImageUrl)
  const sharePage = async () => {

    try {
      await navigator.share({
        title: 'Title of the page',
        text: 'Description of the page',
        url: imageUrl,
      });
    } catch (error) {
      console.error('Error sharing the page:', error);
    }
  };

  return (
      <ShareAltOutlined onClick={sharePage}/>

  );
};

export default ShareButton;
