import React, { useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import Container from "react-bootstrap/Container";

const AddAlbum = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [cardData, setCardData] = useState([]);

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      align:'center',

    },
    {
      title: 'Album from / to prices',
      dataIndex: 'type',
      key: 'type',
      align:'center',
      render: (_, record,index) => renderInputs(_, record, index),
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      align:'center',
      render: (_, record) => (
        <>
          {!record.isNew && (
            <Button onClick={() => handleAddRow(record.size, record.key)} style={{ marginBottom: '10px', marginRight: '10px' }}>
              +
            </Button>
          )}
          {record.isNew && !selectedRowKeys.includes(record.key) && (
            <Button onClick={() => handleDeleteRow(record.key)} style={{ marginBottom: '10px', marginRight: '10px' }}>
              -
            </Button>
          )}
        </>
      ),
    },
  ];


  const sizes = ['2.5 x 3.5', '3.5 x 4.5', '4 x 5','4 x 6','8 x 8 ', '10 x 10']
  const initialData = sizes.map((size, i) => ({
    key: i,
    size: size,
    type: [{ from: '', to: '', price: '', discountPrice: '' }],
  }));

  const [data, setData] = useState(initialData);

  const handleInputChange = (key, columnIndex, field, value) => {
    setData((prevData) =>
      prevData.map((row) => {
        if (row.key === key) {
          const updatedType = [...row.type];
          updatedType[columnIndex] = {
            ...updatedType[columnIndex],
            [field]: value,
          };

          return {
            ...row,
            type: updatedType,
          };
        }
        return row;
      })
    );
  };



  const renderInputs = (text, record, columnIndex) => (
    <Row>
      <Col span={6}>
        <Input
          value={record?.type[columnIndex]?.from || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'from', e.target.value)}
          placeholder="From"
          type="number"
          style={{ padding: '5px 0', width: '70%', textAlign: 'center' }}
        />
      </Col>
      <Col span={6}>
        <Input
          value={record?.type[columnIndex]?.to || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'to', e.target.value)}
          placeholder="To"
          type="number"
          style={{ padding: '5px 0',width: '70%',textAlign:'center' }}
        />
      </Col>
      <Col span={6}>
        <Input
          value={record?.type[columnIndex]?.price || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'price', e.target.value)}
          placeholder="Price"
          type="number"
          style={{ padding: '5px 0', width: '70%', textAlign:'center' }}
        />
      </Col>
      <Col span={6}>
        <Input
          value={record?.type[columnIndex]?.discountPrice || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'discountPrice', e.target.value)}
          placeholder="Discount Price"
          type="number"
          style={{ padding: '5px 0', width: '70%', textAlign:'center' }}
        />
      </Col>
    </Row>
  );


  const handleAddRow = (selectedSize, i) => {
    const newData = [...data];
    newData.splice(i + 1, 0, {
      key: i + 1,
      size: selectedSize,
      type: [{ from: '', to: '', price: '', discountPrice: '' }], // Initialize as an array containing an object
      isNew: true,
    });

    // Update the keys of all rows
    for (let j = 0; j < newData.length; j++) {
      newData[j].key = j;
    }

    setData(newData);
  };

  const handleDeleteRow = (rowKey) => {
    console.log('Deleting row with key:', rowKey);

    if (data.length <= 1) {
      return;
    }
    const newData = data.filter((item) => item.key !== rowKey);
    console.log('New data after deletion:', newData);
    setData(newData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

    },
  };

  const handleSubmit = () => {
    const cardData = data.map((item) => ({
      size: item.size,
      type: item.type.map((typeItem) => ({
        from: typeItem?.from || '',
        to: typeItem?.to || '',
        price: typeItem?.price || '',
        discountPrice: typeItem?.discountPrice || '',
      })),
    }));

    console.log(cardData, 'cardData');

    setCardData(cardData);
  };


  return (
    <div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.key}
      />
      <Container className={'text-center'}>
        <Button onClick={handleSubmit }type="primary" htmlType="submit"  className='send-email-btn' >
          Submit
        </Button>
      </Container>
    </div>
  );
};

export default AddAlbum;


