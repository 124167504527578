import React from 'react';
import '../ShoppingCart.css';
import {Col, Row, Table} from "antd";
import CardPreview from "../CardPreview";
import * as PropTypes from "prop-types";
import ImageRenderer from "../../Utils/ImageRenderer";
import {CheckCircleTwoTone} from "@ant-design/icons";



ImageRenderer.propTypes = {image: PropTypes.any};
const Order = ({props})=> {
  const columns = [
    {
      title: '',
      dataIndex: 'num',
      width: 2,
    },
    {
      title: 'Image',
      dataIndex: 'original_image_back',
      render: (_, record) => {
        if (record?.productType?.includes('Card') && record?.cardObject) {
          return <CardPreview cardObject={record.cardObject} />;
        }
        return <ImageRenderer image={record.original_image_back} record={record} />;
      },

      width: 200,
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (_, record) => (
        <div>
          {record.quantity}
        </div>
      ),
      width: 100,
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      render: (_, record) => (
        <Row>
          { record.productType ? (<Col span={24}>{`${record.productType} - ${record.size}`} </Col>): ''}
          { record.retouch && record.retouch.length>0 ? (<Col span={24}><CheckCircleTwoTone twoToneColor="#52c41a" />  Retouch </Col>): ''}
          { record.checkedValues ? (<Col span={24}>{`${record.checkedValues}`} </Col>): ''}
          { record.textValues ? (<Col span={24}>{`${record.textValues}`} </Col>): null}
          { record.selectCardType ? (<Col span={24}>{`${record.selectCardType}`} </Col>): ''}
          { record.verticalHorizontal ? (<Col span={24}>{`${record.verticalHorizontal}`} </Col>): ''}
        </Row>
      ),
      width: 200,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: 50,
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      width: 100,
    },
  ];
  const data = props.cartData.map((item, index) => {
    let itemPrice;
    if (item.price) {
    itemPrice = parseFloat(item.price);
  } else if (item.currentSize && item.currentSize.price) {
    itemPrice = parseFloat(item.currentSize.price);
  } else if (item.templateForm && item.templateForm.price) {
    itemPrice = parseFloat(item.templateForm.price);
  } else {
    itemPrice = 0; // Default to 0 if none of the above conditions are met
  }
    return {
      num: index + 1,
      key: index,
      image: item.image ? item.image : item.printImage,
      original_image: item.original_image,
      processedData: item.processedData ? item.processedData : '',
      selectedBg: item.selectedBg,
      quantity: item.quantity,
      productType: item.productType,
      size: `${item.currentSize?.width || ''} x ${item.currentSize?.height || ''}`,
      currentSize: {
        width: `${item.currentSize?.width || ''}`,
        height: `${item.currentSize?.height || ''}`,
      },
      price: itemPrice, // Use the determined itemPrice
      totalPrice: (itemPrice * item.quantity).toFixed(2),
      retouch: item.retouch ? item.retouch : '',
      textValues: item.textValues ? item.textValues : '',
      checkedValues: item.checkedValues ? item.checkedValues : '',
      switcher: item.switcher,
      selectCardType: item.templateForm ? item.templateForm.product_type : '',
      verticalHorizontal: item.templateForm ? item.templateForm.template_pos : '',
      cardObject: item.cardObject,
    };
  });
    return (
      <>
        <h3 className={'text-uppercase mb-4 header-title'}>Order Information </h3>
            <Table className='scrollableDiv'
                   dataSource={data}
                   columns={columns}
                   scroll={{x: 100}}
                   pagination={false}
                   style={{ height: '80%' }}
            />
      </>
    )
  }


export default Order;