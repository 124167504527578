import React, { useState, useEffect, useRef } from 'react';
import {  Col, Row, Button } from 'antd';
import 'react-18-image-lightbox/style.css';
import {useNavigate} from "react-router-dom";
import AlbumSidebar from "./AlbumSidebar";
import AlbumSidebarHeader from "./AlbumSidebarHeader";
import MyBook from "./FlipBook";
import ScrollableContainer from "./ScrollableContainer";
const Album = () => {

    const [selectedCover, setSelectedCover] = useState('https://localhost:3000/static/media/08-CA8901.1ed3041bbb5d4f297baf.jpg');
    const [selectedAlbumCoverType, setSelectedAlbumCoverType] = useState("Full Material");
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isCoverMaterialSidebarOpen, setCoverMaterialSidebarOpen] = useState(false);
    const [isCameoOpen, setCameoOpen] = useState(false);
    const [isStampingOpen, setIsStampingOpen] = useState(false);
    const [isStampingUVOpen , setIsStampingUVOpen] = useState(false);
    const [showCameo, setShowCameo] = useState(null);
    const [activePage, setActivePage] = useState(0);
    const [lastPage, setLastPage] = useState(false);
    const [textProperties, setTextProperties] = useState({
        position: { top: "50%", left: "50%", type:'CENTER'},
        color: "#000",
        fontSize: "30px",
        fontFamily: "",
        content: "Text here",
    });

    const pages = [
        { label: 'Cover', imageSrc: selectedCover },
        { label: 'Endleaf', imageSrc: 'https://cdn.dragdrop.design/album/assets/endleaf/white_textured_endleaves.jpg' },
        { label: 'Cover', imageSrc: selectedCover },
        { label: 'Cover', imageSrc: selectedCover },
        { label: 'Cover', imageSrc: selectedCover },
        { label: 'Cover', imageSrc: selectedCover },

        // ... Add as many pages as you need
    ];


    const navigate = useNavigate();
    const goGalleryPage = () => {
        navigate('/client/gallery_list');
        // window.location.reload()
    };

        return (
            <>
                <Row>

                    <Col className="album-sidebar-header album-header-bg" lg={20} md={20} span={20}>
                        Header
                    </Col>

                    <Col lg={20} md={20} span={20}>
                        <ScrollableContainer pages={pages} />
                    </Col>


                    <Col className="album-sidebar-header" lg={4} md={4} span={4}>
                        <AlbumSidebarHeader/>
                    </Col>
                    <Col lg={20} md={20} span={20}>
                        <MyBook
                            selectedCover={selectedCover}
                            showCameo = {showCameo}
                            textProperties = {textProperties}
                            setTextProperties = {setTextProperties}
                            activePage = {activePage}
                            setActivePage = {setActivePage}
                            lastPage = {lastPage}
                            setLastPage = {setLastPage}
                        />
                    </Col>

                    <Col className="album-sidebar" lg={4} md={4} span={4}>
                        {activePage === 0 ? (
                            <AlbumSidebar
                                selectedCover={selectedCover}
                                setSelectedCover={setSelectedCover}
                                selectedAlbumCoverType={selectedAlbumCoverType}
                                setSelectedAlbumCoverType={setSelectedAlbumCoverType}
                                setSidebarOpen={setSidebarOpen}
                                setCoverMaterialSidebarOpen={setCoverMaterialSidebarOpen}
                                isSidebarOpen={isSidebarOpen}
                                isCoverMaterialSidebarOpen={isCoverMaterialSidebarOpen}
                                isCameoOpen={isCameoOpen}
                                setCameoOpen={setCameoOpen}
                                showCameo = {showCameo}
                                setShowCameo = {setShowCameo}
                                textProperties = {textProperties}
                                setTextProperties = {setTextProperties}
                                isStampingOpen = {isStampingOpen}
                                setIsStampingOpen = {setIsStampingOpen}
                                isStampingUVOpen ={isStampingUVOpen}
                                setIsStampingUVOpen = {setIsStampingUVOpen}
                                activePage = {activePage}
                                setActivePage = {setActivePage}
                            />
                        ) : activePage === 1 || activePage === 2 ? (
                            // Render some other component or content for pages 3-4
                            <div>Page {activePage}-{activePage + 1}</div>
                        ) : lastPage === true ? (
                            // Default content for all other pages
                            <div>Page Last Page</div>
                        ): (
                            // Default content for all other pages
                            <div>Page {activePage}-{activePage + 1}</div>
                        )}

                    </Col>
                    <Col className="" lg={24} md={24} span={24}>
                         Footer content
                    </Col>
                </Row>


            </>

        );

};

export default Album;
