import {  Table} from 'antd';
import {useEffect, useState} from 'react';
import clientAPI from "../../../../api/api";
import LoadingSpin from "../../../Utils/LoadingSpin";
import {useContractContext} from "../../../../context/ContactContext";

const UnitDefault = ({parent_id,product_type},{form}) => {
    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([]);
    const { formData,setFormData } = useContractContext();


    const fetchSizesAndDetails = async (parent_id,product_type) => {
        try {
            const sizesRes = await clientAPI.getProductObjectSizes(22, 'SIZE');
            const headerRes = await clientAPI.getPrintPriceHeaderByID(parent_id);
            const headerRes2 = await clientAPI.getPrintPriceLabDefault(product_type);

            if (sizesRes.data && headerRes.data && headerRes2.data) {
                const detailsRes2 = await clientAPI.getPrintPriceDetails(headerRes2.data.id);
                if (detailsRes2.data) {
                    const detailsRes = await clientAPI.getPrintPriceDetails(parent_id);
                    setLoading(false);
                    const sizesBody = sizesRes.data.Units;
                    const combinedData = detailsRes2.data.map((detail2) => {
                        const matchingDetail = detailsRes.data.find((detail) => detail.item_id === detail2.item_id);
                        let size = null;
                        if (matchingDetail) {
                            size = sizesBody.find((size) => size.ID === matchingDetail.item_id);
                        }
                        return {
                            id: detail2.item_id,
                            name: size ? `${size.width} x ${size.height}` : null,
                        };
                    });

                    const filteredCombinedData = combinedData.filter((item) => item.name !== null);
                    setData(filteredCombinedData);
                    return detailsRes.data;
                };
            }
        } catch (error) {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchSizesAndDetails(parent_id,product_type);
    }, [parent_id]);






    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (formData?.size_data) {
            const updatedSizeData = formData.size_data.filter((item) => {
                if (item.title_name === "Units" && !newSelectedRowKeys.includes(item.id)) {
                    return false;
                }
                return true;
            });

            const updatedFormData = {
                ...formData,
                size_data: updatedSizeData,
            };

            setFormData(updatedFormData);
        }
    };

    const rowSelection = {
        onChange: onSelectChange,
    };
    useEffect(() => {
        let newId = formData.size_data?.length > 0 ? Math.max(...formData.size_data.map(item => item.id)) + 1 : 1;
        const selectedData = data
            .filter((item) => selectedRowKeys.includes(item.id))
            .map((item) => ({
                ...item,
                id: newId++,
                type:1,
                item_id:item.id,
                quantity:1,
                title_name: "Units",
            }));

        if (selectedData.length > 0) {
            if (formData?.size_data) {
                const updatedSizeData = formData.size_data.filter((item) => {
                    if (item.title_name === "Units" && !selectedRowKeys.includes(item.id)) {
                        return false; // Exclude items with title_name "Prints" and not selected
                    }
                    return true;
                });

                const existingIds = new Set(updatedSizeData.map((item) => item.id));

                const newDataToAdd = selectedData.filter((item) => !existingIds.has(item.id));

                if (newDataToAdd.length > 0) {
                    const updatedFormData = {
                        ...formData,
                        size_data: [...updatedSizeData, ...newDataToAdd],
                    };
                    setFormData(updatedFormData);
                } else {
                    const updatedFormData = {
                        ...formData,
                        size_data: updatedSizeData,
                    };
                    setFormData(updatedFormData);
                }
            } else {
                const newFormData = {
                    parent_id: parent_id,
                    size_data: selectedData,
                };
                setFormData(newFormData);
            }
        }
    }, [data, selectedRowKeys, parent_id]);



    if (loading) {
        return (
            <LoadingSpin>
                <div>Loading...</div>
            </LoadingSpin>
        );
    }
    return (
        <>
            <div>
                <Table
                    className="priceListTable"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data.map((item) => ({...item, key: item.id}))}
                    pagination={false}
                    style={{maxHeight: '300px', overflowY: 'auto'}}
                />
            </div>
        </>
    );
};

export default UnitDefault;
