import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Container from 'react-bootstrap/Container';

export default function LoginValidation(props) {


    const { register, handleSubmit,  formState: { errors }   } = useForm();
    const [smShow, setSmShow] = useState(false);
    const [email, createUser] = useState(props.email);
    const [errorMessage, setErrorMessage] = React.useState(props.errorMessage);
    const [successMessage, setSuccessMessage] = React.useState(props.successMessage);

    const forget = {error:''};


    const onSubmit = async (e) => {
        const post = { email: email };
        try {
            const res = await axios.post('https://dev.studioseye.com/api/forgotpass', post);
            setSuccessMessage(res.data.success);
            setErrorMessage('');

        } catch (e) {
            setErrorMessage(e.response.data.error);
            setSuccessMessage('');
            forget.error = e.response.data.error;

        };

    };

    return (
        <div>
            <Button onClick={() => {
                setSmShow(true);
                setErrorMessage(false);
                setSuccessMessage(false);

            }
            } className='forgot-pass'>Forgot your Password / Username? </Button>
            <Modal
                size="md"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className={'forget-password-modal'} closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm" className='forgot-pass-title'>
                        FORGOT YOUR PASSWORD / USERNAME ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="forget-pass" className={'d-block enter-email-address'}>
                           Enter Your Email Address
                        </label>
                        <input
                            {...register("mail", { required: "Email Address is required" })}
                            aria-invalid={errors.mail ? "true" : "false" || forget.error ? "true" : "false"}
                            type="email"
                            id={'forget-pass'}
                            className={'forget-pass-border'}
                            onChange={(event) => {
                                createUser(event.target.value)
                            }}
                            value={email}
                        />
                        {errors.mail && <p role="alert" className={'error-message'} id='errorMessage'>{errors.mail?.message}</p>}
                        {errorMessage && <p role="alert" className={'error-message'}>{errorMessage}</p>}
                        {successMessage && <p role="alert" className={'success-message'}>{successMessage}</p>}
                            <Container className={'text-center'}>
                            <Button type='submit' className='send-email-btn login-btn'>Send</Button>
                        </Container>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}