import React, {useEffect,useState} from 'react';
import {Checkbox, Input, Row, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


const AlbumsList= () => {
  const navigate = useNavigate();
  const [albumData, setAlbumData] = useState([]);


  useEffect(() => {

    const albumLocal = localStorage.getItem('albumData');
    const albumDataArray = [JSON.parse(albumLocal)];
    console.log(albumDataArray)
    setAlbumData(albumDataArray);
  }, []);

  const editAlbum = (id) => {
    localStorage.setItem('edited_album',id);
    navigate('/studio/album_edit');
    window.location.reload()
  };


  const handleDelete = (id) => {
    const deleteAlbum= albumData.filter((item) => item.id !== id);
    setAlbumData(deleteAlbum);

    const updatedLocalStorage = albumData.filter((item) => item.id !== id);
    localStorage.setItem('albumData', JSON.stringify(updatedLocalStorage));
  };

;

  const columns = [
    {
      key: 'count',
      title: '#',
      dataIndex: 'count',
      render: (text, record, index) => index + 1,
    },
    {
      key: 'name',
      title: 'Album Name',
      dataIndex: 'name',
    },
    {
      key: 'is_default',
      title: 'Is Default',
      dataIndex: 'is_default',
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: (record) => record.id,
      render: (_, record) => (
        <EditOutlined  className="my_ant_icons_table"  onClick= {() => editAlbum(record.id)}/>
      ),
    },
    {
      key: 'delete',
      title: 'Delete',
      render: (_, record, index) => (
        <DeleteOutlined
          className="my_ant_icons_table"
          onClick={() => handleDelete(record.id)}
        />
      ),
    },
  ]



  return (
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Albums List Studio</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        {albumData && albumData.length > 0 ? (
          <Table
            className="priceListTable"
            dataSource={albumData}
            columns={columns}
            bordered={true}
            rowKey={(record) => record.id}
            style={{maxHeight: '100%', overflowY: 'auto'}}
            scroll={{x: 100}}
          />
        ) : (
          <Table
            className="priceListTable"
            bordered={true}
            rowKey={(record) => record.id}
            style={{maxHeight: '100%', overflowY: 'auto'}}
            scroll={{x: 100}}
          />
        )}
      </div>
    </>
  )


}

export default AlbumsList;