import {Col, Form, Radio, Row, Select, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import style from "../Template.module.css";
import clientAPI from "../../../../api/api";
import {useFetching} from "../../../../hoc/fetchingHook";

const SelectUnit = () => {

    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [templatePosition, setTemplatePosition] = useState('Front');

    const handleTemplatePosition = (e) => {
        const templatePosition = e.target.value;
        setTemplatePosition(templatePosition);
    };

    const handleItemSelect = (newSelectedItem) => {
        setSelectedItem(newSelectedItem);

    };

    const [getSizesList, getSizesLoading, getSizesError] = useFetching(async () => {
        const {data: res} = await clientAPI.getProductObjectSizes(22, "SIZE");
        if (res) {
            const body = res.Units
            setData([...body]);
            const newData = body.map((item) => ({
                id: item.ID,
                name: `${item.width} x ${item.height}`,
            }));
            setData(newData);
        }
    });

    useEffect(() => {
        getSizesList()
    }, []);

    return (
        <div>
            <Row justify='space-between' className='mb20'>
                <Col span={24} >
                    <Form.Item
                      label="Unit Size"
                      name="size_id"
                      rules={[
                          {
                              required: true,
                              message: "Please choose Units Size",
                          },
                      ]}
                    >
                        <Select
                          className={style.selectedPrice}
                          placeholder="Select Units Size"
                          value={selectedItem}
                          onChange={handleItemSelect}
                        >
                            {getSizesLoading ? (
                              <Select.Option className={style.selectedOption} key="loading" value="loading">
                                  <Spin />
                              </Select.Option>
                            ) : (
                              data.map((item) => (
                                <Select.Option
                                  key={item.id}
                                  value={item.id}
                                  className={style.selectedOption}
                                >
                                    {item.name}
                                </Select.Option>
                              ))
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='space-between' className='mb-10'>
                <Col span={24} >
                    <Form.Item
                      name="templatePosition"
                      label="Upload Template Position"
                      rules={[{required: true}]}
                    >
                        <Radio.Group
                          style={{display:'flex',justifyContent:'space-evenly'}}
                          onChange={handleTemplatePosition}
                          value={templatePosition}
                        >
                            <Radio value="front">Front</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};
export default SelectUnit;
