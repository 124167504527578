import React, {useState} from 'react';
import {Button, Input, Space, Table, message, Row, Col, Upload, Modal} from 'antd';
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import './background.css';

const UploadBackground = () => {
    const [data, setData] = useState([
        {
            name: "",
            path: "",
        },
    ]);
    const [uploadedImagePath, setUploadedImagePath] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const handleImageClick = () => {
        setUploadedImagePath(uploadedImagePath);
        setPreviewOpen(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
            width: 400,
            align: 'center',
            render: (text, record, index) => (
                <Input
                    value={record.name}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    key={`input-${index}`}
                />
            ),
        },
        {
            title: 'Background',
            dataIndex: 'path',
            key: "path",
            align: 'center',
            render: (text, record, index) => (
                <img
                    style={{width: '70px', height: "70px", cursor: 'pointer'}}
                    src={uploadedImagePath}
                    key={`image-${index}`}
                    onClick={() => handleImageClick()}
                />
            ),
        },
        {
            title: 'Upload',
            dataIndex: "upload",
            key: "upload",
            align: 'center',
            render: (_, record) => (
                <Upload
                    showUploadList={false}
                    customRequest={handleCustomRequest}
                    accept="image/*"
                >
                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                </Upload>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            align: 'center',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined className="my_ant_icons_table" onClick={() => deleteItem(record.id)}
                                    key={`delete-${record.id}`}/>
                </Space>
            ),
        },
    ];

    const handleInputChange = (index, value) => {
        const updatedData = [...data];
        updatedData[index].name = value;
        setData(updatedData);
    };
    const deleteItem = (id) => {
        const initialDate = [
            {
                name:'',
                path:''
            }
        ]
        setData(initialDate);
        setUploadedImagePath('');
    };

    const handleCustomRequest = (options) => {
        const { onSuccess, file } = options;
        const imageUrl = URL.createObjectURL(file);
        const updatedData = [...data];
        updatedData[0].path = imageUrl;
        if (file.name){
            updatedData[0].name = file.name;
        }else {
            updatedData[0].name = "";
        }
        setData(updatedData);
        setUploadedImagePath(imageUrl);
        onSuccess();
        message.success(`${file.name} file uploaded successfully`);
    };
    const handleSave = async () => {
        const backgroundData = data.map((item) => ({
            name: item.name,
            path: item.path,
        }));
        console.log("Background Data:", backgroundData);
    };


    return (
        <>
            <Modal
                title="Preview Image"
                onCancel={() => setPreviewOpen(false)}
                footer={null}
                open={previewOpen}
            >
                <img
                    alt="Preview"
                    style={{width: '100%'}}
                    src={uploadedImagePath}
                />
            </Modal>

            <div className="text-center mt-4">
                <h3 className="text-uppercase mb-4 header-title"> Upload Background </h3>
            </div>
            <div style={{margin: 16}} className='inputshHader'>
                <Row>
                    <Col span={24} className='btnHeader'>
                        <Button className="btn_price btn_save background__button-save" st="true" onClick={handleSave}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
            <Table
                columns={columns}
                dataSource={data.map((item, index) => ({...item, key: index}))}
            />
        </>
    );
};

export default UploadBackground;
