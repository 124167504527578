import React, { createContext, useContext, useState } from 'react';

const PolicyContext = createContext();

export const PolicyProvider = ({ children }) => {
  const [policyData, setPolicyData] = useState([]);
  return (
    <PolicyContext.Provider value={{ policyData, setPolicyData }}>
      {children}
    </PolicyContext.Provider>
  );
};

export const usePolicyContext = () => {
  return useContext(PolicyContext);
};
