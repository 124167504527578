import React from 'react';
import { PinturaEditor } from '@pqina/react-pintura';
import { getEditorDefaults } from '@pqina/pintura';

import '@pqina/pintura/pintura.css';

const CardsEditor = ({
                       droppedImage,
                       aspectRatio,
                       setIsModalOpen,
                        currentImage,
    setImageDropZones,
    setCurrentImage,
                         divName
                     }) => {
  const handleProcess = (res) => {
      setCurrentImage(prev => ({
          ...prev,
          node_name: divName,
          crop_data: res.imageState.crop,
      }));

      setImageDropZones(prev => ({
          ...prev,
          [divName]: {
              ...currentImage,
              crop_data: res.imageState.crop
          }
      }));      setIsModalOpen(false)
  };
  return (
      <div style={{ height: '500px' }}>
        <PinturaEditor
            cropEnableRotationInput={false}
            cropEnableZoomAutoHide={false}
            cropActiveTransformTool='zoom'
            imageCrop ={currentImage?.crop_data ? currentImage?.crop_data : ""}
            imageCropAspectRatio={aspectRatio}
            {...getEditorDefaults()}
            src={droppedImage.img_path}
            id={droppedImage.id}
            onProcess={handleProcess}
        />
      </div>
  );
};

export default CardsEditor;