import React,{useEffect, useState } from 'react';
import triColorLogo from "../../Images/TRICOLOR CENTER.png";
import isAuth from "../../hoc/IsAuth";
import useCheckUserType from '../../hoc/checkUserType';
import LoadingSpin from '../Utils/LoadingSpin';
import Logout from '../Logout/Logout';
import Buttons from '../Utils/Buttons';
import AddStaff from '../Lab/Staff/AddStaff';
import ListStaff from '../Lab/Staff/ListStaff';
import EditStaff from '../Lab/Staff/EditStaff';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { menuHelper } from "../../hoc/menuHoc";
import { staffItems } from "./LeftSidebarStaffItems"; 
import { staffStudioItems } from "./StaffStudioItems";
import { stringToBool } from '../Utils/stringToBool';
import { HomeOutlined, LeftCircleFilled, RightCircleFilled,LogoutOutlined, RollbackOutlined} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme,Button } from 'antd';
import { useWindowSize } from '../Utils/WindowSize';

const { Header, Content, Sider } = Layout;
let goToStudio = {};
const user_id = parseInt(localStorage.getItem('user_id'));
const studio_id = parseInt(localStorage.getItem('studio_id'));

if(user_id !== studio_id){
  goToStudio = {
    key: 'go_to_studio',
    icon: <RollbackOutlined />,
    label: 'Go To Studio',
  };
}else{
  goToStudio = {
    key: 'staff_list',
    icon: <RollbackOutlined />,
    label: 'Home',
  };
}

const navStafftItems = [{
  key: 'staff_list',
  icon: <HomeOutlined />,
  label: 'STAFF',
 },
  {
  key: 'logout',
  icon: <LogoutOutlined />,
  label: 'Logout',
 }
]

const navStudioItems = [
  goToStudio,
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
  }
]

const StaffDashboard = (props) => {


  const currentUserType = useCheckUserType();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isUserAuthenticated = isAuth();
  const navigate = useNavigate();
  const currentPath = '/staff/';
  const [itemsB, setItemsB] = useState([]);
  const [selectedKey, setSelectedKey] = useState('/');
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [navItmes, setNavItmes] = useState([]);
  const [activeStudio, setActiveStudio] = useState(stringToBool(localStorage.getItem('studio_mode')));
  const windowSize = useWindowSize();

  const showBreadcrumbs = (items, e) => {
    const parentMenu = menuHelper.getParentLabel(items,e);
    const clickedItem = menuHelper.getMenuLabel(items,e);
    let bread;
    if(clickedItem === "dashboard"){
      bread = [
        { title: 'Dashboard', href: '/staff/dashboard' },
      ];
    }else if (clickedItem === parentMenu){
      bread = [
        { title: 'Dashboard', href: '/staff/dashboard' },
        { title: clickedItem, href: `/staff/${e}` },
      ];
    }else if(clickedItem === parentMenu) {
      bread = [
        { title: 'Dashboard', href: '/staff/dashboard' },
        { title: parentMenu},
        { title: clickedItem, href: `/staff/${e}` },
      ];
    }else{
      bread = [
        { title: 'Dashboard', href: '/staff/dashboard' },
        { title: parentMenu},
        { title: clickedItem, href: '/staff/staff_edit' },
      ];
    }
    setItemsB(bread);
  }


  const handleMenuClick = (e) => {
    if(e.key === 'go_to_studio'){
      localStorage.setItem('user_type',"STUDIO");
      const currentPath2 = '/studio/list_client';
      navigate(currentPath2);
    }else{
      setSelectedKey(e.key);
      showBreadcrumbs(items,e.key);
      navigate(currentPath+e.key);
    }
  };
  const chengeStudioMode = (event) => {

    setActiveStudio(!activeStudio);
    localStorage.setItem('studio_mode',!activeStudio);

    if(stringToBool(localStorage.getItem('studio_mode')) === true){
      setItems(staffStudioItems);
      setNavItmes(navStudioItems);
    }else{
      setItems(staffItems);
      setNavItmes(navStafftItems);
    }
  };
  const renderConditionalContent = () => {
    if (currentUserType === 'STUDIO' || (currentUserType === 'LAB')) {
      return (
        <Buttons
          activeStudio={activeStudio}
          chengeStudioMode={chengeStudioMode}
          studioModeLabel="Studio mode"
          clientModeLabel="Staff mode"
        />
      );
    } else {
      return null;
    }
  };
  let { id } = useParams();
  useEffect(() => {
      setActiveStudio(stringToBool(localStorage.getItem('studio_mode')));
      if(stringToBool(localStorage.getItem('studio_mode')) === true){
        setItems(staffStudioItems);
        setNavItmes(navStudioItems);
      }else{
        setItems(staffItems);
        setNavItmes(navStafftItems);
      }
      if(id){
        setSelectedKey(id);
        showBreadcrumbs(items,id);
      }else if(props.page){
        setSelectedKey(props.page);
        showBreadcrumbs(items,props.page);
        navigate(currentPath+props.page);
      }
  }, []);

  useEffect(() => { 
  }, [items,navItmes]);

  useEffect(() => { 
      if(windowSize.width < 768){
        setCollapsed(true);
      }else if(windowSize.width > 768) {
        setCollapsed(false);
      }
  }, [windowSize]);


    if(!isUserAuthenticated){ 
       return <>  <LoadingSpin />  </>
    }else{
      return (
        <Layout style={{
          minHeight: '100vh',
        }}>
          <Header className="header">
            <div className="logo">
              <img src={triColorLogo} alt="tricolor-icon"/>
            </div>
            <div className="colapseButtonContainer">
              <Button 
                className="colapseButton"
                type="text"
                icon={collapsed ? <RightCircleFilled className="colapseButtonColor"/> : <LeftCircleFilled className="colapseButtonColor"/>}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
            <div className="studioModeContainer">
              {renderConditionalContent()}
            </div>
            <Menu 
              className="top_menu"       
              onClick={handleMenuClick}
              selectedKeys={[selectedKey]} 
              theme="light" 
              mode="horizontal" 
              defaultSelectedKeys={['2']}
              items={navItmes} 
            />
          </Header>
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
              <Menu
                items={items}
                onClick={handleMenuClick}
                selectedKeys={[selectedKey]}
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{
                  height: '100%',
                  borderRight: 0,
                }}                 
              />
            </Sider>
            <Layout style={{ padding: '0 24px 24px' }}>
              <Breadcrumb
                style={{ margin: '16px 0' }}
                items={itemsB}
              />
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                  background: colorBgContainer,
               }}
              >
                {selectedKey === 'logout' && <Logout/>}
                {selectedKey === 'staff_add' && <AddStaff/>}
                {selectedKey === 'staff_list' && <ListStaff/>}
                {selectedKey === 'staff_edit' && <EditStaff/>}
              </Content>
            </Layout>
          </Layout>
        </Layout>
      );
    }
};
export default StaffDashboard;
