import React from 'react';
import AddCardsStudio from "../AddCardsStudio";

const AddFoldOverStudio = ({setHelperShow,helperShow}) => {

  const nameCards = "Fold Over";
  const navigate_link = '/studio/fold_over_list';
  const mainCardType = 3;

  return (
    <div>
      <AddCardsStudio nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType}  setHelperShow={setHelperShow} helperShow={helperShow}/>
    </div>
  );
};

export default AddFoldOverStudio;


