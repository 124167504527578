import React, {useRef, useState,useEffect} from 'react';
import {Button, Row, Select, Col, Space, Input, Form, Radio, message,} from 'antd';
import {PlusOutlined,} from "@ant-design/icons";
import Container from "react-bootstrap/Container";
import SelectPrice from "./SelectPrice";
import SelectCard from "./SelectCard";
import SelectUnit from "./SelectUnit";
import SelectAlbum from "./SelectAlbum";
import SelectTemplate from "./SelectTemplate";
import style from "../Template.module.css";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";



const UploadTemplate = () => {

    let index = 0;
    const {Option} = Select;
    const [form] = Form.useForm();
    const inputRef = useRef(null);
    const [selectType, setSelectType] = useState('Print');
    const [selectValue, setSelectValue] = useState('Print');
    const [selectedCategory, setSelectedCategory] = useState('Print');
    const [isTemplate, setTemplate] = useState([])
    const [selectedId, setSelectedId] = useState([]);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const handleChange = (value) => {
        setSelectType(value);
        setSelectValue(value);
    };


  const [items1, setItems1] = useState(['Photographer']);
  const [name, setName] = useState('');

    const [assigneeTemplateCreate, assigneeLoading, assigneeError] = useFetching(async (obj) => {
        const { data: res } = await clientAPI.assigneeTemplate(obj);
        if (res) {
            console.log(res);
        }
    });


  const addItem = (e) => {
      e.preventDefault();
      setItems1([...items1, name || `New item ${index++}`]);
      setName('');
      setTimeout(() => {
          inputRef.current?.focus();
      }, 0);
  };

  const onTemplateSelect = (template) => {
    setTemplate([...isTemplate,template])
  };
  const [messageApi, contextHolder] = message.useMessage();

  const key = 'updatable';

  const openMessage = () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Save Upload Template...',
      style: { marginTop: '25%', },
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: 'success',
        content: 'Saved Upload Template!',
        duration: 2,
        style: { marginTop: '25%', },
      });
    }, 1000);
  };


  const onFinish = (values) => {
    values.template = isTemplate;
    assigneeTemplateCreate(values);
    openMessage();
    setSelectedId([]);
  };
    return (
        <>

          <Form
              className={style.selected}
              form={form}
              size={"middle"}
              name="validate_other"
              onFinish={onFinish}
              layout="vertical"
              justify={' space-between'}
              initialValues={{
                productCategory: 'Print',
                templatePosition:'Front',
                selectedCategory:'Print',
                verticalHorizontal:'Horizontal',

              }}
            >
            {contextHolder}
            <div className={'text-center mt-4'}>
              <h3 className={'text-uppercase mb-4 header-title'}>Upload Template {selectValue}</h3>
            </div>
              <Row justify='space-between' >
                <Col lg={11} span={24}>
                  <Row justify='space-between' className='mb-10'>
                    <Col span={24}>
                      <Form.Item
                        label="Product Category"
                        name="productCategory"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your card number",
                          },
                        ]}
                      >
                        <Select
                          className={style.selectedPrice}
                          onChange={handleChange}
                          value={selectValue}
                        >
                          <Option className={style.selectedOption} value="Print">Print</Option>
                          <Option className={style.selectedOption} value="Card">Card</Option>
                          <Option className={style.selectedOption} value="Units">Units</Option>
                          <Option className={style.selectedOption} value="Album">Album</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" className='mb-10'>
                    <Col  span={24}>
                      {selectType === 'Print' ? <SelectPrice />: null}
                      {selectType === 'Card' ? <SelectCard  /> : null}
                      {selectType === 'Units' ? <SelectUnit  /> : null}
                      {selectType === 'Album' ? <SelectAlbum /> : null}
                    </Col>
                  </Row>
                    <Row justify='space-between' className='mb-10'>
                        <Col span={24} >
                            <Form.Item
                                value={form.getFieldValue("verticalHorizontal")}
                                name="verticalHorizontal"
                                label="Template Position H / V"
                                rules={[{ required: true }]}
                            >
                                <Radio.Group
                                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                >
                                        <>
                                            <Radio value="Horizontal">Horizontal</Radio>
                                            <Radio value="Vertical">Vertical</Radio>
                                        </>
                                </Radio.Group>
                            </Form.Item>

                        </Col>
                    </Row>



                  <Row justify="center" className='mb-10'>
                    <Col span={24}>
                      <Form.Item
                        label="Upload Template Category"
                        name="templateCategory"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Upload Template Category",
                          },
                        ]}
                      >
                        <Select
                          mode={"multiple"}
                          optionFilterProp="children"
                          className={style.selectedPrice}
                          placeholder="Select UploadTemplate Category"
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Space >
                                <Input
                                  placeholder="Please enter item"
                                  ref={inputRef}
                                  value={name}
                                  onChange={onNameChange}
                                />
                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                  Add item
                                </Button>
                              </Space>
                            </>
                          )}
                          options={items1.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} span={24} >
                  <Row>
                    <Col span={24}>
                      <SelectTemplate
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        onTemplateSelect={onTemplateSelect}
                        isTemplate={isTemplate}
                        setTemplate={setTemplate}

                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            <Container className="text-center mt-4 mb-2">
              <Button
                type="primary"
                htmlType="submit">
                Save Upload Template
              </Button>
            </Container>
          </Form>
        </>
    )
}

export default UploadTemplate;


