import React from 'react';
import EditCardsStudio from "../EditCardsStudio";

const EditFoldOverStudio = () => {

  const nameCards = "Fold Over";
  const navigate_link = '/studio/fold_over_list';
  const mainCardType = 3;

  return (
    <div>
      <EditCardsStudio nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};

export default EditFoldOverStudio;


