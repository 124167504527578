import React, {useState,useEffect} from 'react';
import clientAPI from "../../../../api/api";
import MoreInfo from "../../../Utils/MoreInfo";
import EditPassword from "../../../Utils/EditPassword";
import LoadingSpin from "../../../Utils/LoadingSpin";
import Container from "react-bootstrap/Container";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFetching } from "../../../../hoc/fetchingHook";
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import { handleTagChange, validateMessages } from '../../../Utils/isFunction';

const EditContact = () => {
    
    const { Option } = Select;
    const [isTypeSchool, setIsTypeSchool] = useState(false);
    const [expand, setExpand] = useState(false);
    const [data, setData] = useState();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };

    const [updateUserInfo, isUserLoading, UserError] = useFetching(async (current_user_id, values) => {
        const {data: res} = await clientAPI.updateUserInfo(current_user_id, values);
        if (res) {
            let  currentPath = '/studio/list_client';
            navigate(currentPath);
            window.location.reload();
        }
    });

    function onTypeChange(e){
        e === "1" ? setIsTypeSchool(true) : setIsTypeSchool(false)
    }
    const onFinish = (values) => {
        const current_user_id = localStorage.getItem('client_id');
        updateUserInfo(current_user_id,values);
    };

    const [fetchStaffData, isDataLoading, dataError] = useFetching(async (studio_id) => {
        const {data: res} = await clientAPI.getClientByParentID(studio_id);
   

        if (res) {
    
            if(res.type === "1"){
                setIsTypeSchool(true);
            }else if(res.type === "2"){
                setIsTypeSchool(false);
            }
            setData(res);
        }
    });
    useEffect(() => {
        const client_id = localStorage.getItem('client_id');
        fetchStaffData(client_id);
    }, []);


    if (isDataLoading) {
        return (
            <>
                <LoadingSpin />
            </>
        );
    }else{
    return(
        <>
            <div className={'text-center mt-4'}>
                <h3 className={'text-uppercase mb-4 header-title'}>Edit Client / School</h3>
            </div>
            <div className={'mt-4 album-notifications-select'}>
            <Form
                form={form}
                name="validate_other"
                onFinish={onFinish}
                size={"large"}
                layout="vertical"
                style={{
                    maxWidth: 600,
                    margin: "auto"
                }}
                validateMessages={validateMessages}
                initialValues={data}
                scrollToFirstError
            >
                <Form.Item
                    name="type"
                    label="Client Type"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select placeholder="select client type" onChange={onTypeChange}>
                        <Option value="2">Client</Option>
                        <Option value="1">School</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Enter tags (email addresses)"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter at least one email address.',
                            type: 'array',
                        },
                    ]}
                >
                    <Select
                        mode="tags"
                        onChange={handleTagChangee}
                        style={{width: '100%'}}
                        placeholder="Enter your (email addresses)"
                    />
                </Form.Item>

                <Row>
                    <Col lg={24} md={24} span={24}>
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                {isTypeSchool && (
                    <Form.Item
                        name="firstname"
                        label="School name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                )}
                {!isTypeSchool && (
                    <Row justify="space-between">
                        <Col lg={11} md={11} span={24}>
                            <Form.Item
                                name="firstname"
                                label="Firstname"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col lg={11} md={11} span={24}>
                            <Form.Item
                                name="lastname"
                                label="Lastname"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Col className={"collapse-client"} lg={24} md={24} span={24}>
                   <EditPassword />
                </Col>
                <Col className={"collapse-client"} lg={24} md={24} span={24}>
                    <a onClick={() => { setExpand(!expand);}}>
                        <DownOutlined rotate={expand ? 180 : 0} /> More Details
                    </a>
                </Col>
                {expand && (
                    <MoreInfo />
                )}
                <Container className={'text-center'}>
                    <Button type="primary" htmlType="submit"  className='send-email-btn' >
                        Update
                    </Button>
                </Container>
            </Form>
            </div>
        </>
    )
    }
};
export default EditContact;