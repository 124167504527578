import React,{useEffect, useState } from 'react';
import clientAPI from "../../../../api/api";
import getColumnSearchProps from '../../../Utils/getColumnSearchProps'
import { useFetching } from "../../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { sortColumns, onChangeTable, showDeleteConfirm } from '../../../Utils/isFunction'
import { Space, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";


const ListVenue = () => {

    const navigate = useNavigate();
    const PREFIX = "https://studioseye.com/pricing/upload/"; // Replace with your prefix
    const [data, setData] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
        },
    });
    const handleTableChange = onChangeTable(tableParams, setTableParams);
    const [fetchVenueList, isVenueLoading, venueError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getVenueListByStudioID(studio_id);
        if (res) {
            console.log(res,'res')
            setData(res);
            }
        })


    useEffect(() => {
        const studio_id = localStorage.getItem('studio_id');
        fetchVenueList(studio_id);
    }, []);


    const edit_staff = (staff_id) => {
        localStorage.setItem('staff_id',staff_id);
        localStorage.setItem('current_user_id',staff_id);
        if(localStorage.getItem("user_type") === "LAB"){
        navigate('/labs/staff_edit');
        }
         if(localStorage.getItem("user_type") === "STUDIO"){
        navigate('/studio/staff_edit');
        }
        window.location.reload();
    };

    const venueDelete = (id) => {
        showDeleteConfirm(id, 'Venue').then((result) => {
            if (result) {
                console.log('yes')
                const updatedData = data.filter((item) => item.id !== id);
                setData(updatedData);
            } else {
                console.log('NO')
            }
        });
    };
    const columns = [
        {
            key: 'count',
            title: '#',
            dataIndex: 'count',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'ID',
            dataIndex: 'value',
            key: "value",
            ...getColumnSearchProps("value"),
            sorter: (a, b) => a.value - b.value,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Venue Name',
            dataIndex: 'label',
            key: 'label',
            ...getColumnSearchProps("label"),
            sorter: sortColumns('label'),
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Edit',
            dataIndex: 'studio_edit',
            key: 'studio_edit',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined  className="my_ant_icons_table" onClick={()=> edit_staff(record.id)}/>
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined  className="my_ant_icons_table" onClick={()=> venueDelete(record.id)}/>
                </Space>
            ),
        },
    ];
 
    if(isVenueLoading){
        return (
            <Table
                className="table-striped-rows studio-table-rows"
                columns={columns}
                dataSource={[]}
                loading={isVenueLoading}
                pagination={tableParams.pagination}
            />
        );
    }else{
        return(
            <>
             <div className={'text-center mt-4'}>
              <h3 className={'text-uppercase mb-4 header-title'}> List Venue Studio</h3>
              <Table
                className="table-striped-rows studio-table-rows"
                pagination={true}
                loading={isVenueLoading}
                rowKey={(record) => record.value}
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                scroll={{ x: 100 }}
                summary={() => (
                  <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={100}>
                          <b>All Venues: {data.length}</b>
                      </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
            />
           </div>
            </>
          
        );
    }
};

export default ListVenue;