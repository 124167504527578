import React, { useState, useEffect } from "react";
import { Modal, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import getColumnSearchProps from "../../Utils/getColumnSearchProps";
import OrderDetails from "../../Cart/Checkout/OrderDetails";

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const handleShow = (record) => {
    setOpen(true);
    setSelectedOrder(record);
  };

  const handleCancel = () => {
    setOpen(false);
  };


  useEffect(() => {
    const objOrderStr = localStorage.getItem("objOrder");
    if (objOrderStr) {
      const objOrder = JSON.parse(objOrderStr);
      setOrders(objOrder);
    }
  }, []);

  const columns = [
    {
      key: "count",
      title: "#",
      dataIndex: "count",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      align: "center",
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
      ...getColumnSearchProps("orderNumber"),
      render: (orderNumber) => orderNumber,
    },
    {
      title: "Show",
      dataIndex: "show",
      align: "center",
      render: (_, record) => (
        <EyeOutlined
          className="my_ant_icons_table"
          onClick={() => handleShow(record)}
        />
      ),
    },
  ];


  return (
    <div>
      <div className={"text-center"}>
        <h3 className={"text-uppercase mb-4 header-title"}>Order List</h3>
      </div>
      <div className={"mt-4"}>
        {orders.length > 0 && (
          <Table
            columns={columns}
            className="table-striped-rows"
            rowKey={(record) => record.orderNumber}
            dataSource={orders}
            pagination={true}
            scroll={{ x: 100 }}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={100}>
                  <b>All Orders: {orders.length}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        )}
      </div>
      <Modal
        centered
        open={open}
        onCancel={handleCancel}
        cancelButtonProps
        footer={false}
        width={1200}
      >
        {selectedOrder && (
          <OrderDetails
            totalPrice={selectedOrder.totalPrice}
            balance={selectedOrder.balance}
            checkout={selectedOrder.checkout}
            shippingAddress={selectedOrder.shippingAddress}
            orderDetails={selectedOrder.orderDetails}
            shoppingCartInfo={selectedOrder.shoppingCartInfo}
            cartData={selectedOrder.cartData}
          />
        )}
      </Modal>
    </div>
  );
};

export default Orders;
