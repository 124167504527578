import React, {useEffect,useState} from 'react';
import { Table} from 'antd';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientsAPI from "../../../../api/api";
import {showDeleteConfirmPrice} from "../../../Utils/isFunction";

const ListAlbumLab= () => {
  const navigate = useNavigate();
  const [albumsCards, setAlbumsCards] = useState([]);


  const [getAlbumList, isLoading, error] = useFetching(async () => {
    //???????????????????????????????????????????????????????????????????
    const { data: res } = await clientsAPI.getCardListByUserIDAndCardType();
    if (res) {
      console.log(res);
      setAlbumsCards(res);
    }
  });

  useEffect(() => {
    getAlbumList();
  }, []);

  const handleDelete = (id) => {
    showDeleteConfirmPrice(id).then((result) => {
      if (result) {
        console.log('yes')
        const deleteFlatCard = albumsCards.filter((albumsCard) => albumsCard.id !== id);
        setAlbumsCards(deleteFlatCard);

        const updatedLocalStorage = albumsCards.filter((albumsCard) => albumsCard.id !== id);
        localStorage.setItem('albumsCards', JSON.stringify(updatedLocalStorage));
      }else {
        console.log('NO')
      }
    })

  };

  const editFlatCard = (id) => {
    console.log('edit')
    navigate('/labs/album_edit');
    window.location.reload()
  };


  const columns = [
    {
      key: 'count',
      title: '#',
      dataIndex: 'count',
      render: (text, record, index) => index + 1,
    },

    {
      key: 'name',
      title: 'Name Flat Card',
      dataIndex: 'name',
    },
    {
      key: 'default_checked',
      title: 'Is Default',
      dataIndex: 'default_checked',
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: (record) => record.id,
      render: (_, record) => (
        <EditOutlined  className="my_ant_icons_table"  onClick= {() => editFlatCard(record.id)}/>
      ),
    },
    {
      key: 'delete',
      title: 'Delete',
      render: (_, record, index) => (
        <DeleteOutlined
          className="my_ant_icons_table"
          onClick={() => handleDelete(record.id)}
        />
      ),
    },
  ]
  return (
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Album List Lab</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        {isLoading ? (
          <Table
            loading={isLoading}
            className="priceListTable"
            columns={columns}
            style={{ maxHeight: '100%', overflowY: 'auto' }}
          />
        ) : (
          <Table
            loading={isLoading}
            className="priceListTable priceTable"
            dataSource={albumsCards}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={false}
            style={{ maxHeight: '100%', overflowY: 'auto' }}
             scroll={{x:100}}
          />
        )}
      </div>
    </>

  )
}
export default ListAlbumLab;