import React,{useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Space, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import '../Prints/Price.css'
import clientAPI from "../../../../../api/api";
import {useFetching} from "../../../../../hoc/fetchingHook";
import {showDeleteConfirmPrice} from "../../../../Utils/isFunction";

const ListUnitsStudio = () => {

    const navigate = useNavigate();
    const PREFIX = "https://studioseye.com/pricing/upload/";
    const [data, setData] = useState([]);
    const [getStudioList, isStudiosLoading, studioError] = useFetching(async () => {
        localStorage.setItem('product_type',2);
        const { data: res } = await clientAPI.getPrintPriceByUserId();
        console.log(res)
        if (res) {
            console.log(res,"res")
            setData(res)
        }
    });
    const [getPriceListDetailList, getPriceListDetailsLoading, getPriceListDetailError] = useFetching(async (price_list_id) => {
        localStorage.setItem('product_type',2);
        const { data: res } = await clientAPI.getPrintPriceDetails(price_list_id);
        console.log(res)
        if (res) {
            localStorage.setItem('parent_id',price_list_id);
            navigate("/studio/units_edit")
            window.location.reload()
        }
    });

    useEffect(() => {
        getStudioList();
    }, []);

    const edit_price = (price_list_id) => {
        console.log(price_list_id);
        getPriceListDetailList(price_list_id)

    }
    const deleteListItem = (price_list_id) => {
        showDeleteConfirmPrice(price_list_id).then((result) => {
            if (result) {
                console.log("User clicked Yes");
                const updatedData = data.filter((item) => item.id !== price_list_id);
                setData(updatedData);
            } else {
                console.log("User clicked No");
            }
        })
    };
    const columns = [
        {
            key: 'count',
            title: '#',
            dataIndex: 'count',
            render: (text, record, index) => index + 1,
        },

        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Is default',
            dataIndex: 'default_checked',
            key: "isdefault",
        },
        {
            title: 'Edit',
            dataIndex: 'price_edit',
            key: 'price_edit',
            render: (_, record) => (
              <Space wrap>
                  <EditOutlined  className="my_ant_icons_table" onClick={()=> edit_price(record.id)}/>
              </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
              <Space wrap>
                  <DeleteOutlined  className="my_ant_icons_table" onClick={()=> deleteListItem(record.id)}/>
              </Space>
            ),
        },
    ];

    if(isStudiosLoading){
        return (
          <Table
            className="table-striped-rows studio-table-rows priceListTable"
            columns={columns}
            dataSource={data}
            loading={isStudiosLoading}
          />
        );
    }else{
        return(
          <>
              <div className="text-center mt-4">
                  <h3 className="text-uppercase mb-4 header-title">Units Price List Studio</h3>
              </div>
              <Table
                className="table-striped-rows studio-table-rows priceListTable"
                loading={isStudiosLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                key = {(record) => record.id}
              />
          </>
        );
    }
};

export default ListUnitsStudio;