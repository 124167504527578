import React, {useEffect, useRef, useState} from 'react';
import {
    Col, Form, Row, Select, DatePicker, Input, Spin, Tour, InputNumber
} from 'antd';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";
import {useNavigate} from "react-router-dom";
import { validateMessages } from '../../../Utils/isFunction';
import {useHelperTab} from "../../../../context/HelperTabContext";
const { Option } = Select;

const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select time!',
        },
    ],
}

const AddEvent = ({helperShow,setHelperShow}) => {
    const [formFilled, setFormFilled] = useState(false);

    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const { helperTab } = useHelperTab();

    const [open, setOpen] = useState(helperShow);

    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }

    }, [helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);


    const steps = [
        {
            title: 'Select Client from Existing List',
            description: 'This feature allows you to choose a client from an already populated dropdown list of existing clients. It designed for ease of access and efficiency, enabling you to quickly search and select from a list of clients who are already part of your system. To find a specific client, simply click on the dropdown menu and start typing the name in the search bar. The system will dynamically filter and display the matching results, making it easy to locate and select the client you need. If the client youre looking for is not in the list, it indicates they havent been added to the system yet. In such cases, you can easily navigate to the "Client" menu to add a new client. This feature streamlines the process of associating specific clients with your activities or records, ensuring a smooth and organized workflow',
            target: () => ref1.current,
        },
        {
            title: 'Select Photographer from Existing List',
            description: 'This function enables you to choose a photographer from a pre-existing list of photographers already registered in your system. Ideal for quickly assigning photographers to events or projects, this dropdown list simplifies the selection process. Simply click on the dropdown, and you can start typing the photographer\'s name in the search field. The system will automatically filter and display relevant results, allowing you to find and select the desired photographer with ease. If the photographer you are looking for is not present in the dropdown list, it indicates they have not been added to your database yet. In this case, you should proceed to the "Photographer" section to add a new photographer. This feature ensures efficient management and assignment of photographers, facilitating a more organized and streamlined operation.',
            target: () => ref2.current,
        },
        {
            title: 'Select Venue from Existing List',
            description: 'This feature allows you to select a venue from an existing list within your system. It is designed to streamline the process of booking or assigning venues for events. By clicking on the dropdown menu, you can access a comprehensive list of all venues that have been previously entered into the database. Start typing the name of the venue in the search bar to quickly filter and find the specific venue you need. This intuitive search functionality helps in efficiently locating the desired venue without the need to scroll through the entire list. If the venue you require is not available in the dropdown list, it suggests that it has not yet been added to your system. In such cases, you can go to the \'Venue\' section to add a new venue. This tool is particularly useful for event planners and coordinators, ensuring a smooth and efficient venue selection process.',
            target: () => ref3.current,
        },
        {
            title: 'Event Details: Name, Type, and Date',
            description: 'Event Name: Here, you can enter the specific name or title for the event as desired by the client. This could be a personalized name like "Emily\'s 30th Birthday" or a more formal title like "Annual General Meeting 2024". The flexibility to input any name allows for custom identification of each event, making it unique and easily distinguishable.\n' +
                '\n' +
                'Event Type: This dropdown menu lets you select the nature of the event from predefined options such as "Wedding", "Party", "Conference", etc. The event type helps in categorizing the event, which can be useful for planning, logistics, and tailoring services specific to the event’s needs.\n' +
                '\n' +
                'Event Date: For setting the date of the event, you can use the integrated calendar feature. Simply click on the date field, and a calendar will appear, allowing you to select the exact date of the event. This ensures accuracy and convenience in scheduling, preventing manual entry errors and providing a clear visual cue for the selected date.',
            target: () => ref4.current,
        },
        {
            title: 'Create Event',
            description: 'The "Create" button is a key feature for finalizing the event setup process. Once you have entered all the necessary details for your event, including its name, type, and date, clicking this button will save and formalize the event in the system. It\'s important to review all the information you\'ve entered to ensure accuracy, as this action will confirm and secure the event\'s details. The button is intuitively designed for ease of use and is prominently placed for easy access. Upon clicking "Create Event," the system will process the information and typically respond with a confirmation message, indicating that your event has been successfully created and registered. This streamlined process makes event management efficient and user-friendly, allowing for quick and organized planning',
            target: () => ref5.current,
        },
    ];

    const navigate = useNavigate();
    const [clientListData, setClientListData] = useState([]);
    const [clientById, setClientById] = useState('');
    const [phListData, setPhListData] = useState([]);
    const [venueListData, setVenueListData] = useState([]);
    const [form] = Form.useForm();
    const client_id = parseInt(localStorage.getItem('client_id'));

    const [createEvent, isEventLoading, eventError] = useFetching(async (values) => {
        const { data: res } = await clientAPI.createEvent(values);
        if (res) {
            const currentPath = '/studio/list_event';
            navigate(currentPath);
            window.location.reload();
        }
    });

    const [getClientById, clientListLoading, clientListError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getClientListByStudioIdToken(studio_id);
        if (res) {
            const selectedClient = res.client_list.find(client => client.id === client_id);
            setClientById(selectedClient.name);
        }
    });
    const [fetchClientList, isPostsLoading, postError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getClientListByStudioID(studio_id);
        if (res) {
            setClientListData(res);
        }
    });
    const [fetchPhList, isPhLoading, phError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getPhListByStudioID(studio_id);
        if (res) {
            setPhListData(res);
        }
    });
    const [fetchVenueList, isVenueLoading, venueError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getVenueListByStudioID(studio_id);
        if (res) {
            setVenueListData(res);
        }
    });

    useEffect(() => {
        const studio_id = localStorage.getItem('studio_id');
        fetchClientList(studio_id);
        getClientById(studio_id);
        fetchPhList(studio_id);
        fetchVenueList(studio_id);
    }, []);

    let initialValues = {}
    useEffect(() => {
        if (client_id) {
            initialValues = {
                client_id: client_id,
                event_type: "Wedding",
                event_name: clientById,
                event_date:'',
                photographer_id:[]
            };
            form.setFieldsValue(initialValues);
        }
    }, [clientById]);

    const onFinish = (fieldsValue) => {
        const values = {
            ...fieldsValue,
            'event_date': fieldsValue['event_date'].format('MM-DD-YYYY'),


        };
        console.log(values,'values')
        // createEvent(values);
    };


    const options = [
        {
            key: 'sub3',
            value: 'Anniversary',
            label: 'Anniversary',
        },
        {
            key: 'sub10',
            value: 'Bar Mitzvah',
            label: 'Bar Mitzvah',
        },
        {
            key: 'sub2',
            value: 'Bat Mitzvah',
            label: 'Bat Mitzvah',
        },
        {
            key: 'sub4',
            value: 'Birthday',
            label: 'Birthday',
        },
        {
            key: 'sub6',
            value: 'Baptism',
            label: 'Baptism',
        },
        {
            key: 'sub9',
            value: 'Dance',
            label: 'Dance',
        },
        {
            key: 'sub5',
            value: 'Engagement Photos',
            label: 'Engagement Photos',
        },
        {
            key: 'sub7',
            value: 'Graduation',
            label: 'Graduation',
        },
        {
            key: 'sub8',
            value: 'School',
            label: 'School',
        },
        {
            key: 'sub1',
            value: 'Wedding',
            label: 'Wedding',
        },

        {
            key: 'sub11',
            value: 'Other',
            label: 'Other',
        },
    ];

    const onFieldsChange = (changedFields, allFields) => {
        console.log(changedFields,'changedFields')
        console.log(allFields,'allFields')
        for (const item of allFields) {
            console.log(item,'item')
            if (item.value === null || (Array.isArray(item.value) && item.value.length === 0)) {
                setFormFilled(false);
            }else{
                setFormFilled(true);
            }

        }


    }

    return(
        <>
        <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Create event</h3>
    </div>
    <div className={'mt-4 album-notifications-select'}>
        <Form
            form={form}
            size={"large"}
            name="validate_other"
            onFinish={onFinish}
            layout="vertical"
            initialValues={initialValues}
            style={{
                maxWidth: 600,
                margin: "auto"
            }}
            validateMessages={validateMessages}
            onFieldsChange={onFieldsChange}
        >
            <div ref={ref1}>
            <Form.Item
                    name="client_id"
                    label="Select Client"
                    rules={[{required: true}]}
            >
                {isPostsLoading && <Spin />}
                {!isPostsLoading && (
                    <Select
                        showSearch
                        placeholder="Search to Client"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={clientListData.map((client) => ({ value: client.value, label: client.label }))}
                    />
                )}
            </Form.Item>
            </div>
            <div ref={ref2}>
            <Form.Item
                name="photographer_id"
                label="Select Photographer"
                rules={[{required: true}]}
            >
                {isPhLoading && <Spin />}
                {!isPhLoading && (
                <Select
                    mode="multiple"
                    placeholder="Search to Photographer"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={phListData.map((ph) => ({ value: ph.value, label: ph.label }))}
                />
                )}
            </Form.Item>
            </div>
            <div ref={ref3}>
            <Form.Item
                name="venue_id"
                label="Select Venue"
            >
                {isVenueLoading && <Spin />}
                {!isVenueLoading && (
                <Select
                    mode="multiple"
                    placeholder="Search to Venue"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={venueListData.map((ven) => ({ value: ven.value, label: ven.label }))}
                />
                )}
            </Form.Item>
            </div>
            <Row ref={ref4} justify="space-between">
                <Col  lg={8} md={8} span={24}>
                    <Form.Item
                      name="event_name"
                      label="Event Name"
                      rules={[{required: true}]}
                    >
                    <Input />
                    </Form.Item>
                </Col>
                <Col lg={8} md={8} span={24}>
                    <Form.Item
                        name="event_type"
                        label="Event Type"
                        rules={[{required: true}]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Type"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            options={options}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={6} span={24}>
                    <Form.Item name="event_date" label="Event Date" {...config} className={'date-picker'}>
                        <DatePicker  format='MM-DD-YYYY'/>
                    </Form.Item>
                </Col>
            </Row>
            <Container  className={'text-center'}>
                <Button ref={ref5} type="submit" className='formBtn send-email-btn' >
                    Create
                </Button>
            </Container>
        </Form>
        {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
        </div>
        </>
        )

}
export default AddEvent;