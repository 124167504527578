import React, {useEffect, useState} from 'react';

export const CartContext = React.createContext({
    cart: [], // Ensure this is an array
    setCart: () => {},
    // Any other initial states or functions
});

export const CartProvider = ({ children }) => {
    let localCart = localStorage.getItem('cart');
    if (!localCart) {
        localCart = [];
    } else {
        localCart = JSON.parse(localCart);
    }
    const [cart, setCart] = useState(localCart);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [textValues, setTextValues] = useState('');
    const [processedDataI, setProcessedDataI] = useState(null);
    const [isSwitchCheckedI, setIsSwitchCheckedI] = useState(0)

    const updateQuantity = (newQuantity) => {
        setQuantity(newQuantity);
    };

    useEffect(() => {
      const quantity = cart.reduce((total, item) => total + Number(item.quantity), 0);

        setTotalQuantity(quantity);
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item) => {
        setCart((currentCart) => [...currentCart, item]);
    };

    const removeFromCart = (index) => {
        if (index === 'all') {
            setCart([]);
        } else {
            setCart((currentCart) => {
                const updatedCart = [...currentCart];
                updatedCart.splice(index, 1);
                return updatedCart;
            });
        }
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                setCart,
                addToCart,
                removeFromCart,
                totalQuantity,
                quantity,
                updateQuantity,
                setQuantity,
                processedDataI,
                setProcessedDataI,
                isSwitchCheckedI,
                setIsSwitchCheckedI,
                textValues,
                setTextValues,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
