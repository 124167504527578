import React from 'react';
import AddCardsLab from "../AddCardsLab";

const AddFlatCardsLab = () => {

  const nameCards = "Flat Cards";
  const navigate_link = '/labs/flat_cards_list';
  const mainCardType = 1;

  return (
    <div>
      <AddCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};



export default AddFlatCardsLab;


