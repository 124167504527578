import React, { useEffect, useState } from 'react';
import { Col, Row } from "antd";
import { createDefaultImageReader, createDefaultImageWriter, processImage } from "@pqina/pintura";

const CardPreview = ({ cardObject }) => {
    const baseHeight = 150;
    const [processedImages, setProcessedImages] = useState({ front: [], back: [] });

    useEffect(() => {
        const processImagesForSide = async (side) => {
            const processed = await Promise.all(cardObject[side].images.map(async (image) => {
                return await processCardDataIfAvailable(image);
            }));
            setProcessedImages(prev => ({ ...prev, [side]: processed }));
        };

        // Initiate processing for both sides
        processImagesForSide('front');
        processImagesForSide('back');
    }, [cardObject]);

    const processCardDataIfAvailable = async (currentImage) => {
        if (currentImage?.crop_data) {
            try {
                const { crop_data } = currentImage;
                const res = await processImage(currentImage.img_path, {
                    imageReader: createDefaultImageReader(),
                    imageWriter: createDefaultImageWriter(),
                    imageCrop: crop_data,
                    imageRotation: 0
                });
                return URL.createObjectURL(res.dest);
            } catch (error) {
                console.error("Error while processing image:", error);
                return currentImage?.img_path; // Fallback to original path in case of error
            }
        } else {
            return currentImage?.img_path;
        }
    };

    const renderCardSide = (side) => {
        const { template } = cardObject[side];
        const aspectRatio = template[0]?.W / template[0]?.H;
        const baseWidth = baseHeight * aspectRatio;
        const scalingFactor = baseWidth / template[0]?.W;

        const processTextWithIndent = (text) => {
            const lines = text.split('\n');
            const loveIndex = lines.findIndex(line => line.includes('Love,'));

            if (loveIndex !== -1) {
                lines[loveIndex] = '<br>'+'<br>' + lines[loveIndex] +'<br>';
            }
            return lines.join('\n');
        };

        const renderTextBlock = (textBlock, index) => {
            const originalStyle = cardObject[side].text[index]?.style || {};
            const originalFontSize = parseFloat(originalStyle.fontSize);
            const originalLineHeight = parseFloat(originalStyle.lineHeight);

            const scaledFontSize = originalFontSize ? originalFontSize * scalingFactor : undefined;
            const scaledLineHeight = originalLineHeight ? originalLineHeight * scalingFactor : undefined;

            const scaledStyle = {
                ...originalStyle,
                fontSize: scaledFontSize ? `${scaledFontSize}px` : originalStyle.fontSize,
                lineHeight: scaledLineHeight ? `${scaledLineHeight}px` : originalStyle.lineHeight,
            };
            const htmlText = cardObject[side].text[index]?.html;
            const processedHtml = processTextWithIndent(htmlText);

            return (
              <div key={index} style={{
                  position: 'absolute',
                  left: `${textBlock?.L * scalingFactor}px`,
                  top: `${textBlock?.T * scalingFactor}px`,
                  width: `${textBlock?.W * scalingFactor}px`,
                  height: `${textBlock?.H * scalingFactor}px`,
              }}>
                  <div className="shopping_cart_text"
                       style={{
                           ...scaledStyle,
                           width: `${textBlock?.W * scalingFactor}px`,
                           height: `${textBlock?.H * scalingFactor}px`,
                         top:'3px',
                         left:'3px',
                       }}
                       dangerouslySetInnerHTML={{ __html: processedHtml }}
                  />
              </div>
            );
        };

        return (
          <div style={{ position: 'relative', width: `${baseWidth}px`, height: `${baseHeight}px` }}>
              <img src={template[0]?.P} alt="Template" style={{ width: '100%', height: 'auto' }} />
              {processedImages[side].map((processedImage, index) => (
                <div key={index} style={{
                    position: 'absolute',
                    left: `${template[1][index]?.L* scalingFactor}px`,
                    top: `${template[1][index]?.T * scalingFactor}px`,
                    width: `${template[1][index]?.W * scalingFactor}px`,
                    height: `${template[1][index]?.H * scalingFactor}px`,
                    backgroundImage: `url(${processedImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  transform: `${template[1][index]?.R === 1 ? 'rotate(180deg)' : 'rotate(0deg)'}`
                }} />
              ))}
              {template[2] && template[2].map(renderTextBlock)}
          </div>
        );
    };

    return (
      <Row justify='space-between'>
          <Col span={24}>
              <h6>Front</h6>
              {renderCardSide('front')}
          </Col>
          <Col span={24}>
              <h6>Back</h6>
              {renderCardSide('back')}
          </Col>
      </Row>
    );
};

export default CardPreview;
