import axios from "axios";


const instance = axios.create({
    baseURL: 'https://dev.studioseye.com/api/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
});


export const clientsAPI = {
    getClients(studioID){
        return instance.post(`getclients?studio_id=${studioID}`)
    },

};

export const studiosAPI = {
    getClientsList(studioID,currentPage,perPage) {
        return instance.post(`getstudiosclientlist?studio_id=${studioID}&currentPage=${currentPage}&perPage=${perPage}`)
    }
}


export const galleryAPI = {
    getEventsByClientId(clientId) {
        return instance.post(`geteventsbyclientid?client_id=${clientId}`)
    }
}


export const loginAPI = {
    getLoginInfo(data){
        return instance.post(`login?uname=${data.username}&pass=${data.password}`)
    },

    getUserInfo(data){
        return instance.post(`checkauth?token=${data.token}&uid=${data.uid}&user_type=${data.user_type}`)
    }


}



export default class dataApi {

    static async getAll(studio_id,  current, page) {
        try {
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post(
                'https://dev.studioseye.com/api/getstudiosclientlist',
                {
                    user_id: user_id,
                    studio_id: studio_id,
                    currentPage: current,
                    perPage: page,
                },
                { headers } // Pass the headers object in the request config
            );

            return response.data;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getClientListByStudioIdToken(studio_id, offset, limit) {
        try {
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/getclientlistbystudioidtoken', {
                    user_id: user_id,
                    studio_id: studio_id,
                    offset,
                    limit
                },
                { headers } // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getPhListByStudioIdToken(studio_id,token) {

        try {
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/list_ph_by_studio_id', {
                    user_id: user_id,
                    studio_id: studio_id,
                },
                { headers } // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async createClient(client_data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const studio_id = localStorage.getItem('studio_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/create_client', {
                    user_id: user_id,
                    studio_id: studio_id,
                    client_data: client_data,
                },
                { headers } // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async createPh(client_data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            let studio_id =  parseInt(localStorage.getItem('studio_id'));
            if(isNaN(studio_id)){
                studio_id = user_id;
            }
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/create_ph', {
                    user_id: user_id,
                    studio_id: studio_id,
                    ph_data: client_data,
                },
                {headers} // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async createEvent(client_data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const studio_id = localStorage.getItem('studio_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/create_event', {
                    user_id: user_id,
                    studio_id: studio_id,
                    event_data: client_data,
                },
                {headers} // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async createStudio(lab_id,studio_data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/create_studio', {
                    user_id: user_id,
                    lab_id: lab_id,
                    studio_data: studio_data,
                },
                {headers} // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async createStaff(staff_data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const user_type = localStorage.getItem('user_type');
            let parent_id =  parseInt(localStorage.getItem('studio_id'));
            if(user_type === "LAB"){
                parent_id = user_id;
            }else if(user_type === "STUDIO"){
                if(isNaN(parent_id)){
                    parent_id = user_id;
                }
            }
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post(`https://dev.studioseye.com/api/staff_add`, {
                    user_id: user_id,
                    parent_id: parent_id,
                    staff_data:staff_data
                },
                {headers} // Pass the headers object in the request config
            );
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async getClientListByStudioID(studio_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/getclientslistbystudioid', {
                    user_id: user_id,
                    studio_id: studio_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            console.error(error);
        }
    }

    static async getVenueListByStudioID(studio_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/getvenuelistbystudioid', {
                    user_id: user_id,
                    studio_id: studio_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            console.error(error);
        }
    }

    static async getPhListByStudioID(studio_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/getphlistbystudioid', {
                    user_id: user_id,
                    studio_id: studio_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getStudioListByLabIdToken(lab_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/getstudiolist', {
                    user_id: user_id,
                    lab_id: lab_id

                },
                {headers}
            );
        } catch (error) {
            console.error(error);
        }
    }

    static async getStudioInfoByToken() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/getstudioinfo', {
                    user_id: user_id
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getEventListByStudioID(studio_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/geteventlistbystudioid', {
                    user_id: user_id,
                    studio_id: studio_id,
                    token: token,
                },
                {headers}
            );
        } catch (error) {
            console.error(error);
        }
    }

    static async getStudioByID(studio_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_studio', {
                    user_id: user_id,
                    studio_id: studio_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }




    static  async getEventsByClientId(client_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const user_type = localStorage.getItem('user_type');
            let parent_id =  parseInt(localStorage.getItem('client_id'));
            if(user_type === "LAB"){
                parent_id = user_id;
            }else if(user_type === "STUDIO"){
                if(isNaN(parent_id)){
                    parent_id = user_id;
                }
            }else if(user_type === "CLIENT"){
                if(isNaN(parent_id)){
                    parent_id = client_id;
                }
            }
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/geteventsbyclientid', {
                    user_id: user_id,
                    parent_id: parent_id,
                    client_id: client_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static  async getEventPromoPage(event_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const user_type = localStorage.getItem('user_type');
            let parent_id =  parseInt(localStorage.getItem('event_id'));
            if(user_type === "LAB"){
                parent_id = user_id;
            }else if(user_type === "STUDIO"){
                if(isNaN(parent_id)){
                    parent_id = user_id;
                }
            }else if(user_type === "CLIENT"){
                if(isNaN(parent_id)){
                    parent_id = user_id;
                }
            }
            
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/event_promo_page', {
                    user_id: user_id,
                    event_id: event_id,
                    parent_id: parent_id
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getStaffListByUserID() {
        try {
            const user_id = parseInt(localStorage.getItem('user_id'));
            const user_type = localStorage.getItem('user_type');
            const token = localStorage.getItem('token');
            let parent_id =  parseInt(localStorage.getItem('studio_id'));
            if(!parent_id){
                parent_id = user_id;
            }
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/staff_list', {
                    parent_id: parent_id,
                    user_id: user_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getStaffByParentID(staff_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const user_type = localStorage.getItem('user_type');
            let parent_id =  parseInt(localStorage.getItem('studio_id'));
            if(user_type === "LAB"){
                parent_id = user_id;
            }else if(user_type === "STUDIO"){
                if(isNaN(parent_id)){
                    parent_id = user_id;
                }
            }

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/staff_edit', {
                    user_id: user_id,
                    parent_id: parent_id,
                    staff_id: staff_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getPhotographersByParentID(ph_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const user_type = localStorage.getItem('user_type');
            let parent_id =  parseInt(localStorage.getItem('studio_id'));
            if(user_type === "STUDIO"){
                if(isNaN(parent_id)){
                    parent_id = user_id;
                }
            }

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/edit_ph', {
                    user_id: user_id,
                    parent_id: parent_id,
                    ph_id: ph_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getClientByParentID(client_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            let parent_id =  parseInt(localStorage.getItem('studio_id'));
    
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/client_edit', {
                    user_id: user_id,
                    parent_id: parent_id,
                    client_id: client_id,
                },
                {headers} 
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async checkIsAuth() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/check_auth', {
                    user_id: user_id
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async checkUserType() {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                return await axios.post('https://dev.studioseye.com/api/check_auth', {
                        user_id: user_id
                    },
                    {headers} // Pass the headers object in the request config
                );
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }

    static async changePassword(current_user_id,password) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/change_password', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    password: password,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async updateUserInfo(current_user_id,user_info) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/update_user_info', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    user_info: user_info,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async updateEvent(event_data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const studio_id = localStorage.getItem('studio_id');
            const event_id = localStorage.getItem('event_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/event_update', {
                    user_id: user_id,
                    studio_id: studio_id,
                    event_id: event_id,
                    event_data: event_data,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getEventData(event_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/event_edit', {
                    user_id: user_id,
                    event_id: event_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

     static async chechUsertType() {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                return await axios.post('https://dev.studioseye.com/api/check_user_type', {
                        user_id: user_id,
                    },
                    {headers} // Pass the headers object in the request config
                );
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }

    static async getEventImagesByEventID(offset,take) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const event_id = localStorage.getItem('event_id');
            // const offset = localStorage.getItem('offset');
            // const take = localStorage.getItem('take');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_event_images', {
                    user_id: user_id,
                    event_id: event_id,
                    offset: offset,
                    take: take,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getEventFoldersImages() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const event_id = localStorage.getItem('event_id');
            const studio_id = localStorage.getItem('studio_id');
            const client_id = localStorage.getItem('client_id');

            // const offset = localStorage.getItem('offset');
            // const take = localStorage.getItem('take');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_event_folders_images', {
                    user_id,
                    event_id,
                    studio_id,
                    client_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async addEventFolderImage(imageID, folderId) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/add_event_folder_image', {
                    user_id,
                    folder_id: folderId,
                    image_id: imageID,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async addFavorite(favObj) {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/add_favorite', {
                    studio_id:favObj.studio_id,
                    client_id:favObj.client_id,
                    event_id:favObj.event_id,
                    user_id:favObj.user_id,
                    image_id:favObj.image_id,
                    folder_type:favObj.folder_type,
                    method_mode:favObj.method_mode,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getFavoritImages(favObj) {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_favorite', {
                    studio_id:favObj.studio_id,
                    client_id:favObj.client_id,
                    event_id:favObj.event_id,
                    user_id:favObj.user_id,
                    folder_type:favObj.folder_type,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async checkoutOrder(data,order_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/checkout_order', {
                    user_id,
                    shoppingCartInfo:data.shoppingCartInfo,
                    checkout:data.checkout,
                    shippingAddress:data.shippingAddress,
                    cartData:data.cartData,
                    order_id,

                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getOrderID(data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_order_id', {
                    user_id,
                    shoppingCartInfo:data,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async updateFolderData(folderData) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            console.log(folderData)
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/update_folder_data', {
                    user_id,
                    folderData: folderData
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async removeEventFolderImage(imageID, folderId) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/remove_folder_image', {
                    user_id,
                    folder_id: folderId,
                    image_id: imageID,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async removeEventFolder(folderId) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/remove_folder', {
                    user_id,
                    folder_id: folderId,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getCreditByUserId() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_credit', {
                    user_id,

                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }



    static async getCreditByOwnerId(ownerID) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_credit_owner_id', {
                    user_id,
                    ownerID,

                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getCardByGuid(guid,pw,ph,part) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_card_by_guid', {
                    user_id: user_id,
                    guid: guid,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getProductObjectSizes(main_id,opt_type) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/object_items', {
                    user_id: user_id,
                    main_id:main_id,
                    opt_type:opt_type
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async setProductObjectSizes(obj) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/add_print_price', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    obj
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getPrintPriceByUserId() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            let product_type = localStorage.getItem('product_type');
            if(2 !== parseInt(product_type) ){
                product_type=1
            }
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_print_price', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    product_type:product_type,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getPrintPriceDetails(price_list_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_print_price_details', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                    price_list_id,

                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async updatePrintPriceDetails(obj) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/update_print_price_details', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                    obj
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getPrintPriceHeaderByID(parent_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_print_price_header', {
                    user_id: user_id,
                    parent_id
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getPrintPriceLabDefault(product_type) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_print_price_lab_default', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                    product_type:product_type,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getPrintPriceStudioDefault(product_type) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_print_price_studio_default', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                    product_type:product_type,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getCompletedListZip() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };


            return await axios.post('https://dev.studioseye.com/api/get_completed_zip_by_studio?studio_id=78&user_id=60', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getInProccessListZip() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };


            return await axios.post('https://dev.studioseye.com/api/get_in_process_zip_by_studio?studio_id=78&user_id=60', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    //studio_terms
    static async studioTerms(updatedData) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const studio_id = localStorage.getItem('studio_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/insert_studio_terms', {
                  user_id: user_id,
                  studio_id: studio_id,
                  name:updatedData.name,
                  content:updatedData.content
              },
              { headers } // Pass the headers object in the request config
            );
            console.log(updatedData,'updatedDatAPI')
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async updateStudioTerms(updatedData) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const studio_id = localStorage.getItem('studio_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/update_studio_terms', {
                  user_id: user_id,
                  studio_id: studio_id,
                  id:updatedData.id,
                  name:updatedData.name,
                  content:updatedData.content
              },
              { headers } // Pass the headers object in the request config
            );
            console.log(updatedData,'updatedDatAPI')
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async showStudioTerms() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const studio_id = localStorage.getItem('studio_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/show_studio_terms', {
                  user_id: user_id,
                  studio_id: studio_id,
              },
              { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getCardPrice(obj) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_card_price', {
                  user_id:user_id,
                  name:obj.priceName,
                  sizeInfoData:obj.sizeInfoData

              },
              { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }

    }


    static getHeaders() {
        const token = localStorage.getItem('token');
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
    }

    static async getCardPrices() {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.get('https://dev.studioseye.com/api/card_price_list',
                { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async createCardPrice(data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            console.log(dataApi.getHeaders());
            const response = await axios.post('https://dev.studioseye.com/api/card_price_list',
                {
                    current_user_id:current_user_id,
                    user_id:user_id,
                    data
                },
                { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


        static async getCardListByUserIDAndCardType() {
        try {
            const current_user_id = localStorage.getItem('current_user_id');
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const card_type = localStorage.getItem('card_type');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_card_price', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                    card_type: card_type,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getEditedCardById() {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const edited_card = localStorage.getItem('edited_card');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/edit_card', {
                  user_id: user_id,
                  edited_card: edited_card,
                  current_user_id: current_user_id,
              },
              { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getStudioCardDefailtByType(cardType) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_card_price_studio_default', {
                  user_id: user_id,
                  studio_id: current_user_id,
                  card_type: cardType,
              },
              { headers }
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getCardLabDefaultDetails(cardType) {
        try {
            console.log(cardType)
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_card_price_lab_details', {
                    user_id: user_id,
                    card_type: cardType,
                },
                { headers }
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }



    static async getCreditListByUser() {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/credit_list', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async storeCredit(data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/credit_store', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    ...data
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async storeShoppingCart(shoppingCartData,shoppingCardInfo) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/shopping_cart_store', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    shoppingCartData:shoppingCartData,
                    shoppingCardInfo:shoppingCardInfo,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getShoppingCart(ShoppingCartInfo) {
        try {
         
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const status = 0;
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/shopping_cart_get', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    ShoppingCartInfo:ShoppingCartInfo,
                    status:status
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getShoppingCartOrdered(ShoppingCartInfo) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const status = 1;

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/shopping_cart_get', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                    ShoppingCartInfo:ShoppingCartInfo,
                    status:status

                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getTemplatesByData(data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_template_list_by_data', {
                    user_id: user_id,
                    studio_id: current_user_id,
                    data: data,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async updateCardPrice(id, data) {
        try {
            const response = await axios.put(`https://dev.studioseye.com/api/card_price_list/${id}`,
                data, { headers: dataApi.getHeaders() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async deleteCardPrice(id) {
        try {
            const response = await axios.delete(`https://dev.studioseye.com/api/card_price_list/${id}`,
                { headers: dataApi.getHeaders() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async getCardPriceLabDefault(card_type) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_card_default_lab', {
                  user_id: user_id,
                  current_user_id:current_user_id,
                  card_type:card_type,
              },
              {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getBackgroundsByUserId() {
        try {
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            return axios.get('https://dev.studioseye.com/api/background', {
                headers: headers,
                params: {
                    user_id: user_id,
                    current_user_id: current_user_id
                }
            });
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async checkBackgrounds(background_group) {
        try {
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            return axios.post('https://dev.studioseye.com/api/save_studio_background', {
                    current_user_id: current_user_id,
                    user_id: user_id,
                    background_group:background_group,
                },
                {headers}
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getBackgroundListByUserId() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_studio_background_list', {
                    user_id: user_id,
                    current_user_id: current_user_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getBackgroundDetails(list_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_background_list_id', {
                    user_id: user_id,
                    current_user_id:current_user_id,
                    list_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }


    static async getClientBackgroundByStudioId(studio_id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_default_bg', {
                    current_user_id: studio_id,
                    user_id: user_id,
                },
                {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async savePackage(pkg) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/package_headers', {
                    current_user_id: current_user_id,
                    user_id: user_id,
                    pkg: pkg,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async updatePackage(pkg) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.put(`https://dev.studioseye.com/api/package_headers/${pkg.id}`, {
                    current_user_id: current_user_id,
                    user_id: user_id,
                    pkg: pkg,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getPackageByStudioId() {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/get_package_studios_by_id', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }

        static async getPackageCategory() {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/get_package_category', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }

        static async removePackageCategory(cat_id) {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/remove_package_category', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                        cat_id: cat_id,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }

        static async getPackageAddOn() {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/get_package_add_on', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }

    static async getPackageList() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_package_list', {
                    current_user_id: current_user_id,
                    user_id: user_id,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

        static async addPackageCategory(catName) {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/add_package_category', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                        cat_name: catName,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }


        static async assigneeTemplate(data) {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/assignee_template', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                        data: data,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }


        static async createFolder(data) {
            try {
                const user_id = localStorage.getItem('user_id');
                const token = localStorage.getItem('token');
                const current_user_id = localStorage.getItem('current_user_id');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('https://dev.studioseye.com/api/create_folder', {
                        current_user_id: current_user_id,
                        user_id: user_id,
                        data: data,
                    },
                    { headers }
                );

                return response;
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }


    // Templates
    static async getTemplatesListByUserId() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            return await axios.post('https://dev.studioseye.com/api/get_template_list', {
                  user_id: user_id,
                  current_user_id: current_user_id,
              },
              {headers} // Pass the headers object in the request config
            );
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    // static async getPhListByStudioID(studio_id) {
    //     return await axios.post('https://dev.studioseye.com/api/getphlistbystudioid', {
    //         studio_id: studio_id,
    //     });
    // }


    //CONTRACT

    static async insertContract(data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/insert_contract', {
                    user_id: user_id,
                    studio_id: current_user_id,
                    data: data,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getContractListByStudioId() {
        try {
            const user_id = localStorage.getItem('user_id');
            const current_user_id = localStorage.getItem('current_user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/get_contract_list', {
                    user_id: user_id,
                    studio_id: current_user_id,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    static async getClientInfo() {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/client_info', {
                    user_id: user_id,
                },
                { headers }
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    static async getClientInfoByID(id) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/client_info', {
                    user_id: user_id,
                    id
                },
                { headers }
            );
            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

    // Stripe

    static async charge(data) {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');
            const current_user_id = localStorage.getItem('current_user_id');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.post('https://dev.studioseye.com/api/charge', {
                    current_user_id: current_user_id,
                    user_id: user_id,
                    data: data,
                },
                { headers }
            );

            return response;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }

}



