import React, { useEffect, useState} from 'react';
import { Col, Row, Table, Space, Tag,Modal , Form, Input, Select,} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  MailOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import {showDeleteConfirm} from "../../../../Utils/isFunction";
import moment from "moment";
import PDFGenerator from "../PDFGenerator";
import Contract from "../ContractAdd/Contract";
import ReactQuill from "react-quill";
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientAPI from "../../../../../api/api";

const ContractList = () => {
  const [contracts, setContracts] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [isModalEmail, setIsModalEmail] = useState(false);
  const [isModalPDF, setIsModalPDF] = useState(false);
  const [isModalContract, setIsModalContract] = useState(false);
  const [pdfId, setPdfId] = useState(null);
  const dateFormat = "MM-DD-YYYY";

  const returnCurrentDate = (current_date) => {
    const date = moment(current_date);
    return date.format(dateFormat);
  }
  const showModal = () => {
    setIsModalEmail(true);
  };
  const handleOk = () => {
    setIsModalEmail(false);
  };
  const handleCancel = () => {
    setIsModalEmail(false);
    setIsModalPDF(false);
    setIsModalContract(false);
  };

  const [getContract, getContractLoading, getContractError] = useFetching(async () => {
    const {data: res} = await clientAPI.getContractListByStudioId();
    if (res) {
      try {
        const dataStorage = res;

        if (dataStorage && Array.isArray(dataStorage)) {
          const contractDataALL = dataStorage.map((contract, index) => ({
            id:contract.contract_data.id,
            key: index,
            count: index + 1,
            contract: `Contract ${index + 1}`,
            clients: contract.contract_data?.clients || [],
            status: 1,
            eventType: contract.contract_data?.type,
            eventData: returnCurrentDate(contract.contract_data?.eventDate),
            ...contract
          }));
          setContracts(contractDataALL);
          setContractList(dataStorage);
        } else {
          console.log('No contract data available');
        }
      } catch (error) {
        getContractError()
        console.error('Error fetching data', error);
      } finally {
        getContractLoading()
      }
    }
  });

  useEffect(() => {
    getContract();
  }, []);



  const handleDelete = (id) => {
    showDeleteConfirm(id, 'Contract').then((result) => {
      if (result) {
        const updatedData = contracts.filter((item) => item.id !== id);
        setContracts(updatedData);
      } else {
        console.log('NO')
      }
    });
  };
  const handlePreview = (id) => {
    setPdfId(id);
    setIsModalPDF(true);
  };
  const handleEdit = (id) => {
    setPdfId(id);
    setIsModalContract(true);
  };
  const columns = [
    {
      key: 'count',
      title: '#',
      dataIndex: 'count',
      align:"center",
    } ,
    {
      key: 'contract',
      title: 'Contract',
      dataIndex: 'contract',
      align:"center",
    },{
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      align:"center",
      render: (status) => (
        <Space size={[0, 8]}  direction={"vertical"}>
          {status === 1 && (
            <Tag icon={<CheckCircleOutlined />} color="success">
              success
            </Tag>
          )}
          {status === 2 && (
            <Tag icon={<SyncOutlined spin />} color="processing">
              processing
            </Tag>
          )}
          {status === 3 && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              error
            </Tag>
          )}
          {status === 4 && (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              warning
            </Tag>
          )}
          {status === 5 && (
            <Tag icon={<ClockCircleOutlined />} color="default">
              waiting
            </Tag>
          )}
          {status === 6 && (
            <Tag icon={<MinusCircleOutlined />} color="default">
              stop
            </Tag>
          )}
        </Space>
      ),
    },
    {
      key: 'eventType',
      title: 'Event Type',
      dataIndex: 'eventType',
      align:"center",
    },
    {
      key: 'eventData',
      title: 'Event Data',
      dataIndex: 'eventData',
      align:"center",
    },
    {
      key: 'clients',
      title: 'Client Name ',
      dataIndex: 'clients',
      align:"center",
      render: (clientsArray) => (
        <span>
        {Array.isArray(clientsArray) && clientsArray.length > 0 ? (
          clientsArray.map((client, index) => (
            <React.Fragment key={client.clientId}>
              <br />
              <p>{client.clientfirstname} {client.clientlastname}</p>
              {index < clientsArray.length - 1 && <hr />}
            </React.Fragment>
          ))
        ) : (
          "No clients"
        )}
      </span>
      ),
    },
    {
      key: 'clients',
      title: 'Client Email',
      dataIndex: 'clients',
      align:"center",
      render: (clientsArray) => (
        <span>
        {Array.isArray(clientsArray) && clientsArray.length > 0 ? (
          clientsArray.map((client, index) => (
            <React.Fragment key={client.clientId}>
              {client.email.map((email, emailIndex) => (
                <React.Fragment key={emailIndex}>
                  {email}
                  {emailIndex < client.email.length - 1 && <br />}
                </React.Fragment>
              ))}
              {index < clientsArray.length - 1 && <hr />}
            </React.Fragment>
          ))
        ) : (
          "No client emails"
        )}
      </span>
      ),
    },
    {
      key: 'clients',
      title: 'Client Phone ',
      dataIndex: 'clients',
      align:"center",
      render: (clientsArray) => (
        <span>
        {Array.isArray(clientsArray) && clientsArray.length > 0 ? (
          clientsArray.map((client, index) => (
            <React.Fragment key={client.clientId}>
              <br />
              <p>{client.phone}</p>
              {index < clientsArray.length - 1 && <hr />}
            </React.Fragment>
          ))
        ) : (
          "No client phones"
        )}
      </span>
      ),
    },
    {
      title: ' Preview',
      dataIndex: 'preview_contract',
      align:"center",
      key: (record) => record.id,
      render: (_, record) => (
        <EyeOutlined  className="my_ant_icons_table"  onClick={() => handlePreview(record.id)}/>
      ),
    },
    {
      title: 'Send',
      dataIndex: 'send',
      key: (record) => record.id,
      align:"center",
      render: (_, record) => (
        <MailOutlined   className="my_ant_icons_table"  onClick={showModal} />
      ),
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: (record) => record.id,
      render: (_, record) => (
        <EditOutlined className="my_ant_icons_table"  onClick={() => handleEdit(record.id)}/>
      ),
    },
    {
      key: 'delete',
      title: 'Delete',
      align:"center",
      render: (_, record, index) => (
        <DeleteOutlined
          className="my_ant_icons_table"
          onClick={() => handleDelete(record.id)}
        />
      ),
    },
  ]
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']

    ]
  };

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list','ordered', 'bullet', 'link', 'image', 'video', 'color', 'background',
    'font', 'align', 'size', 'indent', 'direction', 'script', 'code-block', 'clean','p','ol'
  ];

  return (
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Contract List</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        <Row>
          <Col span={24} className='mt-4'>
            {contracts && <Table
                className="contactListTable priceTable"
                dataSource={contracts}
                columns={columns}
                bordered={true}
                rowKey={(record) => record.key}
                pagination={false}
                scroll={{ x: 100 }}
                loading={getContractLoading}
              />}
          </Col>
        </Row>
      </div>
      <Modal  open={isModalEmail} onOk={handleOk} onCancel={handleCancel}>
        <Form  layout="vertical"  >
          <Row justify='space-between'>
            <Col span={10}>
              <Form.Item label="Select Client " className='mb-4'>
                <Select>
                  <Select.Option value="demo">Demo</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item label="Select Email Template " className='mb-4'>
                <Select>
                  <Select.Option value="demo">Demo</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Subject / Title" className='mb-4'>
                <Input></Input>
              </Form.Item>
              <Form.Item label="Email Template Body">
                <ReactQuill
                  modules={modules}
                  formats={formats}
                  placeholder="Enter your Email Template..."
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal  open={isModalPDF}  onCancel={handleCancel} className='PDFModal' footer={null}>
        <PDFGenerator pdfId={pdfId} contractList={contractList} />
      </Modal>
      <Modal  open={isModalContract}  onCancel={handleCancel} className='PDFModal' footer={null}>
        <Contract pdfId={pdfId} contractList={contractList} />
      </Modal>


    </>
  )
};
export default ContractList;