import React from 'react';
import { useNavigate } from 'react-router-dom';
import {LogoutOutlined} from "@ant-design/icons";
import  './ClientDashbord.css'
import style from './Gallery/Gallery.module.css'
import {Button, Tooltip} from "antd";

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <Tooltip title="Logout">
      <span  className="Logout"  onClick={handleLogout}>
      <LogoutOutlined/> <span className={style.CompareText}> Logout</span>
     </span>
    </Tooltip>
  );
}

export default LogoutButton;
