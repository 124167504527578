import React from 'react';
import { Result, Typography } from 'antd';

const CheckoutOrder = ({orderNumber}) => {
  const { Title, Paragraph } = Typography;
  return (
    <div>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Title level={3} style={{ color: '#223d8f' }}>
          Thank you for your order!
        </Title>
        {orderNumber ? (
          <>
            <Paragraph>
              <Result
                status="success"
                title="Your order number is:"
                subTitle={orderNumber}
              />
            </Paragraph>
          </>
        ) : (
          <Paragraph>Loading order details...</Paragraph>
        )}
      </div>
    </div>
  );
};

export default CheckoutOrder;