import React from 'react';
import './BookCoverStyle.css';

const BookCoverStyle = () => {
    return (
        <div className="book-container">
            <div className="cover-left"></div>
            <div className="spine">
                <div className="spine_white_shadow"></div>
                <div className="spine_black_shadow"></div>
            </div>
            <div className="cover-right"></div>
        </div>
    );
}

export default BookCoverStyle;