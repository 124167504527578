import {Button, Col, Input, Modal, Row, Space, Table} from 'antd';
import React, {useEffect, useState} from 'react';

import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
import {Checkbox} from 'antd';
import {useNavigate} from 'react-router';
import LoadingSpin from "../../Utils/LoadingSpin";
import {DeleteOutlined} from "@ant-design/icons";
import {showDeleteConfirm} from "../../Utils/isFunction";


const EditBackground = () => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editedData, setEditedData] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [text, setText] = useState("");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [checked, setChecked] = useState("NO");
    const navigate = useNavigate();
    const [previewImage, setPreviewImage] = useState({
        name: '',
        path: '',
    });
    const studio_id = localStorage.getItem('studio_id');
    if (studio_id && studio_id.trim() !== "") {
        localStorage.setItem('current_user_id', studio_id);
    }
    const [getBackgroundList, backgroundLoading, backgroundError] = useFetching(async (price_list_id) => {
        const {data: res} = await clientAPI.getBackgroundsByUserId();
        if (res) {
            setData(res);
        }
    });

    const [saveBackgroundCheckSuccess, backgroundCheckLoading, backgroundCheckError] = useFetching(async (obj) => {
        const {data: res} = await clientAPI.checkBackgrounds(obj);
        if (res) {
            // setLoading(false);
            console.log(res);
            let currentPath = '';
            const user_type = localStorage.getItem('user_type');
            console.log("user_type", user_type)
            if (user_type === "LAB") {
                currentPath = '/labs/background_group_list';
            } else if (user_type === "STUDIO") {
                currentPath = '/studio/background_group_list';
            }
            navigate(currentPath);
            window.location.reload();
        }
    });


    const [getBackgroundByParentID, backgroundGetLoading, backgroundGetError] = useFetching(async (parent_id) => {
        const {data: res} = await clientAPI.getBackgroundDetails(parent_id);
        if (res) {
            setText(res.name);
            // Check the checkbox if is_default is "YES"
            setCheckboxChecked(res.is_default === "YES");
            // Check the rows in the table based on the backgrounds array
            const selectedBackgroundIds = res.backgrounds.map(bg => bg.id);
            setSelectedRowKeys(selectedBackgroundIds);
            const newEditedData = res.backgrounds.reduce((acc, bg) => {
                acc[bg.id] = bg;
                return acc;
            }, {});
            setEditedData(newEditedData);
            console.log(res, "res");
        }
    });

    const handleCancel = () => setPreviewOpen(false);
    const handleInputChangeText = (e) => {
        setText(e.target.value);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        // Determine which rows were added or removed
        const addedKeys = newSelectedRowKeys.filter(key => !selectedRowKeys.includes(key));
        const removedKeys = selectedRowKeys.filter(key => !newSelectedRowKeys.includes(key));
        // Update editedData based on added or removed rows
        setEditedData(prevState => {
            let newState = {...prevState};
            // Add newly selected rows to editedData
            addedKeys.forEach(key => {
                const record = data.find(item => item.id === key);
                if (record) {
                    newState[key] = record;
                }
            });

            // Remove unselected rows from editedData
            removedKeys.forEach(key => {
                delete newState[key];
            });
            return newState;
        });
    };


    const handleChecked = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const inputChange = (event, record) => {
        const updatedValue = event.target.value;
        // Check if the row is selected
        if (selectedRowKeys.includes(record.id)) {
            setEditedData(prevState => ({
                ...prevState,
                [record.id]: {
                    ...record,
                    name: updatedValue
                }
            }));
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSave = async () => {
        const parent_id = localStorage.getItem('parent_id');
        const obj = {
            parent_id: parent_id,
            background_group: editedData,
            is_default: checked,
            name: text
        };
        console.log(obj);
        saveBackgroundCheckSuccess(obj);
    };

    const handleChange = (rec) => {
        setPreviewOpen(true);
        setPreviewImage({
            name: rec.name,
            path: `/background/${rec.path}`,
        });
    };


    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
            width: 400,
            align: 'center',
            render: (text, record) => {
                const editedValue = editedData[record.id]?.name;
                const isRowSelected = selectedRowKeys.includes(record.id);
                return (
                    <Input
                        value={editedValue || text}
                        onChange={e => inputChange(e, record)}
                        disabled={!isRowSelected} // Disable the input if the row is not selected
                    />
                );
            }
        },
        {
            title: 'Background',
            dataIndex: 'path',
            key: "path",
            align: 'center',
            render: (path, record) => (
                <img
                    onClick={e => handleChange(record)}
                    src={`/background/${path}`}
                    alt="Background"
                    style={{width: '70px', height: '70px'}} // Optional: set width and height if you want
                />
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            align: 'center',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined className="my_ant_icons_table" onClick={() => deleteItem(record.id)}/>
                </Space>
            ),
        },
    ];
    const deleteItem = (id) => {
        showDeleteConfirm(id, 'Background').then((result) => {
            if (result) {
                console.log('yes')
            } else {
                console.log('NO')
            }
        });
    };
    useEffect(() => {
        const parent_id = localStorage.getItem('parent_id');
        getBackgroundByParentID(parent_id)
        getBackgroundList();
    }, []);

    useEffect(() => {
        checkboxChecked ? setChecked("YES") : setChecked("NO")
    }, [checkboxChecked]);

    return (
        <>
            <Modal open={previewOpen} title={previewImage.name} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage.path}
                />
            </Modal>
            <div className="text-center mt-4">
                <h3 className="text-uppercase mb-4 header-title">Background List LAB</h3>
            </div>


            <div style={{margin: 16}} className='inputshHader'>
                <Row>
                    <Col md={24} span={24}>
                        <label className="textInput">Price list name</label>
                        <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
                    </Col>
                    <Col md={12} span={24}>
                        <Checkbox className="default_checkbox" checked={checkboxChecked}
                                  onChange={() => handleChecked()}>
                            <div className="default_text">Check if you want set your default price list</div>
                        </Checkbox>
                    </Col>
                    <Col md={12} span={24} className='btnHeader'>
                        <Button loading={backgroundCheckLoading} className="btn_price btn_save background__button-save"
                                st="true" onClick={handleSave}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
            <Table
                className="table-striped-rows studio-table-rows  background_Table"
                rowSelection={rowSelection}
                loading={backgroundLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                key={(record) => record.id}
            />
        </>
    );
};

export default EditBackground;
