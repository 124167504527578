import React, { useState} from 'react';
import {Input, Row, Col,Form} from 'antd';

const SelectOther = () => {
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
      <Row justify="space-between">
        <Col span={24} className='mb10'>
          <Form.Item name="myown_folder" label="My Own Folder" rules={[{ required: true }]} >
            <Input
                placeholder="My Own Folder"
                value={inputValue}
                onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
    );
};
export default SelectOther;
