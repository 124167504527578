import React from 'react';
import AddCardsLab from "../AddCardsLab";

const AddFoldOverLab = () => {

  const nameCards = "Fold Over";
  const navigate_link = '/labs/fold_over_list';
  const mainCardType = 3;

  return (
    <div>
      <AddCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};

export default AddFoldOverLab;


