import * as React from 'react';
import {Form, Input,Modal,Button,notification, Space} from 'antd';
import clientAPI from "../../api/api";
import {useFetching} from "../../hoc/fetchingHook";

const EditPassword = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Password Change',
            description:
                'Your password successfully changed.',
        });
    };
  const showModal = () => {
    setIsModalOpen(true);
   
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

    const [changePassword, isChangeLoading, ChangeError] = useFetching(async (current_user_id,password) => {
        const { data: res } = await clientAPI.changePassword(current_user_id,password);
        if (res) {
            openNotificationWithIcon('success');
            setIsModalOpen(false);
        }
    });

  const onFinish = (values) => {
  const current_user_id = localStorage.getItem('current_user_id');
  changePassword(current_user_id,values);
};

  return (
    <>
       {contextHolder}
      <Button type="primary"  onClick={showModal}>
     Change Password
    </Button>
    <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form
        form={form}
        name="change_pass"
        size={"large"}
        onFinish={onFinish}
        layout="vertical"
        style={{
          maxWidth: 600, 
          margin: "auto"
        }}
        scrollToFirstError
      >
          <Form.Item
              name="password"
              label="Password"
              rules={[
                  {
                      required: true,
                      min:6,
                      message: 'Please input your password min 6 symbol!',
                  },
              ]}
              hasFeedback
          >
              <Input.Password autoComplete="new-password"/>
          </Form.Item>

          <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                  {
                      required: true,
                      min:6,
                      message: 'Please input your password min 6 symbol!',
                  },
                  ({ getFieldValue }) => ({
                      validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                  }),
              ]}
          >
              <Input.Password autoComplete="new-password"/>
          </Form.Item>

      </Form>
    </Modal>
    </>

  );
};
export default EditPassword;