import React from 'react';
import { Button } from 'antd';

const HelperShow = ({ helperShow, setHelperShow }) => {
  const toggleHelperShow = () => {
    setHelperShow(!helperShow);
  };

  return (
      <Button
        className='helperShow'
        type="primary"
        onClick={toggleHelperShow}
      >
        Show Helper Tour
      </Button>
  );
};

export default HelperShow;
