import React from 'react';
import AddCardsStudio from "../AddCardsStudio";

const AddPhotoCardStudio = ({setHelperShow,helperShow}) => {

  const nameCards = "Photo Cards";
  const navigate_link = '/studio/photo_cards_list';
  const mainCardType = 4;

  return (
    <div>
      <AddCardsStudio nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} setHelperShow={setHelperShow} helperShow={helperShow}/>
    </div>
  );
};

export default AddPhotoCardStudio;


