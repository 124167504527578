import React, {useState} from "react";
import { useForm } from "react-hook-form";
import usernameLogo from '../../Images/Group 40.png';
import passwordLogo from '../../Images/password.png';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ForgetPassword from "./ForgetPassword";
import Button from "react-bootstrap/Button";
import {loginAPI} from "../../api/api";
import DashboardContainer from "../Dashboard/DashboardContainer";
import PasswordVisibilityToggle from "./PasswordVisibilityToggle";



export default function LoginForm() {
    const { register, watch,  formState: { errors }, handleSubmit  } = useForm();

    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    // React.useEffect(() => {
    //     const subscription = watch((value, { name, type }) => console.log(value, name, type));
    //     return () => subscription.unsubscribe();
    // }, [watch]);
    React.useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);
    const  onSubmitLogin = data => {

        loginAPI.getLoginInfo(data).then(response => {
            //SET LOCAL STORAGE
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('user_id', response.data.user_id)
            localStorage.setItem('current_user_id', response.data.user_id)
            localStorage.setItem('user_type', response.data.user_type)
            if(response.data.user_type === 'STUDIO'){
                localStorage.setItem('studio_id', response.data.user_id)
            }else if(response.data.user_type === 'CLIENT'){
                localStorage.setItem('client_id', response.data.user_id)
            }
            else if(response.data.user_type === 'STAFF'){
                localStorage.setItem('staff_id', response.data.user_id)
            }
            const rememberMeChecked = watch("checkbox");
            if (rememberMeChecked) {
                localStorage.setItem('remember_me', 'true');
            } else {
                localStorage.removeItem('remember_me');
            }
            // SET DATA STATE
            setRedirect(true);
            setErrorMessage('');

        }).catch(function (error) {
            if (isMounted) {
                if (error.response) {
                    console.log(error.response.data)
                    setErrorMessage(error.response.data.message);

                }
            }
        });
    };

    if(redirect){
        return (
            <DashboardContainer/>
        );
    }
    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmitLogin" */
        <form onSubmit={handleSubmit(onSubmitLogin)} >
            {/* register your input into the hook by invoking the "register" function */}
            <div className='col-lg-5 col-md-6 col-10 mt-5 right-sidebar login-form'>
                <div className='form-group login-input login-bg pt-0'>
                    <div className='p-2 pb-0'>
                        <img src={usernameLogo} alt="username-icon" className='login-icons'/>
                        <input  {...register("[username]", { required: true })} className='border-0 input-username d-inline login-bg form-control' placeholder='Username'/>
                    </div>
                    {errors.username &&  <p className={'error-message text-end required-inp'}>Username is required</p>}
                    <hr className={'login-hr'}/>
                    <div className='p-2 pt-0 login-div'>
                        <div className='text-lg-start text-md-start text-sm-start'>
                            <img src={passwordLogo} alt="password-icon" className='login-icons'/>
                            <input
                              {...register("[password]", { required: true })}
                              type={showPassword ? 'text' : 'password'}
                              // type={'password'}
                              className='border-0 input-username d-inline login-bg form-control'
                              placeholder='Password'
                            />
                        </div>
                        <div className={'text-lg-end text-md-end text-sm-end'}>
                            <PasswordVisibilityToggle onToggle={(visible) => setShowPassword(visible)} />
                        </div>
                    </div>

                    {errors.password && <p className={'error-message text-end pass-required required-inp'}>Password is required</p>}
                </div>
                <p className={'error-message text-end px-3'}> {errorMessage} </p>
            </div>
            <Container fluid className='col-lg-5 col-md-6 col-10 mt-3 p-0'>
                <Row>
                    <Col  lg={12} className={'remember-me-container'}>
                        <label className={'p-0'}>
                            <input {...register("checkbox")} type="checkbox"  className={'remember-me-input'}/>
                            Remember me
                        </label>
                    </Col>
                    <Col  lg={12} className={'remember-me-container'}>
                        <ForgetPassword email='' errorMessage='' successMessage='' />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='col-lg-5 col-md-6 col-10 mt-3 mb-5 p-0'>
                <div  className='mt-3 btnDiv '>
                        <Button className="text-uppercase create-btn btn-media mt-2">
                            Create an account
                        </Button>
                        <Button type="submit" className="text-uppercase login-btn next  btn-media mt-2">
                            Login
                        </Button>
                </div>
            </Container>
        </form>
    );
}