import React,{useEffect, useState } from 'react';
import clientAPI from "../../../api/api";
import getColumnSearchProps from '../../Utils/getColumnSearchProps'
import { useFetching } from "../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import {sortColumns, onChangeTable, showDeleteConfirm} from '../../Utils/isFunction'
import { Space, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const ListStaff = () => {

    const navigate = useNavigate();
    const PREFIX = "https://studioseye.com/pricing/upload/"; // Replace with your prefix
    const [data, setData] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
        },
    });
    const handleTableChange = onChangeTable(tableParams, setTableParams);
    
    const [getStudioList, isStudiosLoading, studioError] = useFetching(async () => {
        const { data: res } = await clientAPI.getStaffListByUserID();
        console.log(res)
        if (res) {
            setData(res.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: res.total,
                },
            });
        }
    });

    useEffect(() => {
        getStudioList();
    }, []);

    const edit_staff = (staff_id) => {
        localStorage.setItem('staff_id',staff_id);
        localStorage.setItem('current_user_id',staff_id);
        if(localStorage.getItem("user_type") === "LAB"){
        navigate('/labs/staff_edit');
        }
         if(localStorage.getItem("user_type") === "STUDIO"){
        navigate('/studio/staff_edit');
        }
        window.location.reload();
    };
    const deleteStaff = (staff_id) => {
        showDeleteConfirm(staff_id, 'Staff').then((result) => {
            if (result) {
                console.log('yes')
                const updatedData = data.filter((item) => item.id !== staff_id);
                setData(updatedData);
            } else {
                console.log('NO')
            }
        });
    };


    const columns = [
        {
            key: 'count',
            title: '#',
            dataIndex: 'count',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: "id",
            ...getColumnSearchProps("id"),
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'FirstName',
            dataIndex: 'first_name',
            key: 'first_name',
            ...getColumnSearchProps("first_name"),
            sorter: sortColumns('first_name'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            ...getColumnSearchProps("last_name"),
            sorter: sortColumns("last_name"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'staff_email',
            key: 'staff_email',
            ...getColumnSearchProps("staff_email"),
            sorter: sortColumns("staff_email"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Phone',
            dataIndex: 'staff_phone',
            key: 'staff_phone',
            ...getColumnSearchProps("staff_phone"),
            sorter:sortColumns("staff_phone") ,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Edit',
            dataIndex: 'studio_edit',
            key: 'studio_edit',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined  className="my_ant_icons_table" onClick={()=> edit_staff(record.id)}/>
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined  className="my_ant_icons_table" onClick={()=> deleteStaff(record.id)}/>
                </Space>
            ),
        },
    ];
 
    if(isStudiosLoading){
        return (
            <Table
                className="table-striped-rows studio-table-rows"
                columns={columns}
                dataSource={[]}
                loading={isStudiosLoading}
                pagination={tableParams.pagination}
            />
        );
    }else{
        return(
            <>
             <div className={'text-center mt-4'}>
              <h3 className={'text-uppercase mb-4 header-title'}>Staff List </h3>
              <Table
                className="table-striped-rows studio-table-rows"
                pagination={true}
                loading={isStudiosLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                scroll={{ x: 100 }}
                summary={() => (
                  <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={100}>
                          <b>All Photographers: {data.length}</b>
                      </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
            />
           </div>
            </>
          
        );
    }
};

export default ListStaff;