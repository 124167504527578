import React, { useState, useEffect } from "react";
import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
import {Form} from "antd";



const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function StripeCheckout({
                                           shoppingCartInfo,
                                           checkout,
                                           shippingAddress,
                                           checkOutOrder,
                                           cartData,
                                           balance,
                                           totalPrice,
                                           orderDataForCreditCard,
                                           setOrderNumber,
                                           orderNumber
                                       }) {

    const [message, setMessage] = useState("");
    const [form] = Form.useForm();

    const [saveFolder, folderLoading, folderError] = useFetching(async (data) => {
        const {data: res} = await clientAPI.charge(data);
        if (res) {
            checkOutOrder(data,orderNumber,res)
            console.log(res)
         //
        }
    });

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                const obj ={
                    ...values,
                  orderDataForCreditCard,
                  ...shoppingCartInfo,
                    shoppingCartInfo,
                    checkout,
                    shippingAddress,
                    cartData,
                    balance,
                    totalPrice,
                    order_id:orderNumber
                }
                saveFolder(obj);

            })
            .catch(errorInfo => {
                console.log('Failed:', errorInfo);
            });
    };

    const [getOrderID, idLoading, idError] = useFetching(
      async (data) => {
          const {data: res} = await clientAPI.getOrderID(data);
          if (res && res.order_id) { // Ensure that res has an order_id property
              setOrderNumber(res.order_id); // Set orderNumber to the value of res.order_idconst
          } else {
              console.log('Cannot add Image or get Order ID');
          }
      }
    );



    useEffect(() => {
        getOrderID(shoppingCartInfo)
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            checkOutOrder()
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);
    return message ? (
        <Message message={message} />
    ) : (
        <span  className='StripeCheckoutSection'>
        <section className='StripeCheckoutSection'>
            <Form form={form} onFinish={handleOk}>
                <button type="submit" className='StripeCheckout'>
                    Checkout
                </button>
            </Form>
        </section>
            </span>  
    );
}