import React,{useContext, useEffect, useState} from 'react';
import {CartContext} from "../../context/CartContext";
import {Col, Modal, Row} from "antd";
import CardMain from "../Clients/CardDesign/CardMain";
import AddButton from "../Clients/AddButton/AddButton";

const CardEdit = ({ editCard, setEditCard, isEditorCartOpen }) => {
  const { cart, setCart } = useContext(CartContext);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (editCard !== null) {
      const selectedItem = cart[editCard];
      if (selectedItem) {
        setFormData({ ...selectedItem });
      }
    }
  }, [editCard, cart]);

  const handleModalClose = () => {
    setEditCard(null);
  };

  const updateCartData = (cardObject) => {
    const updatedCart = [...cart];
    updatedCart[editCard] = { ...updatedCart[editCard], cardObject: formData.cardObject };
   
    setCart(updatedCart);
    handleModalClose();
  };

  return (
    <Modal
      title="Edit Shopping Cart"
      open={editCard !== null}
      onCancel={handleModalClose}
      footer={null}
      className="modalEditCard"
    >
      <Row>
        <Col span={24}>
          <CardMain
            isEditorCartOpen={isEditorCartOpen}
            cardObjectEdit={formData.cardObject}
            // updateCartData={updateCartData}
          />
        </Col>
        <Col span={24}>
          <AddButton
            quantity={formData.quantity}
            updateCartData={updateCartData}
            printImage={formData.image}
            currentSize={formData.currentSize}
            processedData={formData.processedDataI}
            isEditorCartOpen={isEditorCartOpen}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CardEdit;
