import React, {useEffect, useRef, useState} from 'react';
import './MainImage.css';
import {Button, Modal, Row, Col, Checkbox,Input} from 'antd';
import {EditOutlined, MobileOutlined, TabletOutlined} from '@ant-design/icons';
import ImageEditor from '../ImageEditor/imageEditor';
import {
    processImage,
    createDefaultImageReader,
    createDefaultImageWriter,
} from '@pqina/pintura';
import '@pqina/pintura/pintura.css';

const MainImage = ({
                       printImage,
                       setPrintImage,
                       currentSize,
                       setProcessedData,
                       processedData,
                       setImageSize,
                       imageSize,
                       isSwitchChecked,
                       setIsSwitchChecked,
                       newEditor,
                       printImageOpen,
                       selectedBg,
                       setSelectedBg
                   }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [calculatedWidth, setCalculatedWidth] = useState(null);
    const [check, setCheck] = useState(false)
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(printImage)
    const IMG_SERVER = 'https://studioseye.com';
    const H_THUMBNAILS = 'H_THUMBNAILS/';
    const storedObject = JSON.parse(localStorage.getItem('ImagesForOrder'));
    const prevPrintImageRef = useRef(printImage);

    useEffect(() => {
        if (!printImageOpen && storedObject) {
            setPrintImage(IMG_SERVER + storedObject.IMAGE_LINK_PATH + H_THUMBNAILS + storedObject.IMAGE_NAME);
            { storedObject.selectedBg && setSelectedBg(`${storedObject.selectedBg}`);}
        }
    }, [printImageOpen, storedObject]);


    useEffect(() => {
        if (newEditor === true) {
            if (imageSize.width > imageSize.height) {
                setIsSwitchChecked(true);
            } else if (imageSize.width < imageSize.height) {
                setIsSwitchChecked(false);
            }
        }
    }, [imageSize]);

    useEffect(() => {
        const processImageDataIfAvailable = async () => {
            if (printImage === prevPrintImageRef.current) {
                if (processedData) {
                    try {
                        const {crop} = processedData;
                        const res = await processImage(printImage, {
                            imageReader: createDefaultImageReader(),
                            imageWriter: createDefaultImageWriter(),
                            imageCrop: crop,
                            imageRotation: 0
                        });
                        setBackgroundImageUrl(URL.createObjectURL(res.dest));
                    } catch (error) {
                        console.error("Error while processing image:", error);
                        throw error;
                    }
                } else {
                    setBackgroundImageUrl(printImage);
                }
            } else if (!check) {
                setBackgroundImageUrl(printImage);
                setProcessedData("");
            }else if(check){
                try {
                    const {crop} = processedData;
                    const res = await processImage(printImage, {
                        imageReader: createDefaultImageReader(),
                        imageWriter: createDefaultImageWriter(),
                        imageCrop: crop,
                        imageRotation: 0
                    });
                    setBackgroundImageUrl(URL.createObjectURL(res.dest));
                } catch (error) {
                    console.error("Error while processing image:", error);
                    throw error;
                }
            }
            prevPrintImageRef.current = printImage;
        };

        processImageDataIfAvailable();
    }, [processedData, printImage, check]);


    useEffect(() => {
        if (isSwitchChecked === false) {
            setAspectRatio(currentSize.width / currentSize.height);
            setCalculatedWidth((currentSize.width / currentSize.height) * 570);
        } else {
            setAspectRatio(currentSize.height / currentSize.width);
            setCalculatedWidth((currentSize.height / currentSize.width) * 570);
        }
    }, [currentSize, isSwitchChecked]);

    useEffect(() => {
        const loadImage = () => {
            const img = new Image();
            img.onload = () => {
                const {width, height} = img;
                setImageSize({width, height});
            };
            img.src = printImage;
        };

        loadImage();
    }, [printImage]);

    const openPinturaEditor = () => {
        setIsModalOpen(true);
    };

    const closePinturaEditor = () => {
        setIsModalOpen(false);
    };

    const handleSwitchChange = (checked) => {
        setIsSwitchChecked(checked);
    };
    const onChange = (e) => {
     setCheck(!check)
    };


    return (
      <>
        <div className='containerDiv'>
          <div className="imageDiv">
            <div className="forBg"
                 style={{
                   width:'100%',
                   maxWidth: `${calculatedWidth}px`,
                   height: '570px',
                   backgroundImage: selectedBg ? `url(${selectedBg})` : 'none',
                   backgroundSize: 'cover',
                   backgroundPosition: 'center',
                 }}
            >
              <div className="mainImageDiv"
                   style={{
                     width:'100%',
                     maxWidth: `${calculatedWidth}px`,
                     height: '570px',
                     backgroundImage: `url(${backgroundImageUrl})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                   }}
              />
            </div>
          </div>
          {isModalOpen && (
            <Modal open={isModalOpen} footer={null} onCancel={closePinturaEditor}>
              <ImageEditor
                setIsModalOpen={setIsModalOpen}
                img={printImage}
                aspectRatio={aspectRatio}
                setProcessedData={setProcessedData}
                processedData={processedData}
              />
            </Modal>
          )}
        </div>
        <Row justify="space-between">
          <Col  span={14}  justify="space-between">
            <Row justify="start" className='centered'>
              <Col xs={24} sm={9}>
                <Button className="main-image-button" onClick={() => openPinturaEditor(printImage)}>
                  ADJUST CROP
                </Button>
              </Col>
              <Col xs={24} sm={14}  className="setCheck">
                <Checkbox onChange={onChange}>Hold Crop for Next Image</Checkbox>
              </Col>
            </Row>
          </Col>
          <Col  span={10} className="switchContainer">
            <div className="mr10 pointer" onClick={() => handleSwitchChange(false)}>
              <MobileOutlined className={`fs25 ${!isSwitchChecked ? 'clGreen' : ''}`}/> Portrait
            </div>
            <div className="mr10 pointer" onClick={() => handleSwitchChange(true)}>
              <TabletOutlined className={`fs25 rotate90deg ${isSwitchChecked ? 'clGreen' : ''}`}/> Landscape
            </div>
          </Col>
        </Row>
      </>

    );
};

export default MainImage;
