import {Space, Table} from 'antd';
import React,{ useEffect, useState, } from 'react';
import dataApi  from "../../../../api/api";
import getColumnSearchProps from '../../../Utils/getColumnSearchProps'
import { useFetching } from "../../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { CameraOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { sortColumns, onChangeTable, showDeleteConfirm } from '../../../Utils/isFunction'



const PhotographersList = (props) => {

    const studio_id = localStorage.getItem('user_id');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
            total:0
        },
    });

    const handleTableChange = onChangeTable(tableParams, setTableParams);

    const [getClients, isClientsLoading, clientsError] = useFetching(async (studio_id, token) => {
        const { data: res } = await dataApi.getPhListByStudioIdToken(studio_id,token);
        if (res) {
            setData(res.client_list);
            setTableParams({
                ...tableParams,
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: res.total,
                },
            });
            if (totalItems === 0) {
                setTotalItems(res.total);
            }
        }
    });

    const goGalleryPage = (client_id) => {
        localStorage.setItem('client_id',client_id);
        navigate('/client/gallerylist');
    };
    const goEditPhotographerPage = (ph_id) => {
        localStorage.setItem('current_user_id',ph_id);
        localStorage.setItem('ph_id',ph_id);
        if(localStorage.getItem("user_type") === "LAB"){
            navigate('/studio/edit_ph');
            }else if(localStorage.getItem("user_type") === "STUDIO"){
            navigate('/studio/edit_ph');
            }else if(localStorage.getItem("user_type") === "PHOTOGRAPHERS"){
            navigate('/photographer/list_ph');
            }
        window.location.reload()
    };

   
    useEffect(() => {
        getClients(studio_id,10);
    }, []);


    const photographerDelete = (id) => {
        showDeleteConfirm(id, 'Photographer').then((result) => {
            if (result) {
                console.log('yes')
                const updatedData = data.filter((item) => item.id !== id);
                setData(updatedData);
            } else {
                console.log('NO')
            }
        });
    };
    const columns = [
        {
            key: 'count',
            title: '#',
            dataIndex: 'count',
            render: (text, record, index) => index + 1,
        },
        {
        title: '#',
        dataIndex: 'client_logo',
        key: 'client_logo',
        render: (_, record) => (
            <Space wrap>
            <CameraOutlined className="my_ant_icons_table" onClick={() => goGalleryPage(record.id)} />
            </Space>
        ),
        },
        {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        ...getColumnSearchProps('id'),
        sorter: (a, b) => a.id - b.id,
        className: 'table-id',
        },
        {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        className: 'table-name',
        ...getColumnSearchProps('name'),
        sorter: sortColumns('name'),
        sortDirections: ['descend', 'ascend'],
        },
        {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        sorter: sortColumns('email'),
        sortDirections: ['descend', 'ascend'],
        },
        {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        ...getColumnSearchProps('phone'),
        sorter: sortColumns('phone'),
        sortDirections: ['descend', 'ascend'],
        },
        {
        title: 'Edit',
        dataIndex: 'studio_login',
        key: 'studio_login',
        render: (_, record) => (
            <Space wrap>
            <EditOutlined className="my_ant_icons_table" onClick={() => goEditPhotographerPage(record.id)} />
            </Space>
        ),
        },
        {
        title: 'Delete',
        dataIndex: 'studio_delete',
        key: 'studio_delete',
        render: (_, record) => (
            <Space wrap>
            <DeleteOutlined className="my_ant_icons_table" onClick={() => photographerDelete(record.id)} />
            </Space>
        ),
        },
    ];

    if(isClientsLoading){
        return (
            <Table
                className="table-striped-rows studio-table-rows"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={[]}
                loading={isClientsLoading}

            />
        );
    }else{
        return(
            <Table
                className="table-striped-rows studio-table-rows"
                pagination={true}
                loading={isClientsLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                scroll={{ x: 100 }}
                summary={() => (
                  <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={100}>
                          <b>All Photographers: {data.length}</b>
                      </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
            />
        );
    }
};
export default PhotographersList;