import React from 'react';
import EditCardsStudio from "../EditCardsStudio";

const EditPhotoCardStudio = () => {

  const nameCards = "SelectTemplate Cards";
  const navigate_link = '/studio/photo_cards_list';
  const mainCardType = 4;

  return (
    <div>
      <EditCardsStudio nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};

export default EditPhotoCardStudio;


