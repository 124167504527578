import React, { useState,useEffect } from 'react';
import {Button, Col, Input, Row, Table, message, Checkbox} from 'antd';
import CheckedColumn from "../CheckedColumn";
import {PlusSquareOutlined,MinusSquareOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router';
import clientsAPI from "../../../../api/api";
import {useFetching} from "../../../../hoc/fetchingHook";
import {showDeleteConfirmPrice} from "../../../Utils/isFunction";

const AddCardsLab = ({ nameCards, navigate_link, mainCardType }) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [data, setData] = useState([]);
  const [sizes, setSizes] = useState([]);
  const current_user_id = localStorage.getItem('user_id');
  const [typeChecked, setTypeChecked] = useState({})
  const [fromValue, setFromValue] = useState(0)
  const [inputNameValue, setInputNameValue] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [checked, setChecked] = useState("NO");
  localStorage.setItem('current_user_id', current_user_id);

  const [createCard, isLoading, error] = useFetching(async (cardData) => {
    const { data: res } = await clientsAPI.createCardPrice(cardData);
    if (res) {
      console.log(res)
      navigate(`${navigate_link}`);
      window.location.reload();

    }
  });

  const [getSizesList, getSizesLoading, getSizesError] = useFetching(async () => {
    const { data: res } = await clientsAPI.getProductObjectSizes(2, "SIZE");
    if (res) {
      const sizesData = res[nameCards];
      setSizes([...sizesData]);
      const initialData = sizesData.map((item, index) => ({
        key: index,
        id: item.ID,
        size: `${item.width} x ${item.height}`,
        type: {
          standard: { from: '', to: '', price: '', isFromAPI: true },
          linen: { from: '', to: '', price: '', isFromAPI: true },
          plain: { from: '', to: '', price: '', isFromAPI: true },
          // watercolor: { from: '', to: '', price: '', isFromAPI: true },
          // pearl: { from: '', to: '', price: '', isFromAPI: true },
          // canvas: { from: '', to: '', price: '', isFromAPI: true },
        },
        isNew: false,
      }));
      setData(initialData);
    }
  });


  useEffect(() => {
    defaultCheck ? setChecked("YES") : setChecked("NO")
  }, [defaultCheck]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getSizesList();
      } catch (error) {
        console.error('Error fetching sizes', error);
      } finally {
        console.log('error')
      }
    }
    localStorage.setItem('card_type',mainCardType);
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 100,
    },
    {
      title: '',
      dataIndex: 'type',
      width: 200,
      children: [
        {
          title: <CheckedColumn text={'Standard'} checked={typeChecked['standard']} onChange={(newChecked) => handleTypeCheckedChange('standard', newChecked)} />,
          dataIndex: 'standard',
          key: 'standard',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'standard'),
        },
        {
          title: <CheckedColumn text={'Linen'} checked={typeChecked['linen']} onChange={(newChecked) => handleTypeCheckedChange('linen', newChecked)} />,
          dataIndex: 'linen',
          key: 'linen',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'linen'),
        },

        {
          title: <CheckedColumn text={'Pearl'} checked={typeChecked['pearl']} onChange={(newChecked) => handleTypeCheckedChange('pearl', newChecked)} />,
          dataIndex: 'pearl',
          key: 'pearl',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'pearl'),
        },
      ],
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      width: 20,
      render: (_, record) => (
        <>
          <Row>
            <Col span={24}>
              {!record.isNew && (
                <PlusSquareOutlined className='my_ant_icons_table fz-40 ' onClick={() => handleAddRow(record.size,record.key,record.id,record.dataIndex)} />

              )}
            </Col>
            <Col span={24}>
              {record.isNew && (
                <MinusSquareOutlined  className='my_ant_icons_table fz-40' onClick={() => handleDeleteRow(record.key)} />
              )}
            </Col>
          </Row>


        </>
      ),
    },
  ];


  const handleInputChange = (key, columnKey, field, value) => {
    setData((prevData) =>
      prevData.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            type: {
              ...row.type,
              [columnKey]: [
                {
                  ...(row.type[columnKey]?.[0] || {}),
                  [field]: value,
                },
              ],
            }
          };
        }
        return row;
      })
    );
  };

  const handleInputChangePrice = (key, columnKey, field, e) => {
    const value = e.target.value;
    handleInputChange(key, columnKey, field, value);
  };

  const handleBlurPrice = (key, columnKey, field, e) => {
    const floatValue = parseFloat(e.target.value).toFixed(2);
    handleInputChange(key, columnKey, field, floatValue);
  };

  const renderInputs = (text, record, columnKey) => {
    const fromValue = parseFloat(record.type[columnKey]?.[0]?.from) || '';
    const toValue = parseFloat(record.type[columnKey]?.[0]?.to) || '';
    const priceValue =record.type[columnKey]?.[0]?.price ||'';
    const isInvalid = toValue !== '' && fromValue >= toValue;

    const error = () => {
      message.error(`Cannot enter a value less than ${fromValue}`);
    };

    return (
      <Row justify={"space-between"}>
        <Col xl={12}  span={24}>
          <Input
            value={fromValue}
            onChange={(e) => handleInputChange(record.key, columnKey, 'from', e.target.value)}
            placeholder="From"
            type="number"
            style={{
              padding: '5px 0',
              textAlign: 'center',
              marginBottom: '4px',
              borderColor: isInvalid ? 'red' : '',
            }}
          />

        </Col>
        <Col xl={11}  span={24}>
          <Input
            value={toValue}
            onChange={(e) => handleInputChange(record.key, columnKey, 'to', e.target.value)}
            placeholder="To"
            type="number"
            style={{ padding: '5px 0',marginBottom:'4px',  textAlign: 'center' }}
            onClick={isInvalid ? null : error}
          />
        </Col>
        <Col span={24}>
          <Input
            value={priceValue}
            onChange={(e) => handleInputChangePrice(record.key, columnKey, 'price', e)}
            onBlur={(e) => handleBlurPrice(record.key, columnKey, 'price', e)}
            onClick ={(e)=>{e.target.value = ''}}
            placeholder="Price"
            type="number"
            style={{ padding: '5px 0', width: '100%', textAlign: 'center' ,}}
          />
        </Col>
      </Row>
    );
  }

  const handleAddRow = (selectedSize, currentIndex, id) => {
    const newData = [...data];

    const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
      if (item.id === id) {
        return index;
      }
      return lastIndex;
    }, -1);

    const newKey = lastIndexWithSameId === -1 ? currentIndex + 1 : newData[lastIndexWithSameId].key + 1;

    const newRow = {
      key: newKey,
      id: id,
      size: selectedSize,
      type: {
        standard: [{ from:  '', to: '', price: '', isFromAPI: false }],
        linen: [{ from:  '', to: '', price: '', isFromAPI: false }],
        pearl: [{ from:  '', to: '', price: '', isFromAPI: false }],
      },
      isNew: true,
    };
    newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);
    const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);
    const standardTo = parseFloat(previousRow.type.standard[0]?.to);
    const linenTo = parseFloat(previousRow.type.linen[0]?.to);
    const plainTo = parseFloat(previousRow.type.plain[0]?.to);
    const watercolorTo = parseFloat(previousRow.type.watercolor[0]?.to);
    const pearlTo = parseFloat(previousRow.type.pearl[0]?.to);
    const canvasTo = parseFloat(previousRow.type.canvas[0]?.to);


    newData.forEach((item, index) => {
      item.key = index;
      if (previousRow && index === lastIndexWithSameId + 1 ) {
        newData[index].type.standard[0].from = standardTo+1;
        newData[index].type.linen[0].from = linenTo+1;
        newData[index].type.plain[0].from = plainTo+1;
        newData[index].type.watercolor[0].from = watercolorTo+1;
        newData[index].type.pearl[0].from = pearlTo+1;
        newData[index].type.canvas[0].from = canvasTo+1;
      }

    });

    setFromValue(standardTo + 1)
    setFromValue(linenTo + 1)
    setFromValue(plainTo + 1)
    setFromValue(watercolorTo + 1)
    setFromValue(pearlTo+1)
    setFromValue(canvasTo + 1)

    setData(newData);
  };

  const handleDeleteRow = (rowKey) => {
    showDeleteConfirmPrice(rowKey).then((result) => {
      if (result) {
        console.log('Yes')
        const rowToDelete = data.find((item) => item.key === rowKey);

        if (rowToDelete ) {
          if (data.length <= 1) {
            return;
          }
          const newData = data.filter((item) => item.key !== rowKey);
          const updatedData = newData.map((item, index) => ({ ...item, key: index }));
          setData(updatedData);
        } else {
          console.log('No')
        }
      }
    })
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleTypeCheckedChange = (typeKey, newChecked) => {
    setTypeChecked((prevTypeChecked) => ({
      ...prevTypeChecked,
      [typeKey]: newChecked,
    }));
  };

  const handleSubmit = async () => {
    const sizeInfoData = data
      .filter((item) => selectedRowKeys.includes(item.key))
      .map((item) => {
        const selectedTypes = Object.keys(item.type)
          .filter((typeKey) => item.type[typeKey][0]?.from !== '' && item.type[typeKey][0]?.to !== '' && item.type[typeKey][0]?.price !== '')
          .map((typeKey) => ({
            checkType: typeChecked[typeKey] !== undefined ? typeChecked[typeKey] : false,
            type: typeKey,
            from: item.type[typeKey][0]?.from,
            to: item.type[typeKey][0]?.to,
            price: item.type[typeKey][0]?.price,
          }));

        return {
          itemId:item.id,
          size: item.size,
          cardName: selectedTypes,
        };
      });

    const newCardData  = {
      mainCardType :mainCardType,
      default_check : defaultCheck,//poxel  checked(Yes/NO)
      priceName: inputNameValue,
      sizeInfoData: sizeInfoData
    };
    createCard(newCardData);
  };

  const handleInputNameChange = (e) => {
    const newValue = e.target.value;
    setInputNameValue(newValue);
  };

  return (
    <div>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Add {nameCards} Lab</h3>
      </div>
      <div style={{ margin: 16}}  >
        <Row>
          <Col md={24} span={24}  >
            <label >Add price Name</label>
            <Input
              type="text"
              className="input_price"
              value={inputNameValue}
              onChange={handleInputNameChange}
            />
          </Col>
          <Col md={12} span={24}>
            <Checkbox className="default_checkbox" checked={defaultCheck}
                      onChange={() => setDefaultCheck(!defaultCheck)}>
              <div className="default_text">Check if you want set your default price list</div>
            </Checkbox>
          </Col>
          <Col md={12} span={24} className='btnHeader'>
            <Button onClick={handleSubmit } type="primary" htmlType="submit"   className="btn_price btn_save" st="true" >
              Save
            </Button>
          </Col>
        </Row>
      </div>
      {getSizesLoading?
        ( <Table
          className='priceTable'
          columns={columns}
          loading={getSizesLoading}
        />):(  <Table
          className='priceTable'
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.key}
          pagination={false}
          loading={getSizesLoading}
          scroll={{ x: 100 }}
        />)}
      <div className="divSave">
        <Button onClick={handleSubmit } type="primary" htmlType="submit"   className="btn_price btn_save" st="true" >
          Save
        </Button>
      </div>
    </div>
  );
};


export default AddCardsLab;


