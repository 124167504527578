import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, message, Row, Select, Radio } from 'antd';
import { handleTagChange, validateMessages } from '../../Utils/isFunction';
import clientsAPI from "../../../api/api";
import { useFetching } from "../../../hoc/fetchingHook";


const ShippingAddress = React.forwardRef((props, ref) => {
  const { setShippingAddress, shippingAddress } = props


  const [form] = Form.useForm();
  const [value, setValue] = useState();
  const { Option } = Select;
  const [selectedItems, setSelectedItems] = useState([]);
  const client_id = localStorage.getItem('client_id')
  const studio_mode = localStorage.getItem('studio_mode');
  const hasFetched = useRef(false);


  const onChange = (e) => {
    setValue(e.target.value);
  };
  const OPTIONS = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  const handleTagChangee = (selectedTags) => {

    handleTagChange(selectedTags, form, message);
  };
  const [getStudioInfo, isGetStudioInfo, studioInfoError] = useFetching(async (id) => {
    try {
      const res = await clientsAPI.getClientInfoByID(id);
      if (res && res.data) {
        form.resetFields();
        let obj = {};
        if (value === 1) {
          obj = {
            addressType: value,
            address1: res.data.studioInfo.address1 || '',
            address2: res.data.studioInfo.address2 || '',
            firstName: res.data.studioInfo.firstname || '',
            lastName: res.data.studioInfo.lastname || '',
            appNumber: res.data.studioInfo.appNumber || '',
            county: res.data.studioInfo.country || 'US',
            state: res.data.studioInfo.state || 'New York',
            city: res.data.studioInfo.city || '-',
            zipCode: res.data.studioInfo.zipCode || '-',
            email: res.data.studioInfo.email || [],
            phone: res.data.studioInfo.phone || '-',
          }
          setShippingAddress(obj);
          form.setFieldsValue({ obj });
        } else if (value === 2) {
          obj = {
            addressType: value,
            address1: '12 Platinum Court Medford, NY 11763' || '',
            address2: '' || '',
            firstName: 'Tricolor' || '',
            lastName: 'Pic' || '',
            appNumber: "" || '',
            county: 'US' || 'US',
            state: 'New York' || 'New York',
            city: 'New York' || '-',
            zipCode: '11563' || '-',
            email: ['info@tricolorimage.com'] || [],
            phone: '877.522.8588 , (631) 205-5100' || '-',
          }
          setShippingAddress(obj);
          form.setFieldsValue({ obj });
        } else {
          form.setFieldsValue({
            addressType: value || value,
            state: 'New York' || '-',
          });
        }

      }
    } catch (error) {
      console.error('Error fetching studio info:', error);
    }
  });

  React.useImperativeHandle(ref, () => ({
    validateAndProceed: () => {
      return new Promise((resolve, reject) => {
        form
          .validateFields()
          .then((values) => {
            const addressData = {
              ...values,
            };
            props.onNext && props.onNext(addressData);
            resolve();
          })
          .catch((info) => {
            reject();
          });
      });
    },
  }));

  const handleFormValuesChange = (changedValues, allValues) => {
    setShippingAddress(allValues);
  };

  useEffect(() => {
    form.setFieldsValue(shippingAddress);
  }, [shippingAddress]);


  useEffect(() => {
    if (value && !hasFetched.current) {
      getStudioInfo(client_id);
      hasFetched.current = true; 
    }
  }, [value, client_id]);


  return (
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title mt-5'}>Shipping Address</h3>
      </div>
      <Form
        form={form}
        name="register"
        size={'large'}
        onValuesChange={handleFormValuesChange}
        layout="vertical"
        style={{ maxWidth: 500, margin: 'auto' }}
        validateMessages={validateMessages}
        scrollToFirstError
      >
        <Row justify='space-between' className='mt-4'>
          <Col span={24}>
            <Form.Item
              name="addressType"
              label="Choose Shipping Address "
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Ship to studio</Radio>
                {studio_mode === 'true' && <Radio value={2}>Pick up at location</Radio>}
                <Radio value={3}>Ship to address</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, },]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, },]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="address1"
              label="Address 1"
              rules={[{ required: true, },]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={19}>
            <Form.Item
              name="address2"
              label="Address 2"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="appNumber"
              label="Apartment Number"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="county"
              label="County"
              initialValue="US"
              rules={[{ required: true, },]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, },]}
            >
              <Select
                showSearch
                placeholder="Select State"
                value={selectedItems}
                onChange={setSelectedItems}
                options={filteredOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, },]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[{ required: true, },]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="email"
              label="Enter tags (email addresses)"
              rules={[
                {
                  required: true,
                  message: 'Please enter at least one valid email address.',
                  type: 'array',
                },
                {
                  validator: (_, value) =>
                    value.every((email) => /\S+@\S+\.\S+/.test(email))
                      ? Promise.resolve()
                      : Promise.reject(new Error("One or more emails are invalid")),
                },
              ]}
            >
              <Select
                mode="tags"
                onChange={handleTagChangee}
                style={{ width: '100%' }}
                placeholder="Enter your email addresses"
              />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, },]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})

export default ShippingAddress;