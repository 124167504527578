import React, { useState, useEffect } from 'react';
import { Collapse, List, Select, } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import clientAPI from '../../../../api/api';
import { useFetching } from '../../../../hoc/fetchingHook';
import { ProductType } from '../../ProductType/ProductType';
import './SelectImage.css'
import { CheckCircleTwoTone} from '@ant-design/icons';

const SelectImage = ({
                       printImage,
                       setPrintImage,
                       setInlineResult,
                       setImageID,
                       setPrintImageOpen,
                       productType,
                       setProductType,
                       bgImages,
                       setSelectedBg,
                       updateStoredMessagesFlag,
                     }) => {
  const [images, setImages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [folderDataList, setFolderDataList] = useState([]);
  const [folderImages, setFolderImages] = useState([]);
  const [storedMessages, setStoredMessages] = useState([]);
  const [height, setHeight] = useState(500);
  const [gridConfig, setGridConfig] = useState({
    gutter: 1,
    column: 2,
  });
  const IMG_SERVER = 'https://studioseye.com';
  const H_THUMBNAILS = 'H_THUMBNAILS/';
  const THUMBNAILS = 'THUMBNAILS/';
  const [getGalleryImages, isGalleryLoading, galleryError] = useFetching(async (offset, take) => {
    try {
      const { data: res } = await clientAPI.getEventImagesByEventID(offset, take);

      if (res) {
        if (res.items.length === 0) {
          setHasMore(false);
        } else {
          setImages((prevImages) => [...prevImages, ...res.items]);
          setOffset((prevOffset) => prevOffset + res.items.length);
          setTotal(res.total);
        }
      }
    } catch (error) {
      galleryError()
    }
  });

  const [getFoldersImages, isFoldersLoading, foldersError] = useFetching(async () => {
    try {
      const { data: res } = await clientAPI.getEventFoldersImages();
      if (res?.length > 0) {
        const filteredRes = res.filter(item => item.folder_name === "Print" || item.folder_name === "Other");
        setFolderDataList(filteredRes);
      }
    } catch (error) {
      foldersError();
      console.error(error);
    }
  });


  const fetchStoredMessages = () => {
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
    setStoredMessages(cartData);
  };

  useEffect(() => {
    fetchStoredMessages();
  }, [updateStoredMessagesFlag]);

  const modifiedData = storedMessages.map((item) => {
    if (item.productType === 'Print' && images.some((image) => image.ID === item.imageID)) {
      return {
        ...item,
        isChecked: true,
      };
    }
    return item;
  });

  const loadMoreData = () => {
      getGalleryImages(offset, 5);
  };

  const generateNormalizedURL = (url) => {
      const normalizedURL = encodeURI(url);
      return normalizedURL.replace(/\(/g, '%28').replace(/\)/g, '%29');
  };

  const handelImageChange = (url, id) => {
      setImageID(id);
      setPrintImage(url);
      setInlineResult('');
      setPrintImageOpen(true);
  };

  const handleMenuSelect = (value) => {
    setSelectedMenuItem(value);
  };

  useEffect(() => {
    const selectedItem = folderDataList.find((item) => item.id === Number(selectedMenuItem));
    if (selectedItem) {
      setFolderImages(selectedItem.images);
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    getGalleryImages(offset, 8);
    getFoldersImages();
  }, []);

  useEffect(() => {
    if (images.length > 0 && !printImage) {
      const firstImage = images[0];
      const url = generateNormalizedURL(
        IMG_SERVER + firstImage.IMAGE_LINK_PATH + H_THUMBNAILS+ firstImage.IMAGE_NAME
      );
      setPrintImage(url);
    }
  }, [images, printImage, setPrintImage]);

  useEffect(() => {
    fetchStoredMessages();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setHeight (140)
        setGridConfig({
          gutter: 1,
          column: 4,
        });
      } else {
        setGridConfig({
          gutter: 1,
          column: 2,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Call it initially to set the correct grid configuration

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const items = [
    {
      key: '1',
      label: 'Product Type',
      children: <ProductType selectedProducts={productType} productType ={productType} setProductType={setProductType} />,
    },
    {
      key: '2',
      label: 'Select Gallery Images',
      children:    <InfiniteScroll
        className="scrollableDiv"
        dataLength={images.length}
        next={() => loadMoreData()}
        hasMore={hasMore}
        scrollableTarget="scrollablePanel"
        height={height}
      >
        <List
          grid={gridConfig}
          dataSource={images}
          renderItem={({ ID, IMAGE_LINK_PATH, IMAGE_NAME }) => (
            <div
              className="selectedImage_div"
              key={ID}
              id={ID}
              onClick={() => {
                handelImageChange(
                  generateNormalizedURL(IMG_SERVER + IMAGE_LINK_PATH + H_THUMBNAILS + IMAGE_NAME),
                  ID
                );
              }}
            >
              {modifiedData.some(item => item.imageID === ID && item.isChecked) && (
                <CheckCircleTwoTone  className='checkedCart' twoToneColor="#52c41a" id={ID} />
              )}
              <img
                src={IMG_SERVER + IMAGE_LINK_PATH + THUMBNAILS + IMAGE_NAME}
                style={
                  printImage ===
                  generateNormalizedURL(IMG_SERVER + IMAGE_LINK_PATH + H_THUMBNAILS + IMAGE_NAME)
                    ? { opacity: '1', border: 'solid 2px #223d8f' }
                    : null
                }
                className="selectedImage_img"
                alt={IMAGE_NAME}
              />
            </div>
          )}
        />
      </InfiniteScroll>
    },
    {
      key: '3',
      label: 'Select Folder Print  Images',
      children:<>
        <Select className='selectFolderImages' onSelect={handleMenuSelect} value={selectedMenuItem} placeholder="Select an item">
          {folderDataList.map((item) => (
            <Select.Option key={item.id} >
              <div>
                {`${item.folder_name} `}
                {(item.selectcard_type !== '0' && item.selectcard_type !== 0) ? `${item.selectcard_type} ` : ' '}
                {(item.selected_size !== '0' && item.selected_size !== 0)  ? `${item.selected_size} ` : ' '}
                {item.quantity ? " ( " : null}
                <span style={{ color: item.images.length > item.quantity ? 'red' : 'inherit' }}>
                    {item.quantity ? item.images.length : null}
                  </span>
                {item.quantity ? ` / ${item.quantity} )` : null}
              </div>

            </Select.Option>
          ))}
        </Select>

        <InfiniteScroll
          className="scrollableDiv"
          dataLength={folderImages.length}
          next={() => loadMoreData()}
          hasMore={hasMore}
          scrollableTarget="scrollablePanel"
          height={height}
        >
          <List
            grid={gridConfig}
            dataSource={folderImages}
            renderItem={({ ID, IMAGE_LINK_PATH, IMAGE_NAME }) => (
              <div
                className="selectedImage_div"
                key={ID}
                id={ID}
                onClick={() => {
                  handelImageChange(
                    generateNormalizedURL(IMG_SERVER + IMAGE_LINK_PATH + H_THUMBNAILS + IMAGE_NAME),
                    ID
                  );
                }}
              >
                {modifiedData.some(item => item.imageID === ID && item.isChecked) && (
                  <CheckCircleTwoTone  className='checkedCart' twoToneColor="#52c41a" id={ID} />
                )}
                <img
                  src={IMG_SERVER + IMAGE_LINK_PATH + THUMBNAILS + IMAGE_NAME}
                  style={
                    printImage ===
                    generateNormalizedURL(IMG_SERVER + IMAGE_LINK_PATH + H_THUMBNAILS + IMAGE_NAME)
                      ? { opacity: '1', border: 'solid 2px #223d8f' }
                      : null
                  }
                  className="selectedImage_img"
                  alt={IMAGE_NAME}
                />
              </div>
            )}
          />
        </InfiniteScroll>
      </>,
    },
    {
      key: '4',
      label: 'Select Background',
      children:   <InfiniteScroll
        className="scrollableDiv"
        dataLength={ bgImages}
        hasMore={hasMore}
        scrollableTarget="scrollablePanel"
        height={height}
      >
        <List
          grid={gridConfig}
          dataSource={bgImages}
          renderItem={({ id, path, name }) => (
            <div
              className="selectedImage_div"
              key={id}
              id={id}
            >
              {modifiedData.some(item => item.imageID === id && item.isChecked) && (
                <CheckCircleTwoTone  className='checkedCart' twoToneColor="#52c41a" id={id} />
              )}
              <img
                onClick={() => setSelectedBg(`/background/${path}`)}
                src={`/background/${path}`}
                className="selectedImage_img"

              />
            </div>
          )}
        />
      </InfiniteScroll>,
    },
  ];

  return (
    <Collapse items={items} ghost accordion defaultActiveKey={['2']} expandIconPosition="end" />
  );
};

export default SelectImage;
