import React, { useState,useEffect,useRef } from 'react';
import {Button, Col, Input, Row, Table, message, Space, Checkbox, Tour} from 'antd';
import Container from "react-bootstrap/Container";
import {PlusSquareOutlined,MinusSquareOutlined} from '@ant-design/icons'
import CheckedColumn from "../CheckedColumn";
import {useNavigate} from 'react-router';
import {showDeleteConfirmPrice} from "../../../../Utils/isFunction";
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientsAPI from "../../../../../api/api";
import LoadingSpin from "../../../../Utils/LoadingSpin";
import {usePriceContext} from "../../../../../context/PriceContext";
import {useHelperTab} from "../../../../../context/HelperTabContext";


const AddCardsStudio = ({ nameCards, navigate_link, mainCardType,helperShow ,setHelperShow}) => {
  const { helperTab } = useHelperTab();
  const isWindowWidthGreaterThan577 = window.innerWidth > 577;
  const [open, setOpen] = useState(helperTab);
  useEffect(() => {
    if (!open) { setHelperShow(false); }
  }, [open]);

  useEffect(() => {
    if (helperShow) { setOpen(true);  }
  }, [helperShow]);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);


  const steps = [
    {
      title: 'Price List Title',
      description: 'Please enter the name of your price list in the designated field. Should you require this price list to be the default for future use, ensure to mark the accompanying checkbox. This action will establish the entered price list as the standard selection across the platform.',
      target: () => ref1.current,
    },
    {
      title: 'Price Adjustment',
      description: 'Enter the percentage you\'d like to add to the current price of your items. If you\'ve already got a selling price, typing in \'100\' means you\'ll be doubling the price – so something that\'s $10 will jump to $20. No selling price yet? No worries! We\'ll boost the lab price by the same percentage you choose. This nifty tool helps you keep your prices aligned with your business goals without the math headache. Just type in the number and let the magic happen!"',
      target: () => ref2.current,
    },
    {
      title: 'Size Customizer',
      description: 'Size Customizer: Choose the sizes you offer and assign your selling prices to each. This tool streamlines the process, enabling you to tailor your product offerings to meet customer needs while ensuring profitability. Simply select from the available sizes and input the corresponding selling price for an efficient and personalized pricing structure',
      target: () => ref3.current,
    },
    {
      title: 'Price Customizer',
      description: 'Price Check Alert: Be mindful when setting your selling prices. If you accidentally input a selling price that\'s lower than your lab cost, our system will flag this with a negative cost indicator. This feature helps prevent potential losses by alerting you to pricing errors, ensuring that your selling prices always cover your costs and contribute to your profit margin',
      target: () => ref4.current,
    },
    {
      title: 'Save and Personalize',
      description: 'Save and Personalize Your Price List: Once you\'ve finalized your pricing, hit \'Save\' to add these details to your price list. This action locks in your carefully selected prices. After saving, you have the flexibility to assign different items from this list to specific clients. This feature allows for tailored pricing strategies, ensuring that each client receives a customized selection that suits their needs and your business goals. It\'s all about giving you control over your pricing, client by client.',
      target: () => ref5.current,
    },
  ];

  const navigate = useNavigate();
  const [selectionType, setSelectionType] = useState('checkbox');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [typeChecked, setTypeChecked] = useState({})
  const [fromValue, setFromValue] = useState(0)
  const [inputNameValue, setInputNameValue] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [checked, setChecked] = useState("NO");
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);
  const { inputNumberValue, setInputNumberValue } = usePriceContext();

  const  studio_id = localStorage.getItem('studio_id')
  localStorage.setItem('current_user_id', studio_id)

  const [createCard, isLoading, error] = useFetching(async (cardData) => {
    const { data: res } = await clientsAPI.createCardPrice(cardData);
    if (res) {
      navigate(`${navigate_link}`);
      window.location.reload();
    }
  });

  const fetchSizesAndDetails = async () => {

    const groupByRowId = (arrayOfArrays) => {
      return arrayOfArrays.reduce((acc, array) => {
        if (Array.isArray(array)) { // Check if it's an array
          array.forEach(item => {
            const rowId = item.row_id;
            if (!acc[rowId]) {
              acc[rowId] = {
                id: item.item_id,
                itemID: item.id,
                row_id: rowId,
                items: [],
              };
            }
            acc[rowId].items.push(item);
          });
        } else {
          console.warn("Encountered a non-array element:", array);
        }
        return acc;
      }, {});
    };

    try {
      const sizesRes = await clientsAPI.getProductObjectSizes(2, 'SIZE');
      const card_type =localStorage.getItem('card_type')
      const headerRes = await clientsAPI.getCardPriceLabDefault(card_type);
      if (sizesRes.data && headerRes.data) {

        setInputNameValue(headerRes.data.name);
        setDefaultCheck(headerRes.data.default_checked === 'YES');
         localStorage.setItem('edited_card',headerRes.data.id)
        const detailsRes2 = await clientsAPI.getEditedCardById();///api default price
        if (detailsRes2.data) {
          const sizesData = sizesRes.data[nameCards];
          setSizes(sizesData);

          // Group data by row_id
          const groupedData = groupByRowId(detailsRes2.data.details);
          const apiTypes = {};
          const apiSizes = [];
          // Loop through each key in groupedData
          for (let key in groupedData) {
            const apiItemTypes = groupedData[key].items;
            apiSizes.push(groupedData[key].itemID);

            // Loop through each item in items array
            for (let item of apiItemTypes) {
              apiTypes[item.item_type] = true;

            }
          }

          setCheckedRowKeys(apiSizes);
          setTypeChecked(apiTypes);

          // Process each group
          let i = 0;
          const transformedData = Object.values(groupedData).map(group => {
            ++i

            const size = sizesData.find(size => size.ID === group.id);
            if (size) {
              return  {
                key: i,
                id: group.id,
                itemID: group.itemID,
                size: `${size.width} x ${size.height}`,
                items: group.items.map(detail => ({
                  [detail.item_type]: {
                    checkType: typeChecked[detail.item_type] !== undefined ? typeChecked[detail.item_type] : false,
                    from: detail.item_from.toString(),
                    to: detail.item_to.toString(),
                    price: parseFloat(detail.item_price).toFixed(2),
                    default_price: parseFloat(detail.item_price).toFixed(2),
                    isFromAPI: true,
                  },
                })),
                isNew: false,
              };


            }
          }).filter(item => item !== null);

          const normalizeTransformedData = (transformedData) => {
            return transformedData.map(item => {
              const normalizedItems = item.items.reduce((acc, detail) => {
                const [itemType, itemValue] = Object.entries(detail)[0];
                acc[itemType] = itemValue;
                return acc;
              }, {});
              return { ...item, items: [normalizedItems] };
            });
          };
          const apiData =  normalizeTransformedData(transformedData)
          const combinedData = [ ...apiData];
          const sortedData = combinedData.sort((a, b) => a.id - b.id);
          setData(sortedData);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching sizes and details', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('card_type',mainCardType);
    fetchSizesAndDetails();
  }, []);

  useEffect(() => {
    defaultCheck ? setChecked("YES") : setChecked("NO")
  }, [data,checkedRowKeys,defaultCheck]);

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 100,
    },
    {
      title: '',
      dataIndex: 'type',
      width: 200,
      children: [
        {
          title: <CheckedColumn text={'Standard'} checked={typeChecked['standard']} onChange={(newChecked) => handleTypeCheckedChange('standard', newChecked)} />,
          dataIndex: 'standard',
          key: 'standard',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'standard'),
        }, {
          title: <CheckedColumn text={'Linen'} checked={typeChecked['linen']} onChange={(newChecked) => handleTypeCheckedChange('linen', newChecked)} />,
          dataIndex: 'linen',
          key: 'linen',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'linen'),
        },
        {
          title: <CheckedColumn text={'Pearl'} checked={typeChecked['pearl']} onChange={(newChecked) => handleTypeCheckedChange('pearl', newChecked)} />,
          dataIndex: 'pearl',
          key: 'pearl',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'pearl'),
        },
      ],
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      width: 20,
      render: (_, record) => (
        <>
          <Row>
            <Col span={24}>
              {!record.isNew && (
                <PlusSquareOutlined className='my_ant_icons_table fz-40 ' onClick={() => handleAddRow(record.size,record.key,record.id,record.dataIndex)} />

              )}
            </Col>
            <Col span={24}>
              {record.isNew && (
                <MinusSquareOutlined  className='my_ant_icons_table fz-40' onClick={() => handleDeleteRow(record.key)} />
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleToInputBlur = (key, columnKey) => {
    setData((prevData) => {
      return prevData.map((row) => {
        if (row.key === key) {
          const fromValue = Number(row.items[0][columnKey]['from']);
          const toValue = Number(row.items[0][columnKey]['to']);
          if (fromValue >= toValue) {
            return {
              ...row,
              items: [
                {
                  ...row.items[0],
                  [columnKey]: {
                    ...row.items[0][columnKey],
                    'from': (toValue - 1).toString(),
                  },
                },
              ],
            };
          }
        }
        return row;
      });
    });
  };

  const handleInputChange = (key, columnKey, typeKey, field, value) => {
    setData((prevData) => {
      return prevData.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            items: [
              {
                ...row.items[0],
                [columnKey]: {
                  ...(row.items[0]?.[columnKey] || {}),
                  [field]: value,
                },
              },
            ],
          };
        }
        return row;
      });
    });
  }
 const handleInputChangePrice = (key, columnKey, typeKey, field, e) => {
    const value = e.target.value
    setData((prevData) => {
      return prevData.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            items: [
              {
                ...row.items[0],
                [columnKey]: {
                  ...(row.items[0]?.[columnKey] || {}),
                  [field]: value,
                },
              },
            ],
          };
        }
        return row;
      });
    });
  }
 const handleInputChangeBlur = (key, columnKey, typeKey, field, e) => {
    const value = parseFloat(e.target.value).toFixed(2)
   handleInputChange(key, columnKey, typeKey, field, value)
  }

  const renderInputs = (text, record, columnKey, typeKey) => {
    if (record && Array.isArray(record.items)) {
      const typeData = record.items[0][columnKey] || {};
      const fromValue = typeData.from || '';
      const toValue = typeData.to || '';
      const isInvalid = toValue !== '' && false ;

      const error = () => {
        message.error(`Cannot enter a value less than ${fromValue}`);
      };

      return (
        <Row justify={"space-between"}>
          <Col xl={12}  span={24}>
            <Input
              value={fromValue}
              onChange={(e) => handleInputChange(record.key, columnKey, typeKey, 'from', e.target.value)}
              placeholder="From"
              type="number"
              style={{
                padding: '5px 0',
                textAlign: 'center',
                marginBottom: '4px',
                borderColor: isInvalid ? 'red' : '',
              }}
            />
          </Col>
          <Col xl={11}  span={24}>
            <Input
              value={toValue}
              disabled={isInvalid}
              onClick={isInvalid ? null : error}
              onChange={(e) => handleInputChange(record.key, columnKey, typeKey, 'to', e.target.value)}
              onBlur={() => handleToInputBlur(record.key, columnKey)}
              placeholder="To"
              type="number"
              style={{
                padding: '5px 0',
                textAlign: 'center',
                marginBottom: '4px',
                borderColor: isInvalid ? 'red' : '',
              }}
            />
          </Col>
          <Col span={24}>
            <Input
              value={typeData.price || ''}
              onChange={(e) => handleInputChangePrice(record.key, columnKey, typeKey, 'price', e)}
              onBlur={(e) => handleInputChangeBlur(record.key, columnKey, typeKey, 'price', e)}
              onClick ={(e)=>{e.target.value = ''}}
              placeholder="Price"
              type="number"
              style={{
                padding: '5px 0',
                textAlign: 'center',
                width: '100%',
              }}
            />
          </Col>
        </Row>
      );
    }else{
      return null;
    }
  }

  const handleAddRow = (selectedSize, currentIndex, id) => {
    const newData = [...data];
    const newKey = `${id}-${Date.now()}`;
    const newRow = {
      key: newKey,
      id: id,
      size: selectedSize,
      items:[{
        standard: [{ from:  '', to: '', price: '', isFromAPI: false }],
        linen: [{ from:  '', to: '', price: '', isFromAPI: false }],
        pearl: [{ from:  '', to: '', price: '', isFromAPI: false }],
      }],
      isNew: true,
    };

    const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
      if (item.id === id) {
        return index;
      }
      return lastIndex;
    }, -1);

    newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);
    const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);

    if (previousRow !== undefined) {
      const standardTo = parseFloat(previousRow.items[0].standard?.to);
      const linenTo = parseFloat(previousRow.items[0].linen?.to);
      const pearlTo = parseFloat(previousRow.items[0].pearl?.to);

      // Only update the 'from' values for the newly added row
      if (previousRow) {
        newRow.items[0].standard.from = standardTo + 1;
        newRow.items[0].linen.from = standardTo + 1;
        newRow.items[0].pearl.from = pearlTo + 1;
      }

      setFromValue(standardTo + 1);
      setFromValue(linenTo + 1);
      setFromValue(pearlTo + 1);
    }

    setData(newData);
  };

  const handleDeleteRow = (rowKey) => {
    showDeleteConfirmPrice(rowKey).then((result) => {
      if (result) {
        const rowToDelete = data.find((item) => item.key === rowKey);
        if (rowToDelete) {
          if (data.length <= 1) {
            return;
          }
          const newData = data.filter((item) => item.key !== rowKey);
          const updatedData = newData.map((item, index) => ({ ...item, key: index }));
          setData(updatedData);
        }
      } else {
        console.log("User clicked No");
      }
    });
  }

  const rowSelection = {
    selectedRowKeys: checkedRowKeys,
    onChange: (selectedRowKeys) => {
      setCheckedRowKeys(selectedRowKeys);
    },
  };

  const handleTypeCheckedChange = (typeKey, newChecked) => {
    setTypeChecked((prevTypeChecked) => ({
      ...prevTypeChecked,
      [typeKey]: newChecked,
    }));
  };

  const handleSubmit = async () => {
    const edited_card_id = localStorage.getItem('edited_card');
    const sizeInfoData = data.filter((item) => checkedRowKeys.includes(item.itemID) || checkedRowKeys.includes(item.key))
      .map((item) => {
        const selectedTypes = Object.keys(item.items[0])
          .filter((typeKey) => item.items[typeKey]?.from !== '' && item.items[typeKey]?.to !== '' && item.items[typeKey]?.price !== '')
          .map((typeKeys) => ({
            checkType: typeChecked[typeKeys] !== undefined ? typeChecked[typeKeys] : false,
            type: typeKeys,
            from: item.items[0][typeKeys]?.from,
            to: item.items[0][typeKeys]?.to,
            price: item.items[0][typeKeys]?.price,

          }));
        return {
          itemId: item.id,
          size: item.size,
          cardName: selectedTypes,
        };
      });

    const newCardData  = {
      mainCardType : mainCardType,
      default_check : defaultCheck,
      priceName: inputNameValue,
      sizeInfoData: sizeInfoData
    };
    try {
      createCard(newCardData);

    } catch (err) {
      console.error("Error creating card:", err);
    }
  };

  const handleInputNameChange = (e) => {
    const newValue = e.target.value;
    setInputNameValue(newValue);
  };

  const handleInputNumber = (e) => {
    const newValue = e.target.value;
    setInputNumberValue(newValue);
  };


  const handlePriceChange = () => {
    const updatedData = data.map((item) => {
      const newItem = { ...item };
      newItem.items = newItem.items.map((detail) => {
        const updatedDetail = {};
        for (const [itemType, itemValue] of Object.entries(detail)) {
          const updatedPrice =
              (inputNumberValue * parseFloat(itemValue.default_price)) / 100 +
              parseFloat(itemValue.default_price);

          updatedDetail[itemType] = {
            ...itemValue,
            price: updatedPrice.toFixed(2),
          };
        }

        return updatedDetail;
      });
      return newItem;
    });
    setData(updatedData);
  };


  const handleReset = () => {
    setInputNumberValue(0);

    const updatedData = data.map((item) => {
      const newItem = { ...item };
      newItem.items = newItem.items.map((detail) => {
        const updatedDetail = {};

        for (const [itemType, itemValue] of Object.entries(detail)) {
          updatedDetail[itemType] = {
            ...itemValue,
            price: itemValue.default_price,
          };
        }

        return updatedDetail;
      });
      return newItem;
    });

    setData(updatedData);
  };

  return (
    <div>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Add {nameCards} Studio</h3>
      </div>
      <div>
        <div >
          <Row ref={ref1}  justify='start'>
            <Col xs={24} lg={12} xl={8} className='divChecked'>
              <label className="textInput">Add your price list name</label>
              <Input
                type="text"
                className="input_price"
                value={inputNameValue}
                onChange={handleInputNameChange}
              />
            </Col>
            <Col xs={23} lg={12} xl={16}>
              <Checkbox
                className="default_checkbox"
                checked={defaultCheck}
                onChange={() => setDefaultCheck(!defaultCheck)}
              >
                <div className="default_text">Check if you want set your default price list</div>
              </Checkbox>
            </Col>
          </Row>
          <Row justify='start'>
            <Col xs={24} lg={12} xl={8} className='divChecked'>
              <label className='textInput'>Price Adjustment %</label>
              <Input type="number" className="input_price" value={inputNumberValue} onChange={handleInputNumber}/>
            </Col>
            <Col xs={24} lg={12} xl={16}>
              <Row justify='space-between'>
                <Col span={16}>
                  {inputNumberValue > 0 ? (
                    <Space direction={"horizontal"}>
                      <Button className="btn_price" onClick={handlePriceChange}>Set</Button>
                      <Button className="btn_price" onClick={handleReset}>Reset</Button>
                    </Space>
                  ) : null}
                </Col>
                <Col span={8}>
                  <Button className="btn_price btn_save " st="true" onClick={handleSubmit}> Save </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={"for-ref-left"} ref={ref3}></div>
        <div className={"for-ref-card"} ref={ref4}></div>
        <Table
          className='priceTable'
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.itemID ? record.itemID : record.key}
          pagination={false}
          loading={loading}
        />
        <div className="divSave">
          <Button onClick={handleSubmit } type="primary" htmlType="submit"   className="btn_price btn_save" st="true" >
            Save
          </Button>
        </div>
      </div>
      {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
    </div>
  );
};


export default AddCardsStudio;


