import {Button, Col, Input, Row, Table} from 'antd';
import {useEffect, useState} from 'react';
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";
import {Checkbox} from 'antd';
import {useNavigate} from 'react-router';
import LoadingSpin from "../../../Utils/LoadingSpin";

const EditPriceLab = () => {

    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [text, setText] = useState("");
    const [checked, setChecked] = useState("NO");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [data, setData] = useState([]);
    const parent_id = localStorage.getItem('parent_id');
    const navigate = useNavigate();

    const [setSizesList, setSizesLoading, setSizesError] = useFetching(async (obj) => {
        const {data: res} = await clientAPI.updatePrintPriceDetails(obj);
        if (res) {
            navigate('/labs/price_list');
            window.location.reload();
        }
    });

    const fetchSizesAndDetails = async (parent_id) => {
        setLoading(true);
        try {
            const sizesRes = await clientAPI.getProductObjectSizes(5, "SIZE");
            const headerRes = await clientAPI.getPrintPriceHeaderByID(parent_id);
            const detailsRes = await clientAPI.getPrintPriceDetails(parent_id);

            if (sizesRes.data && detailsRes.data && headerRes.data) {
                const sizesBody = sizesRes.data.Print;
                const selectedIds = detailsRes.data.map(item => item.item_id);
                setText(headerRes.data.name);
                setCheckboxChecked(headerRes.data.default_checked === "YES");
                setSizes([...sizesBody]);
                setSelectedRowKeys(selectedIds);

                const combinedData = sizesBody.map(size => {
                    const detail = detailsRes.data.find(d => d.item_id === size.ID) || {};
                    return {
                        id: size.ID,
                        name: `${size.width} x ${size.height}`,
                        sellingPrice: detail.price ? detail.price.toFixed(2) : 0.00,
                    };
                });
                setLoading(false);
                setData(combinedData);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching sizes and details", error);
        }
    };

    useEffect(() => {
        fetchSizesAndDetails(parent_id);
    }, []);

    useEffect(() => {
        checkboxChecked ? setChecked("YES") : setChecked("NO")
    }, [checkboxChecked]);

    const sellingPriceChange = (event, id) => {
        const {value} = event.target;
        const newValue = value.trim() !== '' ? parseFloat(value) : 0.00;
        handleSellingPriceChange(newValue, id);
    };

    const handleSellingPriceChange = (value, id) => {
        const updatedData = data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    sellingPrice: value,
                };
            }
            return item;
        });
        setData(updatedData);
    };

    const clearInput = (id) => {
        const updatedData = data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    sellingPrice: '',
                };
            }
            return item;
        });
        setData(updatedData);
    };

    const handleBlur = (e, id) => {
        const value = parseFloat(e.target.value).toFixed(2)
        handleSellingPriceChange(value,id)
    };

    const handleChecked = () => {
        setCheckboxChecked(!checkboxChecked);
    };
    const handleInputChangeText = (e) => {
        setText(e.target.value);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            key: 'price',
            title: 'Selling Price',
            dataIndex: 'sellingPrice',
            width: 150,
            render: (_, record) => (
                <Input
                    type="number"
                    className="input_price"
                    suffix="$"
                    value={record.sellingPrice}
                    onChange={(e) => sellingPriceChange(e, record.id)}
                    onBlur={(e) => handleBlur(e, record.id)}
                    onClick={() => clearInput(record.id)}
                    key={record.id}
                />
            ),
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSave = () => {
        const selectedData = data.filter((item) => selectedRowKeys.includes(item.id));
        const obj = {
            product_type:1,
            parent_id: parent_id,
            size_data: selectedData,
            is_default: checked,
            name: text
        };
        setSizesList(obj)
    };
    if (loading) {
        return (
            <LoadingSpin>
                <div>Loading...</div>
            </LoadingSpin>
        );
    }
    return (
        <>
            <div className="text-center mt-4">
                <h3 className="text-uppercase mb-4 header-title">Edit Print Price Lab</h3>
            </div>
            <div>
                <div style={{marginBottom: 16}}>
                    <Row>
                        <Col md={24} span={24}>
                            <label className="textInput">Price list name</label>
                            <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
                        </Col>
                        <Col md={12} span={24}>
                            <Checkbox className="default_checkbox" checked={checkboxChecked}
                                      onChange={() => handleChecked()}>
                                <div className="default_text">Check if you want set your default price list</div>
                            </Checkbox>
                        </Col>
                        <Col md={12} span={24} className='btnHeader'>
                            <Button className="btn_price btn_save" st="true" onClick={handleSave}>
                                Update
                            </Button>
                        </Col>
                    </Row>

                </div>
                <Table
                    className="priceListTable"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data.map((item) => ({...item, key: item.id}))}
                    pagination={false}
                />
                <div className="divSave">
                    <Button className="btn_price btn_save" st="true" onClick={handleSave}>
                        Update
                    </Button>
                </div>
            </div>
        </>
    );
};

export default EditPriceLab;
