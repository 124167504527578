import React, { useState,useEffect } from 'react';
import {Col, Form, Input, message, Row, Select, Modal, Button, Checkbox, Tour} from 'antd';
import {handleTagChange} from "../../../../Utils/isFunction";
import styles from './Contract.module.css'
import Container from "react-bootstrap/Container";

const AddClient = ({ onNewClient} ) => {

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [checkboxValue,setCheckboxValue] = useState(false);

  const { Option } = Select;
  const [selectedItems, setSelectedItems] = useState([]);
  const OPTIONS = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  useEffect(() => {
  }, [checkboxValue]);

  useEffect(() => {
    let contractData = JSON.parse(localStorage.getItem('contract_data'));
    if(contractData?.clients.length > 0){
      setCurrentId(contractData.clients.length + 1)
    }else{
      setCurrentId(1)
    }

  }, []);

  const onChangeCheckbox = (e) => {
    setCheckboxValue(e.target.checked);
  };

  const handleTagChangee = (selectedTags) => {
    handleTagChange(selectedTags, form, message);
  };


  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const valuesId = {
        clientId: currentId,
        ...values,
      };
      if (onNewClient && typeof onNewClient === 'function') {
        onNewClient(valuesId);
      }
      form.resetFields();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Validation error:', error);
    }
  };


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
  <>
  <Button
    onClick={showModal}
    type="primary"
    className='formBtn send-email-btn mt-4'
  >
    Add Client
  </Button>
    <Modal open={isModalOpen} footer={false} onCancel={handleCancel} >
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Add Client</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
      <Form
        form={form}
        name="addClintForm"
        size="large"
        layout="vertical"
        initialValues={{ state: 'New York' }}
      >
        <Row justify="space-between">
          <Col lg={11} md={11} span={11}>
            <Form.Item
              name="email"
              label="Enter tags (email addresses)"
              rules={[
                {
                  required: true,
                  message: 'Please enter at least one email address.',
                  type: 'array',
                }
              ]}
            >
              <Select
                mode="tags"
                onChange={handleTagChangee}
                style={{width: '100%'}}
                placeholder="Enter your (email addresses)"
              />

            </Form.Item>
          </Col>
          <Col lg={11} md={11} span={11} >
            <Form.Item
              name="relationOptional"
              label="Relation / Optional"
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col lg={11} md={11} span={24}>
            <Form.Item
              label="Client Firstname"
              name="clientfirstname"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={11} md={11} span={24} >
            <Form.Item
              label="Client Lastname"
              name="clientlastname"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[ { required: true}]}
              >
                <Input/>
              </Form.Item>
            </Col>
          <Col span={11}>
          <Form.Item
            name="clientaddress1"
            label="Client Address 1"
            rules={[ {  required: true }]}
          >
            <Input/>
          </Form.Item></Col>
          <Col span={11} offset={2}>
          <Form.Item
            name="clientaddress2"
            label="Client Address 2"
          >
            <Input/>
          </Form.Item></Col>
          </Row>
          <Row justify="space-between">
          <Col lg={11} md={11} span={24}>
          <Form.Item
            name="appNumber"
            label="Apartment Number"
          >
            <Input/>
          </Form.Item>
          </Col>
            <Col lg={11} md={11} span={24}>
              <Form.Item
                name="zipCode"
                label="Zip Code"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        <Row justify="space-between">
          <Col lg={7} md={7} span={24}>
            <Form.Item
              name="city"
              label="City"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={7} md={7} span={24}>
            <Form.Item
              name="state"
              label="State"
            >
              <Select
                showSearch
                placeholder="Select State"
                value={selectedItems}
                onChange={setSelectedItems}
                options={filteredOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={7} span={24}>
            <Form.Item
              name="county"
              label="County"
              initialValue="US"
            >
              <Input disabled />
            </Form.Item>

          </Col>
        </Row>
          <Row justify="space-between">
            <Col lg={24} md={24} span={24}>
              <Form.Item
                name="notifyClient"
                valuePropName="checked"
              >
                <Checkbox onChange={onChangeCheckbox}> Notify Client</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Container className="text-end mt-4 mb-2">
        <Button htmlType="button" type="primary"  style={{marginRight:'15px'}} className={styles.contractBtn}  onClick={handleOk} >
          Save
        </Button>
        <Button htmlType="button" type="primary" className={styles.contractBtn}  onClick={handleCancel} >
          Cancel
        </Button>
      </Container>

      </Modal>
  </>
  )
}
export default AddClient;