import React, {useState,useEffect} from 'react';
import {Button, Col, Form, Input, Row, Space} from "antd";
import '../ShoppingCart.css'
import styles from "../../Studios/ClientSidebar/Contract/ContractAdd/Contract.module.css";
import { useCreditStudio } from './MainCheckout/store/selector';
const Checkout =  React.forwardRef((props, ref) => {
  const {
    balance,
    totalPrice,
    checkout,
    creditStudio,
    setCheckout,
    setBalance,
    setShippingAddress,
    shippingAddress,
    onNext
  
  }  = props

  const [form] = Form.useForm()
  const [shippingHandling, setShippingHandling] = useState('');
  const [creditUsed, setCreditUsed] = useState(0.00);
  const [creditUsedApply, setCreditUsedApply] = useState(0.00);
  const [couponCode, setCouponCode] = useState();
  const [couponValue, setCouponValue] = useState(0.00);


  const creaditStudio = useCreditStudio()




    const taxRate = 0.08625;
    const tax = (totalPrice * taxRate).toFixed(2);
    const total = (parseFloat(totalPrice) + parseFloat(shippingHandling) +parseFloat(tax) ).toFixed(2);
    const totalCredit = parseFloat(total-couponValue) .toFixed(2);


  const applyCoupon = () => {
    const couponValues = {
      coupon100: { type: '$', value: 100.00 },
      coupon200: { type: '$', value: 200.00 },
      coupon10percent: { type: '%', value: 0.10 },
    };

    const appliedCoupon = couponValues[couponCode];

    if (appliedCoupon) {
      if (appliedCoupon.type === '$') {
        const discountAmount = (appliedCoupon.value).toFixed(2);
        setCouponValue( discountAmount)
      } else if (appliedCoupon.type === '%') {
        const discountAmount = (total * appliedCoupon.value).toFixed(2);
        setCouponValue(discountAmount)
      }
    }
  };


  const hendelCreditUsedApply = () => {
    const maxValue = parseFloat(creditStudio.credit_balance / 100);
    const value = parseFloat(creditUsed).toFixed(2);
    const total = parseFloat(totalCredit).toFixed(2);

   

    if (parseFloat(value) < parseFloat(total) && creditUsed <= maxValue) {
      setCreditUsedApply(creditUsed ? creditUsed : totalCredit);
    } else {}
  };


  const onBlurCredit = () => {
    const formattedValue = parseFloat(creditUsed).toFixed(2);
    form.setFieldsValue({
      creditUsed: formattedValue || '',
    });
  };


  const handleFormValuesSave = (changedValues, allValues) => {
    setShippingAddress(allValues);
    setCheckout(allValues);
  };

  useEffect(() => {
    const newBalance = (parseFloat(total) - parseFloat(creditUsedApply) -parseFloat(couponValue)).toFixed(2)
    setBalance(newBalance);
    form.setFieldsValue({
      creditUsed: creditUsed? parseFloat(creditUsed).toFixed(2) : totalCredit,
    });
  }, [total, creditUsedApply,couponValue]);



  useEffect(() => {
    {
      shippingAddress.addressType !== 2?
        setShippingHandling('12.00'):
        setShippingHandling('0.00');
    }

  }, [shippingAddress.addressType]);

  React.useImperativeHandle(ref, () => ({
    validateAndProceed: () => {
      return new Promise((resolve, reject) => {
        form
          .validateFields()
          .then((obj) => {
            const formDate = {
              subtotal: totalPrice,
              shippingHandling: shippingHandling,
              total: total,
              balance: balance,
              tax: tax,
              creditUsedApply: parseFloat(creditUsedApply).toFixed(2),
              couponCode:couponCode,
              couponValue:couponValue,
              ...obj,
            };
            setCheckout(formDate);
            onNext && onNext(formDate);
            resolve();
          })
          .catch((info) => {
            reject();
          });
      });
    },
  }));



  return (
    <div style={{padding:'0 10px'}}>
        <div className={'text-center mt-4'}>
          <h3 className={'text-uppercase mb-4 header-title mt-5'}>Checkout</h3>
        </div>
        <Form
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          form={form}
          name="checkout"
          onValuesChange={handleFormValuesSave}
          className="form"
          style={{ maxWidth: 400, margin: 'auto' }}
        >
          <Space direction="vertical"  className={styles.textSpace}>
            {creaditStudio.credit_balance  && <div className={styles.pdfPackage}>
              <strong className="itemStrong">Available credit line (does not include this transaction)</strong>
              <b className={styles.itemP}>$ {(parseFloat(creaditStudio.credit_balance/100)).toFixed(2)}</b>
            </div>}
            <hr />
            <h5 style={{textAlign:'center'}} className={styles.itemStrong}>Order details</h5>
            <div className={styles.pdfPackage}>
              <strong className={styles.itemStrong}>Sub Total: </strong>
              <p className={styles.itemP}>$ {totalPrice}</p>
            </div>
            <div className={styles.pdfPackage} >
              <strong className={styles.itemStrong}>Tax 8.625%:  </strong>
              <p className={styles.itemP}>
                $ {tax}
              </p>
            </div>

            {shippingAddress.addressType!==2 ?(
                <div className={styles.pdfPackage}>
                  <strong className={styles.itemStrong}>Shipping and handling: </strong>
                  <p className={styles.itemP}>$ 12.00</p>
                </div>): null}
            <div className={styles.pdfPackage}>
              <strong className={styles.itemStrong}> Total: </strong>
              <p className={styles.itemP}>$ {total}</p>
            </div>
            <hr />
              <h5 style={{textAlign:'center'}} className={styles.itemStrong}>Payment Details</h5>

            {couponCode && <Form.Item
              labelCol={{span: 15}}
              wrapperCol={{span: 9}}
              name="couponCode"
              label="Coupon code"
            >
              {couponValue && <Input
                type="text"
                onClick={(e) => (e.target.value = '')}
                onChange={(e) => setCouponCode(e.target.value)}
                onBlur={applyCoupon}
              />}
              {couponValue > 0 && <p className={styles.itemP}>$ {parseFloat(couponValue).toFixed(2)}</p>}
            </Form.Item>
            }
            {creaditStudio.credit_balance && <Row justify='space-between'  style={{alignItems:'end'}}>

              <Col span={19} >
                   <Form.Item
                     labelCol={{span: 18}}
                     wrapperCol={{span: 6}}
                     name="creditUsed"
                     label="Apply from available credit line"
                     rules={[
                       {required: true, message: 'Please enter the applied credit amount!'},
                       {
                         validator(_, value) {
                           const maxValue = (parseFloat(creaditStudio.credit_balance / 100)).toFixed(2)
                           if (parseFloat(value) > maxValue) {
                             return Promise.reject(`Cannot apply more than available credit: $${maxValue}`);
                           } else if (parseFloat(value) > total) {
                             return Promise.reject(`Cannot apply more than available total sum: $${total}`);
                           }
                           return Promise.resolve();
                         },
                       },
                     ]}
                   >
                     <Input
                       suffix="$"
                       type="text"
                       onChange={(e) => setCreditUsed(e.target.value)}
                       onClick={(e) => (e.target.value = '')}
                       onBlur={onBlurCredit}
                       placeholder="0.00"
                     />
                   </Form.Item>
                 </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  onClick={hendelCreditUsedApply}
                  className={'ant-btn-set'}
                >
                  Apply
                </Button>
              </Col>

              <div className={styles.pdfPackage}>
                <strong className={styles.itemStrong}> Applied credit: </strong>
                <p className='itemP'>$ {parseFloat(creditUsedApply).toFixed(2)}</p>
              </div>
            </Row>


            }
            <Form.Item
              labelCol={{ span: 18 }}
              wrapperCol={{ span: 6 }}
              name="balance"
              label="Balance"
            >
              <p className={styles.itemP}>$ {parseFloat(balance).toFixed(2)}</p>
            </Form.Item>

          </Space>
        </Form>
    </div>
  );
})

export default Checkout;