import React,{useEffect, useState } from 'react';
import clientAPI from "../../../api/api";
import { useFetching } from "../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { Space, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import {showDeleteConfirm, showDeleteConfirmPrice} from "../../Utils/isFunction";

const BackgroundList = () => {

    const navigate = useNavigate();
    const PREFIX = "https://studioseye.com/pricing/upload/"; // Replace with your prefix
    const [data, setData] = useState([]);
    const current_user_id = localStorage.getItem('user_id');
    localStorage.setItem('current_user_id',current_user_id);
    const [getBgList, BgLoading, BgError] = useFetching(async () => {

        const { data: res } = await clientAPI.getBackgroundListByUserId();
        console.log(res)
        if (res) {
            console.log(res,"res")
            setData(res)
        }
    });
    const studio_id = localStorage.getItem('studio_id');

    if (studio_id && studio_id.trim() !== "") {
        localStorage.setItem('current_user_id', studio_id);
    }

    const edit_price = (price_list_id) => {
        localStorage.setItem('parent_id',price_list_id);
        if (studio_id && studio_id.trim() !== "") {
            navigate("/studio/background_edit");
            window.location.reload();
        }else{
            navigate("/labs/background_edit");
            window.location.reload();
        }



    }

    const deleteListItem = (id) => {
        showDeleteConfirm(id, 'Background list').then((result) => {
            if (result) {
                console.log('yes')
                const updatedData = data.filter((item) => item.id !== id);
                setData(updatedData);
            } else {
                console.log('NO')
            }
        });
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Is default',
            dataIndex: 'is_default',
            key: "is_default",
        },
        {
            title: 'Edit',
            dataIndex: 'price_edit',
            key: 'price_edit',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined  className="my_ant_icons_table" onClick={()=> edit_price(record.id)}/>
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined  className="my_ant_icons_table" onClick={()=> deleteListItem(record.id)}/>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getBgList();
    }, []);


        return(
            <>
                <div className="text-center mt-4">
                    <h3 className="text-uppercase mb-4 header-title">Print Price List Lab</h3>
                      <Table
                        className="table-striped-rows studio-table-rows priceListTable"
                        loading={BgLoading}
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={data}
                        key={(record) => record.id}
                        scroll={{x: 100}}
                      />
                </div>
            </>
        );

};

export default BackgroundList;