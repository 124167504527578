import {
    CarryOutOutlined,
    ReadOutlined,
    HomeOutlined,
    PictureOutlined,
} from "@ant-design/icons";

export const clientItems = [
    {
        key: 'gallery_list',
        icon: <HomeOutlined />,
        label: 'Galleries',
    },
    {
        key: 'events_list',
        icon: <CarryOutOutlined />,
        label: 'Events',
    },
    {
        key: 'album_list',
        icon: <ReadOutlined />,
        label: 'Albums',
    },
    {
        key: 'card_list',
        icon: <PictureOutlined />,
        label: 'Cards',
    }
]