import React, {useEffect, useState} from 'react';
import {Breadcrumb, Col, Layout, Menu, Row, theme} from 'antd';
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import logo from '../../../logo.png';
import {items} from './ClientItems';
import {
    RightCircleOutlined,
    LeftCircleOutlined,
} from '@ant-design/icons';
import isAuth from "../../../hoc/IsAuth";
import Login from "../../Login/Login";
import ClientHeader from "../ClidenNavBar/ClientNavbar.jsx";
import GalleryList from "../../Clients/Gallery/GalleryList";
import ClientCreditList from "./ClientCredit/ClientCreditList";
import {stringToBool} from "../../Utils/stringToBool";
const { Sider, Content,Footer } = Layout;
const ClientSidebar = (props) => {
    const navigate = useNavigate();
    const currentPath = '/client/';
    const [selectedKey, setSelectedKey] = useState('/');
    const [collapsed, setCollapsed] = useState(false);
    const [studioMode, setStudioMode] = useState(stringToBool(localStorage.getItem('studio_mode')));


    const {token: { colorBgContainer },} = theme.useToken();
    let { id } = useParams();

    useEffect(() => {
        if(id){
            setSelectedKey(id);
        }else if(props.page){
            setSelectedKey(props.page);
            navigate(currentPath+props.page);
        }

    }, []);


    useEffect(() => {
       
    }, [studioMode]);


    const handleMenuClick = (e) => {
        setSelectedKey(e.key);
        navigate(currentPath+e.key);
    };

    if(!isAuth()){
        return (
            <Login/>
        );
    }else{
        return (
            <Layout>
            {<ClientHeader
                studioMode={studioMode} setStudioMode={setStudioMode}
            />}
                <Layout>
                    {studioMode && (
                        <Sider trigger={null} collapsible collapsed={collapsed}
                               style={{
                                   background: '#223D8F',
                                   zIndex:1,
                                   top:0,
                                   bottom:0
                               }}
                        >
                            <div className="logo tricolor-logo-sidebar">
                                <img
                                    className={'sidebar-logo'}
                                    src={logo}
                                />

                            </div>
                            <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}
                                  theme="dark"
                                  mode="inline"
                                  defaultSelectedKeys={['3']}
                                  defaultOpenKeys={['sub1']}
                                  items={items}
                                  style={{
                                      background: '#223D8F'
                                  }}
                            />
                        </Sider>
                    )}
                <Layout>
                    {studioMode && (<>
                        {React.createElement(collapsed ? RightCircleOutlined  : LeftCircleOutlined, {
                            className: 'trigger',
                            onClick: () => setCollapsed(!collapsed),
                            style: { fontSize: '30px',
                                position: 'relative',
                                color: '#fff',
                                borderRadius: '50%',
                                backgroundColor:'rgb(34 61 143)',
                                zIndex: '14',
                                top:'33px',
                                left: '-17px',
                                width: '30px'
                            }

                        })}

                    </>)}
                    <Content style={{ margin: '0', padding: 0,minHeight: '100vh', }}>
                        <Row className="">
                            <Col span={22} offset={1}>
                                <Breadcrumb style={{ margin: '16px 0' }}>
                                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>List</Breadcrumb.Item>
                                    <Breadcrumb.Item>App</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col span={22} offset={1}>
                                {selectedKey === 'gallerylist' &&  <GalleryList/>}
                                {selectedKey === 'clientCredit' && <ClientCreditList />}
                            </Col>
                        </Row>
                    </Content>
                    <Footer className="footer">
                        Tricolorpic.com ©2023 Created by Tricolor professional imaging center
                    </Footer>
                </Layout>
                </Layout>
            </Layout>
        );
    }
}
export default ClientSidebar;
