import React, {useEffect, useState} from 'react';
import {Modal, Button, Row, Select, Col, Checkbox, Form, Input, Tooltip} from 'antd';
import Container from 'react-bootstrap/Container';
import styles from '../Gallery.module.css';
import SelectPriceDefault from './SelectPriceDefault';
import SelectCardPackage from './SelectCardPackage';
import SelectedAlbumPackage from './SelectedAlbumPackage';
import SelectOther from './SelectOther';
import { useFetching } from '../../../../hoc/fetchingHook';
import SelectUnitDefault from './SelectUnitDefault';
import style from "./CreateFolder.module.css";
import clientAPI from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setFolderDataList } from '../GalleryHeader/store/galleryHeaderSlice';
const { Option } = Select;

const CreateFolder = ({
                          folderDataList,
                          
                          itemId,
                          icon,
                          buttonText,
}) => {

  const studio_mode = localStorage.getItem('studio_mode');
    const [form] = Form.useForm();
    const [updateFolder, setUpdateFolder] = useState('');
    const [sizeId, setSizeId] = useState('');
    const [sizePrice, setSizePrice] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectType, setSelectType] = useState('');
    const [productType, setProductType] = useState('');
    const [checked, setChecked] = useState(false)
    const [countOfImages, setCountOfImages] = useState(0);

    const dispatch = useDispatch()

    const [saveFolder, folderLoading, folderError] = useFetching(async (folderData) => {
    const {data: res} = await clientAPI.createFolder(folderData);
        if (res && res.folder) {
            res.folder.images = [];
            dispatch(setFolderDataList(res.folder))

            
            // setFolderDataList(prevFolders => [...prevFolders, res.folder]);
        }
  });

    const handleOk = () => {
      form
        .validateFields()
        .then(values => {
          const studio_id = localStorage.getItem('studio_id');
          const client_id = localStorage.getItem('client_id');
          const event_id = localStorage.getItem('event_id');
          values['key'] = folderDataList.length.toString();
          values['studio_id'] = studio_id;
          values['client_id'] = client_id;
          values['event_id'] = event_id;
          values['size_id'] = sizeId
          // values['size_price'] = sizePrice
          values['product_type'] = productType
          values['quantity'] = values.quantity || 0
          values['checked'] = checked !== undefined ? checked: false;
          saveFolder(values);
          form.resetFields();
          setIsModalOpen(false);
        })
        .catch(errorInfo => {
          console.log('Failed:', errorInfo);
        });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = (id) => {
        setUpdateFolder(id)
        setIsModalOpen(true);
    };

    const handleChange = (value) => {
    let productType;

    if (value === 'Print') {
      productType = '1';
    } else if (value === 'Card') {
      productType = '2';
    } else if (value === 'Album') {
      productType = '3';
    } else if (value === 'Unit') {
      productType = '4';
    } else if (value === 'Other') {
      productType = '5';
    }

    setProductType(productType);
    setSelectType(value);
  };

    const handleCheckboxChange = (e) => {
       setChecked(!checked);
    };

    const CountOfImages = (e) => {
      setCountOfImages(e.target.value);
    };

    useEffect(() => {
    const folderToUpdate = folderDataList.find(folder => folder.id === updateFolder);
    if (folderToUpdate) {
      setSelectType(folderToUpdate.folder_name)
      form.setFieldsValue({
        folder_name: folderToUpdate.folder_name,
        quantity: folderToUpdate.quantity ,
        key: folderToUpdate.key,
        studio_id:folderToUpdate.studio_id,
        client_id:folderToUpdate.client_id,
        event_id:folderToUpdate.event_id,
        size_id:folderToUpdate.sizeId,
        product_type:folderToUpdate.productType,
        checked:folderToUpdate.checked,
        selected_size:folderToUpdate.selected_size,
        selectcard_type:folderToUpdate.selectcard_type,
        myown_folder:folderToUpdate.myown_folder,
      });
    }
  }, [updateFolder,selectType,form]);

  const handleUpdate = async () => {
    try {
      await form.validateFields();
      // Find the index of the folder to update
      const folderIndex = folderDataList.findIndex(folder => folder.id === updateFolder);

      if (folderIndex !== -1) {
        // Update the existing item
        folderDataList[folderIndex] = {
          ...folderDataList[folderIndex],
          ...form.getFieldsValue(), // Get form values directly
        };
        // Update the folder data list after the form is updated
        setFolderDataList([...folderDataList]);
      }
      setIsModalOpen(false);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

    useEffect(() => {
    }, [folderDataList,updateFolder,]);

  return (
      <>

        {itemId ?(<Button onClick={() =>showModal(itemId.id)} className={isModalOpen ? styles.galleryBtnActiveItems : styles.galleryBtnItems}>
              {icon} <span className={styles.CompareText}>{buttonText}</span>
          </Button>):(
          (<Button onClick={showModal} className={isModalOpen ? styles.galleryBtnActive : styles.galleryBtn}>
            {icon} <span className={styles.CompareText}>{buttonText}</span>
          </Button>)
        )}

          <Modal open={isModalOpen} footer={false} onCancel={handleCancel} className={styles.createFolder}>
              <div className={'text-center mt-4'}>
                  <h3 className={'text-uppercase mb-4 header-title'}>
                      {itemId ? 'Edit Create Folder' : 'Create Folder'}
                  </h3>
              </div>
              <div className={styles.selected}>
                  <Form form={form} onFinish={handleOk}>
                        <Row justify='center'>
                            <Col span={16}>
                                <Row justify="space-between">
                                    <Col span={24} className='mb10'>
                                        <Form.Item  label='Type' name='folder_name'>
                                            <Select className={styles.selectedMain} onChange={handleChange} value={selectType}>
                                                <Option value="Print">Print</Option>
                                                <Option value="Card">Card</Option>
                                                <Option value="Album">Album</Option>
                                                <Option value="Units">Units</Option>
                                                <Option value="Other">Other</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {selectType === 'Print' ? (
                                  <SelectPriceDefault setSizeId={setSizeId}  setSizePrice={setSizePrice} selectType={selectType} />
                                ) : null}
                                {selectType === 'Card' ? (
                                  <SelectCardPackage setSizeId={setSizeId} setSizePrice={setSizePrice}  selectType={selectType} />
                                ) : null}
                                {selectType === 'Album' ? (
                                  <SelectedAlbumPackage setSizeId={setSizeId} selectType={selectType} />
                                ) : null}
                                {selectType === 'Units' ? (
                                  <SelectUnitDefault setSizeId={setSizeId} setSizePrice={setSizePrice} selectType={selectType} />
                                ) : null}
                                {selectType === 'Other' ? <SelectOther selectType={selectType} /> : null}
                             <Row justify="space-between">

                               {studio_mode === 'true' && <Col span={24} className='mb10'>
                                    <Form.Item label="Number Of Images:" name="quantity" >
                                      <Input
                                        type="number"
                                        className={style.contractInput}
                                        value={countOfImages}
                                        onChange={CountOfImages}
                                      />
                                    </Form.Item>
                                  </Col>
                                }


                                    {/*<Col span={24} className='mb10'>*/}
                                    {/*    <Form.Item  name="checked" valuePropName="checked">*/}
                                    {/*        <Checkbox*/}
                                    {/*          className="mt10"*/}
                                    {/*          onChange={handleCheckboxChange}*/}
                                    {/*        >*/}
                                    {/*            Show only for me*/}
                                    {/*        </Checkbox>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                </Row>
                            </Col>
                        </Row>
                  </Form>
              </div>
              <Container className="text-end mt-4 mb-2">

                      {itemId ? (<Button htmlType="button" type="primary" style={{ marginRight: '15px' }} onClick={handleUpdate}>
                          Update
                      </Button> ): ( <Button htmlType="button" type="primary" style={{ marginRight: '15px' }} onClick={handleOk}>
                          Save
                      </Button>)}


                  <Button htmlType="button" type="primary" onClick={handleCancel}>
                      Cancel
                  </Button>
              </Container>
          </Modal>
      </>
    );
};

export default CreateFolder;
