import { useFetching } from "./fetchingHook";
import React, { useEffect, useState } from 'react';
import clientAPI from "../api/api";

const useCheckUserType = () => {
    const [userType, setUserType] = useState('');
    const [checkType] = useFetching(async () => {
        const response = await clientAPI.chechUsertType();
        if (response.data.message) {
            setUserType(response.data.message)
            localStorage.setItem('user_type',response.data.message)
        } else {
            setUserType('');
            localStorage.setItem('user_type','')
        }
    });

    useEffect(() => {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("user_id");
        const user_type = localStorage.getItem("user_type");
        if (token && user_id && user_type) {
            checkType();
        } else {
            setUserType('');
        }
    }, []);

    return userType;
};

export default useCheckUserType;