import React, {useEffect, useState} from 'react';
import { Form, Input, Row, Col, Select } from 'antd';



const MoreInfo = () => {
  const { Option } = Select;
  const [selectedItems, setSelectedItems] = useState([]);
  const OPTIONS = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  return (
    <>
      <Form.Item
        name="address1"
        label="Address 1"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address2"
        label="Address 2"
      >
        <Input />
      </Form.Item>
      <Row justify="space-between">
        <Col lg={11} md={11} span={24}>
          <Form.Item
            name="appNumber"
            label="Apartment Number"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={11} md={11} span={24}>
          <Form.Item
            name="phone"
            label="Phone"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col lg={11} md={11} span={24}>
          <Form.Item
            name="county"
            label="County"
            initialValue="US"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={11} md={11} span={24}>
          <Form.Item
            name="state"
            label="State"
          >
            <Select
              showSearch
              placeholder="Select State"

              value={selectedItems}
              onChange={setSelectedItems}
              options={filteredOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Form.Item>
        </Col>
        <Col lg={11} md={11} span={24}>
          <Form.Item
            name="city"
            label="City"
          >
            <Input />
          </Form.Item>
        </Col>


        <Col lg={11} md={11} span={24}>
          <Form.Item
            name="zipCode"
            label="Zip Code"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default MoreInfo;
