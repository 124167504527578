import React,{useEffect, useState,} from 'react';
import clientAPI from "../../../../../api/api";
import getColumnSearchProps from '../../../../Utils/getColumnSearchProps'
import { useFetching } from "../../../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { sortColumns, showDeleteConfirm, onChangeTable } from '../../../../Utils/isFunction'
import { Space, Table } from 'antd';
import {LoginOutlined, EditOutlined, DeleteOutlined, DownloadOutlined} from "@ant-design/icons";


const Completed = () => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });

  const [getCompletedList, isCompletedLoading, completedError] = useFetching(async () => {
    const { data: res } = await clientAPI.getCompletedListZip();
    if (res) {
      console.log(res, "res data getCompletedLISTZIP");

      const modifiedData = res.map((item) => ({
        ...item,
        size: formatSize(item.size),
        current_offset: formatSize(item.current_offset),
        count_files: item.count_files,
      }));

      console.log('modifiedData:', modifiedData);

      setData(modifiedData);
      setTableParams({
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: 20,
          total:modifiedData.length,
        },
      });
    }
  });


  const formatSize = (bytes) => {
    const gb = bytes / (1024 * 1024 * 1024); // Конвертируем байты в гигабайты
    return `${gb.toFixed(3)} GB`;
  };
  const handleDownload = (directLink) => {
    window.location.href = directLink;
  };


  useEffect(() => {
    getCompletedList();
  }, []);

  const handleTableChange = onChangeTable(tableParams, setTableParams);

  const columns = [
    {
      title: 'Count Files',
      dataIndex: 'count_files',
      key: 'count_files',
      ...getColumnSearchProps("count_files"),
      sorter: sortColumns("count_files"),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      ...getColumnSearchProps("size"),
      sorter: sortColumns("size"),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Current Offset',
      dataIndex: 'current_offset',
      key: 'current_offset',
      ...getColumnSearchProps("current_offset"),
      sorter: sortColumns("current_offset"),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Link',
      dataIndex: 'studio_delete',
      key: 'studio_delete',
      render: (_, record) => (
          <Space wrap>
            <DownloadOutlined
                className="my_ant_icons_table"
                onClick={() => handleDownload(record.direct_link)}
            />
          </Space>
      ),
    },

  ];
  if(isCompletedLoading){
    return (
        <>
          <Table
              className="table-striped-rows studio-table-rows"
              columns={columns}
              rowKey= "id"
              loading={isCompletedLoading}
              pagination={tableParams.pagination}
          />
        </>
    );
  }else{
    return(
        <>
          <Table
              className="table-striped-rows studio-table-rows"
              pagination={tableParams.pagination}
              loading={isCompletedLoading}
              rowKey= "id"
              columns={columns}
              dataSource={data}
              onChange={handleTableChange}
              scroll={{x:100}}
          />
        </>
    );
  }
};
export default Completed;