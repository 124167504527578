import React, {useEffect, useState,useRef} from 'react';
import styles from './Contract.module.css';
import {Col, Form, Input, Row, Select, DatePicker, TimePicker, Table, Tour} from 'antd';
import {DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import AddClient from "./AddClient";
import EditClientt from "../EditClientt";
import AddVenue from "./AddVenue";
import dayjs from "dayjs";
import {showDeleteConfirm} from "../../../../Utils/isFunction";
import {useHelperTab} from "../../../../../context/HelperTabContext";


const StepFirst = React.forwardRef((props, ref,) => {
  const isWindowWidthGreaterThan577 = window.innerWidth > 577;
  const { helperTab } = useHelperTab();
  const [open, setOpen] = useState(helperTab);
  useEffect(() => {
    if (typeof props.setHelperShow === 'function') {
      if (!open) { props.setHelperShow(false); }
    }
  }, [open]);

  useEffect(() => {
    if (props.helperShow) { setOpen(true);  }
  }, [props.helperShow]);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  useEffect(() => {
    props.setRefNext(ref5);
  }, [ref5, props]);
  const steps= [
    {
      title: 'Event Type',
      description: 'This dropdown menu lets you select the nature of the event from predefined options such as "Wedding", "Party", "Conference", etc. The event type helps in categorizing the event, which can be useful for planning, logistics, and tailoring services specific to the event’s needs',
      target: () => ref1.current,
    },
    {
      title: 'Event Date',
      description: 'For setting the date of the event, you can use the integrated calendar feature. Simply click on the date field, and a calendar will appear, allowing you to select the exact date of the event. This ensures accuracy and convenience in scheduling, preventing manual entry errors and providing a clear visual cue for the selected date. ',
      target: () => ref2.current,
    },
    {
      title: 'Add New Venue',
      description: 'This feature allows you to select a venue from an existing list within your system. It is designed to streamline the process of booking or assigning venues for events. By clicking on the dropdown menu, you can access a comprehensive list of all venues that have been previously entered into the database. Start typing the name of the venue in the search bar to quickly filter and find the specific venue you need. This intuitive search functionality helps in efficiently locating the desired venue without the need to scroll through the entire list. If the venue you require is not available in the dropdown list, it suggests that it has not yet been added to your system. In such cases, you can go to the \'Venue\' section to add a new venue. This tool is particularly useful for event planners and coordinators, ensuring a smooth and efficient venue selection',
      target: () => ref3.current,
    },{
      title: 'Add New Client',
      description: 'This feature allows you to choose a client from an already populated dropdown list of existing clients. It\'s designed for ease of access and efficiency, enabling you to quickly search and select from a list of clients who are already part of your system. To find a specific client, simply click on the dropdown menu and start typing the name in the search bar. The system will dynamically filter and display the matching results, making it easy to locate and select the client you need. If the client you\'re looking for is not in the list, it indicates they haven\'t been added to the system yet. In such cases, you can easily navigate to the "Client" menu to add a new client. This feature streamlines the process of associating specific clients with your activities or records, ensuring a smooth and organized workflow',
      target: () => ref4.current,
    },{
      title: 'Next',
      description: 'The "Next" button is an essential navigation tool in the form or process you are completing. It becomes active once all the required fields in the current step have been filled out correctly. This button is your pathway to advancing to the next stage of the process. Before clicking "Next," it\'s important to review the information you\'ve entered to ensure its accuracy and completeness. Once you are satisfied that all necessary data has been inputted, clicking the "Next" button will save your current progress and take you to the subsequent step. This functionality is designed for a seamless and organized progression through multi-step forms or processes, ensuring that each stage is completed before moving forward. The "Next" button is typically located in a prominent and intuitive place, making it easy to find and use. Its activation upon the completion of required fields also serves as a helpful indicator that you\'re ready to proceed.',
      target: () => ref5.current,
    },


  ];


  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [clients, setClients] = useState([]);
  const [venue, setVenue] = useState([]);
  const dateFormat = "MM-DD-YYYY";
  const timeFormat ="h:mm a"
  const { TextArea } = Input;


  React.useImperativeHandle(ref, () => ({
    validateAndProceed: () => {
      return new Promise((resolve, reject) => {
        form.validateFields()
            .then((values) => {
              const clientData = {
                ...values,
                clients: [...clients],
                venue: [...venue],
              };
              localStorage.setItem('contract_data', JSON.stringify(clientData));
              props.onNext && props.onNext(clientData);
              resolve();
            })
            .catch((info) => {
              reject();
            });
      });
    }
  }));

  const addClientToList = (newClient) => {
    setClients([...clients, newClient]);
  };
  const addVenueToList = (newVenue) => {
    setVenue([...venue, newVenue]);
  };


  const handleDelete = (clientId) => {
    showDeleteConfirm(clientId, 'Client').then((result) => {
      if (result) {
        const deleteClients = clients.filter((client) => client.clientId !== clientId);
        setClients(deleteClients);
      } else {
        console.log('NO')
      }
    });
  };
  const handleUpdateClient = (updatedClient) => {
    const updatedClients = clients.map((client) => {
      if (client.clientId === updatedClient.clientId) {
        return { ...client, ...updatedClient };
      }
      return client;
    });
    setClients(updatedClients);
  };

  const columns = [
    {
      key: 'count',
      title: '#',
      dataIndex: 'count',

    },
    {
      key: 'notifyClient',
      title: 'Notify Client',
      dataIndex: 'notifyClient',
      render: (notify) => (notify ? <CheckCircleOutlined className={styles.notifyIcon} /> : ''),

    },
    {
      key: 'firstname',
      title: 'First Name',
      dataIndex: 'clientfirstname',
    },
    {
      key: 'lastname',
      title: 'Last Name',
      dataIndex: 'clientlastname',
    },
    {
      key: 'email',
      title: 'E-mail',
      dataIndex: 'email',
      render: (emailList) => (
        <span>
       {emailList.map((email, index) => (
        <React.Fragment key={index}>
          {email}
          {index < emailList.length - 1 && <br/>}
        </React.Fragment>
      ))}
    </span>
      )
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: (record) => record.clientId,
      render: (_, record) => (
        <EditClientt client={record} clients={clients} onUpdateClient={handleUpdateClient} />
      ),
    },
    {
      key: 'delete',
      title: 'Delete',
      width: 80,
      render: (_, record, index) => (
        <DeleteOutlined
          className="my_ant_icons_table"
          onClick={() => handleDelete(record.clientId)}
        />
      ),
    },
  ]

  const options = [
    {
      key: 'sub3',
      value: 'Anniversary',
      label: 'Anniversary',
    },
    {
      key: 'sub10',
      value: 'Bar Mitzvah',
      label: 'Bar Mitzvah',
    },
    {
      key: 'sub2',
      value: 'Bat Mitzvah',
      label: 'Bat Mitzvah',
    },
    {
      key: 'sub4',
      value: 'Birthday',
      label: 'Birthday',
    },
    {
      key: 'sub6',
      value: 'Baptism',
      label: 'Baptism',
    },
    {
      key: 'sub9',
      value: 'Dance',
      label: 'Dance',
    },
    {
      key: 'sub5',
      value: 'Engagement Photos',
      label: 'Engagement Photos',
    },
    {
      key: 'sub7',
      value: 'Graduation',
      label: 'Graduation',
    },
    {
      key: 'sub8',
      value: 'School',
      label: 'School',
    },
    {
      key: 'sub1',
      value: 'Wedding',
      label: 'Wedding',
    },

    {
      key: 'sub11',
      value: 'Other',
      label: 'Other',
    },
  ];

  const handleFormValuesChange = (changedValues, allValues) => {
    setFormData(allValues);
    props.setStepFirstData(allValues);
  };

  useEffect(() => {
    if (props.stepFirstData) {
      const eventDate = props.stepFirstData.eventDate ? dayjs(props.stepFirstData.eventDate) : null;
      const contractDate = props.stepFirstData.contractDate ? dayjs(props.stepFirstData.contractDate) : null;
      const eventTime = props.stepFirstData.eventTime ? dayjs(props.stepFirstData.eventTime, timeFormat) : null;
      const ceremonyTime = props.stepFirstData.ceremonyTime ? dayjs(props.stepFirstData.ceremonyTime, timeFormat) : null;
      const formattedData = {
        ...props.stepFirstData,
        eventDate: eventDate && eventDate.isValid() ? eventDate : null,
        eventTime: eventTime && eventTime.isValid() ? eventTime : null,
        contractDate: contractDate && contractDate.isValid() ? contractDate : null,
        ceremonyTime: ceremonyTime && ceremonyTime.isValid() ? ceremonyTime : null,
      };

      form.setFieldsValue(formattedData);
      setFormData(formattedData);
    }
  }, [props.stepFirstData, form]);


  useEffect(() => {
    if (props.stepFirstData?.clients) {
      setClients(props.stepFirstData.clients);
    }
  }, [props.stepFirstData?.clients]);

  useEffect(() => {
    if (props.stepFirstData?.venue) {
      setVenue(props.stepFirstData.venue);
    }
  }, [props.stepFirstData?.venue]);



  return (
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Contract</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        <Form
          form={form}
          initialValues={formData}
          name="first_step"
          size="large"
          layout="vertical"
          style={{
            maxWidth: 1000,
            margin: 'auto',
          }}
          onValuesChange={handleFormValuesChange}
        >
          <Row justify="space-between">
            <Col lg={14} md ={14} span={24}>
              <Row justify="space-between">
                <Col  sm ={11} span={24}>
                <Form.Item label="Referred by" name="referredBy" >
                <Input className={styles.contractInput} />
              </Form.Item>
                </Col>
                  <Col ref={ref1}  sm ={11} span={24}>
              <Form.Item
                name="type"
                label="Event Type"
                rules={[ {  required: true, } ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Type"
                  optionFilterProp="children"

                  options={options}
                />
              </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col ref={ref2} span={11}>
                  <Form.Item label="Event Date" name="eventDate" rules={[ { required: true, } ]}>
                    <DatePicker
                        format={dateFormat}
                        className={styles.contractInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <Form.Item label="Event Time" name="eventTime" >
                    <TimePicker use12Hours format="h:mm a"  className={styles.contractInput}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="ceremony_location"
                    label="Ceremony Location"
                  >
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <Form.Item label="Ceremony Time" name="ceremonyTime" >
                    <TimePicker use12Hours format="h:mm a"  className={styles.contractInput}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} className='mt-4'>
                  <Form.Item
                    name="contractTextArea"
                  >
                    <TextArea placeholder="Note" rows={2} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={9} md ={9} span={24} >
              <Row justify='space-between'>
                <Col md={24} sm={7} xs={24}>
                  <Form.Item label="Contract Date" name="contractDate">
                    <DatePicker format={dateFormat} className={styles.contractInput} />
                  </Form.Item>
                </Col>
                <Col md={24}  sm={7} xs={24}>
                  <Form.Item
                    name="adults"
                    label="Number of the guests"
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col  md={24} sm={7} xs={24}>
                  <Form.Item
                    name="children"
                    label="Number of th children"
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>

              </Row>
              <Row justify="space-between">
                <Col  span={20}>
                  <Form.Item name="venueSelect" label="Venue">
                    <Select
                      showSearch
                      placeholder="Search to Venue Name"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                    >
                      {venue?.map((ven, index) => (
                        <Select.Option key={index} value={ven.venueName}>
                          {ven.venueName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}  ref={ref3}>
                  <Form.Item label="Add">
                    <AddVenue onNewVenue={addVenueToList} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span style={{padding:'10px'}}  ref={ref4}> <AddClient onNewClient={addClientToList} /></span>
              <Col span={24} className='mt-4'>
                <Table
                  className="priceListTable"
                  dataSource={clients?.map((client, index) => ({
                    ...client,
                    key: client.clientId,
                    count: index+1,
                  }))}
                  columns={columns}
                  bordered={ true}
                  rowKey={(record) => record.clientId}
                  pagination={false}
                  style={{ maxHeight: '200px', overflowY: 'auto' }}
                  scroll={{x:100}}
                />
              </Col>
            </Col>
          </Row>
        </Form>
        {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps} />}
      </div>
    </>
  )

});
export default StepFirst;