const DISCOUNT_TYPE_PRICE = '$';
const DISCOUNT_TYPE_PERCENT = '%';
const DISCOUNT_TYPES = [DISCOUNT_TYPE_PRICE, DISCOUNT_TYPE_PERCENT];

const initialState = {
    total: 0,
    sub_total: 0,
    discount: 0,
    discount_type: DISCOUNT_TYPE_PRICE,
    total_add_on: 0,
}

const packageRedosuer = (state = initialState ,action) => {
    let subTotal = 0;
    switch (action.type) {
        case 'init_data':
            return {
                ...state,
                total: action.total || 0,
                sub_total: action.sub_total || 0,
                discount: action.discount || 0,
                discount_type: action.discount_type || DISCOUNT_TYPE_PRICE,
                total_add_on: action.total_add_on || 0,
            };
        case 'add_total':
            subTotal = Number(action.total) + Number(state.discount);
            if (state.discount_type === DISCOUNT_TYPE_PERCENT) {
                subTotal = state.discount == 0 ? Number(action.total) : (action.total * 100 / (100 - Number(state.discount)));
            }
            return {
                ...state,
                sub_total: subTotal,
                total: Number(action.total),
            };
        case 'add_discount':
            subTotal = Number(state.total) + Number(action.discount);
            if (state.discount_type === DISCOUNT_TYPE_PERCENT) {
                subTotal = Number(action.discount) == 0 ? Number(state.total) : (state.total * 100 / (100 - Number(action.discount)));
            }
            return {
                ...state,
                sub_total: subTotal,
                discount: action.discount,
            };
        case 'add_discount_type':
            subTotal = Number(state.total) + Number(state.discount);
            if (action.discount_type === DISCOUNT_TYPE_PERCENT) {
                subTotal = state.discount == 0 ? Number(state.total) : (state.total * 100 / (100 - Number(state.discount)));
            }
            return {
                ...state,
                sub_total: subTotal,
                discount_type: action.discount_type,
            };
        case 'add_total_add_on':
            return {...state, total_add_on: action.total_add_on};
        default:
            return state;
    }
}


export default packageRedosuer;

