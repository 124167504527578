import React from 'react';
import {Col,Row} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import Quantity from "../../Utils/Quantity";

const AddButton = ({isEditorCartOpen,selectedIcon,isAddedToCart, updateCartData, handleAddItem,quantity,setQuantity}) => {
    return (
        <Row justify="center">
          {isEditorCartOpen ? null  :  ( <Col className="flexCenterClass" lg={12} md={12} span={12}>

              <div className="quantity pl20 mt5">
                <p>Quantity</p>
                <Quantity quantity={quantity} setQuantity={setQuantity} />
              </div>
           </Col>
            )}
                {isEditorCartOpen ? (
                    <Col className={`flexCenterClassSave mt35`} lg={12} md={12} span={12}>
                        <div className={selectedIcon ? 'addToCartOK' : 'add-to-cart'} onClick={updateCartData}>
                            {selectedIcon && <CheckCircleOutlined />}
                            <span> {selectedIcon ? 'SAVED TO CART!' : ' SAVE CART'} </span>
                        </div>
                    </Col>
                ) : (
                    <Col className={`flexCenterClassAdd mt35`} lg={12} md={12} span={12}>
                        <div className={isAddedToCart ? 'addToCartOK' : 'add-to-cart'} onClick={handleAddItem}>
                            {selectedIcon && <CheckCircleOutlined />}
                            <span> {(selectedIcon && isAddedToCart) ? 'ADDED TO CART!' : ' ADD TO CART'} </span>
                        </div>
                    </Col>
                )}
        </Row>
    );
};

export default AddButton;