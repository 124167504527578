import React, { useState,useEffect } from 'react';
import {Button, Col, Input, Row, Table, message, Space, Checkbox} from 'antd';
import Container from "react-bootstrap/Container";
import {PlusSquareOutlined,MinusSquareOutlined} from '@ant-design/icons'
import CheckedColumn from "../CheckedColumn";
import {useNavigate} from 'react-router';
import {showDeleteConfirmPrice} from "../../../Utils/isFunction";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientsAPI from "../../../../api/api";
import LoadingSpin from "../../../Utils/LoadingSpin";


const EditCardsLab = ({ nameCards, navigate_link, mainCardType }) => {

  const navigate = useNavigate();
  const [selectionType, setSelectionType] = useState('checkbox');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sizes, setSizes] = useState([]);
  const current_user_id = localStorage.getItem('user_id');
  const [typeChecked, setTypeChecked] = useState({})
  const [fromValue, setFromValue] = useState(0)
  const [inputNameValue, setInputNameValue] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [checked, setChecked] = useState("NO");
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);


  localStorage.setItem('current_user_id', current_user_id);

  const [createCard, isLoading, error] = useFetching(async (cardData) => {
    const { data: res } = await clientsAPI.createCardPrice(cardData);
    if (res) {
      navigate(`${navigate_link}`);
      window.location.reload();
    }
  });

  const fetchSizesAndDetails = async () => {

    const groupByRowId = (arrayOfArrays) => {
      return arrayOfArrays.reduce((acc, array) => {
        if (Array.isArray(array)) { // Check if it's an array

          array.forEach(item => {
            const rowId = item.row_id;
            if (!acc[rowId]) {
              acc[rowId] = {
                id: item.item_id,
                itemID: item.id,
                row_id: rowId,
                items: [],
              };
            }
            acc[rowId].items.push(item);
          });
        } else {
          console.warn("Encountered a non-array element:", array);
        }
        return acc;
      }, {});
    };

    try {
      const sizesRes = await clientsAPI.getProductObjectSizes(2, 'SIZE');
      if (sizesRes.data) {
        const detailsRes2 = await clientsAPI.getEditedCardById();
        if (detailsRes2.data) {
          const sizesData = sizesRes.data[nameCards];
          setSizes(sizesData);
          setInputNameValue(detailsRes2.data.header.name);
          setDefaultCheck(detailsRes2.data.header.default_checked === 'YES');
          // Group data by row_id
          const groupedData = groupByRowId(detailsRes2.data.details);
          const apiTypes = {};
          const apiSizes = [];
          // Loop through each key in groupedData
          for (let key in groupedData) {
            const apiItemTypes = groupedData[key].items;
            apiSizes.push(groupedData[key].itemID);

            // Loop through each item in items array
            for (let item of apiItemTypes) {
              apiTypes[item.item_type] = true;

            }
          }

          setCheckedRowKeys(apiSizes);
          setTypeChecked(apiTypes);

          // Process each group
          let i = 0;
          const transformedData = Object.values(groupedData).map(group => {
            ++i
            const size = sizesData.find(size => size.ID === group.id);
            console.log(group.items);
            if (size) {
              return  {
                key: i,
                id: group.id,
                itemID: group.itemID,
                size: `${size.width} x ${size.height}`,
                items: group.items.map(detail => ({
                  [detail.item_type]: {
                    checkType: typeChecked[detail.item_type] !== undefined ? typeChecked[detail.item_type] : false,
                    from: detail.item_from.toString(),
                    to: detail.item_to.toString(),
                    price: parseFloat(detail.item_price).toFixed(2),
                    isFromAPI: true,
                  },
                })),
                isNew: true,
              };


            }
          }).filter(item => item !== null);

          const normalizeTransformedData = (transformedData) => {
            return transformedData.map(item => {
              const normalizedItems = item.items.reduce((acc, detail) => {
                const [itemType, itemValue] = Object.entries(detail)[0];
                acc[itemType] = itemValue;
                return acc;
              }, {});
              return { ...item, items: [normalizedItems] };
            });
          };



          const initialDataSize = sizesData.map((item, index) => ({
            key: index+1000,
            id: item.ID,
            size: `${item.width} x ${item.height}`,
            items: [{
              standard: { from: '', to: '', price: '', isFromAPI: false },
              linen: { from: '', to: '', price: '', isFromAPI: false },
              plain: { from: '', to: '', price: '', isFromAPI: false },

            }],
            isNew: false,
          }));
          const apiData =  normalizeTransformedData(transformedData)
          const combinedData = [...initialDataSize, ...apiData];
          const sortedData = combinedData.sort((a, b) => a.id - b.id);
          setData(sortedData);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching sizes and details', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('card_type',mainCardType);
    fetchSizesAndDetails();
  }, []);

  useEffect(() => {
    defaultCheck ? setChecked("YES") : setChecked("NO")
  }, [data,checkedRowKeys,defaultCheck]);

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 100,
    },
    {
      title: '',
      dataIndex: 'type',
      width: 200,
      children: [
        {
          title: <CheckedColumn text={'Standard'} checked={typeChecked['standard']} onChange={(newChecked) => handleTypeCheckedChange('standard', newChecked)} />,
          dataIndex: 'standard',
          key: 'Standard',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'standard'),
        }, {
          title: <CheckedColumn text={'Linen'} checked={typeChecked['linen']} onChange={(newChecked) => handleTypeCheckedChange('linen', newChecked)} />,
          dataIndex: 'linen',
          key: 'linen',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'linen'),
        },


        {
          title: <CheckedColumn text={'Pearl'} checked={typeChecked['pearl']} onChange={(newChecked) => handleTypeCheckedChange('pearl', newChecked)} />,
          dataIndex: 'pearl',
          key: 'pearl',
          width: 200,
          render: (_, record) => renderInputs(_, record, 'pearl'),
        },

      ],
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      width: 20,
      render: (_, record) => (
        <>
          <Row>
            <Col span={24}>
              {!record.isNew && (
                <PlusSquareOutlined className='my_ant_icons_table fz-40 ' onClick={() => handleAddRow(record.size,record.key,record.id,record.dataIndex)} />

              )}
            </Col>
            <Col span={24}>
              {record.isNew && (
                <MinusSquareOutlined  className='my_ant_icons_table fz-40' onClick={() => handleDeleteRow(record.key)} />
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleToInputBlur = (key, columnKey) => {
    setData((prevData) => {
      return prevData.map((row) => {
        if (row.key === key) {
          const fromValue = Number(row.items[0][columnKey]['from']);
          const toValue = Number(row.items[0][columnKey]['to']);
          if (fromValue >= toValue) {
            return {
              ...row,
              items: [
                {
                  ...row.items[0],
                  [columnKey]: {
                    ...row.items[0][columnKey],
                    'from': (toValue - 1).toString(),
                  },
                },
              ],
            };
          }
        }
        return row;
      });
    });
  };

  const handleInputChange = (key, columnKey, typeKey, field, value) => {
    setData((prevData) => {
      return prevData.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            items: [
              {
                ...row.items[0],
                [columnKey]: {
                  ...(row.items[0]?.[columnKey] || {}),
                  [field]: value,
                },
              },
            ],
          };
        }
        return row;
      });
    });
  }
  const handleInputChangePrice = (key, columnKey, typeKey, field, e) => {
    const value = e.target.value;
    handleInputChange(key, columnKey, typeKey, field, value);
  };

  const handleBlurPrice = (key, columnKey, typeKey, field, e) => {
    const floatValue = parseFloat(e.target.value).toFixed(2);
    handleInputChange(key, columnKey, typeKey, field, floatValue);
  };

  const renderInputs = (text, record, columnKey, typeKey) => {
    if (record && Array.isArray(record.items)) {
      const typeData = record.items[0][columnKey] || {};
      const fromValue = typeData.from || '';
      const toValue = typeData.to || '';
      const isInvalid = toValue !== '' && false ;

      const error = () => {
        message.error(`Cannot enter a value less than ${fromValue}`);
      };

      return (
        <Row justify={"space-between"}>
          <Col xl={12}  span={24}>
            <Input
              value={fromValue}
              onChange={(e) => handleInputChange(record.key, columnKey, typeKey, 'from', e.target.value)}
              placeholder="From"
              type="number"
              style={{
                padding: '5px 0',
                textAlign: 'center',
                marginBottom: '4px',
                borderColor: isInvalid ? 'red' : '',
              }}
            />
          </Col>
          <Col xl={11}  span={24}>
            <Input
              value={toValue}
              disabled={isInvalid}
              onClick={isInvalid ? null : error}
              onChange={(e) => handleInputChange(record.key, columnKey, typeKey, 'to', e.target.value)}
              onBlur={() => handleToInputBlur(record.key, columnKey)}
              placeholder="To"
              type="number"
              style={{
                padding: '5px 0',
                textAlign: 'center',
                marginBottom: '4px',
                borderColor: isInvalid ? 'red' : '',
              }}
            />
          </Col>
          <Col span={24}>
            <Input
              value={typeData.price || ''}
              placeholder="Price"
              onChange={(e) => handleInputChangePrice(record.key, columnKey, typeKey, 'price', e)}
              onBlur={(e) => handleBlurPrice(record.key, columnKey, typeKey, 'price', e)}
              onClick ={(e)=>{e.target.value = ''}}
              type="number"
              style={{
                padding: '5px 0',
                textAlign: 'center',
                width: '100%',
              }}
            />
          </Col>
        </Row>
      );
    }else{
      console.log(record)
      return null;
    }
  }

  const handleAddRow = (selectedSize, currentIndex, id) => {
    const newData = [...data];
    const newKey = `${id}-${Date.now()}`;
    const newRow = {
      key: newKey,
      id: id,
      size: selectedSize,
      items:[{
        standard: [{ from:  '', to: '', price: '', isFromAPI: false }],
        linen: [{ from:  '', to: '', price: '', isFromAPI: false }],
        plain: [{ from:  '', to: '', price: '', isFromAPI: false }],
        // watercolor: [{ from:  '', to: '', price: '', isFromAPI: false }],
        // pearl: [{ from:  '', to: '', price: '', isFromAPI: false }],
        // canvas:[ {  from:'', to: '', price: '', isFromAPI: false }],
      }],
      isNew: true,
    };

    const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
      if (item.id === id) {
        return index;
      }
      return lastIndex;
    }, -1);

    newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);
    const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);

    if (previousRow !== undefined) {
      const standardTo = parseFloat(previousRow.items[0].standard?.to);
      const linenTo = parseFloat(previousRow.items[0].linen?.to);
      const plainTo = parseFloat(previousRow.items[0].plain?.to);
      const watercolorTo = parseFloat(previousRow.items[0].watercolor?.to);
      const pearlTo = parseFloat(previousRow.items[0].pearl?.to);
      const canvasTo = parseFloat(previousRow.items[0].canvas?.to);

      // Only update the 'from' values for the newly added row
      if (previousRow) {
        newRow.items[0].standard.from = standardTo + 1;
        newRow.items[0].linen.from =standardTo + 1;
        newRow.items[0].plain.from = plainTo + 1;
        newRow.items[0].watercolor.from = watercolorTo + 1;
        newRow.items[0].pearl.from = pearlTo + 1;
        newRow.items[0].canvas.from = canvasTo + 1;
      }

      setFromValue(standardTo + 1);
      setFromValue(linenTo + 1);
      setFromValue(plainTo + 1);
      setFromValue(watercolorTo + 1);
      setFromValue(pearlTo + 1);
      setFromValue(canvasTo + 1);
    }

    setData(newData);
  };

  const handleDeleteRow = (rowKey) => {
    showDeleteConfirmPrice(rowKey).then((result) => {
      if (result) {
        console.log("User clicked Yes");

        const rowToDelete = data.find((item) => item.key === rowKey);
        if (rowToDelete) {
          if (data.length <= 1) {
            return;
          }
          const newData = data.filter((item) => item.key !== rowKey);
          const updatedData = newData.map((item, index) => ({ ...item, key: index }));
          setData(updatedData);
        }
      } else {
        console.log("User clicked No");
      }
    });
  }

  const rowSelection = {
    selectedRowKeys: checkedRowKeys,
    onChange: (selectedRowKeys) => {
      setCheckedRowKeys(selectedRowKeys);
    },
  };

  const handleTypeCheckedChange = (typeKey, newChecked) => {
    setTypeChecked((prevTypeChecked) => ({
      ...prevTypeChecked,
      [typeKey]: newChecked,
    }));
  };


  const handleSubmit = async () => {
    const edited_card_id = localStorage.getItem('edited_card');
    const sizeInfoData = data.filter((item) => checkedRowKeys.includes(item.itemID) || checkedRowKeys.includes(item.key))
      .map((item) => {
        const selectedTypes = Object.keys(item.items[0])
          .filter((typeKey) => item.items[typeKey]?.from !== '' && item.items[typeKey]?.to !== '' && item.items[typeKey]?.price !== '')
          .map((typeKeys) => ({
            checkType: typeChecked[typeKeys] !== undefined ? typeChecked[typeKeys] : false,
            type: typeKeys,
            from: item.items[0][typeKeys]?.from,
            to: item.items[0][typeKeys]?.to,
            price: item.items[0][typeKeys]?.price,
          }));
        return {
          itemId: item.id,
          size: item.size,
          cardName: selectedTypes,
        };
      });

    const newCardData  = {
      mainCardType : mainCardType,
      edited_card_id:edited_card_id,
      default_check : defaultCheck,
      priceName: inputNameValue,
      sizeInfoData: sizeInfoData
    };

    try {
      createCard(newCardData);
    } catch (err) {
      console.error("Error creating card:", err);
    }
  };

  const handleInputNameChange = (e) => {
    const newValue = e.target.value;
    setInputNameValue(newValue);
  };

  if (loading) {
    return (
      <LoadingSpin>
        <div>Loading...</div>
      </LoadingSpin>
    );
  }

  return (
    <div>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Edite {nameCards} Lab</h3>
      </div>

        <div style={{ margin: 16}}  >
          <Row>
            <Col md={24} span={24}  >
              <label >Add price Name</label>
              <Input
                type="text"
                className="input_price"
                value={inputNameValue}
                onChange={handleInputNameChange}
              />
            </Col>
            <Col md={12} span={24}>
              <Checkbox className="default_checkbox" checked={defaultCheck}
                        onChange={() => setDefaultCheck(!defaultCheck)}>
                <div className="default_text">Check if you want set your default price list</div>
              </Checkbox>
            </Col>
            <Col md={12} span={24} className='btnHeader'>
              <Button onClick={handleSubmit } type="primary" htmlType="submit"   className="btn_price btn_save" st="true" >
                Update
              </Button>
            </Col>
          </Row>
        </div>
        <Table
          className='priceTable'
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.itemID ? record.itemID : record.key}
          pagination={false}
        />

        <div className="divSave">
          <Button onClick={handleSubmit } type="primary" htmlType="submit"   className="btn_price btn_save" st="true" >
            Update
          </Button>
        </div>
      </div>
  );
};


export default EditCardsLab;


