import React, { useEffect } from "react";
import { PinturaEditor } from '@pqina/react-pintura';
import { getEditorDefaults } from '@pqina/pintura';
import '@pqina/pintura/pintura.css';

const ImageEditor = ({
                         img,
                         aspectRatio,
                         setIsModalOpen,
                         setProcessedData,
                         processedData
                     }) => {
    // This function will be called when the image processing is done
    const handleProcess = (res) => {
        // Close the modal once processing is complete
        setIsModalOpen(false);

        // Construct a processed data object from the result
        const processedObject = {
            name: res.dest.name,
            crop: res.imageState.crop,
            rotation: res.imageState.rotation
        };

        // Update the processed data state with the new object
        setProcessedData(processedObject);
        console.log(res)
    };

    // Log the aspect ratio to console - this can be removed if not needed
    useEffect(() => {
        console.log('Aspect Ratio:', aspectRatio);
    }, [aspectRatio]);

    return (
        <div style={{ height: '600px' }}>
            <PinturaEditor
                cropEnableRotationInput={false}
                cropEnableZoomAutoHide={false}
                cropActiveTransformTool='zoom'
                imageCrop={processedData ? processedData.crop : ""}
                imageCropAspectRatio={aspectRatio}
                {...getEditorDefaults()}
                src={img}
                onProcess={handleProcess}
            />
        </div>
    );
};

export default ImageEditor;

