import React from 'react';
import AddCardsLab from "../AddCardsLab";

const AddFoldedAccordionLab = () => {

  const nameCards = "Tri Folded & Accordion Cards";
  const navigate_link = '/labs/folded_accordion_list';
  const mainCardType = 2;
  return (
    <div>
      <AddCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};
export default AddFoldedAccordionLab;

