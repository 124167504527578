import React, {useEffect, useRef, useState} from 'react';
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";
import {Checkbox} from 'antd';
import {useNavigate} from 'react-router';
import LoadingSpin from "../../../Utils/LoadingSpin";
import {Button, Col, Input, Row, Table, Tour} from 'antd';

const AddPriceLab = ({helperShow,setHelperShow}) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const [open, setOpen] = useState(true);
    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }

    }, [helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const steps = [
        {
            title: 'Select Print Name',
            description: 'Choose your Price list name and set your default price list',
            target: () => ref1.current,
        },
        {
            title: 'Select Size',
            description: 'Choose your print  price size and Price',
            target: () => ref2.current,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [text, setText] = useState("");
    const [checked, setChecked] = useState("NO");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const current_user_id = localStorage.getItem('user_id');
    localStorage.setItem('current_user_id', current_user_id);

    const [getSizesList, getSizesLoading, getSizesError] = useFetching(async () => {
        const {data: res} = await clientAPI.getProductObjectSizes(5, "SIZE");
        if (res) {

            const body = res.Print
            setSizes([...body]);
            const newData = body.map((item) => ({
                id: item.ID,
                name: `${item.width} x ${item.height}`,
                sellingPrice: parseFloat(0.00).toFixed(2),
            }));

            setData(newData);
        }
    });
    const [setSizesList, setSizesLoading, setSizesError] = useFetching(async (obj) => {
        const {data: res} = await clientAPI.setProductObjectSizes(obj);
        if (res) {
            setLoading(false);
            navigate('/labs/price_list');
            window.location.reload();
        }
    });

    const sellingPriceChange = (event, id) => {
        const {value} = event.target;
        const newValue = value.trim() !== '' ? parseFloat(value) : 0.00;
        handleSellingPriceChange(newValue, id);
    };

    useEffect(() => {
        checkboxChecked ? setChecked("YES") : setChecked("NO")
    }, [checkboxChecked]);

    useEffect(() => {
        setLoading(true);
        // Fetch data
        getSizesList()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error fetching sizes', error);
            });
    }, []);
    if (loading) {
        return (
            <LoadingSpin>
                <div>Loading...</div>
            </LoadingSpin>
        );
    }
    const handleSellingPriceChange = (value, id) => {
        const updatedData = data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    sellingPrice: value,
                };
            }
            return item;
        });
        setData(updatedData);
    };
    const clearInput = (id) => {
        const updatedData = data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    sellingPrice: '',
                };
            }
            return item;
        });
        setData(updatedData);
    };

    const handleBlur = (e, id) => {
      const value = parseFloat(e.target.value).toFixed(2)
        handleSellingPriceChange(value,id)
    };

    const handleChecked = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const handleInputChangeText = (e) => {
        setText(e.target.value);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ref: ref2,
            width: 300
        },
        {
            title: 'Selling Price',
            dataIndex: 'sellingPrice',
            className: 'sellingPrice',
            width: 150,
            align: 'center',

            render: (_, record) => (
              <Input
                className="input_price"
                type="number"
                value={record.sellingPrice}
                suffix="$"
                key={record.id}
                onChange={(e) => sellingPriceChange(e, record.id)}
                onBlur={(e) => handleBlur(e, record.id)}
                onClick={() => clearInput(record.id)}
              />
            ),
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSave = () => {
        const selectedData = data.filter((item) => selectedRowKeys.includes(item.id));
        const obj = {
            product_type:1,
            size_data: selectedData,
            is_default: checked,
            name: text
        };
        setSizesList(obj)
    };

    return (
        <>
            <div className="text-center mt-4">
                <h3 className="text-uppercase mb-4 header-title"> Print Price List Lab</h3>
            </div>
            <div>
                <div>
                    <Row ref={ref1}>
                        <Col md={24} span={24}  >
                            <label   className="textInput">Price list name</label>
                            <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
                        </Col>
                        <Col md={12} span={24}>
                            <Checkbox className="default_checkbox" checked={checkboxChecked}
                                      onChange={() => handleChecked()}>
                                <div className="default_text">Check if you want set your default price list</div>
                            </Checkbox>
                        </Col>
                        <Col md={12} span={24}>
                            <Button  className="btn_price btn_save" st="true" onClick={handleSave}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Table
                    className='peicTable'
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data.map((item) => ({...item, key: item.id}))}
                    pagination={false}
                />
                <div className="divSave">
                    <Button className="btn_price btn_save" st="true" onClick={handleSave}>
                        Save
                    </Button>
                </div>
                {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
            </div>
        </>
    );
};

export default AddPriceLab;
