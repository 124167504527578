import React, {useEffect, useRef, useState} from 'react';
import { Table, Col, Form, Input,Row,  Select, Collapse, InputNumber, Button, Space, Divider} from "antd";
import Container from "react-bootstrap/Container";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import styles from "../Contract/ContractAdd/Contract.module.css";
import {useContractContext} from "../../../../context/ContactContext";
import PriceDefault from "./PriceDefault";
import {useNavigate} from "react-router-dom";
import AlbumPackage from "./AlbumPackage";
import CardPackage from "./CardPackage";
import {showDeleteConfirm, sortColumns} from "../../../Utils/isFunction";
import Downloads from "./Downloads";
import clientAPI from "../../../../api/api";
import {useFetching} from "../../../../hoc/fetchingHook";
import UnitDefault from "./UnitDefault";
import style from "../../../Clients/Gallery/CreateFolder/CreateFolder.module.css";
import getColumnSearchProps from "../../../Utils/getColumnSearchProps";
import {useDispatch, useSelector} from "react-redux";
import TextArea from "antd/es/input/TextArea";

let index = 0;
const {Panel} = Collapse;

const EditPackage = () => {
    const state = useSelector(state => state.packageReducer);
    const dispatch = useDispatch();
    const {Option} = Select;
    const formRef = useRef(null);
    const [form] = Form.useForm();
    const {formData, setFormData} = useContractContext();
    const [data, setData] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [lastId, setLastId] = useState(1000);
    const [categories, setCategories] = useState([]);
    const [nameCategory, setNameCategory] = useState('');
    const inputRef = useRef(null);
    const [printDefaultId, setPrintDefaultId] = useState(null);
    const [unitDefaultId, setUnitDefaultId] = useState(null);
    const [printType, setPrintType] = useState(1);
    const [unitType, setUnitType] = useState(2);
    const [specialOffers, setSpecialOffers] = useState([]);
    const [selectedRowOffer, setSelectedRowOffer] = useState([]);
    const packageData = JSON.parse(localStorage.getItem('package_edit'));
    const [packageType, setPackageType] = useState(packageData.package_type);
    const formInitialValues = {
        id: packageData.id,
        packageType: packageData.package_type,
        packageCategory: Object.keys(packageData.package_category)?.map(Number),
        packageName: packageData.package_name,
        maxImagePos: packageData.max_image_pos,
        description: packageData.description,
        size_data: packageData.size_data,
        totalAddOn: packageData.total_add_on

    }

    let imagePositions = [...Array(20).keys()].map((_, index) => {
        let value = (index + 1).toString(); // convert numerical index to string and add 1 to start from 1
        return {
            label: value,
            value: value,
        };
    });
    imagePositions.push({ label: "Unlimited", value: "Unlimited" });

    const [getStudioDefaultPrint, printDefaultLoading, printDefaultError] = useFetching(async (printType) => {
        const {data: res} = await clientAPI.getPrintPriceStudioDefault(printType);
        if (res) {
            setPrintDefaultId(res.id);
        }
    });

    const [getStudioDefaultUnits, unitDefaultLoading, unitDefaultError] = useFetching(async (unitType) => {
        const {data: res} = await clientAPI.getPrintPriceStudioDefault(unitType);
        if (res) {
            setUnitDefaultId(res.id);
        }
    });

    const [getPCategory, catLoading, catError] = useFetching(async () => {
        const {data: res} = await clientAPI.getPackageCategory();
        if (res) {
            setCategories(res);
        }
    });

    const [getPAddOn, addLoading, addError] = useFetching(async () => {
        const {data: res} = await clientAPI.getPackageAddOn();
        if (res) {
            setSpecialOffers(res);
        }
    });


    const [updatePackage, packageLoading, packageError] = useFetching(async (pkg) => {
        const {data: res} = await clientAPI.updatePackage(pkg);
        if (res) {
            navigate('/studio/list_package')
            window.location.reload();
        }
    });

    const onNameCategoryChange = (event) => {
        setNameCategory(event.target.value);
    };


    const addItemCategory = async (e) => {
        if (nameCategory.trim() === '') {
            return;
        }

        try {
            // API call to add a new category
            const { data: newCategory } = await clientAPI.addPackageCategory(nameCategory);
            setCategories([...categories, newCategory]);
            setNameCategory('');
        } catch (error) {
            // Handle the error. Maybe show a notification to the user
            console.error("Error adding category:", error);
        } finally {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    };


    const navigate = useNavigate()
    const handleAddButtonClick = () => {
        let newId = formData.size_data?.length > 0 ? Math.max(...formData.size_data.map(item => item.id)) + 1 : 1;
        const existingIds = new Set(formData?.size_data?.map(item => item.item_id));
        let newItemId = lastId + 1;
        while (existingIds.has(newItemId)) {
            newItemId++;
        }
        setLastId(newItemId);

        const newItem = {
            id: newId++,
            type: 0,
            title_name: selectedType,
            item_id: newItemId,
            name:form.getFieldValue('typeDescription'),
            quantity: 1,

        };
        if (formData?.size_data) {
            const zeroCostItem = formData.size_data.find(item => item.type === 0 && item.cost === 0);
            if (zeroCostItem) {
                newItem.initialCost = zeroCostItem.initialCost;
            }
            const updatedFormData = {
                ...formData,
                size_data: [...formData.size_data, newItem],
            };
            setFormData(updatedFormData);
        }
        else{
            formData.size_data = [];
            const updatedFormData = {
                ...formData,
                size_data: [...formData.size_data, newItem],
            };
            setFormData(updatedFormData);
        }
    };

    const handleQuantityChange = (event, id,) => {
        const newQuantity = event;

        const updatedData = formData.size_data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    quantity: newQuantity,
                };
            }
            return item;
        });

        setData(updatedData);

        const updatedFormData = {
            ...formData,
            size_data: updatedData,
        };

        setFormData(updatedFormData);
    };


    const handleDelete = (id,) => {
        showDeleteConfirm(id ,'item').then((result) => {
            if (result) {
                const updatedSizeData = formData.size_data.filter((e) => e.id !== id);
                const updatedFormData = {
                    ...formData,
                    size_data: updatedSizeData,
                };
                setFormData(updatedFormData);
            } else {
            }
        })
    }

    const deleteEmptyData = (data) => {
        const filteredData = {};

        for (const [key, value] of Object.entries(data)) {
            if (value.length > 0) {
                filteredData[key] = value;
            }
        }

        if (Object.keys(filteredData).length === 0) {
            return null;
        }
        return filteredData;
    }

    const ServicePackege = () => {
        const [items1, setItems1] = useState(['Photographer']);
        const [name, setName] = useState('');
        const handleSelectChange = (value) => {
            setSelectedType(value);
        };
        const addItem = (e) => {
            e.preventDefault();
            setItems1([...items1, name || `New item ${index++}`]);
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        };
        const onNameChange = (event) => {
            setName(event.target.value);
        };
        return (
            <div>
                <Col span={24}>
                    <Form.Item name="type" label="Service" rules={[{required: true,}]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                )
                            }
                            allowClear
                            className="Select_Delete"
                            onChange={handleSelectChange}
                            placeholder="Type"
                            style={{
                                width:"100%"
                            }}

                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0', }}/>
                                    <Space style={{ padding: '0 8px 4px', }} >
                                        <Input
                                            placeholder="Please enter item"
                                            ref={inputRef}
                                            value={name}
                                            onChange={onNameChange}
                                        />
                                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                            Add Service
                                        </Button>
                                    </Space>
                                </>
                            )}
                            options={items1.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                        />

                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Service Description:" name="typeDescription">
                        <Input className={styles.contractInput}/>
                    </Form.Item>
                </Col>

                <Col span={24} className='mt-4'>
                    <Form.Item name="referredBy">
                        <Button
                            type="primary"
                            // htmlType="submit"
                            className='formBtn send-email-btn'
                            onClick={handleAddButtonClick}
                        > Add
                        </Button>
                    </Form.Item>
                </Col>

            </div>
        );
    };


    const handleCreateButtonClick = () => {

        const newPackageInfo = {
            id: packageData.id,
            package_type: packageType,
            package_name: form.getFieldValue('packageName'),
            package_category: form.getFieldValue('packageCategory'),
            max_image_pos: form.getFieldValue('maxImagePos'),
            description: form.getFieldValue('description'),
            total: state.total,
            total_add_on: form.getFieldValue('totalAddOn'),
            size_data: formData && formData.size_data ? formData.size_data : null,
            package_category_offers: deleteEmptyData(selectedRowOffer),
        };

        const { sub_total, ...packageInfoWithoutSubTotal } = newPackageInfo;

        updatePackage(packageInfoWithoutSubTotal);
    };

    const columns = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            width: 200,
            render: (_, record) => (
                <div>
                    {record.title_name + "  " + record.name}
                </div>
            ),
        },
        {
            title: 'Count',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 50,
            render: (_, record) => (
                <div>
                    <InputNumber
                        style={{width: "50px"}}
                        min={1}
                        defaultValue={record.quantity}
                        onChange={(e) => handleQuantityChange(e, record.id, record)}
                    />
                </div>
            ),
        },
        {
            key: 'actions',
            title: 'Actions',
            width: 80,
            render: (_, record) => (
                <DeleteOutlined
                    className="my_ant_icons_table"
                    onClick={() => handleDelete(record.id)}
                />
            ),
        }
    ];

    const columnsOffer = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            width: 200,
            render: (_, record) => (
                <div>
                    {record.package_name}
                </div>
            ),
        },
        {
            title: 'Add-on Items',
            dataIndex: 'title_name',
            key: 'title_name',
            ...getColumnSearchProps("title_name"),
            sorter: sortColumns('title_name'),
            sortDirections: ['descend', 'ascend'],
            align:'center',
            render: (_, record) => (
                <span>
                    {record.size_data && record.size_data.map(item => (
                        <div style = {{borderBottom:'1px solid #6d6868' }} key={item.id}>{item.title_name}  {item.name}</div>
                    ))}
                </span>
            ),
        },
    ];

    const items = [
        {
            key: '1',
            label: 'Services',
            children: <ServicePackege />
        },
        {
            key: '2',
            label: 'Print',
            children: <PriceDefault product_type="1" parent_id={printDefaultId} form={form}/>
        },
        {
            key: '3',
            label: 'Card',
            children: <CardPackage parent_id={printDefaultId} form={form}/>
        },
        {
            key: '4',
            label: 'Album',
            children: <AlbumPackage form={form} />
        },
        {
            key: '5',
            label: 'Downloads',
            children: <Downloads form={form}/>
        },
        {
            key: '6',
            label: 'Units',
            children: <UnitDefault product_type="2" parent_id={unitDefaultId} form={form}/>
        },
    ];

    const categoriesWithSpecialOffers = categories.map(category => {
        const data = specialOffers.filter(item => item.package_category.includes(category.id));

        const selectedCategoriesKeys = Object.keys(selectedRowOffer)?.map(Number) || [];
        let defaultActiveKey, selectedRowKeys = [];

        if (selectedCategoriesKeys.includes(category.id)) {
            defaultActiveKey = [category.id];
            selectedRowKeys = selectedRowOffer[category.id];
        }

        return {
            key: category.id,
            label: category.name,
            showArrow: !!data.length,
            children: data.length > 0 ? <Table
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowOffer) => {
                        setSelectedRowOffer({ ...selectedRowOffer, [category.id]: newSelectedRowOffer });
                    },
                }}
                className="categoriesWithSpecialOffersTable"
                columns={columnsOffer}
                dataSource={data} pagination={false}
                style={{maxHeight: '300px', overflowY: 'auto'}}
                rowKey={(record) => record.id}
                key={(record) => record.id}
            /> : null
        };
    }) || [];


    useEffect(() => {
        setFormData({
            ...formData,
            size_data: [...packageData.size_data],
        });
        setSelectedRowOffer(packageData.package_category_offers);

        getStudioDefaultPrint(printType);
        getStudioDefaultUnits(unitType);
        getPCategory();
        getPAddOn();
        dispatch({type: 'init_data', ...packageData});
    }, []);

    const onChange = (key) => {
        console.log(key);
    };

    return (
        <>
            <div className={'text-center mt-4'}>
                <h3 className={'text-uppercase mb-4 header-title'}> Edit Package </h3>
                <div className={'mt-4 album-notifications-select'}>
                    <Form
                        initialValues={formInitialValues}
                        ref={formRef}
                        form={form}
                        name="validate_other"
                        onFinish={(values) => {  return false;}}
                        size="large"
                        layout="vertical"
                        style={{
                            maxWidth: 1300,
                            margin: 'auto',
                        }}
                    >
                        <Row justify="space-between">
                            <Col lg={9} md={9} span={24}>
                                <Form.Item name="packageType" label="Package Type" rules={[{required: true}]}>
                                    <Select
                                        className={style.selectedCard}
                                        showSearch
                                        onChange={setPackageType}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "").toLowerCase().localeCompare(
                                                (optionB?.label ?? "").toLowerCase()
                                            )
                                        }
                                        value={packageType}
                                        // Set the value based on selectedType
                                    >
                                        {[
                                            {
                                                label: "Package",
                                                value: "Package",
                                            },
                                            {
                                                label: "Add-on",
                                                value: "Add-on",
                                            },
                                        ].map((option) => (
                                            <Select.Option key={option.value} value={option.value}
                                                           className={style.selectedOption}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>


                                <Form.Item name="packageCategory" label="Package Category" rules={[{required: true}]}
                                >
                                    <Select
                                        mode={"multiple"}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare(
                                                (optionB?.label ?? '').toLowerCase()
                                            )
                                        }
                                        allowClear
                                        className="Select_Delete"
                                        onChange={(value) => setSelectedCategories(value)}
                                        placeholder="Package Category"
                                        style={{
                                            width:"100%"
                                        }}

                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{margin: '8px 0',}} />
                                                <Space style={{padding: '0 8px 4px',}}>
                                                    <Input
                                                        placeholder="Please enter category name"
                                                        ref={inputRef}
                                                        value={nameCategory}
                                                        onChange={onNameCategoryChange}
                                                    />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={addItemCategory}>
                                                        Add Category
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {categories.map((option) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>

                                </Form.Item>
                                <Form.Item label="Package Name:" name="packageName">
                                    <Input className={styles.contractInput}/>
                                </Form.Item>

                                <Form.Item name="maxImagePos" label="Image Positions" rules={[{required: true}]}>
                                    <Select
                                        className={style.selectedCard}
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "").toLowerCase().localeCompare(
                                                (optionB?.label ?? "").toLowerCase()
                                            )
                                        }

                                    >
                                        {imagePositions.map((option) => (
                                            <Select.Option key={option.value} value={option.value}
                                                           className={style.selectedOption}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item name="description" label="Description">
                                    <TextArea rows={4}/>
                                </Form.Item>

                                <Col lg={24} md={24} span={24} className="mt-4">
                                    <Collapse items={items} accordion onChange={onChange} />
                                </Col>
                            </Col>

                            <Col lg={14} md={14} span={24}>
                                <Col span={24} className='mt-4'>

                                    <Table
                                        className="priceListTable"
                                        columns={columns}
                                        dataSource={Array.isArray(formData?.size_data) ? formData.size_data.map((item) => ({
                                            ...item,
                                            key: item.id
                                        })) : []} pagination={false}
                                        style={{maxHeight: '300px', overflowY: 'auto'}}
                                    />
                                    {packageType === 'Package' ? (
                                        <>
                                            <h5 className={'mb-3'}>Special offers</h5>
                                            {categoriesWithSpecialOffers.map(catItem => (
                                                <Col key={`cat-item-${catItem.key}`} lg={24} md={24} span={24} className="mt-1">
                                                    <div >
                                                    <Collapse
                                                        collapsible={!!catItem.children ? '' : 'disabled'}
                                                        items={[catItem]}
                                                        accordion
                                                        onChange={onChange}
                                                    />
                                                    </div>
                                                </Col>
                                            ))}
                                        </>
                                    ) : null}
                                    <Container className={'text-end'}>

                                        <div style={{marginTop: '16px', fontWeight: 'bold'}}>
                                            Total:
                                            <Input
                                                style={{ width: "100px",textAlign:"center" }}
                                                type="text"
                                                value={state.total}
                                                onChange={(e) => dispatch({type: 'add_total', total: e.target.value})}
                                            />
                                        </div>

                                        {packageType === 'Add-on' ? (
                                            <Form.Item name="totalAddOn" rules={[{required: true}]} style={{display: 'flex',  'flex-direction': 'row-reverse', 'margin-right': '100px', 'margin-top': '10px'}}
                                                       labelCol={{span: 24}}
                                                       wrapperCol={{span: 14}}
                                                       label={<label  style={{fontWeight: 'bold'}}>Add-on Total:</label>}
                                            >
                                                <Input
                                                    id={'totalAddOn'}
                                                    style={{ width: "100px",textAlign:"center" }}
                                                />
                                            </Form.Item>
                                        ) : null}

                                    </Container>

                                    <Container className={'text-end'}>
                                        <Button
                                            type="primary"
                                            className='formBtn send-email-btn'
                                            onClick={handleCreateButtonClick}
                                        > Update
                                        </Button>
                                    </Container>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default EditPackage;