import {Button, Col, Input, Row, Space, Table} from 'antd';
import React,{useEffect, useState} from 'react';
import './Price.css'
import {Checkbox} from 'antd';
import {useNavigate} from 'react-router';
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientAPI from "../../../../../api/api";
import {usePriceContext} from "../../../../../context/PriceContext";


const EditPriceStudio = () => {
    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [text, setText] = useState("");
    const [checked, setChecked] = useState("NO");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [data, setData] = useState([]);
    const {inputNumberValue, setInputNumberValue} = usePriceContext();
    const [detailsData, setDetailsData] = useState([]);
    const navigate = useNavigate();
    const parent_id = localStorage.getItem('parent_id');
    const  studio_id = localStorage.getItem('studio_id')
    localStorage.setItem('current_user_id', studio_id)

    const [setSizesList, setSizesLoading, setSizesError] = useFetching(async (obj) => {
        const {data: res} = await clientAPI.updatePrintPriceDetails(obj);
        if (res) {
            navigate('/studio/price_list');
            localStorage.removeItem('parent_id');
            window.location.reload();
        }else {
            setSizesError()
        }
    });

    const fetchSizesAndDetails = async (parent_id) => {
        try {
            const sizesRes = await clientAPI.getProductObjectSizes(5, 'SIZE');
            const headerRes = await clientAPI.getPrintPriceHeaderByID(parent_id);
            const headerRes2 = await clientAPI.getPrintPriceLabDefault();
            if (sizesRes.data && headerRes.data && headerRes2.data) {
                const detailsRes2 = await clientAPI.getPrintPriceDetails(headerRes2.data.id);
                if (detailsRes2.data) {
                    const detailsRes = await clientAPI.getPrintPriceDetails(parent_id);
                    setLoading(false);
                    const sizesBody = sizesRes.data.Print;
                    const selectedIds = detailsRes.data.map((item) => item.item_id);
                    setText(headerRes.data.name);
                    setInputNumberValue(headerRes.data.parseInt);
                    setCheckboxChecked(headerRes.data.default_checked === 'YES');
                    setSizes([...sizesBody]);
                    setSelectedRowKeys(selectedIds);
                    setDetailsData(detailsRes2.data);
                    setChecked(headerRes.data.default_checked);

                    const nonEmptyDetails = detailsRes.data.filter((detail) => detail.price);
                    const nonEmptyDetails2 = detailsRes2.data.filter((detail) => detail.price);

                    // Merge nonEmptyDetails and nonEmptyDetails2 based on item_id
                    const combinedData = nonEmptyDetails2.map((detail2) => {
                        const labPrice = detail2.price;

                        const matchingDetail = nonEmptyDetails.find((detail) => detail.item_id === detail2.item_id);
                        const {price, costPrice} = calculatePrice(matchingDetail?.price || detail2.price); // Use detail2.price if no matching detail found
                        const size = sizesBody.find((size) => size.ID === detail2.item_id);
                        const cost = matchingDetail ? (matchingDetail.price - detail2.price).toFixed(2) : '0.00';
                        return {
                            id: detail2.item_id,
                            name: `${size.width} x ${size.height}`,
                            sellingPrice: price,
                            cost: cost,
                            lab_price: labPrice,

                        };
                    });

                    setData(combinedData);
                    return detailsRes.data;
                }
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching sizes and details', error);
        }
    };

    useEffect(() => {
        fetchSizesAndDetails(parent_id);
    }, []);

    useEffect(() => {
        checkboxChecked ? setChecked("YES") : setChecked("NO")
    }, [checkboxChecked]);

    const calculatePrice = (detailPrice) => {

        const numericPrice = parseFloat(detailPrice);

        if (isNaN(numericPrice)) {
            return {
                price: '0.00',
                costPrice: '0.00',
            };
        }

        const price = ((numericPrice * inputNumberValue) / 100) + numericPrice;
        const costPrice = price - numericPrice;

        return {
            price: price.toFixed(2),
            costPrice: costPrice.toFixed(2),
        };
    };

    const sellingPriceChange = (event, id, originalSellingPrice) => {
        const { value } = event.target;
        const newValue = value !== '' ? parseFloat(value): originalSellingPrice;
        const updatedData = data.map((item) => {
            if (item.id === id) {
                const updatedSellingPrice = parseFloat(newValue).toFixed(2) !== originalSellingPrice ? newValue : originalSellingPrice;
                const defaultSellingPrice = detailsData.find((detail) => detail.item_id === id)?.price || 0;
                const cost = updatedSellingPrice - defaultSellingPrice ;
                return {
                    ...item,
                    sellingPrice: updatedSellingPrice,
                    cost: cost.toFixed(2),
                };
            }
            return item;
        });
        setData(updatedData);
    };

    const handlePriceChange = () => {
        const updatedData = data.map((item) => {
            const { price, costPrice } = calculatePrice(item.sellingPrice);
            const detailsRes2Price = detailsData.find((detail) => detail.item_id === item.id)?.price || 0;
            const cost = price - detailsRes2Price;
            return {
                ...item,
                sellingPrice: price,
                cost: cost.toFixed(2),
            };
        });
        setData(updatedData);
    };

    const handleInputChange = (e) => {
        setInputNumberValue(parseFloat(e.target.value));
    };

    const handleReset = () => {
        const updatedData = data.map((item) => {
            const detail = detailsData.find((detail) => detail.item_id === item.id);
            const detailPrice = detail ? detail.price : 0;
            return {
                ...item,
                sellingPrice: detailPrice.toFixed(2),
                cost: 0
            };
        });

        setInputNumberValue(0);
        setData(updatedData);
    };

    const handleChecked = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const handleInputChangeText = (e) => {
        setText(e.target.value);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Lab Price',
            dataIndex: 'lab_price',
            key: 'lab_price',
            render: (_, record) => (
                <span>
                {parseFloat(record.lab_price).toFixed(2)}
            </span>
            ),
        },
        {
            title: 'Gross Profit',
            dataIndex: 'cost',
            key: 'cost',
            render: (_, record) => (
                <span className={record.cost > 0 ? 'costGreen' : 'costRed'}>
                {record.cost}
              </span>
            ),
        },
        {
            key: 'price',
            title: 'Selling Price',
            dataIndex: 'sellingPrice',
            className: 'sellingPrice',
            render: (_, record) => (
                <Input
                    type="number"
                    suffix="$"
                    className="input_price"
                    value={record.sellingPrice}
                    onChange={(e) => sellingPriceChange(e, record.id, record.sellingPrice)}
                    key={record.id}
                    onClick={(e) => e.target.value = ''}
                    onBlur={(e) => {
                        const formattedPrice = parseFloat( e.target.value).toFixed(2);
                        sellingPriceChange(e, record.id,formattedPrice);
                    }}
                />

            ),
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSave = () => {
        const selectedData = data.filter((item) => selectedRowKeys.includes(item.id));
        const obj = {
            product_type: 1,
            parent_id: parent_id,
            size_data: selectedData,
            is_default: checked,
            name: text

        };
        setSizesList(obj)
    };
    return (
        <>
            <div className="text-center mt-4">
                <h3 className="text-uppercase mb-4 header-title">Edit Print Price Studio</h3>
            </div>
            <div>
                <div>
                    <Row  justify='start'>
                        <Col xs={24} lg={12} xl={8} className='divChecked'>
                            <label className="textInput">Add your price list name</label>
                            <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
                        </Col>
                        <Col xs={23} lg={12} xl={16}>
                            <Checkbox className="default_checkbox" checked={checkboxChecked}
                                      onChange={() => handleChecked()}>
                                <div className="default_text">Check if you want set your default price list</div>
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row justify='start'>
                        <Col xs={24} lg={12} xl={8} className='divChecked'>
                            <label className='textInput'>Price Adjustment %</label>
                            <Input type="number" className="input_price" value={inputNumberValue} onChange={handleInputChange}/>
                        </Col>
                        <Col xs={24} lg={12} xl={16}>
                            <Row justify='space-between'>
                                <Col span={16}>
                                    {inputNumberValue > 0 ? (
                                      <Space direction={"horizontal"}>
                                          <Button className="btn_price" onClick={handlePriceChange}>Set</Button>
                                          <Button className="btn_price" onClick={handleReset}>Reset</Button>
                                      </Space>
                                    ) : null}
                                </Col>
                                <Col span={8}>
                                    <Button className="btn_price btn_save " st="true" onClick={handleSave}> Save </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </div>
                <Table
                    className="priceListTable"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data.map((item) => ({...item, key: item.id}))}
                    pagination={false}
                    loading={loading}
                />
                <div className="divSave">
                    <Button className="btn_price btn_save" st="true" onClick={handleSave}>
                        Update
                    </Button>
                </div>
        </>
    );
};

export default EditPriceStudio;
