import React from 'react';
import { Button } from 'antd';

const Buttons = ({ activeStudio, chengeStudioMode ,studioModeLabel, clientModeLabel}) => {
  return (
    <div className="studioModeContainer">
      <Button
        type="primary"
        className={activeStudio ? "studioModeLabel formBtn bg_switcher_butoon_green" : " studioModeLabel formBtn bg_switcher_butoon_grey"}
        onClick={(e) => {
          activeStudio ? e.preventDefault() : chengeStudioMode();
        }}
      >
        {studioModeLabel}
      </Button>
      <Button
        type="primary"
        className={activeStudio ? "studioModeLabel formBtn bg_switcher_butoon_grey mrl15" : " studioModeLabel formBtn bg_switcher_butoon_green mrl15"}
        onClick={(e) => {
          !activeStudio ? e.preventDefault() : chengeStudioMode();
        }}
      >
        {clientModeLabel}
      </Button>
    </div>
);
};
export default Buttons;
