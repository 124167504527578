import React from 'react';
import { Row, Col, Space, Typography } from 'antd';
import images from './AlbumSidebarImages';
import {CloseCircleOutlined} from '@ant-design/icons';

function AlbumCoverMaterial({setSelectedCover,setCoverMaterialSidebarOpen,setSidebarOpen}) {
    const { Text } = Typography;
    const goBack = () => {
        setSidebarOpen(true);
        setCoverMaterialSidebarOpen(false);

    };



    return (
        <>
            <Row  gutter={16}>
                <div onClick={goBack}><CloseCircleOutlined className="fs19"/> Back</div>
                <Text  className="album-sidebar-secondary-title"
                       type="secondary">Colors and patterns may vary from representation.</Text>
                <Row className="border-line-bottom">
                    <Col lg={12} md={12} span={24}>
                        <img className="left-sidebar-covers p10" src={images[16].src} alt=""/>
                    </Col>

                    <Col lg={12} md={12} span={24}>
                        <p className="album-sidebar-image-title p10-b">Premium Leathers</p>
                        <p className="album-sidebar-image-desc p10-b">$240.00</p>
                        <p></p>
                    </Col>

                </Row>

                <Row className="border-line-bottom">
                    <Col lg={24} md={24} span={24}>
                        <p className="album-sidebar-image-title p10">Premium Leather</p>
                        <Row>
                            {images.map((image, index) => (
                                (index > 0 && index < 15) && (
                                    <Col key={image.id} lg={6} md={6} span={6}>
                                        <img className="left-sidebar-covers-mini p10"
                                             src={image.src}
                                             alt={image.alt}
                                             onClick={() => setSelectedCover(image.src)}
                                        />
                                    </Col>
                                )
                            ))}


                        </Row>
                    </Col>

                </Row>

            </Row>
        </>
    );
}

export default AlbumCoverMaterial;