import { useSelector } from "react-redux";

const useCreditStudio = () =>useSelector((state)=>state.mainCheckout.creditStudio)

const useOrderDetails= () =>useSelector((state)=>state.mainCheckout.orderDetails)

const useOrderID = () => useSelector((state)=>state.mainCheckout.orderID)

export {
    useCreditStudio,
    useOrderDetails,
    useOrderID
}