import React from 'react';
import { Row, Col, Space, Typography } from 'antd';
import images from './AlbumSidebarImages';
import {useSpring,animated} from "@react-spring/web";
import AlbumCoverMaterial from "./AlbumCoverMaterials";
import AlbumCameoSidebar from "./AlbumCameoSidebar";
import AlbumTextSidebar from "./AlbumTextSidebar";
import AlbumTextUVStamping from "./AlbumTextUVStamping";
const { Text } = Typography;
function AlbumSidebar({
                          selectedCover,
                          setSelectedCover,
                          selectedAlbumCoverType,
                          setSelectedAlbumCoverType,
                          setSidebarOpen,
                          setCoverMaterialSidebarOpen,
                          isSidebarOpen,
                          isCoverMaterialSidebarOpen,
                          isCameoOpen,
                          setCameoOpen,
                          showCameo,
                          setShowCameo,
                          setTextProperties,
                          isStampingOpen,
                          setIsStampingOpen,
                          isStampingUVOpen,
                          setIsStampingUVOpen,

                      }) {



    const sidebarStyle = useSpring({
        opacity: isSidebarOpen ? 1 : 0,
        transform: isSidebarOpen ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`
    });

    const coverMaterialSidebarStyle = useSpring({
        opacity: isCoverMaterialSidebarOpen ? 1 : 0,
        transform: isCoverMaterialSidebarOpen ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`
    });
    const cameoSidebarStyle = useSpring({
        opacity: isCameoOpen ? 1 : 0,
        transform: isCameoOpen ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`
    });

    const stampingSidebarStyle = useSpring({
        opacity: isStampingOpen ? 1 : 0,
        transform: isStampingOpen ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`
    });
    const stampingSidebarStyleUV = useSpring({
        opacity: isStampingUVOpen ? 1 : 0,
        transform: isStampingUVOpen ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`
    });



    const openCameoSidebar = () => {
        setSidebarOpen(false);
        setCoverMaterialSidebarOpen(false);
        setIsStampingOpen(false);
        setIsStampingUVOpen(false);
        setCameoOpen(true);
    };
    const openCoverMaterials = () => {
        setSidebarOpen(false);
        setCameoOpen(false);
        setIsStampingOpen(false);
        setIsStampingUVOpen(false);
        setCoverMaterialSidebarOpen(true);
    };

    const openStampingSidebar = () => {
        setSidebarOpen(false);
        setCameoOpen(false);
        setCoverMaterialSidebarOpen(false);
        setIsStampingOpen(true);

    };
    const openStampingSidebarUV = () => {
        setSidebarOpen(false);
        setCameoOpen(false);
        setCoverMaterialSidebarOpen(false);
        setIsStampingUVOpen(true);
    };
    const handleAlbumCoverTypeClick = (type) => {
        if(type === 'Full Material'){
        setSelectedAlbumCoverType(type);
        setShowCameo(null);
        }else if(type === 'SelectTemplate Wraparound Covers'){
            setSelectedCover(images[23].src);
            setSelectedAlbumCoverType(type);
            setShowCameo(null);
        }else{
            setSelectedAlbumCoverType(type);
        }
    };
    return (
        <>
            <animated.div style={sidebarStyle} className={isSidebarOpen ? "" : "hidden"}>
            <Row gutter={16}>
                <Col span={16}>
                    <h6>Cover Type</h6>
                </Col>
                <Col className="album-side-text-header" span={8}>
                    <Text  type="secondary">$40.00</Text>
                </Col>

            </Row>

{/*COVER TYPE*/}
        <Row  gutter={16}>
            <Text  className="album-sidebar-secondary-title"
                  type="secondary">Select Your album cover.</Text>
            <Row className="border-line-bottom">
                <Col lg={12} md={12} span={24}>
                    <img
                        className="left-sidebar-covers p10"
                        src={images[16].src}
                        alt=""
                        style={selectedAlbumCoverType === 'Full Material' ? {border: '1px solid #000'} : {}}
                        onClick={() => handleAlbumCoverTypeClick('Full Material')}
                    />

                </Col>
                <Col lg={12} md={12} span={24}>
                    <p className="album-sidebar-image-title p25-10-0-10">Full Material</p>

                </Col>
                <Col lg={12} md={12} span={24}>
                <img
                    className="left-sidebar-covers p10"
                    src={images[19].src}
                    alt=""
                    style={selectedAlbumCoverType === 'Acrylic Covers and Cameos' ? {border: '1px solid #000'} : {}}
                    onClick={() => handleAlbumCoverTypeClick('Acrylic Covers and Cameos')}

                />
                </Col>
                <Col lg={12} md={12} span={24}>
                    <p className="album-sidebar-image-title p25-10-0-10">Acrylic Covers and Cameos</p>

                </Col>

                <Col lg={12} md={12} span={24}>
                    <img
                        className="left-sidebar-covers p10"
                        src={images[20].src}
                        alt=""
                        style={selectedAlbumCoverType === 'SelectTemplate Wraparound Covers' ? {border: '1px solid #000'} : {}}
                        onClick={() => handleAlbumCoverTypeClick('SelectTemplate Wraparound Covers')}
                    />
                </Col>

                <Col lg={12} md={12} span={24}>
                    <p className="album-sidebar-image-title p25-10-0-10">Photo Wraparound Covers</p>
                </Col>
            </Row>
        </Row>
 {/*COVER TYPE  */}

{/*COVER OPTIONS    */}
            <Row  gutter={16}>
                <Text  className="album-sidebar-secondary-title"
                       type="secondary">Select Your album options.</Text>
                <Row className="border-line-bottom">
                    {(selectedAlbumCoverType === 'Full Material' ||
                        selectedAlbumCoverType === 'Acrylic Covers and Cameos') && (
                    <>
                    <Col lg={12} md={12} span={24}>
                    <img
                        className="left-sidebar-covers p10"
                        src={selectedCover}
                        alt=""
                        style={(selectedAlbumCoverType === 'Full Material' ||
                            selectedAlbumCoverType === 'Acrylic Covers and Cameos') ? {border: '1px solid #000'} : {}}
                        onClick={openCoverMaterials}
                    />
                    </Col>
                    <Col lg={12} md={12} span={24}>
                          <p className="album-sidebar-image-title p25-10-0-10">Selected Cover</p>
                    </Col>
                     </>
                    )}

                    {selectedAlbumCoverType === 'Acrylic Covers and Cameos' && (
                        <>
                        <Col lg={12} md={12} span={24}>
                            <img
                                className="left-sidebar-covers p10"
                                src={images[17].src}
                                alt=""
                                style={selectedAlbumCoverType === 'Acrylic Covers and Cameos' ? {border: '1px solid #000'} : {}}
                                onClick={openCameoSidebar}
                            />
                        </Col>
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>
                        </>
                    )}

                </Row>

            </Row>
{/*END COVER OPTIONS*/}
{/*STUDIO STAMPING*/}
                <Row  gutter={16}>
                    <Row className="border-line-bottom">
                                <Col lg={12} md={12} span={24}>
                                    <img
                                        className="left-sidebar-covers p10"
                                        src="https://localhost:3000/static/media/08-CA8901.1ed3041bbb5d4f297baf.jpg"
                                        alt=""
                                        onClick={openStampingSidebar}
                                    />
                                </Col>
                        <Col lg={12} md={12} span={24}>
                            <p className="album-sidebar-image-title p25-10-0-10">Foil Stamping</p>
                        </Col>
                    </Row>

                </Row>
{/*STUDIO STAMPING*/}
                <Row  gutter={16}>
                    <Row className="border-line-bottom">
                                <Col lg={12} md={12} span={24}>
                                    <img
                                        className="left-sidebar-covers p10"
                                        src="https://localhost:3000/static/media/08-CA8901.1ed3041bbb5d4f297baf.jpg"
                                        alt=""
                                        onClick={openStampingSidebarUV}
                                    />
                                </Col>
                        <Col lg={12} md={12} span={24}>
                            <p className="album-sidebar-image-title p25-10-0-10">UV Album Stamping</p>
                        </Col>
                    </Row>

                </Row>
{/*STUDIO STAMPING*/}
      {/*COVER MATERIALS*/}
            </animated.div>
            <animated.div style={coverMaterialSidebarStyle}  className={isCoverMaterialSidebarOpen ? "" : "hidden"}>
                <AlbumCoverMaterial
                    setSelectedCover={setSelectedCover}
                    setCoverMaterialSidebarOpen={setCoverMaterialSidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
            </animated.div>
            {/*COVER MATERIALS*/}

            {/*CAMEO STYLES*/}
            <animated.div
                style={cameoSidebarStyle}
                className={isCameoOpen ? "" : "hidden"}>
                <AlbumCameoSidebar
                    setSidebarOpen={setSidebarOpen}
                    isCameoOpen={isCameoOpen}
                    setCameoOpen={setCameoOpen}
                    showCameo = {showCameo}
                    setShowCameo = {setShowCameo}
                />
            </animated.div>
            {/*CAMEO STYLES*/}

             {/*FOIL STAMPING*/}
            <animated.div
                style={stampingSidebarStyle}
                className={isStampingOpen ? "" : "hidden"}>
                <AlbumTextSidebar
                    setSidebarOpen={setSidebarOpen}
                    setTextProperties={setTextProperties}
                    setIsStampingOpen={setIsStampingOpen}
                />
            </animated.div>
            {/*FOIL STAMPING*/}
             {/*FOIL STAMPING*/}
            <animated.div
                style={stampingSidebarStyleUV}
                className={isStampingUVOpen ? "" : "hidden"}>
                <AlbumTextUVStamping
                    setSidebarOpen={setSidebarOpen}
                    setTextProperties={setTextProperties}
                    setIsStampingUVOpen={setIsStampingUVOpen}
                />
            </animated.div>
            {/*FOIL STAMPING*/}


    </>
    );
}

export default AlbumSidebar;