import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import styles from './CreateFolder.module.css';
import style from '../Gallery.module.css';
import CreateFolder from './CreateFolder';
import { showDeleteConfirm } from '../../../Utils/isFunction';
import { useFetching } from '../../../../hoc/fetchingHook';
import clientAPI from '../../../../api/api';
import FolderOrder from '../../../Cart/FolderOrder';
import { useDispatch } from 'react-redux';
import { setFolderDataList, setSelectedFolder } from '../GalleryHeader/store/galleryHeaderSlice';

const ItemCreateFolder1 = ({
                             folderDataList,
                             
                             
                           }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folderData, setFolderData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [itemFold, setItemFold] = useState({});
  const dispatch = useDispatch()


  const [removeFolder, isRemoveFolderLoading, folderError] = useFetching(
    async (folderId) => {
      const { data: res } = await clientAPI.removeEventFolder(folderId);
      if (res) {
        const updatedFolderDataList = folderDataList.filter(folder => folder.id !== folderId);
        dispatch(setFolderDataList(updatedFolderDataList))  
      } else {
        return false;
      }
    }
  );

  const onDelete = async (id) => {
    const result = await showDeleteConfirm(id, 'Folder');
    if (result) {
      removeFolder(id);
    } else {
      folderError()
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onOrder = (item) => {
    setFolderData(item);
    setIsModalOpen(true);
  };
  const onShare = (item) => {
    console.log(item)
  };

  const handleClick = (item) => {
    // setSelectedFolder(item)
    dispatch(setSelectedFolder(item));
  };

  useEffect(() => {
    if (dropdownOpen) {
      const timer = setTimeout(() => {
        setDropdownOpen(false);
      }, 10000); // 10 seconds
      return () => clearTimeout(timer);
    }
  }, [dropdownOpen]);

  useEffect(() => {
    folderDataList.map((item) => {
      setItemFold(item)
    });
  }, [folderDataList, handleClick]);

  return (
    <>
      <Tooltip title="Open View Folder List">
        <Button className={style.buttonIcon} onClick={() => setDropdownOpen(!dropdownOpen)}>
          <UnorderedListOutlined /> <span className={style.CompareTextButtun}>View Folder list</span>
        </Button>
      </Tooltip>
      <div  className={dropdownOpen ? "DropdownOpenBlock DropdownMenu": "DropdownOpenNone"}>
          {folderDataList.map((item) => (
            <Row justify='space-between' key={item.id.toString()}>
              <Col className={styles.DropdownItem}>
                <span  onClick={() => handleClick(item)}>
                  {`${item.folder_name} `}  {item.myown_folder?  item.myown_folder : null}
                  {(item.selectcard_type !== '0' && item.selectcard_type !== 0) ? `${item.selectcard_type} ` : ' '}
                  {(item.selected_size !== '0' && item.selected_size !== 0) ? `${item.selected_size} ` : ' '}
                  {item.quantity >= 0 ? " ( " : null}
                  <span style={{ color: (item.images && item.images.reduce((sum, image) => sum + (image.quantity || 0), 0) > item.quantity) ? 'red' : 'inherit' }}>
                  {item.quantity >= 0 ? item.images.reduce((sum, image) => sum + (image.quantity || 0), 0) : null}
              </span>
                  {item.quantity >= 0 ? ` / ${item.quantity} )` : null}

                </span>
              </Col>
              <Col offset={1}>
                  <span className={styles.menu_icon}>
                      <Tooltip title="Edit Folder">
                        <CreateFolder
                          folderDataList={folderDataList}
                          setFolderDataList={setFolderDataList}
                          itemId={item}
                          icon={<EditOutlined />}
                          buttonText=""
                          className={styles.menu_button}
                        />
                    </Tooltip>
                    <Tooltip title="Delete Folder">
                      <Button className={styles.menu_button} onClick={() => onDelete(item.id)}>
                        <DeleteOutlined style={{ marginRight: '11px' }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Buy Folder">
                      <Button className={styles.menu_button} onClick={() => onOrder(item)}>
                        <ShoppingCartOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Share folder">
                      <Button className={styles.menu_button} onClick={() => onShare(item)}>
                        <ShareAltOutlined style={{ marginLeft: '5px' }}  />
                      </Button>
                    </Tooltip>
                 </span>
              </Col>
            </Row>
          ))}
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel} className='PDFModal' footer={null}>
        <FolderOrder folderData={folderData} />
      </Modal>
    </>
  );
};


export default ItemCreateFolder1;
