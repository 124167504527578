import React, {useEffect,useState} from 'react';
import { Table} from 'antd';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {useFetching} from "../../../../../../hoc/fetchingHook";
import clientsAPI from "../../../../../../api/api";
import {showDeleteConfirmPrice} from "../../../../../Utils/isFunction";

const ListFlatCardsStudio= () => {
  const navigate = useNavigate();
  const [flatCards, setFlatCards] = useState([]);
const  studio_id = localStorage.getItem('studio_id')
localStorage.setItem('current_user_id', studio_id)

  const [getCardList, isLoading, error] = useFetching(async () => {
    const { data: res } = await clientsAPI.getCardListByUserIDAndCardType();
    if (res) {
      setFlatCards(res);
    }
  });

  useEffect(() => {
    localStorage.setItem('card_type','1');
    getCardList();
  }, []);

  const handleDelete = (id) => {
    showDeleteConfirmPrice(id).then((result) => {
      if (result) {
        console.log('yes')
        const deleteFlatCard = flatCards.filter((flatCard) => flatCard.id !== id);
        setFlatCards(deleteFlatCard);

        const updatedLocalStorage = flatCards.filter((flatCard) => flatCard.id !== id);
        localStorage.setItem('flatCards', JSON.stringify(updatedLocalStorage));
      }else {
        console.log('NO')
      }
    })

  };

  const editFlatCard = (id) => {
    localStorage.setItem('edited_card',id);
    navigate('/studio/flat_cards_edit');
    window.location.reload()
  };


  const columns = [
    {
      key: 'count',
      title: '#',
      dataIndex: 'count',
      render: (text, record, index) => index + 1,
    },

    {
      key: 'name',
      title: 'Name Flat Card',
      dataIndex: 'name',
    },
    {
      key: 'default_checked',
      title: 'Is Default',
      dataIndex: 'default_checked',
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: (record) => record.id,
      render: (_, record) => (
          <EditOutlined  className="my_ant_icons_table"  onClick= {() => editFlatCard(record.id)}/>
      ),
    },
    {
      key: 'delete',
      title: 'Delete',
      render: (_, record, index) => (
        <DeleteOutlined
          className="my_ant_icons_table"
          onClick={() => handleDelete(record.id)}
        />
      ),
    },
  ]
return (
  <>
    <div className={'text-center mt-4'}>
      <h3 className={'text-uppercase mb-4 header-title'}>Flat Cards List Studio</h3>
    </div>
    <div className={'mt-4 album-notifications-select'}>
      {isLoading ? (
        <Table
          loading={isLoading}
          className="priceListTable"
          columns={columns}
          style={{ maxHeight: '100%', overflowY: 'auto' }}
        />
      ) : (
        <Table
          loading={isLoading}
          className="priceListTable priceTable"
          dataSource={flatCards}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={false}
          style={{ maxHeight: '100%', overflowY: 'auto' }}
          scroll={{x: 100}}
        />
      )}
    </div>
  </>

)
}
export default ListFlatCardsStudio;