import {Tooltip,  Col, Form, List, Radio, Row, Select,} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import React, {useContext, useEffect, useState} from "react";
import {useWindowSize} from "../../../Utils/WindowSize";
import DropZoneContext from "../../../../context/DropZoneContext";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";
import style from '../../CardDesign/CardMain.css'
import ObjectValueChekcer from "../../../Utils/ObjectValueChekcer";
import { InfoCircleFilled} from '@ant-design/icons';


const TemplateVerticalView = ({setCurrentSide,currentSide,setCardObject,setTemplateForm,cardType,setCardType,quantity,filteredObject,
                                setFilteredObject}) => {

  const { Option } = Select;
  const [form] = Form.useForm();
  const windowSize = useWindowSize();
  const gridConfig = windowSize.width <= 577
    ? {
      gutter: 1,
      column: 3,
    }
    : windowSize.width <= 768
      ?{
        gutter: 1,
        column: 4,
      }
      : windowSize.width <= 1200
        ? {
          gutter: 1,
          column: 5,
        }
        : {
          gutter: 1,
          column: 2,
        };

  const {setImageDropZones } = useContext(DropZoneContext);
  const [paperTypeOptions, setPaperTypeOptions] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [sizesCards, setSizesCards] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [templateData, setTemplateData] = useState({
      template_side:'front',
      product_type:'Fold Over',
      size_id: sizesCards[0]?.id,
      name: sizesCards[0]?.name,
      H: sizesCards[0]?.H,
      W: sizesCards[0]?.W,
      paper_type: paperTypes[0]?.item_type,
      paper_type_id: paperTypes[0]?.id,
      template_pos: 'Vertical',
      cardsPrices:filteredObject.options
  });

  const [getCardItemByID, isCardItemLoading, cardItemError] = useFetching(async (guid) => {
    const res = await clientAPI.getCardByGuid(guid);
    if (res) {
      setCardObject(prevCardObject => ({
        ...prevCardObject,
        [currentSide]: {
          ...prevCardObject[currentSide],
          template: res.data
        }
      }));
      setImageDropZones(prev => ({
        "main": guid,
        "product_type": "CARDS"
      }));
    }
  });

  const [getTemplateList, isTemplateListLoading, isTemplateListError] = useFetching(async (data) => {
    const res = await clientAPI.getTemplatesByData(data);
    if (res) {
      setTemplateList(res.data)
    }
  });

  const changeJsonData = (id, guid, name, VIEW_IMAGE_PATH) => {
    getCardItemByID(guid);

  };

    const handleChange = (value) => {
        let selectorChange;
        if(value === 'Fold Over' && currentSide === 'back'){
            selectorChange = 'inside';
        }else if(value === 'Fold Over' && currentSide === 'front'){
            selectorChange = 'front-back';
        }else{
            selectorChange = currentSide;
        }
        const formValueBasedOnSide = selectorChange;
        form.setFieldsValue({ template_side: formValueBasedOnSide });
        setTemplateData(prevTemplateData => ({
            ...prevTemplateData,
            template_side:formValueBasedOnSide,
            product_type: value,
        }));
        form.setFieldsValue({ size_id: undefined });
        setSizesCards([]);
        setCardType(value);
    };

    const handleChangePos = (value) => {
        setTemplateData(prevTemplateData => ({
            ...prevTemplateData, // Spread the previous templateData to keep other properties
            template_pos: value.target.value, // Update product_type with the new value
        }));

    };

    const handleTemplateSide = (e) => {
        setTemplateData(prevTemplateData => ({
            ...prevTemplateData, // Spread the previous templateData to keep other properties
            template_side: e.target.value, // Update product_type with the new value
        }));

        if(currentSide === 'back'){
            setCurrentSide('front');
        }else{
            setCurrentSide('back');
        }

    };

   const handleChangeSize = (value) => {
    const selectedSize = sizesCards.find((sizeOption) => sizeOption.id === value);
    setTemplateData((prevTemplateData) => ({
      ...prevTemplateData,
      size_id: value,
      H: selectedSize.H,
      W: selectedSize.W,
      name: selectedSize.name,
    }));
  };

  const handleFormChange = (changedValues) => {
    const updatedTemplateData = { ...templateData, ...changedValues };
    setTemplateData(updatedTemplateData);
    form.setFieldsValue(updatedTemplateData);
    setTemplateForm(updatedTemplateData);
  };


    const fetchSizesAndDetails = async (selectedType) => {
        try {
            let productID = 1;
            switch (selectedType) {
                case 'Fold Over': productID = 3; break;
                case 'Flat Cards': productID = 1; break;
                case 'Photo Cards': productID = 4; break;
            }
          let defSizes;
          const  sizesRes = await clientAPI.getProductObjectSizes(2, 'SIZE');
          const studio_mode = localStorage.getItem('studio_mode');
          if(studio_mode !== 'true'){
            defSizes = await clientAPI.getStudioCardDefailtByType(productID);
          }else{
            defSizes = await clientAPI.getCardLabDefaultDetails(productID);
          }
            if (defSizes.data && defSizes.data.item_ids) {
                const paperTypeOptions = defSizes.data.details.map(detail => ({
                    id: detail.id,
                    item_id: detail.item_id,
                    item_type: detail.item_type,
                    price: detail.item_price,
                    from: detail.item_from,
                    to: detail.item_to,
                }));
              setPaperTypeOptions(paperTypeOptions)
                const uniqueItemTypes = Array.from(new Set(paperTypeOptions.map(type => type.item_type)))
                    .map(item_type => {
                        return paperTypeOptions.find(type => type.item_type === item_type);
                    });

                setPaperTypes(uniqueItemTypes);

                if (defSizes.data && defSizes.data.item_ids && Array.isArray(defSizes.data.item_ids) && defSizes.data.item_ids.length > 0) {
                    const sizesBody = sizesRes.data[selectedType];
                    const sizesMap = sizesBody.reduce((acc, size) => {
                        acc[size.ID] = size;
                        return acc;
                    }, {});

                    const combinedData = defSizes.data.item_ids.reduce((acc, item) => {
                        const size = sizesMap[item];
                        if (size) {
                            acc.push({
                                id: item,
                                name: `${size.width} x ${size.height}`,
                                W: `${size.width}`,
                                H:`${size.height}`
                            });
                        }
                        return acc;
                    }, []);

                    setSizesCards(combinedData);
                    return defSizes.data;
                } else {
                    console.error('defSizes.data.item_ids is not an array or is empty');
                }
            }
        } catch (error) {
            console.error('Error fetching sizes and details', error);
        }
    };

    const handlePaperTypeChange = (selectedPaperTypeId) => {
        const selectedPaperType = paperTypes.find(type => type.id === selectedPaperTypeId);
        if (selectedPaperType) {
            setTemplateData(prevTemplateData => ({
                ...prevTemplateData,
                paper_type_id: selectedPaperType.id,
                paper_type: selectedPaperType.item_type,
            }));
        }
    };


  useEffect(() => {
        let selectorChange;
      if(cardType === 'Fold Over' && currentSide === 'back'){
          selectorChange = 'inside';
      }else if(cardType === 'Fold Over' && currentSide === 'front'){
          selectorChange = 'front-back';
        }else{
          selectorChange = currentSide;
      }
        const formValueBasedOnSide = selectorChange;
        form.setFieldsValue({ template_side: formValueBasedOnSide });
        setTemplateData(prevTemplateData => ({
            ...prevTemplateData,
            template_side:formValueBasedOnSide,
        }));
    }, [currentSide, form]);
    useEffect(() => {
        if (templateData.product_type) {
            fetchSizesAndDetails(templateData.product_type);
        }
    }, [templateData.product_type]);
    useEffect(() => {

        if (templateData.product_type === "Prints" || templateData.product_type === "Units" || ObjectValueChekcer(templateData)) {
            getTemplateList(templateData);
        }
      setTemplateForm(templateData)
    }, [templateData]);

  useEffect(() => {
    const defaultPaperType = paperTypes.find(paper => paper.item_type === 'standard');
    const defaultPaperTypeId = defaultPaperType ? defaultPaperType.id : '';
    if (sizesCards.length > 0 && paperTypes.length > 0) {
      setTemplateData(prevTemplateData => ({
        ...prevTemplateData,
        size_id: sizesCards[0].id,
        name: sizesCards[0].name,
        H: sizesCards[0]?.H,
        W: sizesCards[0]?.W,
        paper_type: defaultPaperType?.item_type || 'standard',
        paper_type_id: defaultPaperTypeId,
      }));
    }
  }, [sizesCards, paperTypes]);


  function filterAndCreateObject(templateData, paperTypeOptions) {
    const filteredOptions = paperTypeOptions.filter(option => {
      return option.item_type === templateData.paper_type && option.item_id === templateData.size_id;
    });

    const resultObject = {
      options: filteredOptions.map((option, index) => {
        return {
          id: index,
          from: option.from,
          to: option.to,
          price: parseFloat(option.price).toFixed(2)
        };
      })
    };
    return resultObject;
  }

  useEffect(() =>  {
    const filteredObject = filterAndCreateObject(templateData, paperTypeOptions);
    setFilteredObject(filteredObject);
  }, [templateData.paper_type, templateData.size_id, paperTypeOptions]);



  useEffect(() => {
    form.setFieldsValue({
      size_id: templateData.size_id,
      product_type: templateData.product_type,
      template_pos: templateData.template_pos,
      template_side: templateData.template_side,
      paper_type: templateData.paper_type,
      paper_type_id: templateData.paper_type_id,
    });
  }, [templateData.size_id, templateData.product_type, templateData.paper_type_id, templateData.template_pos, templateData.template_side, templateData.paper_type]);

  return (
    <>
      <Form
        className={style.selected}
        form={form}
        size={"middle"}
        name="validate_Template"
        layout="vertical"
        justify={' space-between'}
        onValuesChange={handleFormChange}
      >
            <Row justify='space-between'>
              <Col>
                <b>Price Card: </b>{filteredObject.options.map((option) => (
                  <span key={option.id}>
                    {quantity >= option.from && quantity <= option.to ? (
                      <span>
                       From {option.from} to {option.to} - $ {option.price}
                      </span>
                    ) : null}
                  </span>
                ))}
                <Tooltip
                  placement="bottom"
                  title={
                    <ul >
                      {filteredObject.options.map((option) => (
                          <li key={option.id}>From {option.from} to {option.to} - $ {option.price}</li>
                      ))}
                    </ul>
                  }
                >
                  <span  className='byButI'><InfoCircleFilled  style={{color: '#e17322'}}/></span>
                </Tooltip>
              </Col>

              {currentSide !=='preview' &&
               <><Col span={24}>
                 <Row justify='space-between'>
                   <Col xl={11} lg={7} >
                     <Form.Item
                       label="Select Product Type"
                       name="product_type"
                     >
                       <Select
                         onChange={handleChange}
                         value={form.getFieldValue("product_type")}
                       >
                         <Option value="Flat Cards">Flat Cards</Option>
                         <Option value="Fold Over">Fold Over</Option>
                         <Option value="Photo Cards">Photo Cards</Option>
                         <Option value="Units">Units</Option>
                         <Option value="Print">Print</Option>

                       </Select>
                     </Form.Item>
                   </Col>
                   <Col xl={11} lg={7} >
                     <Form.Item
                       label="Select Template Size"
                       name="size_id"
                     >
                       <Select
                         onChange={handleChangeSize}
                         value={form.getFieldValue("size_id")}
                       >
                         {sizesCards.map((sizeOption) => (
                           <Option
                             key={sizeOption.id}
                             value={sizeOption.id}
                             defaultValue={form.getFieldValue("size_id")}
                           >
                             {sizeOption.name}
                           </Option>
                         ))}
                       </Select>
                     </Form.Item>
                   </Col>
                   <Col xl={24} lg={7}>
                     <Form.Item name="paper_type_id" label="Select Paper Type">
                       <Select onChange={handlePaperTypeChange} value={form.getFieldValue("paper_type_id")}>
                         {paperTypes.map(type => (
                           <Option key={type.id} value={type.id}
                                   defaultValue={form.getFieldValue("paper_type_id")}>{type.item_type}</Option>
                         ))}
                       </Select>
                     </Form.Item>
                   </Col>
                 </Row>
               </Col>
                 {cardType !== 'Units' && cardType !== 'Print' &&(
                   <Col xl={24} lg={11} className='mt-2'>
                     <Form.Item
                       name="template_side"
                       label="Template Position"
                       rules={[{required: true}]}
                     >
                       <Radio.Group
                         style={{display: 'flex', justifyContent: 'start'}}
                         onChange={handleTemplateSide}
                         value={form.getFieldValue("template_side")}
                       >
                         {cardType === 'Fold Over' && (
                           <>
                             <Radio value="front-back">Front - Back</Radio>
                             <Radio value="inside">Inside</Radio>

                           </>
                         )}
                         {cardType === 'Flat Cards' && (
                           <>
                             <Radio value="front">Front</Radio>
                             <Radio value="back">Back</Radio>
                           </>
                         )}
                         {cardType === 'Photo Cards' && (
                           <>
                             <Radio value="front">Front</Radio>
                           </>
                         )}
                         {cardType === 'Units' && (
                           <>
                             <Radio value="front">Front</Radio>
                           </>
                         )}
                       </Radio.Group>
                     </Form.Item>

                   </Col>
                 )}

                 <Col xl={24} lg={11} className='mt-2'>
                   <Form.Item
                     value={form.getFieldValue("template_pos")}
                     name="template_pos"
                     label="Template Position H / V"
                   >
                     <Radio.Group
                       onChange={handleChangePos}
                       style={{display: 'flex', justifyContent: 'start'}}
                     >
                       <>
                         <Radio value="Horizontal">Horizontal</Radio>
                         <Radio value="Vertical">Vertical</Radio>
                       </>
                     </Radio.Group>
                   </Form.Item>
                 </Col>
               </>
              }
            </Row>
      </Form>

      {templateList && templateList.length >= 0 && (
        <InfiniteScroll
          className="scrollableDivDis"
        dataLength={templateList.length || 0}
          scrollableTarget="scrollablePanel"
        >
          <List
            grid={gridConfig}
            dataSource={templateList}
            renderItem={({ id, GUID, NAME, VIEW_IMAGE_PATH }) => (

              <div
                className="selectedImage_div"
                key={id}
                onClick={() => {
                  changeJsonData(id, GUID, NAME, VIEW_IMAGE_PATH);
                }}
              >
                <img
                  src={VIEW_IMAGE_PATH}
                  className="selectedImage_img"
                  alt={NAME}
                />
                <span>{NAME}</span>
              </div>
            )}
          />
        </InfiniteScroll>
      )}
    </>
  );
};
export default TemplateVerticalView;