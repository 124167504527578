import React, {useEffect, useState} from "react";
import {Button, Form, Input, Select, Spin} from "antd";
import { useNavigate } from "react-router-dom";
import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
const StudioCreditAdd = ({ selectedCreditId,setModalCredit }) => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;
    const [credit,setCredit]= useState('')
    const [studios,setStudios]= useState([''])
    const [selectedClient, setSelectedClient] = useState('');
    const user_id = localStorage.getItem("user_id");

    const [getStudios, isStudiosLoading, clientsError] = useFetching(async (user_id) => {
        const { data: res } = await clientAPI.getStudioListByLabIdToken(user_id);
        if (res) {

            setStudios(res.data)
        }
    });

    useEffect(() => {
        getStudios(user_id)
    }, [user_id]);

    const [storeUserCredit, isCreditLoading, creditError] = useFetching(async (data) => {

        const res = await clientAPI.storeCredit(data);
        if (res) {

        }

    });

    const onFinish = (obj) => {
        const creditDataItem = {
            credit_amount_applied:  0,
            credit_balance:  0,
            ...obj
        };
        storeUserCredit(creditDataItem);

    };

    const hendelSubmit = async () => {
        try {
            if (selectedCreditId) {
                await form.validateFields();
                setModalCredit(false)
            } else {
                await form.validateFields();
                navigate("/labs/studio_credit_list");
                window.location.reload();
            }
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const onChangeCredit = (e) => {
        const value = e.target.value;
        setCredit(value);
    };

    const onBlurCredit = () => {
        const formattedValue = parseFloat(credit).toFixed(2);
        form.setFieldsValue({
            credit_total_amount: formattedValue || '0.00',
        });
    };

    useEffect(() => {
        try {
            if(selectedCreditId){
                const selectedCreditCard = selectedCreditId[0];

                let paymentTypeText = '';
                switch (2) {

                    case 1:
                        paymentTypeText = 'Credit';
                        break;
                    case 2:
                        paymentTypeText = 'Cash';
                        break;
                    case 3:
                        paymentTypeText = 'Check';
                        break;
                    case 4:
                        paymentTypeText = 'Adjustment';
                        break;
                    default:
                        paymentTypeText = 'Unknown';
                }

                const selectUserById = studios.filter((item) => item.studio_id === selectedCreditCard.user_id)[0];
                console.log(selectUserById,'selectUserById')
                if (selectedCreditCard && selectUserById) {
                    form.setFieldsValue({
                        credit_total_amount: (selectedCreditCard.credit_total_amount / 100).toFixed(2) || '',
                        selected_user: `(${selectUserById.studio_id}) ${selectUserById.studio_name}` || '',
                        payment_type: paymentTypeText || '',
                        notes: selectedCreditCard.notes || '',
                    });
                }
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);

        }
    }, [form, selectedCreditId]);

    const onChangeClient = (value, option) => {
        setSelectedClient(option.label);
    };

    return (
      <>
          <div className={'text-center mt-4'}>
              {selectedCreditId?(<h3 className={'text-uppercase mb-4 header-title'}>Edit Credit for Studio </h3>):(
                <h3 className={'text-uppercase mb-4 header-title'}>Add Credit for Studio </h3>
              )}
          </div>
          <div className={"mt-4 album-notifications-select"}>
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                className="form"
              >
                  <Form.Item
                    className="mt-4"
                    name="credit_total_amount"
                    label="Credit"
                    rules={[{ required: true }]}
                  >
                      <Input
                        suffix="$"
                        type="text"
                        value={credit}
                        onChange={onChangeCredit}
                        onClick={(e) => (e.target.value = '')}
                        onBlur={onBlurCredit}
                        placeholder="0.00"
                      />
                  </Form.Item>

                  <Form.Item
                    className="mt-4"
                    name="selected_user"
                    label="Studios"
                    rules={[{ required: true }]}
                  >
                      {isStudiosLoading ? (
                        <Spin size="small" />
                      ) : (
                        <Select
                          showSearch
                          placeholder="Search to Client"
                          optionFilterProp="children"
                          onChange={onChangeClient}
                          value={selectedClient}
                          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                          filterSort={(optionB, optionA) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={isStudiosLoading ? [{ label: <Spin />, value: "loading" }] : studios.map((studio) => ({ value: studio.studio_id, label: `(${studio.studio_id}) ${studio.studio_name}` }))}
                        />
                      )}


                  </Form.Item>
                  <Form.Item
                    className="mt-4"
                    name="payment_type"
                    label="Type"
                    rules={[{ required: true }]}
                  >
                      <Select
                        placeholder="Choose starting type"
                        allowClear
                        onTouchStart={(e) => e.stopPropagation()}                      >
                          <Option value="1">Credit</Option>
                          <Option value="2">Cash</Option>
                          <Option value="3">Check</Option>
                          <Option value="4">Adjustment</Option>
                      </Select>
                  </Form.Item>

                  <Form.Item
                    className="mt-4"
                    name="notes"
                    label="Notes"
                  >
                      <TextArea rows={4} placeholder="Enter your notes" />
                  </Form.Item>
                  <div className='text-center mt-4'>
                      <Button
                        onClick={hendelSubmit}
                        type="primary"
                        htmlType="submit"
                        className={'ant-btn-set'}
                      >
                          {selectedCreditId ? "Update" : 'Save'}
                      </Button>
                  </div>

              </Form>
          </div>
      </>
    );
}

export default StudioCreditAdd;
