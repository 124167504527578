import React, { createContext, useState, useContext } from 'react';

// Create the context
const HelperTabContext = createContext();

// Provider component that wraps your app and makes the context value available to any child component
export const HelperTabProvider = ({ children }) => {
    const [helperTab, setHelperTab] = useState(false);

    const toggleHelperTab = () => setHelperTab(prev => !prev);

    const value = { helperTab, toggleHelperTab };

    return (
        <HelperTabContext.Provider value={value}>
            {children}
        </HelperTabContext.Provider>
    );
};

// Custom hook to use the context
export const useHelperTab = () => useContext(HelperTabContext);
