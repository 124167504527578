import React, { useState, useRef,useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, {Quill} from 'react-quill';
import './TextPolicy.css';
import { Button, Col, Form, Input, Row,notification } from 'antd';
import Container from 'react-bootstrap/Container';
import {usePolicyContext} from "../../../../context/PolicyContext";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";
import LoadingSpin from "../../../Utils/LoadingSpin";


const TextPolicy = () => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [editorHtml, setEditorHtml] = useState('');
  const { setPolicyData } = usePolicyContext();
  const [data, setData] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [createTerms, isPostsLoading, postError] = useFetching(async (updatedData) => {
    const { data: res } = await clientAPI.studioTerms(updatedData);
    if (res) {
    }
  });

  const [updateTerms, isUpdate, updateError] = useFetching(async ( updatedData) => {
    const { data: res } = await clientAPI.updateStudioTerms( updatedData);
    if (res) {
      console.log(res);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data: res } = await clientAPI.showStudioTerms();
      if (res) {
        if(res?.message){
          setInsertOrUpdate(false);
        }else{
          setInsertOrUpdate(true);
        setLoading(false);
        setData({
          id: res.id,
          name: res.name,
          content: res.content,
        });
        setEditorHtml(res.content);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: data.name,
    });
  }, [data, form]);

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        id:data?.id,
        name: values.name,
        content: editorHtml,
      };
      setPolicyData(updatedData);

      if (insertOrUpdate === true) {

        await updateTerms( updatedData);
      } else {
        const response = await createTerms(updatedData);
        if (response) {
          const newId = response.id;
          setData({
            ...data,
            ...updatedData,
            id: newId,
          });
        }
      }

      openNotificationWithIcon('success');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };


  const Quill = ReactQuill.Quill;
  const font = Quill.import("formats/font");
  font.whitelist = ['sans-serif',   'Gabriela','BernhardModern','BickhamScriptPro','NradleyHandeITC','ChopinScript','NuptialScript','SheerElegance',
  'PenyaeLight','PhyllisAttItalic','WendyMedium','Wendyshand' ];
  Quill.register(font, true);
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': font.whitelist }],
      [{ 'align': [] }],
      ['clean']

    ]
  };

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list','ordered', 'bullet', 'link', 'image', 'video', 'color', 'background','font',
     'align', 'size', 'indent', 'direction', 'script', 'code-block', 'clean','p','ol'
  ];

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: 'Successfully',
      description: 'Data added successfully.',
    });
  };

  if (loading) {
    // Show a loading indicator while the data is being fetched
    return (
      <LoadingSpin>
        <div>Loading...</div>
      </LoadingSpin>
    );
  }
  return (
    <div className="mt-4">
      <div className="text-center">
        <h3 className="text-uppercase mb-4 header-title">Terms and Conditions</h3>
      </div>


      <Form
          ref={formRef}
          form={form}
          name="ReactQuill"
          size="large"
          layout="vertical"
          initialValues={{ name: data?.name }} // setting initial value for name
      >
        <Row justify="space-between" className="mb-4">
          <Col span={24}>

            <Form.Item label="Name:" name="name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="rich-text-editor-container ql-snow ql-font">
        <ReactQuill
          theme="snow"
          value={editorHtml}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
          placeholder="Enter your Terms and Conditions..."
        />
      </div>
      <Container className="text-center">
        <Button htmlType="button" type="primary" className='contractBtn' onClick={handleFormSubmit} >
          Save
        </Button>
      </Container>
    </div>
  );
};

export default TextPolicy;