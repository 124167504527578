import React,{useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Space, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import './Price.css'
import clientAPI from "../../../../../api/api";
import {useFetching} from "../../../../../hoc/fetchingHook";
import {showDeleteConfirmPrice} from "../../../../Utils/isFunction";

const ListPriceStudio = () => {

    const navigate = useNavigate();
    const PREFIX = "https://studioseye.com/pricing/upload/";
    const [data, setData] = useState([]);

    const [getStudioList, isStudiosLoading, studioError] = useFetching(async () => {
        localStorage.setItem('product_type',1);
        const { data: res } = await clientAPI.getPrintPriceByUserId();
        if (res) {
            setData(res)
        }else{
            studioError()
        }
    });
    const [getPriceListDetailList, getPriceListDetailsLoading, getPriceListDetailError] = useFetching(async (price_list_id) => {
        localStorage.setItem('product_type',1);
        const { data: res } = await clientAPI.getPrintPriceDetails(price_list_id);
        if (res) {
            localStorage.setItem('parent_id',price_list_id);
            navigate("/studio/price_edit")
            window.location.reload()
            getPriceListDetailsLoading()
        }else {
            getPriceListDetailError()
        }
    });

    useEffect(() => {
        const current_user_id = localStorage.getItem('user_id');
        localStorage.setItem('current_user_id',current_user_id);
        getStudioList();
    }, []);

    const edit_price = (price_list_id) => {
        getPriceListDetailList(price_list_id)

    }
    const deleteListItem = (price_list_id) => {
        showDeleteConfirmPrice(price_list_id).then((result) => {
            if (result) {

                const updatedData = data.filter((item) => item.id !== price_list_id);
                setData(updatedData);
            } else {
                console.log("User clicked No");
            }
        })
    };
    const columns = [
        {
            key: 'count',
            title: '#',
            dataIndex: 'count',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Is default',
            dataIndex: 'default_checked',
            key: "isdefault",
        },
        {
            title: 'Edit',
            dataIndex: 'price_edit',
            key: 'price_edit',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined  className="my_ant_icons_table" onClick={()=> edit_price(record.id)}/>
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined  className="my_ant_icons_table" onClick={()=> deleteListItem(record.id)}/>
                </Space>
            ),
        },
    ];


    return(
        <div className="text-center mt-4">
            <h3 className="text-uppercase mb-4 header-title">List Print Price  Studio</h3>
            {data && <Table
            className="table-striped-rows studio-table-rows priceListTable"
            loading={isStudiosLoading}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data}
            key = {(record) => record.id}
            scroll={{x: 100}}
        />}
        </div>
    );
};

export default ListPriceStudio;