import React from "react";
import logo from '../../logo.png';
import triColorLogo from '../../Images/TRICOLOR CENTER.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import LoginForm from './LoginForm';
import isAuth from "../../hoc/IsAuth";
import DashboardContainer from "../Dashboard/DashboardContainer";
import './Login.css';
import LoadingSpin from "../Utils/LoadingSpin";
const Login = () => {
    const isUserAuthenticated = isAuth();
    if(!isUserAuthenticated){
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("user_id");
        const user_type = localStorage.getItem("user_type");
        if(token && user_id && user_type){
            return <>
                <LoadingSpin/>
            </>
        }else{
        return (
            <div className="login-root">
                <Row>
                    <div className="col-lg-4 col-md-5 d-lg-block d-md-block d-none left-sidebar">
                        <p className="login-moto text-uppercase">
                            professional <br/>Imaging <br/> Center
                        </p>
                        <div className="circle-div">
                            <div className="main-circle">
                                <div className="blue-circle">
                                    <img src={logo} alt="tricolor-icon-white"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none d-md-none d-block imaging-center-div">
                        <p className="text-uppercase imaging-center-sm">Professional Imaging Center</p>
                        <div className="circle-div-sm">
                            <div className="main-circle-sm">
                                <div className="blue-circle-sm">
                                    <img src={logo} alt="tricolor-icon-white"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12  tricolor-login-container">
                        <Container className="login  text-center mt-5">
                            <div className="m-auto tricolor-logo">
                                <img src={triColorLogo} className="w-100 mt-5"  alt="tricolor-icon"/>
                            </div>
                        </Container>
                        <LoginForm />
                        <div className="copyright text-center">
                            <span>Copyright <span className="copy">&copy;</span>  {new Date().getFullYear()}  Tricolorpic.com. All rights reserved. </span>
                        </div>
                    </div>
                </Row>
            </div>
        );
        }
    }else{
        return (<DashboardContainer/>);
    }
};

export default Login;