import React, {useEffect} from 'react';
import { Col, Form,  Row, Space} from 'antd';
import '../ShoppingCart.css';
import styles from "../../Studios/ClientSidebar/Contract/ContractAdd/Contract.module.css";
import {EditOutlined} from "@ant-design/icons";
import Order from "./Order";


const OrderDetails = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const handleEdit = () => {
    if (props.onEdit) {
      props.onEdit();
    }
  };

  React.useImperativeHandle(ref, () => ({
    validateAndProceed: () => {
      return new Promise((resolve, reject) => {
        form
          .validateFields()
          .then((values) => {
            props.onNext && props.onNext(values);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  }));

  const handleFormValuesSave = (changedValues, allValues) => {
    props.setShippingAddress && props.setShippingAddress(allValues);
    props.setCheckout && props.setCheckout(allValues);
  };

  useEffect(() => {
    form.setFieldsValue(props.shippingAddress || {});
  }, [props.shippingAddress]);
    return (
      <>
        <div className={'text-center mt-4 mt-5'}>
        <Row className='orderDiv'>
          <Col lg={15} md={24}>
            <hr/>
            <Order props={props} />
          </Col>
          <Col lg={9} md={24}>
            <hr/>
            <div className={'text-center'}>
              <h3 className={'text-uppercase mb-4 header-title'}>Order Details</h3>
            </div>

                {props.checkout &&
               <>
                        <Row  className='orderShop'>
                      <Col lg={24} md={10} sm={24} className='mt-4 w-100'>
                        <Space direction="vertical" className='textSpace'>
                        <h6>Shipping address: <EditOutlined className="my_ant_icons_table" onClick={handleEdit}/></h6>
                        <Col><span>{props.shippingAddress.firstName} {props.shippingAddress.firstName}</span></Col>
                        <Col> <span>{props.shippingAddress.address1}, {props.shippingAddress.appNumber}</span></Col>
                        <Col> <span> {props.shippingAddress.city}, {props.shippingAddress.state}</span></Col>
                        <Col> <span>{props.shippingAddress.zipCode}, {props.shippingAddress.county}</span></Col>
                        <Col> <span> Phone: {props.shippingAddress.phone}</span></Col>
                        <Col>
                          Email:{props.shippingAddress.email && props.shippingAddress.email.map((email, emailIndex) => (
                          <span key={emailIndex}> {email},</span>
                        ))}
                        </Col>
                        </Space>
                      <hr className='orderShopHr'/>
                      </Col>
                      <Col lg={24} md={14} sm={24} className='mt-4 w-100'>
                        <Space direction="vertical" className='textSpace'>
                        <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Sub Total: </strong>
                          <p className={styles.itemP}>$ {props.totalPrice}</p>
                        </div>
                        <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Tax 8.625%: </strong>
                          <p className={styles.itemP}>$ {props.checkout.tax}</p>
                        </div>
                        <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Shipping and Handling: </strong>
                          <p className={styles.itemP}>$ {props.checkout.shippingHandling}</p>
                        </div>
                        <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Total: </strong>
                          <p className={styles.itemP}>$ {props.checkout.total}</p>
                        </div>
                        < hr />
                        {props.checkout.creditUsedApply> 0 && <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Applied credit: </strong>
                          <p className={styles.itemP}>$ {props.checkout.creditUsedApply}</p>
                        </div>
                        }
                        {props.checkout.couponValue> 0 && <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Coupon pay: </strong>
                          <p className={styles.itemP}>$ {props.checkout.couponValue}</p>
                        </div>
                        }
                        <div className={styles.pdfPackage}>
                          <strong className={styles.itemStrong}>Balance: </strong>
                          <p className={styles.itemP}>$ {props.balance}</p>
                        </div>
                        </Space>
                      </Col>
                     </Row>
                </>
                }
          </Col>
        </Row>
        </div>
      </>
    )
  })


export default OrderDetails;