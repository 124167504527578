import React, { createContext, useContext, useState } from 'react';

const PriceContext = createContext();

const PriceContextProvider = ({ children }) => {
  const [inputNumberValue, setInputNumberValue] = useState(0);

  return (
      <PriceContext.Provider value={{ inputNumberValue, setInputNumberValue }}>
        {children}
      </PriceContext.Provider>
  );
};

const usePriceContext = () => {
  const context = useContext(PriceContext);
  if (!context) {
    throw new Error('usePriceContext must be used within a PriceContextProvider');
  }
  // Provide a default value (e.g., 0) in case inputNumberValue is undefined
  const { inputNumberValue = 0, setInputNumberValue } = context;
  return { inputNumberValue, setInputNumberValue };
};

export { PriceContextProvider, usePriceContext };
