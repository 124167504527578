import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Form, List, Select, Tooltip} from 'antd';
import { useFetching } from '../../../../hoc/fetchingHook';
import clientAPI from '../../../../api/api';
import styles from "../Template.module.css";

const SelectTemplate = ({ onTemplateSelect,
                          selectedCategory,
                          setSelectedCategory,
                          isTemplate,
                          setTemplate,
                          selectedId,
                          setSelectedId,
                        }) => {
  const PREFIX = 'https://quick.studioseye.com/';
  const [data, setData] = useState([]);


  const current_user_id = localStorage.getItem('user_id');
  localStorage.setItem('current_user_id', current_user_id);

  const [getBgList, BgLoading, BgError] = useFetching(async (selectedCategory) => {
    const { data: res } = await clientAPI.getTemplatesListByUserId();
    if (res) {
      console.log(res,'res')
      if (selectedCategory) {
        const body = res[selectedCategory];
        setData([...body]);
      }
    }
  });


  const loadMoreData = async () => {
    setData(5,5)
  };

  const studio_id = localStorage.getItem('studio_id');

  if (studio_id && studio_id.trim() !== '') {
    localStorage.setItem('current_user_id', studio_id);
  }

  useEffect(() => {
    getBgList(selectedCategory);
  }, []);

  const changeTemplate = (PARENT_GUID, SIZE_NAME, IMAGE_PATH) => {
    const template = {
      PARENT_GUID,
      SIZE_NAME,
      IMAGE_PATH
    };
    const templateExists = isTemplate.some(item => item.PARENT_GUID === PARENT_GUID);
    if (templateExists) {
      // Delete the template from the array
      const updatedTemplates = isTemplate.filter(item => item.PARENT_GUID !== PARENT_GUID);
      setTemplate(updatedTemplates);
    } else {
      // Add the template to the array
      setTemplate([...isTemplate, template]);
    }
    const selectedExists = isTemplate.some(item => item.PARENT_GUID === PARENT_GUID);
    if (selectedExists) {
      // If GUID exists in selectedId, remove it
      setSelectedId(prevSelectedId => prevSelectedId.filter(id => id !== PARENT_GUID));
    } else {
      // If GUID doesn't exist in selectedId, add it
      setSelectedId(prevSelectedId => [...prevSelectedId, PARENT_GUID]);
    }
  };
  return (
    <div>
      <Form.Item  label="Category">
        <Select
          value={selectedCategory}
          onChange={(value) => {
            setSelectedCategory(value);
            getBgList(value);
          }}
        >

          <Select.Option value="Print">Print</Select.Option>
          <Select.Option value="Flat cards">Flat cards</Select.Option>
          <Select.Option value="Fold over">Fold over</Select.Option>
          <Select.Option value="Photo cards">Photo cards</Select.Option>
          <Select.Option value="Units">Units</Select.Option>
          <Select.Option value="School\Dance\Events">School\Dance\Events</Select.Option>

        </Select>
      </Form.Item>

      <Form.Item
        label="Template"
        name="template"
      >
        <InfiniteScroll
          dataLength={data.length}
          next={() => loadMoreData()}
          scrollableTarget="scrollablePanel"
          height={450}
        >
          <List
            grid={{
              gutter: 1,
              column: 3,
            }}

            dataSource={data}
            renderItem={({ GUID, SIZE_NAME,NAME, IMAGE_PATH }) => (
              <div
                key={GUID}
                className={`${selectedId.includes(GUID) ? styles.selectedImage_divHover : styles.selectedImage_div}`}
                onClick={() => changeTemplate(GUID, SIZE_NAME,NAME, IMAGE_PATH)}
              >
                <div className={styles.imageContext} key={GUID}>
                 <Tooltip title={NAME}>
                   <img
                     src={PREFIX + IMAGE_PATH}
                     className={styles.selectedImage_img}
                     alt="NAME"
                   />
                 </Tooltip>
                </div>
                <div className={styles.selectedImageSizeName}>{NAME}</div>
              </div>
            )}
          />
        </InfiniteScroll>
      </Form.Item>
    </div>
  );
};

export default SelectTemplate;
