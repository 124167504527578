import * as React from 'react';
import Container from "react-bootstrap/Container";
import MoreInfo from '../../../Utils/MoreInfo'
import clientAPI from "../../../../api/api";
import {useNavigate } from "react-router-dom";
import { useFetching } from "../../../../hoc/fetchingHook";
import {Button, Col, Form, Input, Row, Select, Tooltip, Typography, message, Tour} from 'antd';
import { SolutionOutlined, DownOutlined } from "@ant-design/icons";
import { handleTagChange, validateMessages, handleGenerateUnamePass, generateRandomUsername } from '../../../Utils/isFunction';
import {useHelperTab} from "../../../../context/HelperTabContext";
import {useRef, useState,useEffect} from "react";



const AddPhotographer = ({helperShow,setHelperShow}) => {
  const isWindowWidthGreaterThan577 = window.innerWidth > 577;
  const [form] = Form.useForm();
  const [isTypeSchool, setIsTypeSchool] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const navigate = useNavigate();
  const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };
  const randomUsername = generateRandomUsername(8);
  const handleClick = () => { handleGenerateUnamePass(form) }

  const [createPh, isPhLoading, phError] = useFetching(async (values) => {
    const { data: res } = await clientAPI.createPh(values);
    if (res) {
      const currentPath = '/studio/list_ph';
      navigate(currentPath);
      window.location.reload();
    }
  });

    const { helperTab } = useHelperTab();

    const [open, setOpen] = useState(helperTab);
    useEffect(() => {
      if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
      if (helperShow) { setOpen(true);  }

    }, [helperShow]);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);


    const steps = [

        {
            title: 'Email Addresses',
            description: 'This section allows you to add multiple email addresses. You can enter one or more email addresses as needed. Each email address should be entered separately to ensure accurate communication and record-keeping',
            target: () => ref2.current,
        },
        {
            title: 'Generate Username & Password',
            description: 'Click this button to automatically generate a unique username and password. This feature is designed to provide you with secure and randomized credentials, ensuring enhanced security for your account',
            target: () => ref3.current,
        },
        {
            title: 'More Information',
            description: 'Use the "More Details" option to provide comprehensive information about your client. This section is designed to capture detailed contact information, ensuring that you have all the necessary data for effective communication and service delivery',
            target: () => ref4.current,
        },
        {
            title: 'Create Photographer',
            description: 'The "Create" button is your final step in submitting information or finalizing the process you are working on. When you click this button, it triggers the system to save and process all the data you\'ve entered',
            target: () => ref5.current,
        },
    ];

  const onFinish = (values) => {
    createPh(values);
 };

  return (
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Add Photographer</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        <Form
          form={form}
          name="register"
          size={"large"}
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 600, 
            margin: "auto"
          }}
          validateMessages={validateMessages}
          scrollToFirstError
          initialValues={{ state: 'New York' }}
        >
            <div ref={ref2}>
          <Form.Item
              name="email"
              label="Enter tags (email addresses)"
              rules={[
                  {
                      required: true,
                      message: 'Please enter at least one email address.',
                      type: 'array',
                  },
              ]}
          >
              <Select
                  mode="tags"
                  onChange={handleTagChangee}
                  style={{ width: '100%' }}
                  placeholder="Enter your (email addresses)"
              />
          </Form.Item>
            </div>
          <Row justify='space-between'>
            <Col sm={21} xs={20}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col sm={2} xs={3}  ref={ref2}>
              <Tooltip title="Generate Username and Password">
                <Typography.Link onClick={handleClick}>
                  <SolutionOutlined className={"generate-uname-pass"}/>
                </Typography.Link>
              </Tooltip>

            </Col>
          </Row>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password value={randomUsername} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password  value={randomUsername}/>
          </Form.Item>
          {isTypeSchool && (
            <Form.Item
              name="school name"
              label="School name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
            <Input/>
            </Form.Item>
          )}
          {!isTypeSchool && (
            <Row justify="space-between">
              <Col lg={11} md={11} span={24}>
                <Form.Item
                  name="firstname"
                  label="Firstname"
                  rules={[{ required: true,},]}
                >
                <Input/>
                </Form.Item>
              </Col>
              <Col lg={11} md={11} span={24}>
                <Form.Item
                  name="lastname"
                  label="Lastname"
                  rules={[{ required: true,},]}
                >
                <Input/>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Col ref={ref4} className={"collapse-client"} lg={24} md={24} span={24}>
            <a onClick={() => { setExpand(!expand);}}>
              <DownOutlined rotate={expand ? 180 : 0} /> More Details
            </a>
          </Col>
              
          {expand && (  <MoreInfo /> )}

          <Container ref={ref5} className={'text-center'}>
            <Button type="primary" htmlType="submit"  className='send-email-btn' >
              Create
            </Button>
          </Container>    
        </Form>
        {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
      </div>
    </>
  );
};
export default AddPhotographer;