import React, {useContext, useCallback, useState} from 'react';
import DropZoneContext from '../../../context/DropZoneContext';
import './SpreadStyle.css';
import {useDrop} from "react-dnd";
const SpreadStyle = ({ spreadType }) => {
    // Accessing the context to use its state and updater functions
    const { imageDropZones, setImageDropZones } = useContext(DropZoneContext);
    const [droppedImage, setDroppedImage] = useState(null);
    const [{isOver, canDrop}, drop] = useDrop(() => ({
        accept: 'image',
        drop: (item) => {
          setDroppedImage(item);

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const divStyle = {
        width: "100%",
        height: "100%",
        backgroundImage: droppedImage ? `url(${droppedImage.img_path})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };



    return (
        <>
        <div className="spread-container">
            <div className="spread-left">
                <div className="lines line-cord-plus"></div>
                <div className="lines line-cord-minus"></div>
                <div className="page-shadow-scale-left page-shadow-spread"></div>
            </div>
            <div className="spread-spine">
                <div className="spread-spine-transform spread-spine-transform-bg">
                </div>
            </div>
            <div className="spread-right">
                <div className="lines rline-cord-plus"></div>
                <div className="lines rline-cord-minus"></div>
                <div className="page-shadow-scale-right page-shadow-spread"></div>
            </div>
        </div>
            <div className="spread-container">
                <div className="pos-center full-spread-gray"></div>
                <div className="pos-center mid-line"></div>
            </div>

            {spreadType !== 1 && (
                <div className="spread-container">
                    <div ref={drop} className="pos-center full-spread-white">
                        <div style={divStyle}></div>
                    </div>
                    <div className="pos-center mid-line"></div>
                </div>
            )}


        </>

    );
}

export default SpreadStyle;