import React ,{useEffect, useState,useRef} from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import {Button, Col, Row, Space, Table, Tour} from "antd";
import html2canvas from "html2canvas";
import styles from './PDF.module.css';
import  clientsAPI  from '../../../../api/api';
import moment from "moment";
import {useFetching} from "../../../../hoc/fetchingHook";
import LoadingSpin from "../../../Utils/LoadingSpin";



const PDFGenerator = ( {pdfId,contractList}) => {

  const PREFIX = "https://studioseye.com/pricing/upload/";
  const [contracts, setContracts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [termsConditions, setTermsConditions] = useState([]);
  const [paymentPackages , setPaymentPackages] = useState({});


  const client_id =localStorage.getItem('client_id')
  const [studioInfo, setStudioInfo] = useState();
  const [getStudioInfo, isGetStudioInfo, studioIndoError] = useFetching(async (id) => {
    const res  = await clientsAPI.getClientInfoByID(id);
    if (res) {

      setStudioInfo(res.data);
    }
  });

  const columnsPag = [
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      key: 'package_name',
    },
    {
      title: 'Package Items',
      dataIndex: 'title_name',
      key: 'title_name',
      align:'center',
      render: (_, record) => (
        <span>
     {record.size_data && record.size_data.map(item => (
       <div style={{ borderBottom: '1px solid #6d6868' }} key={item.id}>{item.title_name}  {item.name}</div>
     ))}

      </span>
      ),
    },
    {
      title:'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align:'center',
      render: (_, record) => (
        <span>
        {record.size_data && record.size_data.map(item => (
          <div style = {{borderBottom:'1px solid #6d6868'}} key={item.id}>{item.quantity}</div>
        ))}
      </span>
      ),
    },

  ];
  const dateFormat = "MM-DD-YYYY";
  const timeFormat ="h:mm a"
  const returnCurrentDate = (current_date) => {
    const date = moment(current_date);
    return date.format(dateFormat);
  }
  const returnCurrentTime = (current_date) => {
    const date = moment(current_date);
    return date.format(timeFormat);
  }
  function ordinalSuffix(number) {
    if (number === 1) {
      return 'Deposit         ';
    }

    const j = number % 10;
    const k = number % 100;

    if (j === 1 && k !== 11) {
      return `${number}st Payment`;
    }
    if (j === 2 && k !== 12) {
      return `${number}nd Payment`;
    }
    if (j === 3 && k !== 13) {
      return `${number}rd Payment`;
    }
    return `${number}th Payment`;
  }


  useEffect(() => {
    if (pdfId !== undefined) {
      const dataContractList = contractList;
      const selectedContract = dataContractList.find(item => item.contract_data.id === pdfId);


      if (selectedContract) {
        const contractAll = selectedContract.contract_data || {};
        const contractPackages = selectedContract.contract_packages || [];
        const paymentPackages = selectedContract.paymentPackagesData || {};
        setContracts(contractAll);
        setPackages(contractPackages)
        setPaymentPackages(paymentPackages)

        const fetchData = async () => {
          const { data: res } = await clientsAPI.showStudioTerms();
          if (res) {
            setTermsConditions({
              id: res.id,
              name: res.name,
              content: res.content,
            });
          }
        };
        fetchData()

      } else {
        console.log('No contract found for pdfId:', pdfId);
      }
    }
    else {
      const dataFromLocalStorage = localStorage.getItem('contract_data');
      const contractDataALL = JSON.parse(dataFromLocalStorage);
      const contractPackages = JSON.parse(localStorage.getItem('contract_packages'));

      setPackages(contractPackages)
      setContracts(contractDataALL)
      const fetchData = async () => {
        const { data: res } = await clientsAPI.showStudioTerms();
        if (res) {
          setTermsConditions({
            id: res.id,
            name: res.name,
            content: res.content,
          });
        }

      };
      fetchData()
      getStudioInfo(client_id)
      const paymentPackages = JSON.parse(localStorage.getItem('paymentPackagesData'));
      if (paymentPackages) {


        const formattedDeposits = paymentPackages.payments?.map(deposit => ({
          ...deposit,
          amount: `$${parseFloat(deposit.amount).toFixed(2)}`,
          paymentData: returnCurrentDate(deposit.date),
        }));
        setPaymentPackages({
          ...paymentPackages,
          payments: formattedDeposits,
        });
      }

    }

  }, [pdfId,client_id]);



  const handleExport = () => {
    const invoiceContentElement = document.getElementById('invoice_container');
    const termsContentElement = document.getElementById('terms_container');
    const actionBtnElement = document.getElementById('actionBtn');

    if (actionBtnElement) {
      actionBtnElement.style.display = 'none';
    }

    html2canvas(invoiceContentElement, {backgroundColor: '#ffffff' }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pageWidth = 210;
      const pageHeight = 310;
      const height = (canvas.height * pageWidth) / canvas.width;
      let pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, pageWidth, height);
      const studioLogoElement = document.getElementById('studio_logo');
      if (studioLogoElement) {
        const logoData = studioLogoElement.src;
        pdf.addImage(logoData, 'PNG', 26, 10, 27, 15);
      }

      html2canvas(termsContentElement, {backgroundColor: '#ffffff' }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const height = (canvas.height * pageWidth) / canvas.width;
        position = 0 ;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, pageWidth, height);

        pdf.save('Contract.pdf');
      });
    });
  };
  useEffect(() => {
    console.log("Packages:", packages);
  }, [packages]);
  return (
    <>
      <div className={styles.spacePdf}>
        <div id="invoice_container" className={'text-center'}>
          <div className={styles.pdfContent}>
            <h2 className='mb-10'>Contract information</h2>
            {contracts ?  (
              <div>
                <Row justify="space-between"  className={styles.Row}>
                  <Col span={8}   >
                    {studioInfo ? (
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={12} className={styles.infoStudio}>
                            <img id="studio_logo" className={styles.infoStudioImg} src={PREFIX + studioInfo.studio_logo} alt="Studio Logo" />
                          </Col>
                          <Col span={12}>
                            <p className={styles.infoStudioText}> {studioInfo.studio_name}</p>
                            <p className={styles.infoStudioText}> {studioInfo.email.map((email, emailIndex) => (
                              <span key={emailIndex}> {email}<br /></span>
                            ))}</p>
                            <p className={styles.infoStudioText}> {studioInfo.phone}</p>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <LoadingSpin />
                    )}
                  </Col>
                  <Col span={7}>
                    <Space direction="vertical" className={styles.textSpace}>
                      {contracts && contracts.contractDate ? (<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Contract Date:</strong>
                          <p className={styles.itemP}>{returnCurrentDate(contracts.contractDate)}</p>
                      </div>   ) : null}
                      {contracts.type?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Event Type:</strong>
                        <p className={styles.itemP}>{contracts.type}</p>
                      </div>):null}
                      {contracts.eventDate?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Event Date:</strong>
                        { contracts.eventDate?(<p className={styles.itemP}> {returnCurrentDate(contracts.eventDate)}</p>):null}
                      </div>):null}
                      { contracts.eventTime?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Event Time:</strong>
                         <p className={styles.itemP}>{returnCurrentTime(contracts.eventTime)}</p>
                      </div>):null}
                    </Space>
                  </Col>

                  <Col span={7}>
                    <Space direction="vertical" className={styles.textSpace}>
                      {contracts.ceremony_location?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Ceremony Location:</strong>
                        <p className={styles.itemP}>{contracts.ceremony_location}</p>
                      </div>):null}
                      {contracts.ceremonyTime?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Ceremony Time:</strong>
                        <p className={styles.itemP}>{returnCurrentTime(contracts.ceremonyTime)}</p>
                      </div>):null}
                      {contracts.venueSelect?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Venue:</strong>
                        <p className={styles.itemP}>{contracts.venueSelect}</p>
                      </div>): null}
                    </Space>
                  </Col>
                </Row>
                <Row  className={styles.Row}>
                  <Col span={24}>
                    <h2 className='mb-10'>Client information</h2>
                    {contracts.clients && contracts.clients.length > 0 ? (
                      <div className={styles.clientsContainer}>
                        {contracts.clients.map((client, index) => (
                          <div key={index} className={styles.clients}>
                            <div  direction={"vertical"} className={styles.clientsTextName}>{client.relationOptional}:</div>
                            <Space direction={"vertical"} className={styles.clientsText}>
                              <span>   {client.clientfirstname} {client.clientlastname}</span>
                            </Space>
                            <Space direction={"vertical"} className={styles.clientsText}>
                              <span>{client.clientaddress1}, {client.appNumber}</span>
                              <span> {client.city}, {client.state}</span>
                              <span>{client.zipCode}, {client.county}</span>
                            </Space>
                            <Space direction={"vertical"} className={styles.clientsText}>
                              <span> {client.phone}</span>
                              {client.email.map((email, emailIndex) => (
                                <span key={emailIndex}>{email} < br /></span>
                              ))}
                            </Space>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No clients data available.</p>
                    )}
                  </Col>
                </Row>
              </div>
            ) : null}

            <h2 className='mb-10'>Package Details</h2>
            {packages ? (
              <div>
                <Row justify="space-between"  className={styles.Row}>
                  <Col span={24}>
                    <Table
                      rowKey={(record) => record.id}
                      className="priceListTable"
                      columns={columnsPag}
                      dataSource={packages}  // Updated line
                      pagination={false}
                      style={{paddingBottom: '20px'}}
                    />
                  </Col>
                  {contracts.contractTextArea ? (
                    <Col span={24} className='Notes'>
                      <Space direction="vertical">
                        <h2 className=''>Notes</h2>
                        {contracts.contractTextArea ? (
                          <p className={styles.pNotes}>{contracts.contractTextArea}</p>
                        ) : null}
                      </Space>
                    </Col>
                  ) : null}

                  <Col span={24} className={styles.PaymentTitle}>
                    <h2 className='mb-10'>Payment Scheduled</h2>
                  </Col>
                  <Col span={12} className={styles.PaymentBody}>
                    {paymentPackages.payments && paymentPackages.payments.length > 0 ? (
                      <>
                        {paymentPackages.payments.map((item, index) => (
                          <div key={index} >
                            <Space direction="vertical" className={styles.payment}>
                              <p className={styles.paymentDep}>{`${ordinalSuffix(index + 1)}`}</p>
                              <p className={styles.paymentItem}>{item.paymentData}</p>
                              <p >{item.amount}</p>
                            </Space>
                          </div>
                        ))}
                        <div className={styles.pdfBalance}>
                          <strong className={styles.itemBalance}>Balance</strong>
                          <b className={styles.Balance}> $ {paymentPackages.balance} </b>
                        </div>
                      </>
                    ) : (
                      <LoadingSpin />
                    )}
                  </Col>

                  <Col className={styles.PaymentBodyPackages} span={8}>
                    {paymentPackages ?(<Space direction="vertical" className={styles.textSpace}>

                      <div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Package Sub Total </strong>
                        <p className={styles.itemP}>$ {(parseFloat(paymentPackages.subTotalPackages)).toFixed(2)}</p>
                      </div>
                      {paymentPackages.discountValue >0?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Discount </strong>
                        <p className={styles.itemG}>{paymentPackages.discountType} {paymentPackages.discountValue}</p>
                      </div>):null}

                      {paymentPackages.discountValue >0?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Discount in $ </strong>
                        <p className={styles.itemD}>$ {(parseFloat(paymentPackages.subTotal) - parseFloat(paymentPackages.subTotalPackages)).toFixed(2)}</p>
                      </div>):null}


                      <div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Sub Total </strong>
                        <p className={styles.itemP}>$ {(parseFloat(paymentPackages.subTotal)).toFixed(2)}</p>
                      </div>
                      {paymentPackages.taxAmount >0?(<div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Tax 8.625% </strong>
                        <p className={styles.itemP}>$ {(parseFloat(paymentPackages.taxAmount)).toFixed(2)}</p>
                      </div>):null}

                      <div className={styles.pdfList}>
                        <strong className={styles.itemStrong}>Total </strong>
                        <p className={styles.itemP}>$ {(parseFloat(paymentPackages.totalWithTax)).toFixed(2)}</p>
                      </div>
                    </Space>):null}

                  </Col>
                </Row>
              </div>
            ) : null}


            <div className={styles.pdfLina}/>
            {studioInfo ? ( <div className={styles.pdfLinaDown}> This is a Contract between   <span className={styles.infoStudioText}> {studioInfo.companyName}</span>. Studio, Inc. and "The Purchaser." The Purchaser agrees to purchase the services described above,
              at the prices shown. Contract is subject to the Terms and Conditions printed on the reverse side (page 2).
            </div>):null}
            <Row justify={"space-between"}  className={styles.Row}>
              <Col span={10}>
                <div className={styles.pdfListSignature}>
                  <strong className={styles.itemStrongSignature}>Purchaser's Signature</strong>
                  <p className={styles.itemSignature}></p>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.pdfListSignature}>
                  <strong className={styles.itemStrong}>Contract Date:</strong>
                  {contracts && contracts.contractDate ? (
                    <p className={styles.itemP}>{returnCurrentDate(contracts.contractDate)}</p>
                  ) : null}

                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div id="terms_container" className={'text-center mt-4'} >
          <div className={styles.pdfContent}>

            {termsConditions ? (
              <>
                <h2 className='mb-10 mt-4'>Terms and Conditions</h2>
                <Row  className={styles.Row}>
                  <Col span={24}>
                    <div key={termsConditions.id} >
                      <Space  direction="vertical">
                        <h2>  {termsConditions.name}</h2>
                        <div className={`${styles.pdfTrems} ql-editor`} dangerouslySetInnerHTML={{ __html: termsConditions.content }} />
                      </Space>
                    </div>
                  </Col>
                </Row>
              </>
            ) :  (<LoadingSpin />)}

            <Row justify={"space-between"}  className={styles.Row}>
              <Col span={10}>
                <div className={styles.pdfListSignature}>
                  <strong className={styles.itemStrongSignature}>Purchaser's Signature</strong>
                  <p className={styles.itemSignature}></p>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.pdfListSignature}>
                  <strong className={styles.itemStrong}>Contract Date:</strong>
                  {contracts && contracts.contractDate ? (
                    <p className={styles.itemP}>{returnCurrentDate(contracts.contractDate)}</p>
                  ) : null}

                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Button onClick={handleExport} className={styles.contractBtn} type="primary" >
          Download Contract as PDF
        </Button>
      </div>

    </>
  );
};

export default PDFGenerator;