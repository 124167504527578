import React, {useRef, useState,useEffect} from 'react';
import { Button, Steps, theme } from 'antd';
import StepFirst from './StepFirst'
import CreatePackage from "./CreatePackage";
import OurPolicy from "./OurPolicy";
import PDFGenerator from '../PDFGenerator';
import PaymentSchedule from "./PaymentSchedule";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientAPI from "../../../../../api/api";


const Contract = ({pdfId,helperShow,setHelperShow,contractList}) => {
    const navigate = useNavigate();
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [stepFirstData, setStepFirstData] = useState({});
    const [stepSecondData, setStepSecondData] = useState({});
    const [stepThirdData, setStepThirdData] = useState({});
    const [stepFourData, setStepFourData] = useState({});
    const [refNext, setRefNext] = useState(null);
    const [refPrevious, setRefPrevious] = useState(null);
    const [refDone, setRefDone] = useState(null);
    const stepFirstRef = useRef(null);
    const stepSecondRef = useRef(null);
    const stepThirdRef = useRef(null);
    const stepFourRef = useRef(null);

    const [insertContract, insertContractLoading, insertContractError] = useFetching(async (data) => {
        const {data: res} = await clientAPI.insertContract(data);
        if (res) {
            console.log(res)
            // Retrieve existing array of objects from local storage or initialize a new one
            const contractList = JSON.parse(localStorage.getItem('contractList')) || [];

            // Add the new combinedData object to the array
            contractList.push(data);

            // Save the updated array to local storage
            localStorage.setItem('contractList', JSON.stringify(contractList));

            // Remove data from local storage
            localStorage.removeItem('contract_data');
            localStorage.removeItem('contract_packages');
            localStorage.removeItem('paymentPackagesData');

            // Optionally, you can reset the state in your component if needed
            setStepFirstData({});
            setStepSecondData({});
            setStepFourData({});

            // Navigate to the desired page or perform any other action
            navigate('/studio/contract_list');
            window.location.reload();
        }
    });



    useEffect(() => {
        if (pdfId !== undefined) {
            const dataContractList = contractList;
            const selectedContract = dataContractList.find(item => item.contract_data.id === pdfId);

            if (selectedContract) {
                const contractAll = selectedContract.contract_data || {};
                const contractPackages = selectedContract.contract_packages || [];
                const paymentPackages = selectedContract.paymentPackagesData || {};
                setStepFirstData(contractAll);
                setStepSecondData(contractPackages);
                setStepFourData(paymentPackages);
            } else {
                const jsonFirstStepData = JSON.parse(localStorage.getItem('contract_data'));
                const jsonSecondStepData = JSON.parse(localStorage.getItem('contract_packages'));
                const jsonFourData = JSON.parse(localStorage.getItem('paymentPackagesData'));
                setStepFirstData(jsonFirstStepData || {});
                setStepSecondData(jsonSecondStepData || {});
                setStepFourData(jsonFourData || {});
            }
        }
    }, [pdfId]);

    useEffect(() => {
        const jsonFirstStepData = JSON.parse(localStorage.getItem('contract_data'));
        setStepFirstData(jsonFirstStepData || {});
    }, []);



    const steps = [
        {
            key:1,
            title: 'Client Information',
            content: <StepFirst
              ref={stepFirstRef}
              setRefNext={setRefNext}
              setStepFirstData={setStepFirstData}
              stepFirstData={stepFirstData}
              helperShow={helperShow}
              setHelperShow={setHelperShow}
              contractList={contractList}
            />,
        },
        {
            key:2,
            title: 'Create Package',
            content: <CreatePackage
              setStepSecondData={setStepSecondData}
              stepSecondData={stepSecondData}
              ref={stepSecondRef}
              pdfId={pdfId}
              setRefNext={setRefNext}
              setRefPrevious={setRefPrevious}
              helperShow={helperShow}
              setHelperShow={setHelperShow}
              contractList={contractList}
            />,
        },
        {
            key:3,
            title: 'Terms and Conditions',
            content: <OurPolicy
              setRefNext={setRefNext}
              setRefPrevious={setRefPrevious}
              setStepThirdData={setStepThirdData}
              stepThirdData={stepThirdData}
              helperShow={helperShow}
              setHelperShow={setHelperShow}
            />,
        },

        {
            key:4,
            title: 'Approve or Save Contract',
            content: <PaymentSchedule
              ref={stepFourRef}
              setRefNext={setRefNext}
              setRefPrevious={setRefPrevious}
              setStepFourData={setStepFourData}
              stepFourData={stepFourData}
              helperShow={helperShow}
              setHelperShow={setHelperShow}
              contractList={contractList}
            />,
        },
        {
            key: 5,
            title: 'PDF',
            content: <PDFGenerator setRefDone={setRefDone} setRefPrevious={setRefPrevious}  helperShow={helperShow} setHelperShow={setHelperShow}/>
        },

    ];


    const saveContract = () => {
        // Get data from local storage
        const jsonFirstStepData = JSON.parse(localStorage.getItem('contract_data'));
        const jsonSecondStepData = JSON.parse(localStorage.getItem('contract_packages'));
        const jsonFourData = JSON.parse(localStorage.getItem('paymentPackagesData'));

        const combinedData = {
            ...(jsonFirstStepData && typeof jsonFirstStepData === 'object' ? { jsonFirstStepData: { ...jsonFirstStepData } } : {}),
            ...(Array.isArray(jsonSecondStepData) ?{ jsonSecondStepData: jsonSecondStepData}  : []),
            ...(jsonFourData && typeof jsonFourData === 'object' ? { jsonFourData: { ...jsonFourData } } : {}),
        };

        console.log(combinedData)
        insertContract(combinedData);

    };

    const handleUpdate = () => {

        const jsonFirstStepData = JSON.parse(localStorage.getItem('contract_data'));
        const jsonSecondStepData = JSON.parse(localStorage.getItem('contract_packages'));
        const jsonFourData = JSON.parse(localStorage.getItem('paymentPackagesData'));

        const combinedData = {
            ...(jsonFirstStepData && typeof jsonFirstStepData === 'object' ? { jsonFirstStepData: { ...jsonFirstStepData } } : {}),
            ...(Array.isArray(jsonSecondStepData) ? { jsonSecondStepData: jsonSecondStepData } : []),
            ...(jsonFourData && typeof jsonFourData === 'object' ? { jsonFourData: { ...jsonFourData } } : {}),
        };

        const contractList = JSON.parse(localStorage.getItem('contractList')) || [];
        const contractIndex = contractList.find((item, arrayIndex) => arrayIndex === pdfId);

        if (contractIndex !== -1) {

            contractList[contractIndex] = {
                ...contractList[contractIndex],
                ...combinedData,
            };
        } else {
            contractList.push({ ...combinedData });
        }

        localStorage.setItem('contractList', JSON.stringify(contractList));

        localStorage.removeItem('contract_data');
        localStorage.removeItem('contract_packages');
        localStorage.removeItem('paymentPackagesData');

        setStepFirstData({});
        setStepSecondData({});
        setStepFourData({});

        navigate('/studio/contract_list');
        window.location.reload();
    };

    const next = () => {
        if (current === 0) {
            stepFirstRef.current.validateAndProceed()
              .then(() => {
                  setCurrent(current + 1);
              })
              .catch((error) => {
                  console.error('Validation error:', error);
              });
        } else {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.key,
        title: item.title,
    }));
    const contentStyle = {
        lineHeight: '260px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
    };
    return (
      <>
          <Steps current={current} items={items} />
          <div style={contentStyle}>{steps[current].content}</div>
          <div
            style={{
                marginTop: 24,
            }}

          >

              {current > 0 && (
                    <Button
                      ref={refPrevious}
                      type="primary"
                      htmlType="submit"
                      className='formBtn send-email-btn'
                      style={{  margin: '0 8px',    }}
                      onClick={() => prev()}>Previous
                </Button>
              )}

              {current < steps.length - 1 && (
                     <Button
                       ref={refNext}
                       type="primary"
                       htmlType="submit"
                       className='formBtn send-email-btn' onClick={() => next()}>Next
                </Button>
              )}
              {current === steps.length - 1 && (
                pdfId ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className='formBtn send-email-btn'
                    onClick={() => handleUpdate()}
                  >
                      Update
                  </Button>
                ) : (
                  <Button
                    ref={refDone}
                    type="primary"
                    htmlType="submit"
                    className='formBtn send-email-btn'
                    onClick={() => saveContract()}
                  >
                      Done
                  </Button>
                )
              )}
          </div>
      </>
    );
};
export default Contract;