import React, { createContext, useContext, useState } from 'react';

// Create a context
const ContractContext = createContext();

// Create a provider component
export const ContractProvider = ({ children }) => {
    const [formData, setFormData] = useState({}); // Replace this with your initial data

    return (
        <ContractContext.Provider value={{ formData, setFormData }}>
            {children}
        </ContractContext.Provider>
    );
};

// Create a custom hook for using the context
export const useContractContext = () => {
    return useContext(ContractContext);
};
