import {Layout,  theme} from 'antd';
import React from 'react';
import GalleryNavbar from "../../Clients/Gallery/GalleryNavbar";

const { Header} = Layout;

const ClientHeader = (props) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
            <Header className="header">
                <GalleryNavbar
                    studioMode={props.studioMode}
                    setStudioMode={props.setStudioMode}
                />
            </Header>
    );
};
export default ClientHeader;