import React,{useEffect, useState, } from 'react';
import clientAPI from "../../../api/api";
import getColumnSearchProps from '../../Utils/getColumnSearchProps'
import { useFetching}  from "../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, CameraOutlined, CaretRightOutlined, ShareAltOutlined } from "@ant-design/icons";
import { sortColumns, onChangeTable, showDeleteConfirm} from '../../Utils/isFunction'
import { Button, Space, Table } from 'antd';

const ListVenue = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
        },
    });
    const [getEventList, isEventsLoading, eventsError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getEventListByStudioID(studio_id);
        if (res) {
            setData(res.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: res.total,
                },
            });
        }
    });

    const handleTableChange = onChangeTable(tableParams, setTableParams);

    const goEditEventPage = (event_id) => {
        localStorage.setItem('event_id',event_id);
        navigate('/studio/edit_event');
        window.location.reload()
    };

    useEffect(() => {
        const studio_id = localStorage.getItem('studio_id');
        getEventList(studio_id);
    }, []);

    const eventDelete = (event_id) => {
        showDeleteConfirm(event_id, 'Event').then((result) => {
            if (result) {
                console.log('yes')
                const updatedData = data.filter((item) => item.id !== event_id);
                setData(updatedData);
            } else {
                console.log('NO')
            }
        });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'event_logo',
            key: "event_logo",
            render: (_, record) => (
              <Space wrap>
                  <CameraOutlined  className="my_ant_icons_table" onClick={()=> console.log(123123)}/>
              </Space>
            ),
        },
        {
            title: 'Client ID',
            dataIndex: 'client_id',
            key: "client_id",
            ...getColumnSearchProps("client_id"),
            sorter: (a, b) => a.client_id - b.client_id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Event ID',
            dataIndex: 'event_id',
            key: 'event_id',
            ...getColumnSearchProps("client_id"),
            sorter: (a, b) => a.event_id - b.event_id,
            sortDirections: ['descend', 'ascend'],
            // sortOrder: sortedInfo.columnKey === 'studio_name' ? sortedInfo.order : null,
        },
        {
            title: 'Event Name',
            dataIndex: 'event_name',
            key: 'event_name',
            ...getColumnSearchProps("event_name"),
            sorter: sortColumns("event_name") ,
            sortDirections: ['descend', 'ascend'],
            // sortOrder: sortedInfo.columnKey === 'first_name' ? sortedInfo.order : null,
        },
        {
            title: 'Event Type',
            dataIndex: 'event_type',
            key: 'event_type',
            ...getColumnSearchProps("event_type"),
            sorter: sortColumns("event_type"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Event Date',
            dataIndex: 'event_date',
            key: 'event_date',
            ...getColumnSearchProps("event_date"),
            sorter: sortColumns("event_date") ,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Generate',
            dataIndex: 'event_thumbnail',
            key: 'event_thumbnail',
            render: (_, record) => (
              <Space wrap>
                  <Button disabled  onClick={()=> console.log(123123)} type="primary" size="default">
                      <CaretRightOutlined />Thumbnail
                  </Button>
              </Space>
            ),

        },
        {
            title: 'Share',
            dataIndex: 'event_share',
            key: "event_share",
            render: (_, record) => (
              <Space wrap>
                  <ShareAltOutlined  className="my_ant_icons_table" onClick={()=> console.log(123123)}/>
              </Space>
            ),
        },

        {
            title: 'Edit',
            dataIndex: 'event_edit',
            key: 'event_edit',
            render: (_, record) => (
              <Space wrap>
                  <EditOutlined  className="my_ant_icons_table" onClick={()=> goEditEventPage(record.event_id)}/>
              </Space>
            ),

        },
        {
            title: 'Delete',
            dataIndex: 'event_delete',
            key: 'event_delete',
            render: (_, record) => (
              <Space wrap>
                  <DeleteOutlined  className="my_ant_icons_table" onClick={()=> eventDelete(record.event_id)}/>
              </Space>
            ),

        },

    ];

    if(isEventsLoading){
        return (
          <Table
            className="table-striped-rows studio-table-rows"
            rowKey={(record) => record.event_id}
            columns={columns}
            dataSource={[]}
            loading={isEventsLoading}
            pagination={tableParams.pagination}
          />
        );
    }else{
        return(
          <Table
            className="table-striped-rows studio-table-rows"
            pagination={tableParams.pagination}
            loading={isEventsLoading}
            rowKey={(record) => record.event_id}
            columns={columns}
            dataSource={data}
            onChange={handleTableChange}
          />
        );
    }
};

export default ListVenue;