import React,{useEffect, useState,} from 'react';
import clientAPI from "../../api/api";
import getColumnSearchProps from '../Utils/getColumnSearchProps'
import { useFetching } from "../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { sortColumns, showDeleteConfirm, onChangeTable } from '../Utils/isFunction'
import { Space, Table } from 'antd';
import { LoginOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";


const LabStudioList = () => {

    const PREFIX = "https://studioseye.com/pricing/upload/"; // Replace with your prefix
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
        },
    });

    const [getStudioList, isStudiosLoading, studioError] = useFetching(async (lab_id) => {
        const { data: res } = await clientAPI.getStudioListByLabIdToken(lab_id);
        if (res.data) {
            const modifiedData =  res.data.map((obj) => ({
                ...obj,
                studio_logo: PREFIX + obj.studio_logo
            }));
            setData(modifiedData);
            setTableParams({
                ...tableParams,
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: res.total,
                },
            });
        }
    });
  
    const goStudioPage = (studio_id) => {
        localStorage.setItem('studio_id',studio_id);
        localStorage.setItem('user_type',"STUDIO");
        localStorage.setItem('current_user_id',studio_id);
        navigate('/studio/list_client');
    };

    const edit_studio = (studio_id) => {
        console.log(studio_id);
        localStorage.setItem('studio_id',studio_id);
        localStorage.setItem('current_user_id',studio_id);
        navigate('/labs/edit_studio');
        window.location.reload();
    };
 
    useEffect(() => {
        const lab_id =  localStorage.getItem('user_id');
        getStudioList(lab_id);
    }, []);

     const handleTableChange = onChangeTable(tableParams, setTableParams);


    const deleteStudio = (studio_id) => {
        showDeleteConfirm(studio_id, 'Studio').then((result) => {
            if (result) {
                console.log('yes')
            } else {
                console.log('NO')
            }
        });
    };
     const columns = [
        {
            title: 'Logo',
            dataIndex: 'studio_logo',
            key: "studio_logo",
            render: (studio_logo) => (
                <img
                    src={studio_logo}
                    alt="avatar"
                    className="studio_logo"
                />
            )
        },
        {
            title: 'ID',
            dataIndex: 'studio_id',
            key: "studio_id",
            ...getColumnSearchProps("studio_id"),
            sorter: (a, b) => a.studio_id - b.studio_id,
            sortDirections: ['ascend','descend'],
        },
        {
            title: 'Name',
            dataIndex: 'studio_name',
            key: 'studio_name',
            ...getColumnSearchProps("studio_name"),
            sorter: sortColumns("studio_name") ,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            ...getColumnSearchProps("first_name"),
            sorter: sortColumns("first_name"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            ...getColumnSearchProps("last_name"),
            sorter: sortColumns("last_name") ,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'studio_email',
            key: 'studio_email',
            ...getColumnSearchProps("studio_email"),
            sorter: sortColumns("studio_email"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Phone',
            dataIndex: 'studio_phone',
            key: 'studio_phone',
            ...getColumnSearchProps("studio_phone"),
            sorter: sortColumns("studio_phone") ,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Login',
            dataIndex: 'studio_login',
            key: 'studio_login',
            render: (_, record) => (
                <Space wrap>
                 <LoginOutlined className="my_ant_icons_table" onClick={()=> goStudioPage(record.studio_id)}/>
                </Space>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'studio_edit',
            key: 'studio_edit',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined  className="my_ant_icons_table" onClick={()=> edit_studio(record.studio_id)}/>
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined  className="my_ant_icons_table" onClick={()=> deleteStudio(record.studio_id)}/>
                </Space>
            ),
        },

    ];
    if(isStudiosLoading){
        return (
            <>
            <Table
                className="table-striped-rows studio-table-rows"
                columns={columns}
                dataSource={[]}
                loading={isStudiosLoading}
                pagination={tableParams.pagination}
            />
            </>
        );
    }else{
    return(
        <>
    <Table
        className="table-striped-rows studio-table-rows"
        pagination={tableParams.pagination}
        loading={isStudiosLoading}
        rowKey={(record) => record.studio_id}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        scroll={{ x: 100 }}
        summary={() => (
          <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={100}>
                  {!tableParams.pagination.total > 0 ? (
                    <b>All Studios: {tableParams.pagination.total}</b>
                  ) : (
                    <b>All Studios: {data.length}</b>
                  )}
              </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
    />
        </>
    );
    }
};
export default LabStudioList;