import React, { useState} from 'react';
import { Row, Col, Typography, Tooltip } from 'antd';
import images from './AlbumSidebarImages';
import {CloseCircleOutlined,
    BorderVerticleOutlined,
    BorderBottomOutlined,
    BorderRightOutlined,
} from '@ant-design/icons';
const colors = [
    { id: 1, name: "White", value: "#ffffff" },
    { id: 2, name: "Blue", value: "#0000ff" },
    { id: 3, name: "Dark Blue", value: "#00008b" },
    { id: 4, name: "Black", value: "#000000" },
    { id: 5, name: "Magenta", value: "#ff00ff" },
    { id: 6, name: "Purple", value: "#800080" },
    { id: 7, name: "Red", value: "#ff0000" },
    { id: 8, name: "Dark Red", value: "#8b0000" },
    { id: 9, name: "Pink", value: "#ffc0cb" },
    { id: 10, name: "Yellow", value: "#ffff00" },
    { id: 11, name: "Orange", value: "#ffa500" },
    { id: 12, name: "Beige", value: "#f5f5dc" },
    { id: 13, name: "Green", value: "#008000" },
    { id: 14, name: "Lime", value: "#00ff00" },
    { id: 15, name: "Light Brown", value: "#b5651d" },
    { id: 16, name: "Cyan", value: "#00ffff" },
    { id: 17, name: "Teal", value: "#008080" },
    { id: 18, name: "Brown", value: "#a52a2a" }
  ]; 

function AlbumTextUVStamping({
    setSidebarOpen,
    setTextProperties,
    setIsStampingUVOpen

}) {
    const { Text } = Typography;
    
    const fontClasses = [
        'bernhardModern',
        'bickhamScriptPro',
        'nradleyHandeItc',
        'cathedral',
        'chopinScript',
        'nuptialScript',
        'sheerElegance',
        'penyaeLight',
        'phyllisAttItalic',
        'moderne',
        'wendyMedium',
        'wendysHand'
    ];
    const [textPos, setTextPos] = useState('CENTER');
    const [color, setColor] = useState('');
    const [fontFamily, setFontFamily] = useState("");
    const goBack = () => {
        setSidebarOpen(true);
        setIsStampingUVOpen(false);
    };

    const updateTextProperties = (property, value) => {
        setColor(value)
        setTextProperties(prev => ({ ...prev, [property]: value }));
    };

    const updateTextPosition = (property, value) => {
        setTextProperties(prevState => ({
            ...prevState,
            position: {
                ...prevState.position,
                [property]: value
            }
        }));
    }

    const updatePosition = (position) => {
        setTextPos(position);
        updateTextPosition("type", position);
    }

  const updateFontFamily = (font) => {
      setFontFamily(font);
      updateTextProperties("fontFamily", font);
    }


    return (
        <>
            <Row  gutter={16}>
                <Col lg={24} md={24} span={24}>
                <div onClick={goBack}><CloseCircleOutlined className="fs19"/> Back</div>
                </Col>
                    <Col lg={12} md={12} span={24}>
                        <img className="left-sidebar-covers p10" src={images[24].src} alt=""/>
                    </Col>

                    <Col lg={12} md={12} span={24}>
                        <p className="album-sidebar-image-title p10-b">UV Album Stamping</p>
                        <p className="album-sidebar-image-desc p10-b">$20.00</p>
                        <p></p>
                    </Col>

                <Row className="border-line-bottom">
                    <Col lg={24} md={24} span={24}>
                        <p className="album-sidebar-image-title p10-b">Positions</p>
                        <Row>
                            <Col lg={8} md={8} span={24}>
                            <div className="spamping-icons"
                                 onClick={(e) => updatePosition('CENTER')}>
                                <BorderVerticleOutlined className={(textPos === 'CENTER') ? "stamping-pos-border" : ""} />
                                <p className="album-sidebar-image-desc ">Center-Center</p>
                            </div>
                            </Col>
                            <Col lg={8} md={8} span={24}>
                            <div className="spamping-icons"
                                 onClick={(e) => updatePosition('BOTTOM-RIGHT')}>
                                <BorderRightOutlined className={(textPos === 'BOTTOM-RIGHT') ? "stamping-pos-border" : ""} />
                                <p className="album-sidebar-image-desc ">Bottom-Right</p>
                            </div>
                            </Col>
                            <Col lg={8} md={8} span={24}>
                            <div className="spamping-icons"
                                 onClick={(e) => updatePosition('BOTTOM-CENTER')}>
                                <BorderBottomOutlined className={(textPos === 'BOTTOM-CENTER') ? "stamping-pos-border" : ""} />
                                <p className="album-sidebar-image-desc ">Bottom-Center</p>
                            </div>
                            </Col>
                            <Col lg={24} md={24} span={24} className="mb10">
                                <p className="album-sidebar-image-title p10">Input text here</p>
                            <input className="stamping-input"
                                type="text"
                                onChange={(e) => updateTextProperties("content", e.target.value)}
                                placeholder="Enter your text here"
                                
                            />
                            </Col>

                            <Col lg={24} md={24} span={24} className="pb10 bg-gray">
                                <p className="album-sidebar-image-title p10">Select text color</p>
                                <Row>
                                    {colors.map(({ id, name, value }) => (
                                        <Col key={id} lg={6} md={6} span={24}>
                                        <div className="spamping-colors" onClick={() => updateTextProperties("color", value)}>
                                            <div style={{ background: value }} className={color === value ? "stamping-oval stamping-pos-border" : "stamping-oval"}></div>
                                            <p className="album-sidebar-image-desc">{name}</p>
                                        </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            <p className="album-sidebar-image-title p10-b">Font Size</p>
                            <Col lg={24} md={24} span={24}>
                            <input
                                className="stamping-font-size"
                                type="range"
                                defaultValue="24"
                                min="10"
                                max="48"
                                onChange={(e) => updateTextProperties("fontSize", `${e.target.value}px`)}
                            />
                            </Col>
                           
                            <p className="album-sidebar-image-title p10">Font family</p>
                            <Col lg={24} md={24} span={24}>
                            <Row>
                                {fontClasses.map((fontClass, index) => (
                                    <Col onClick={()=>updateFontFamily(fontClass)} key={index} lg={6} md={6} span={12} className={`${fontClass} stamping-font-family`}>
                                       <Tooltip placement="top" title={fontClass}><p className={(fontFamily ===  fontClass) ? "stamping-font-family-border" : ""}>ABC</p></Tooltip> 

                                    </Col>
                                ))}
                            </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Row>
        </>
    );
}

export default AlbumTextUVStamping;