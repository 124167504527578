import React from 'react';
import EditCardsLab from "../EditCardsLab";


const EditFlatCardsLab = (id) => {
  const nameCards = "Flat Cards";
  const navigate_link = '/labs/flat_cards_list';
  const mainCardType = 1;

  return (
      <div>
        <EditCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
      </div>
  );
};


export default EditFlatCardsLab;


