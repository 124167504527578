import { createSlice, current } from "@reduxjs/toolkit";
import { GetGalleryImages } from "./thunk";



const galleryHeaderSlice = createSlice({
    name: "gallery",
    initialState: {
        images: [],
        totalImages: 0,
        isGalleryLoading: false,
        lightboxIsOpen: false,
        isLightboxLoading: false,
        favoriteImages: [],
        privateImages: [],
        selectedImages: [],
        isSelectedAll: false,
        shopingCard:{},
        folderDataList:[],
        selectedFolder:[],
        selectAllImagesDownload:[],
        downloadImages:{
            download:2,
            include:[],
            exclude:[],
        }

        
        

    },
    reducers: {
        setImages: (state, action) => {
            
            const { total, items } = action.payload;
          
    
            const imagesArray = Array.isArray(items) ? items : [];
            
            state.images = [...state.images, ...imagesArray];
            state.totalImages = total || 0; 
          },
          clearImages:(state)=>{
            state.images = []
          },
          
        setLightBoxIsOpen: (state, action) => {
            state.lightboxIsOpen = action.payload
        },
        setIsLightboxLoading: (state, action) => {
            state.isLightboxLoading = action.payload
        },
        setFavoritePrivate: (state, action) => {
            const { folder_type, data } = action.payload;

            if (folder_type === "Favorite") {
                state.favoriteImages = data;
            } else if (folder_type === "Private") {
                state.privateImages = data;
            }
        },
        toggleFavorite: (state, action) => {
            const { img } = action.payload
            const isFavorite = state.favoriteImages.some(
                (image) => image.ID === img.ID
            );

            if (isFavorite) {
                state.favoriteImages = state.favoriteImages.filter(
                    (image) => image.ID !== img.ID
                );
            } else {
                state.favoriteImages.push(img);
            }
        },

        toggleSelected: (state, action) => {
            const img = action.payload;
            
        
           
            const isSelected = state.selectedImages.some((selectedImg) => selectedImg.ID === img.ID);

        
        
            if (isSelected) {
            
               
               
                state.totalImages -=1
                state.selectedImages = state.selectedImages.filter((selectedImg) => selectedImg.ID !== img.ID);
               
            } 
            
                // download:type:1
                // exclude:[1,2,3,4,5,6,7]
                // include[]
                // }
        
            else {
             
                state.totalImages +=1
                state.selectedImages.push(img);
                
            }
        },

        setDownloadImages:(state,action)=>{
           const {img,type} = action.payload

          

           if(type === 1){
            const isSelected = state.downloadImages.include.some((elem)=>elem.ID === img.ID)

            if(isSelected){
                state.downloadImages.include = state.downloadImages.include.filter((selectedImg) => selectedImg.ID !== img.ID);
                state.downloadImages.download = 2
            }
            else{
                state.downloadImages.include.push(img)
            }
           }
        
           else if(type === 2){
          
            const isSelected = state.downloadImages.exclude.some((elem)=>elem.ID === img.ID)

           
                
            if (!isSelected) {
                const existingImage = state.downloadImages.exclude.find(item => item.ID === img.ID);
                if (!existingImage) {
                    state.downloadImages.exclude.push(img);
                }
                state.downloadImages.download = 1;
            }
            
            else{
              
                state.downloadImages.exclude = state.downloadImages.exclude.filter((selectedImg) => selectedImg.ID !== img.ID);
            }
           }
            
          


        },

        changeDownloadType:(state,action)=>{
            const {type} = action.payload
            if(type === 1){
                state.downloadImages.download =1
            }
            else{
                state.downloadImages.download =2
            }
          
        },


        toggleSelectedAll:(state,action)=>{
            const images = action.payload
            state.selectedImages = images
            

        },
   
        
        selectedAllImages: (state) => {
            state.isSelectedAll = !state.isSelectedAll;
            if (state.isSelectedAll) {
             
              state.selectedImages = state.images
                .filter((elem) => elem)
                .map((elem) => elem.ID);
            } else {
              state.selectedImages = [];
            }
          },
          setShopingCard: (state, action) => {
            if (action.payload) {
                state.shopingCard = action.payload;
            }
        },
        setFolderDataList:(state,action)=>{
            state.folderDataList = action.payload
        },
        setPrivateImages:(state,action) =>{
            state.privateImages = action.payload
        },
        setSelectedFolder:(state,action)=>{
                state.selectedFolder = action.payload
        }
        
        

    },
    extraReducers: (bulider) => {
        bulider.addCase(GetGalleryImages.pending, state => {
            state.isGalleryLoading = true
        }).addCase(GetGalleryImages.fulfilled, state => {
            state.isGalleryLoading = false
        }).addCase(GetGalleryImages.rejected, state => {
            state.isGalleryLoading = false
        })
    }
})

export const {
    setImages,
    setLightBoxIsOpen,
    setIsLightboxLoading,
    setFavoritePrivate,
    toggleFavorite,
    toggleSelected,
    selectedAllImages,
    setShopingCard,
    setFolderDataList,
    setPrivateImages,
    setSelectedFolder,
    toggleSelectedAll,
    clearImages,
    setDownloadImages,
    changeDownloadType
  
    

} = galleryHeaderSlice.actions
export default galleryHeaderSlice

