import React from 'react';

const Quantity = ({ quantity, setQuantity,min,max }) => {

    const handleQuantityChange = (e) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value)) {
            setQuantity(value);
        }
    };

  return (
    <div className='count'>
      <div
        className='plus-minus '
        onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
      >
        -
      </div>
      <input
        type='number'
        className='currentNumber'
        value={quantity}
        onChange={handleQuantityChange}
        style={{textAlign: 'center'}}
      />
      <div className='plus-minus' onClick={() => setQuantity(quantity + 1)}>
        +
      </div>
    </div>
  );
};

export default Quantity;
