import React, {useState,useEffect, useRef} from 'react';
import clientAPI from "../../../api/api";
import MoreInfo from "../../Utils/MoreInfo"
import Container from "react-bootstrap/Container";
import {Button, Col, Form, Input, Row, Select, Tooltip, Typography, message, Tour} from 'antd';
import { SolutionOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFetching } from "../../../hoc/fetchingHook";
import { generateRandomUsername, handleGenerateUnamePass, handleTagChange, validateMessages } from '../../Utils/isFunction';
import {useHelperTab} from "../../../context/HelperTabContext";

const AddStudio = ({helperShow,setHelperShow}) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const { helperTab } = useHelperTab();
    const [open, setOpen] = useState(helperShow);

    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }

    }, [helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const steps = [
        {
            title: 'Email Addresses',
            description: 'This section allows you to add multiple email addresses. You can enter one or more email addresses as needed. Each email address should be entered separately to ensure accurate communication and record-keeping',
            target: () => ref1.current,
        },
        {
            title: 'Generate Username & Password',
            description: 'Click this button to automatically generate a unique username and password. This feature is designed to provide you with secure and randomized credentials, ensuring enhanced security for your account',
            target: () => ref2.current,
        },
        {
            title: 'More Information',
            description: 'Use the "More Details" option to provide comprehensive information about your client. This section is designed to capture detailed contact information, ensuring that you have all the necessary data for effective communication and service delivery',
            target: () => ref3.current,
        },
        {
            title: 'Create Studio',
            description: 'he "Create" button is your final step in submitting information or finalizing the process you are working on. When you click this button, it triggers the system to save and process all the data you\'ve entered',
            target: () => ref4.current,
        },
    ];

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };
    const randomUsername = generateRandomUsername(8);
    const handleClick = () => { handleGenerateUnamePass(form) }

    const [createStudio, isStudioLoading, studioError] = useFetching(async (user_id,values) => {
        const { data: res } = await clientAPI.createStudio(user_id,values);
        if (res) {
            const currentPath = '/labs/studio_list';
            navigate(currentPath);
            window.location.reload();
        }
    });
   
    const onFinish = (values) => {
        const lab_id = localStorage.getItem('user_id');
        createStudio(lab_id,values).then(r  => console.log(r));
    };

    return(
        <>
            <div className={'text-center mt-4'}>
                <h3 className={'text-uppercase mb-4 header-title'}>Add Studio</h3>
            </div>
            <div className={'mt-4 album-notifications-select'}>
                <Form
                    form={form}
                    name="validate_other"
                    onFinish={onFinish}
                    size={"large"}
                    layout="vertical"
                    style={{
                        maxWidth: 600,
                        margin: "auto"
                    }}
                    validateMessages={validateMessages}
                    initialValues={{ state: 'New York' }}
                >
                       <Form.Item
                         name="studio_name"
                         label="Studio Name"
                         rules={[
                             {
                                 required: true,
                             },
                         ]}
                       >
                        <Input/>
                    </Form.Item>
                    <span ref={ref1}>
                        <Form.Item
                            name="email"
                            label="Enter tags (email addresses)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter at least one email address.',
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                onChange={handleTagChangee}
                                style={{ width: '100%' }}
                                placeholder="Enter your (email addresses)"
                            />
                        </Form.Item>
                    </span>
                    <Row justify='space-between'>
                        <Col sm={21} xs={20}>
                            <Form.Item
                              name="username"
                              label="Username"
                              rules={[
                                  {
                                      required: true,
                                  },
                              ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <span ref={ref2}>
                            <Col sm={2} xs={3} >
                                <Tooltip title="Generate Username and Password">
                                    <Typography.Link onClick={handleClick}>
                                        <SolutionOutlined className={"generate-uname-pass"}/>
                                    </Typography.Link>
                                </Tooltip>
                            </Col>
                        </span>
                    </Row>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password value={randomUsername}/>
                    </Form.Item >

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password value={randomUsername}/>
                    </Form.Item>

                        <Row justify="space-between">
                            <Col lg={11} md={11} span={24}>
                                <Form.Item
                                    name="firstname"
                                    label="Firstname"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col lg={11} md={11} span={24}>
                                <Form.Item
                                    name="lastname"
                                    label="Lastname"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <span ref={ref3}>
                            <Col className={"collapse-client"} lg={24} md={24} span={24} >
                                <a onClick={() => { setExpand(!expand);}}>
                                    <DownOutlined rotate={expand ? 180 : 0} /> More Details
                                </a>
                            </Col>
                        </span>

                    {expand && (
                        <MoreInfo />
                    )}
                    <Container className={'text-center'}>
                        <Button ref={ref4} type="primary" htmlType="submit"  className='formBtn send-email-btn' >
                            Create
                        </Button>
                    </Container>
                </Form>
            </div>
            {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
        </>
    )
};
export default AddStudio;