import {Button, Input, Row, Space, Table, Tour} from 'antd';
import React,{useEffect, useState,useRef} from 'react';
import '../Prints/Price.css'
import {Checkbox} from 'antd';
import { useNavigate } from 'react-router';
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientAPI from "../../../../../api/api";
import {usePriceContext} from "../../../../../context/PriceContext";

import {useHelperTab} from "../../../../../context/HelperTabContext";


const AddUnitsStudio = ({helperShow,setHelperShow}) => {
    const { helperTab } = useHelperTab();
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const [open, setOpen] = useState(helperTab);

    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }
    }, [helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    const steps = [
        {
            title: 'Price List Title',
            description: 'Please enter the name of your price list in the designated field. Should you require this price list to be the default for future use, ensure to mark the accompanying checkbox. This action will establish the entered price list as the standard selection across the platform.',
            target: () => ref1.current,
        },
        {
            title: 'Price Adjustment',
            description: 'Enter the percentage you\'d like to add to the current price of your items. If you\'ve already got a selling price, typing in \'100\' means you\'ll be doubling the price – so something that\'s $10 will jump to $20. No selling price yet? No worries! We\'ll boost the lab price by the same percentage you choose. This nifty tool helps you keep your prices aligned with your business goals without the math headache. Just type in the number and let the magic happen!"',
            target: () => ref2.current,
        },
        {
            title: 'Size Customizer',
            description: 'Size Customizer: Choose the sizes you offer and assign your selling prices to each. This tool streamlines the process, enabling you to tailor your product offerings to meet customer needs while ensuring profitability. Simply select from the available sizes and input the corresponding selling price for an efficient and personalized pricing structure',
            target: () => ref3.current,
        },
        {
            title: 'Price Customizer',
            description: 'Price Check Alert: Be mindful when setting your selling prices. If you accidentally input a selling price that\'s lower than your lab cost, our system will flag this with a negative cost indicator. This feature helps prevent potential losses by alerting you to pricing errors, ensuring that your selling prices always cover your costs and contribute to your profit margin',
            target: () => ref4.current,
        },
        {
            title: 'Save and Personalize',
            description: 'Save and Personalize Your Price List: Once you\'ve finalized your pricing, hit \'Save\' to add these details to your price list. This action locks in your carefully selected prices. After saving, you have the flexibility to assign different items from this list to specific clients. This feature allows for tailored pricing strategies, ensuring that each client receives a customized selection that suits their needs and your business goals. It\'s all about giving you control over your pricing, client by client.',
            target: () => ref5.current,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [text, setText] = useState("");
    const [checked, setChecked] = useState("NO");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [data, setData] = useState([]);
    const { inputNumberValue, setInputNumberValue } = usePriceContext();
    const [detailsData, setDetailsData] = useState([]);
    const navigate = useNavigate();
    const [setSizesList, setSizesLoading, setSizesError] = useFetching(async (obj) => {
        const {data: res} = await clientAPI.setProductObjectSizes(obj);
        if (res) {
            navigate('/studio/units_list');
            window.location.reload();

        }
    });



    const fetchSizesAndDetails = async () => {
        try {
            const product_type = localStorage.getItem('product_type');
            const sizesRes = await clientAPI.getProductObjectSizes(22, 'SIZE');
            const headerRes = await clientAPI.getPrintPriceLabDefault(product_type);
            if (sizesRes.data &&  headerRes.data) {
                const detailsRes = await clientAPI.getPrintPriceDetails(headerRes.data.id);
                if(detailsRes.data){
                    setLoading(false);
                    const sizesBody = sizesRes.data.Units;
                    const selectedIds = detailsRes.data.map((item) => item.item_id);
                    setText(headerRes.data.name);
                    setInputNumberValue(headerRes.data.parseInt);
                    setCheckboxChecked(headerRes.data.default_checked === 'YES');
                    setSizes([...sizesBody]);
                    setSelectedRowKeys(selectedIds);
                    setDetailsData(detailsRes.data);
                    setChecked(headerRes.data.default_checked);
                    const nonEmptyDetails = detailsRes.data.filter((detail) => detail.price);
                    const combinedData = nonEmptyDetails.map((detail) => {
                        const { price, costPrice } = calculatePrice(detail.price);
                        const labPrice = detail.price;
                        const size = sizesBody.find((size) => size.ID === detail.item_id);
                        return {
                            id: detail.item_id,
                            name: `${size.width} x ${size.height}`,
                            sellingPrice: price,
                            cost: costPrice,
                            lab_price: parseFloat(labPrice).toFixed(2),
                        };
                    });


                    setData(combinedData);
                }

                return detailsRes.data;
            } } catch (error) {
            setLoading(false);
            console.error('Error fetching sizes and details', error);
        }
    };

    const calculatePrice = (labprice,detailPrice) => {
        const labPrice = parseFloat(labprice);
        const numericPrice = parseFloat(detailPrice);

        if (isNaN(labPrice)) {
            return {
                price: '0.00',
                costPrice: '0.00',
            };
        }
        if (inputNumberValue === 0){
            const price = labPrice;
            const costPrice = price - labPrice;

            return {
                price: price.toFixed(2),
                costPrice: costPrice.toFixed(2),
            };

        }else {
            const price = ((numericPrice * inputNumberValue) / 100) + numericPrice;
            const costPrice = price - labPrice;

            return {
                price: price.toFixed(2),
                costPrice: costPrice.toFixed(2),
            };

        }

    };



    const sellingPriceChange = (event, id, originalSellingPrice) => {
        const { value } = event.target;
        const newValue = value !== '' ? parseFloat(value): originalSellingPrice;
        const updatedData = data.map((item) => {
            if (item.id === id) {
                const updatedSellingPrice = parseFloat(newValue).toFixed(2) !== originalSellingPrice ? newValue : originalSellingPrice;
                const defaultSellingPrice = detailsData.find((detail) => detail.item_id === id)?.price || 0;
                const cost = updatedSellingPrice - defaultSellingPrice ;
                return {
                    ...item,
                    sellingPrice: updatedSellingPrice,
                    cost: cost.toFixed(2),
                };
            }
            return item;
        });
        setData(updatedData);
    };

    const handlePriceChange = () => {
        const updatedData = data.map((item) => {
            const defaultSellingPrice = detailsData.find((detail) => detail.item_id === item.id)?.price || 0;

            const {price, costPrice} = calculatePrice(defaultSellingPrice, item.sellingPrice);

            return {
                ...item,
                sellingPrice: price,
                cost: costPrice,
            };
        });
        setData(updatedData);
    };
    useEffect(() => {
        localStorage.setItem('product_type',2);
        fetchSizesAndDetails();
    }, []);

    useEffect(() => {
        checkboxChecked ?  setChecked("YES") :  setChecked("NO")
    }, [checkboxChecked]);

    const handleChecked = () => {
        setCheckboxChecked(!checkboxChecked);
    };
    const handleInputChange = (e) => {
        setInputNumberValue(parseFloat(e.target.value));
    };
    const handleInputChangeText = (e) => {
        setText(e.target.value);
    };

    const handleReset = () => {
        const updatedData = data.map((item) => {
            const detail = detailsData.find((detail) => detail.item_id === item.id);
            const detailPrice = detail ? detail.price : 0;
            return {
                ...item,
                sellingPrice: detailPrice.toFixed(2),
                cost:0
            };
        });

        setInputNumberValue(0);
        setData(updatedData);
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Lab Price',
            dataIndex: 'lab_price',
            key: 'lab_price',
            render: (_, record) => (
              <span>
                {record.lab_price}
            </span>
            ),
        },
        {
            title: 'Gross Profit',
            dataIndex: 'cost',
            key: 'cost',
            render: (_, record) => (
              <span className={record.cost > 0 ? 'costGreen' : 'costRed'}>
                  {record.cost}
                </span>
            ),
        },
        {
            title: 'Selling Price',
            dataIndex: 'sellingPrice',
            className: 'sellingPrice',
            render: (_, record) => (
              <Input
                type="number"
                className="input_price"
                value={record.sellingPrice}
                onChange={(e) => sellingPriceChange(e, record.id,record.sellingPrice)}
                key={record.id}
                onClick={(e) => e.target.value = ''}
                onBlur={(e) => {
                    const formattedPrice = parseFloat( e.target.value).toFixed(2);
                    sellingPriceChange(e, record.id,formattedPrice);
                }}
              />
            ),
        },
    ];


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSave = () => {
        const selectedData = data.filter((item) => selectedRowKeys.includes(item.id));
        const obj = {
            product_type:2,
            size_data: selectedData,
            is_default: checked,
            name: text,
            parseInt:inputNumberValue
        };
        setSizesList(obj)

    };
    return (
      <>
          <div className="text-center mt-4">
              <h3 className="text-uppercase mb-4 header-title"> Add Units Price Studio</h3>
          </div>
          <div>
              <div style={{ margin: 16}}  className='inputshHader'>
                  <Row>
                      <Space ref={ref1} className='spane'>
                          <label className="textInput">Add your price list name</label>
                          <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
                          <Checkbox className="default_checkbox" checked={checkboxChecked}
                                    onChange={() => handleChecked()}>
                              <div className="default_text">Check if you want set your default price list</div>
                          </Checkbox>
                      </Space>
                  </Row>
                  <Row justify='space-between'>
                      <Space  ref={ref2} className='spane'>
                          <label className='textInput'>Price Adjustment %</label>
                          <Input type="number" className="input_price" value={inputNumberValue} onChange={handleInputChange}/>
                          {inputNumberValue > 0 ? (
                            <>
                                <Button className="btn_price" onClick={handlePriceChange}>Set</Button>
                                <Button className="btn_price" onClick={handleReset}>Reset</Button>
                            </>
                          ) : null}

                      </Space>
                      <Button ref={ref5} className="btn_price btn_save " st="true" onClick={handleSave}> Save </Button>
                  </Row>
              </div>
              <div className={"for-ref-left"} ref={ref3}></div>
              <div className={"for-ref-right"} ref={ref4}></div>
              <Table
                className='priceAddTable priceTable'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data.map((item) => ({...item, key: item.id}))}
                pagination={false}
                loading={loading}
              />
              <div className="divSave" >
                  <Button className="btn_price btn_save" st="true" onClick={handleSave}>
                      Save
                  </Button>
              </div>
          </div>
          {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
      </>
    );
};

export default AddUnitsStudio;
