import React, { useState } from 'react';
import { Input, Button, Popover } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

const CommentArea = ({ clickPercentX, clickPercentY, dataId, cancelComment, saveComment,showAllComments }) => {
    const [comment, setComment] = useState('');
    const [showComment, setShowComment] = useState(false);
    let showHide = "";
    if(showAllComments){
        showHide = "block";
    }else{
        showHide = "none";
    }

    const handleSaveComment = () => {
        let element = document.querySelector(`[data-id="${dataId}"]`);
        if (element) {
            let parentElement = element.parentNode;
            let parentWidth = parentElement.offsetWidth;
            let parentHeight = parentElement.offsetHeight;
            element.style.width = '30px';  // for example
            element.style.top = `${clickPercentY}%`;   // for example
            if (clickPercentX + (30 / parentWidth) * 100 > 100) {
                clickPercentX = 100 - (30 / parentWidth) * 100;
            }
            if (clickPercentY + (30 / parentHeight) * 100 > 100) {
                clickPercentY = 100 - (30 / parentHeight) * 100;
            }

            element.style.left = `${clickPercentX}%`;  // for example
            element.style.top = `${clickPercentY}%`;  // for example

        }
        saveComment(dataId, comment);
        setShowComment(true);

    };

    const handleShowComment = () => {
        let element = document.querySelector(`[data-id="${dataId}"]`);
        if (element) {
            let parentElement = element.parentNode;
            let parentWidth = parentElement.offsetWidth;
            let parentHeight = parentElement.offsetHeight;
            element.style.width = '200px';  // for example
            element.style.top = `${clickPercentY}%`;   // for example

            if (clickPercentX + (200 / parentWidth) * 100 > 100) {
                clickPercentX = 100 - (200 / parentWidth) * 100;
            }

            if (clickPercentY + (130 / parentHeight) * 100 > 100) {
                clickPercentY = 100 - (130 / parentHeight) * 100;
            }

            element.style.left = `${clickPercentX}%`;  // for example
            element.style.top = `${clickPercentY}%`;  // for example

        }
        setShowComment(!showComment);
    };
    // visible={showAllComments}
    return (
        <>
            {showComment ? (
                <>
                 <div className="message-orange" style={{display:showHide}}>
                     <div className="message-timestamp-right">{new Date().toLocaleString("en-US", {timeZone: "America/New_York", hour: '2-digit', minute:'2-digit'})}</div>
                     <p className="message-content">{comment}</p>
                 </div>
                <CommentOutlined className="comment-area-icon" onClick={handleShowComment} />
                </>
            ) : (
                <>
                    <Input.TextArea autoFocus rows={4} value={comment} onChange={(e) => setComment(e.target.value)} />
                    <Button className="mt5 fl" type="primary" onClick={handleSaveComment}>
                        Save
                    </Button>
                    <Button className="mt5 mrl15 fr" type="primary" onClick={() => cancelComment(dataId)} danger>
                        Remove
                    </Button>

                </>
            )}
        </>
    );
};

export default CommentArea;