import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, message, Modal } from "antd";
import { PlusCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import Lightbox from "react-18-image-lightbox";
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import { useSpring } from "@react-spring/web";
import { useFetching } from '../../../hoc/fetchingHook';
import clientAPI from '../../../api/api';
import LoadingSpin from '../../Utils/LoadingSpin';
import GalleryHeader from "./GalleryHeader";
import ImageItem from "./UtilsGallery/ImageItem";
import ShareButton from "./UtilsGallery/ShareButton";
import { clearLocalStorageItems } from "./UtilsGallery/_ClearLocalStorageItems";
import { HelperTabProvider } from "../../../context/HelperTabContext";
import { ClientChat } from "../../HelpChat/Chat";
import CheckoutOrder from "./CheckoutOrder";
import 'react-18-image-lightbox/style.css';
import styles from "./Gallery.module.css";
import { useFavoriteImages, useFolderDataList, useImages, useIsGalleryLoading, useIsLightboxLoading, useLightboxIsOpen, usePrivateImages, useSelectedFolder, useSelectedImages } from './GalleryHeader/store/selector';
import { useDispatch } from 'react-redux';
import { clearImages, setFolderDataList, setImages, setIsLightboxLoading, setLightBoxIsOpen, setSelectedFolder } from './GalleryHeader/store/galleryHeaderSlice';
import { GetFolderImages, GetGalleryFavoritePrivate, GetGalleryImages, GetShopingCard } from './GalleryHeader/store/thunk';
import { throttle } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
const IMG_SERVER = 'https://studioseye.com';
const H_THUMBNAILS = '/H_THUMBNAILS/';
const THUMBNAILS = '/THUMBNAILS/';

const GalleryImages = () => {


  const prevScrollY = useRef(0);
  const navigate = useNavigate();

  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [saveHeights, setSaveHeights] = useState([]);
  const [isLoadImage, setIsLoadImage] = useState(false)




  const [currentPage, setCurrentPage] = useState(1);

  const [orderNumber, setOrderNumber] = useState(null);
  const [openOrder, setOpenOrder] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);

  const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
  const [hideMenu, setHideMenu] = useState(false);
  const [favoriteOpen, setFavoriteOpen] = useState(false);

  const [filteredImage, setFilteredImage] = useState([]);



  const [privateOpen, setPrivateOpen] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);
  const [bgImages, setBgImages] = useState([]);
  const [selectedBg, setSelectedBg] = useState(null);
  const [studioInfo, setStudioInfo] = useState();
  const clientId = localStorage.getItem('client_id') || '';



  const images = useImages()
  const dispatch = useDispatch()
  const isGalleryLoading = useIsGalleryLoading()
  const lightboxIsOpen = useLightboxIsOpen()
  const isLightboxLoading = useIsLightboxLoading()
  const favoriteImages = useFavoriteImages()
  const folderDataList = useFolderDataList()
  const privateImages = usePrivateImages()
  const selectedImages = useSelectedImages()
  const selectedFolder = useSelectedFolder()




  const [getBackgrounds] = useFetching(async (studio_id) => {
    if (studio_id) {
      const { data: res } = await clientAPI.getClientBackgroundByStudioId(studio_id);
      if (res?.length > 0) {
        setBgImages(res);
      }
    }
  });

  const [getStudioInfo] = useFetching(async (id) => {
    const res = await clientAPI.getClientInfoByID(id);
    if (res) {
      setStudioInfo(res.data);
    }
  });







  const [addEventFolderImage] = useFetching(async (imageId, folderId) => {
    const { data: res } = await clientAPI.addEventFolderImage(imageId, folderId);
    if (res) {
      return res;
    } else {
      console.log('Cannot add Image');
    }
  });

  const [removeFolderImage] = useFetching(async (imageId, folderId) => {
    const { data: res } = await clientAPI.removeEventFolderImage(imageId, folderId);
    if (res) {
      return res;
    } else {
      console.log('Cannot remove Image');
    }
  });

  const removeFolderImageFnc = useCallback((img_id, folder_id) => {
    if (img_id) {
      const updatedFolderDataList = folderDataList.map((folder) => {
        if (folder.id === folder_id) {
          removeFolderImage(img_id, folder_id);
          const filteredImages = folder.images.filter((image) => image.ID !== img_id);
          return { ...folder, images: filteredImages };
        }
        return folder;
      });

      if (selectedFolder.id === folder_id) {
        const filteredSelectedImages = selectedFolder.images.filter((image) => image.ID !== img_id);
        dispatch(setSelectedFolder({
          ...selectedFolder,
          images: selectedFolder.images.filter((image) => image.ID !== img_id),
        }));


        // setSelectedFolder({
        //   ...selectedFolder,
        //   images: filteredSelectedImages,
        // });
      }
      dispatch(setFolderDataList(updatedFolderDataList))

      setClickedImage(null);
    }
  }, [folderDataList, selectedFolder]);

  const handleMenuClickHeader = useCallback((item) => {
    if (selectedImages && Array.isArray(selectedImages) && selectedImages.length > 0) {
      const updatedFolderDataListImages = folderDataList.map((folderData) => {

        if (folderData.id === item.id) {
          const newImages = [...(folderData.images || [])];
          selectedImages.forEach((image) => {
            const existingImage = newImages.find((existing) => existing.ID === image.ID);
            if (existingImage) {
              message.error(`This picture (${existingImage.IMAGE_NAME}) has already been added to this folder`);
            } else {
              addEventFolderImage(image.ID, folderData.id);
              newImages.push(image);
            }
          });
          return { ...folderData, images: newImages };
        }
        return folderData;
      });
      dispatch(setFolderDataList(updatedFolderDataListImages));
    }
  }, [selectedImages, folderDataList, addEventFolderImage]);









  const handlePlusCircle = (img) => {
    setClickedImage(img);
  };

  const handlePageChange = (page, take) => {
    setCurrentPage(page);
    dispatch(clearImages());
    const newOffset = (page - 1) * take;
    dispatch(GetGalleryImages({ offset: newOffset }))



  };





  const loadNewData = () => {
    setIsLoadImage(true);
    setOffset((prev) => prev + 50);

    dispatch(GetGalleryImages({ offset }))
      .unwrap()
      .then((res) => {

        setIsLoadImage(false);
      })
      .catch((e) => {
        setIsLoadImage(false);
        console.error(e);
      })
      .finally(() => {
        setIsLoadImage(false);
      });
  };


  useEffect(() => {
    if (isLoadImage) {
      const currentScrollHeight = window.scrollY || document.documentElement.scrollTop;
      setSaveHeights((prevHeights) => {

        if (prevHeights.some((entry) => entry.page === currentPage + 1)) return prevHeights;

        return [
          ...prevHeights,
          { page: currentPage + 1, height: currentScrollHeight }
        ];
      });

    }
  }, [isLoadImage, currentPage]);


  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;


      if (scrollPosition === 0 && currentPage !== 1) {
        setCurrentPage(1);
        return;
      }

      const nextPage = saveHeights.find((entry) => entry.page === currentPage + 1);
      const prevPage = saveHeights.find((entry) => entry.page === currentPage - 1);


      if (nextPage && scrollPosition > nextPage.height && currentPage !== nextPage.page) {
        setCurrentPage(nextPage.page);
      } else if (prevPage && scrollPosition < prevPage.height && currentPage !== prevPage.page) {
        setCurrentPage(prevPage.page);
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, saveHeights]);









  const goGalleryPage = () => {
    clearLocalStorageItems();
    navigate('/client/gallery_list');
    window.location.reload();
  };




  const fetchMoreImages = async () => {
    if (!isLightboxLoading && hasMore) {
      dispatch(setIsLightboxLoading(true))

      if (offset === 0) {
        setOffset(50);
      }
      dispatch(setIsLightboxLoading(false))

    }
  };


  const imageIndexCache = useMemo(() => {
    const folderImages = selectedFolder && Array.isArray(selectedFolder.images) ? selectedFolder.images : images;
    return folderImages.reduce((cache, image, index) => {
      cache[image.ID] = index;
      return cache;
    }, {});
  }, [images, selectedFolder]);

  const openLightbox = useCallback((ID) => {
    const index = imageIndexCache[ID];

    if (index !== undefined) {
      setCurrentImage(index);
      setLightboxImageIndex(index);
      dispatch(setLightBoxIsOpen(true))
    } else {

    }
  }, [imageIndexCache]);







  const imageSources = useMemo(() => {
    return selectedFolder && Array.isArray(selectedFolder.images)
      ? selectedFolder.images.map((img) => IMG_SERVER + img.IMAGE_LINK_PATH + H_THUMBNAILS + img.IMAGE_NAME)
      : images.map((img) => IMG_SERVER + img.IMAGE_LINK_PATH + H_THUMBNAILS + img.IMAGE_NAME);
  }, [selectedFolder, images]);

  const closeLightbox = () => {
    dispatch(setLightBoxIsOpen(false))
  };

  const breakpointColumnsObj = useMemo(
    () => ({
      default: 5,
      1300: 4,
      1100: 3,
      700: 2,
      500: 1,
    }),
    []
  );

  const handleCancel = () => {
    setOpenOrder(false);
  };

  useEffect(() => {
    if (lightboxIsOpen && currentImage === images.length - 1) {
      fetchMoreImages();
    }
  }, [lightboxIsOpen, currentImage, images.length]);



  useEffect(() => {
    if (studioInfo?.client_info.studio_id) {
      getBackgrounds(studioInfo.client_info.studio_id);
    }
  }, [studioInfo?.client_info.studio_id]);

  useEffect(() => {
    getStudioInfo(clientId);
    dispatch(GetGalleryImages({ offset }))
    dispatch(GetGalleryFavoritePrivate())
    dispatch(GetShopingCard())
    dispatch(GetFolderImages())


    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setHideMenu(currentScrollY > prevScrollY.current);
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl.split('?')[1]);

    if (urlParams.has('order_id')) {
      const orderId = urlParams.get('order_id');
      setOrderNumber(orderId);
      setOpenOrder(true);
    }
  }, []);

  const customButton1 = (
    <Button className={styles.inButtonAnticon}>
      <ShareButton initialImageUrl={imageSources[currentImage]} />
    </Button>
  );

  const customButton2 = (
    <Dropdown>
      <Button className={styles.inButtonAnticon}>
        <PlusCircleOutlined />
      </Button>
    </Dropdown>
  );

  const customButton3 = (
    <Button className={styles.inButtonAnticon} key={images[currentImage]}>
      <ShoppingCartOutlined />
    </Button>
  );

  const pageHeightsRef = useRef({});



  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;


      if (!pageHeightsRef.current[currentPage]) {
        pageHeightsRef.current[currentPage] = currentScrollY;
      }


      if (currentPage > 1 && currentScrollY <= pageHeightsRef.current[currentPage - 1]) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage]);


  const handleNextImage = () => {


    if (lightboxImageIndex < images.length - 1) {
      const newIndex = (lightboxImageIndex + 1) % imageSources.length;
      setCurrentImage(newIndex);
      setLightboxImageIndex(newIndex);


      if (newIndex === images.length - 2) {

        const nextPage = currentPage + 1;
        loadNewData(nextPage, 20);
      }
    }
  };


  const handlePrevImage = () => {
    const newIndex = (lightboxImageIndex + imageSources.length - 1) % imageSources.length;
    setCurrentImage(newIndex);
    setLightboxImageIndex(newIndex);
  }



  const navbarAnimation = useSpring({
    top: hideMenu ? '-100px' : '0px',
  });

  const handleScroll = (event) => {
    if (isGalleryLoading && event.deltaY > 0) {
      event.preventDefault();
    }
  };






  useEffect(() => {
    if (isGalleryLoading) {
      window.addEventListener('wheel', handleScroll, { passive: false });
    } else {
      window.removeEventListener('wheel', handleScroll);
    }


    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [isGalleryLoading]);



  if (images) {
    return (
      <>
        <HelperTabProvider>
          <GalleryHeader

            navbarAnimation={navbarAnimation}

            // addEventFolderImage={addEventFolderImage}
            filteredImage={filteredImage}
            setFilteredImage={setFilteredImage}
            goGalleryPage={goGalleryPage}

            favoriteOpen={favoriteOpen}
            setFavoriteOpen={setFavoriteOpen}
            privateOpen={privateOpen}
            setPrivateOpen={setPrivateOpen}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleMenuClickHeader={handleMenuClickHeader}
            bgImages={bgImages}
            studioInfo={studioInfo}
            setSelectedBg={setSelectedBg}

          />

          <InfiniteScroll
            className="InfiniteScroll"
            dataLength={(filteredImage && filteredImage.length > 0) ? filteredImage.length : images.length}
            scrollThreshold={0.9}
            next={() => loadNewData(currentPage + 1, 50)}
            hasMore={hasMore}
            loader={isGalleryLoading ? <LoadingSpin /> : null}
            endMessage={

              <b>You have seen it all</b>

            }
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {(
                (
                  selectedFolder && Array.isArray(selectedFolder.images)
                    ? selectedFolder.images
                    : favoriteOpen && Array.isArray(favoriteImages)
                      ? favoriteImages
                      : privateOpen && Array.isArray(privateImages)
                        ? privateImages
                        : Array.isArray(images)
                          ? images
                          : []
                ).map((img, index) => (
                  <ImageItem
                    index={index}
                    key={`${index}_${img.ID}`}
                    img={img}
                    currentImage={currentImage}
                    openLightbox={openLightbox}
                    imageSources={imageSources}
                    removeFolderImage={removeFolderImage}
                    selectedFolder={selectedFolder}
                    handlePlusCircle={handlePlusCircle}
                    removeFolderImageFnc={removeFolderImageFnc}
                    selectedBg={selectedBg}
                  />
                ))
              )}
            </Masonry>

            {lightboxIsOpen && (
              <Lightbox
                imageTitle={images[currentImage].IMAGE_NAME}
                mainSrc={imageSources[lightboxImageIndex]}
                nextSrc={
                  lightboxImageIndex === images.length - 1
                    ? undefined
                    : imageSources[(lightboxImageIndex + 1) % imageSources.length]
                }
                prevSrc={
                  imageSources[
                  (lightboxImageIndex + imageSources.length - 1) % imageSources.length
                  ]
                }
                onCloseRequest={closeLightbox}

                onMovePrevRequest={handlePrevImage}



                onMoveNextRequest={handleNextImage}

              />
            )}
          </InfiniteScroll>
          <span>
            <ClientChat clientInfo={studioInfo?.client_info} clientId={clientId} />
          </span>
          <Modal open={openOrder} onCancel={handleCancel} footer={false}><CheckoutOrder orderNumber={orderNumber} /></Modal>
        </HelperTabProvider>
      </>
    );
  }
  return null;
};

export default GalleryImages;
