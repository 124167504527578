import React from 'react';
import EditCardsLab from "../EditCardsLab";

const EditPhotoCardLab = () => {

  const nameCards = "Photo Cards";
  const navigate_link = '/labs/photo_card_list';
  const mainCardType = 4;

  return (
    <div>
      <EditCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};


export default EditPhotoCardLab;


