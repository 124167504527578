import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import StudioDashboard from "./components/Studios/StudioDashboard";
import Login from "./components/Login/Login";
import ClientSidebar from "./components/Studios/ClientSidebar/ClientSidebar";
import './App.css';
import LabDashboard from "./components/Lab/LabDashboard";
import ClientDashboard from "./components/Clients/ClientDashboard";
import GalleryImages from "./components/Clients/Gallery/GalleryImages";
import StaffDashboard from "./components/Staff/StaffDashboard";
import PhDashboard from "./components/Photographers/PhDashboard";
import Album from "./components/Clients/Album/Album";
import Prints from "./components/Clients/Prints/Main";
import AlbumMain from "./components/Clients/AlbumDesign/AlbumMain";
import CardMain from "./components/Clients/CardDesign/CardMain";
import StripeCheckout from "./components/Cart/Checkout/StripeCheckout";
import GeneratePDFButton from "./components/Cart/Checkout/GeneratePDF";



function App() {

  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/studio/:id' element={<StudioDashboard />} />
      <Route path='/client' element={<ClientSidebar />} />
      <Route path='/client/:id' element={<ClientDashboard />} />
      <Route path='/staff' element={<StaffDashboard />} />
      <Route path='/staff/:id' element={<StaffDashboard />} />
      <Route path='/ph/:id' element={<PhDashboard />} />
      <Route path='/ph' element={<PhDashboard />} />
      <Route path='/gallery_page' element={<GalleryImages />} />
      <Route path='/generate_pdf' element={<GeneratePDFButton />} />
      <Route path='/labs' element={<LabDashboard />} />
      <Route path='/labs/:id' element={<LabDashboard />} />
      <Route path='/album' element={<Album />} />
      <Route path='/albumdesign' element={<AlbumMain />} />
      <Route path='/carddesign' element={<CardMain />} />
      <Route path='/prints' element={<Prints />} />
      <Route path='/stripe-checkout' element={<StripeCheckout/>} />
    </Routes>
  );
}

export default App;
