import React, {useEffect,  useState} from 'react';
import {Button, Col, Form, Input, Row, Select} from "antd";
import styles from "../Contract/ContractAdd/Contract.module.css";
import clientAPI from "../../../../api/api";
import {useContractContext} from "../../../../context/ContactContext";
import LoadingSpin from "../../../Utils/LoadingSpin";

const AlbumPackage = ({form}) => {
    const [type, setType] = useState('');
    const [countOfImages, setCountOfImages] = useState('');
    const [albumDescription, setAlbumDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [lastId, setLastId] = useState(2000);
    const { formData,setFormData } = useContractContext();

    const fetchSizesAndDetails = async ( ) => {
        try {
            const sizesRes = await clientAPI.getProductObjectSizes(1, 'SIZE');
            if (sizesRes.data) {
                    const sizesBody = sizesRes.data.Album;
                    setData(sizesBody);
                    setLoading(false);

                };

        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchSizesAndDetails();
    }, []);

    const handleAddClick = () => {
        const sizeValue = form.getFieldValue('size');
        let title_name = "Album"
        let newId = 1;
        let newItemId = lastId + 1;
        const existingIds = new Set(formData?.size_data?.map(item => item.item_id));
        while (existingIds.has(newItemId)) {
            newItemId++;
        }
        setLastId(newItemId);
        if (formData.size_data && Array.isArray(formData.size_data)) {
            newId = formData.size_data.length > 0 ? Math.max(...formData.size_data.map(item => item.id)) + 1 : 1;
        }

        const newData = {
            id:newId,
            item_id:sizeValue,
            name:type + "  " + `number of images "${countOfImages}" ` + "  " +  albumDescription,
            quantity: 1,
            title_name:title_name,
            type:0,
            albumDescription: albumDescription,
            countOfImages:countOfImages
        };

        const updatedSizeData = [...(formData.size_data || []), newData];
        const updatedFormData = { ...formData, size_data: updatedSizeData };
        setFormData(updatedFormData);
    };
    if (loading) {
        return (
            <LoadingSpin>
                <div>Loading...</div>
            </LoadingSpin>
        );
    }
    return (
        <div>

            <Form.Item name="size" label="Size" rules={[{ required: true }]}>
                <Select
                    style={{ width: "100%" }}
                    showSearch
                    onChange={(value,option) => setType(option.children)}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                >
                    {data.map((detail) => (
                        <Select.Option style={{textAlign:"center"}} key={detail.ID} value={`${detail.width} x ${detail.height}`}>
                            {detail.width} x {detail.height}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
                <Row justify="space-between">
                    <Col span={24}>
                        <Form.Item label="Number Of Images:" name="countofimages">
                            <Input type="number" className={styles.contractInput}  onChange={(e) => setCountOfImages(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Album Description:" name="albumdescription" >
                    <Input className={styles.contractInput}  onChange={(e) => setAlbumDescription(e.target.value)}
                    />
                </Form.Item>

                <Form.Item name="referredBy">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className='formBtn send-email-btn'
                        onClick={handleAddClick}
                    > Add
                    </Button>
                </Form.Item>
        </div>
    );
};

export default AlbumPackage;