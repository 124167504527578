import {loginAPI} from "../api/api";
import Cookies from "universal-cookie";

const SET_IS_AUTH = 'SET_IS_AUTH';
const REMEMBER_ME = false;
const LOGIN_AND_SET_COOKIES = 'LOGIN_AND_SET_COOKIES';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const cookies = new Cookies();

let initialState = {
    userInfo: {
        token:'',
        username:'',
        user_id:'',
        user_type:'',
    },
    isAuth: false,
    error_message: '',
}
const loginReducer = (state = initialState,action) => {
    switch (action.type) {
        case LOGIN_AND_SET_COOKIES:
            return { ...state, userInfo: action.userInfo}
        case SET_IS_AUTH:
            return { ...state, isAuth: action.isAuth}
        case SET_ERROR_MESSAGE:
            return { ...state, error_message: action.error_message }
        default:
            return state;
    }
}
export const setUserInfoAC = (userInfo) => ({type:LOGIN_AND_SET_COOKIES,userInfo: userInfo});
export const setUserAuthAC = (isAuth) => ({type:SET_IS_AUTH,isAuth: isAuth});
export const setErrorMessage = (error_message) =>({type: SET_ERROR_MESSAGE, error_message: error_message})
export const getLoginForm = (data) => (dispatch) =>{
    loginAPI.getLoginInfo(data).then(response => {
        //SET COOKIES
        cookies.set('uid', response.data.user_id, { path: '/' });
        cookies.set('token', response.data.token, { path: '/' });
        cookies.set('user_type', response.data.user_type, { path: '/' });
        // SET DATA STATE
        dispatch(setUserInfoAC(response.data));
        dispatch(setUserAuthAC(true));
    }).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            dispatch(setErrorMessage(error.response.data.message))
        }
    });
}

export default loginReducer;

