import React from 'react';
import {Row, Col, Tooltip} from 'antd'; // Assuming you are using Ant Design
import styles from './Gallery.module.css';
import {useNavigate} from "react-router-dom";
import {clearLocalStorageItems} from "./UtilsGallery/_ClearLocalStorageItems";

const ClientStudioInfo = ({ studioInfo, clientInfo}) => {
    const clientId = localStorage.getItem('client_id') || '';
    const studio_id = localStorage.getItem('studio_id') || '';
    const eventName = localStorage.getItem('eventName') || '';
    const eventId = localStorage.getItem('event_id') || '';

    const navigate = useNavigate();

    const goGalleryPage = () => {
        clearLocalStorageItems()
        navigate('/client/gallery_list');
        window.location.reload()
    };  const goStudioPage = () => {
        clearLocalStorageItems()
        navigate('/studio/list_client');
        window.location.reload()
    };
    if (!studioInfo || !clientInfo) {
        return null;
    }

    return (
        <>
            {studio_id &&
              <Col className={styles.studioInfo}>
                  <Tooltip title="Go to back Studio">
                    <span onClick={() => goStudioPage()} className={styles.boxdrop}>
                        {studioInfo.studio_name} {studioInfo.lastname} /
                    </span>
                  </Tooltip>
              </Col>
            }
            <Col className={styles.studioInfo}>
                <Tooltip title="Go to back Gallery Page">
                    <span onClick={() => goGalleryPage()} className={styles.boxdrop}>
                      {clientInfo.firstname} {clientInfo.lastname }
                    </span>
                </Tooltip>
            </Col>
            {eventId && eventName  &&
              <Col className={styles.studioInfo}>
                  /  {eventName}
              </Col>
            }
        </>
    );
};

export default ClientStudioInfo;
