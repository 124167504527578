import React from 'react';
import Container from "react-bootstrap/Container";

import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row,  Select, Tooltip, Typography, message } from 'antd';
import { SolutionOutlined, DownOutlined } from "@ant-design/icons";
import { generateRandomUsername, handleGenerateUnamePass, handleTagChange, validateMessages } from '../../../Utils/isFunction';
import clientAPI from "../../../../api/api";
import {useFetching} from "../../../../hoc/fetchingHook";
import MoreInfo from "../../../Utils/MoreInfo";

const AddVenue = () => {
    
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [expand, setExpand] = React.useState(false);
    const randomUsername = generateRandomUsername(8);
    const handleClick = () => { handleGenerateUnamePass(form) }
    const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };

    const [createStaff, isStLoading, StError] = useFetching(async (staff_data) => {
        const { data: res } = await clientAPI.createStaff(staff_data);
        if (res) {
            console.log(res);
            let currentPath = '';
            const user_type = localStorage.getItem('user_type');
            if(user_type === "LAB"){
                currentPath = '/labs/staff_list';
            }else if(user_type === "STUDIO"){
                currentPath = '/studio/staff_list';
            }
            navigate(currentPath);
            window.location.reload();
            }
    });

    const onFinish = (values) => {
        createStaff(values);
    };

    return (
        <>
        <div className={'text-center mt-4'}>
            <h3 className={'text-uppercase mb-4 header-title'}>Add Venue Studio</h3>
        </div>
        <div className={'mt-4 album-notifications-select'}>
        <Form
            form={form}
            name="register"
            size={"large"}
            onFinish={onFinish}
            layout="vertical"
            style={{
                maxWidth: 600,
                margin: "auto"
            }}
            validateMessages={validateMessages}
            scrollToFirstError
            initialValues={{ state: 'New York' }}
        >
            <Form.Item
                name="email"
                label="Enter tags (email addresses)"
                rules={[
                    {
                        required: true,
                        message: 'Please enter at least one email address.',
                        type: 'array',
                    },
                ]}
            >
                <Select
                    mode="tags"
                    onChange={handleTagChangee}
                    style={{ width: '100%' }}
                    placeholder="Enter your (email addresses)"
                />
            </Form.Item>
            <Row justify='space-between'>
                <Col sm={21} xs={20}>
                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[
                          {
                              required: true,
                          },
                      ]}
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col sm={2} xs={3}  >
                    <Tooltip title="Generate Username and Password">
                        <Typography.Link onClick={handleClick}>
                            <SolutionOutlined className={"generate-uname-pass"}/>
                        </Typography.Link>
                    </Tooltip>

                </Col>
            </Row>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input.Password  value={randomUsername}/>
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password  value={randomUsername}/>
            </Form.Item>

                <Row justify="space-between">
                    <Col lg={24} md={24} span={24}>
                        <Form.Item
                            name="venuename"
                            label="Venuename"
                            rules={[{ required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            <Col className={"collapse-client"} lg={24} md={24} span={24}>
                <a onClick={() => { setExpand(!expand);}}>
                    <DownOutlined rotate={expand ? 180 : 0} /> More Details
                </a>
            </Col>
            {expand && (
                <MoreInfo />
            )}
            <Container className={'text-center'}>
                <Button type="primary" htmlType="submit"  className='send-email-btn' >
                    Create
                </Button>
            </Container>
        </Form>
        </div>
        </>
    );
};
export default AddVenue;