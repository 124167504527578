import React from 'react';
import { Row, Col, Space, Typography } from 'antd';
import images from './AlbumSidebarImages';
import {CloseCircleOutlined} from '@ant-design/icons';

function AlbumCameoSidebar({
                               setSidebarOpen,
                               isCameoOpen,
                               setCameoOpen,
                               showCameo,
                               setShowCameo,
}) {
    const { Text } = Typography;
    const goBack = () => {
        setSidebarOpen(true);
        setCameoOpen(false);
    };

    const setCameo = (type) => {
        setShowCameo(type);
    };

    return (
        <>
            <Row  gutter={16}>
                <Col lg={24} md={24} span={24}>
                <div onClick={goBack}><CloseCircleOutlined className="fs19"/> Back</div>
                </Col>
                    <Col lg={12} md={12} span={24}>
                        <img className="left-sidebar-covers p10" src={images[17].src} alt=""/>
                    </Col>

                    <Col lg={12} md={12} span={24}>
                        <p className="album-sidebar-image-title p10-b">Cameo</p>
                        <p className="album-sidebar-image-desc p10-b">$40.00</p>
                        <p></p>
                    </Col>

                <Row className="border-line-bottom">
                    <Col lg={24} md={24} span={24}>
                        <p className="album-sidebar-image-title p10">Types</p>
                        <Row>
                                    <Col lg={12} md={12} span={24}>
                                        <img
                                            className="left-sidebar-covers p10"
                                            src={images[21].src}
                                            alt=""
                                            style={showCameo === 'type1' ? {border: '1px solid #000'} : {}}
                                            onClick={() => setCameo('type1')}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} span={24}>
                                        <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                                    </Col>

                            <Col lg={12} md={12} span={24}>
                                <img
                                    className="left-sidebar-covers p10"
                                    src={images[22].src}
                                    alt=""
                                    style={showCameo === 'type2' ? {border: '1px solid #000'} : {}}
                                    onClick={() => setCameo('type2')}
                                />
                            </Col>
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>

                            <Col lg={12} md={12} span={24}>
                                <img
                                    className="left-sidebar-covers p10"
                                    src={images[25].src}
                                    alt=""
                                    style={showCameo === 'type3' ? {border: '1px solid #000'} : {}}
                                    onClick={() => setCameo('type3')}
                                />
                            </Col>
            
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>

                            <Col lg={12} md={12} span={24}>
                                <img
                                    className="left-sidebar-covers p10"
                                    src={images[26].src}
                                    alt=""
                                    style={showCameo === 'type4' ? {border: '1px solid #000'} : {}}
                                    onClick={() => setCameo('type4')}
                                />
                            </Col>
            
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>
                            <Col lg={12} md={12} span={24}>
                                <img
                                    className="left-sidebar-covers p10"
                                    src={images[27].src}
                                    alt=""
                                    style={showCameo === 'type5' ? {border: '1px solid #000'} : {}}
                                    onClick={() => setCameo('type5')}
                                />
                            </Col>
            
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>
                            <Col lg={12} md={12} span={24}>
                                <img
                                    className="left-sidebar-covers p10"
                                    src={images[28].src}
                                    alt=""
                                    style={showCameo === 'type6' ? {border: '1px solid #000'} : {}}
                                    onClick={() => setCameo('type6')}
                                />
                            </Col>
            
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>
                            <Col lg={12} md={12} span={24}>
                                <img
                                    className="left-sidebar-covers p10"
                                    src={images[29].src}
                                    alt=""
                                    style={showCameo === 'type7' ? {border: '1px solid #000'} : {}}
                                    onClick={() => setCameo('type7')}
                                />
                            </Col>
            
                            <Col lg={12} md={12} span={24}>
                                <p className="album-sidebar-image-title p25-10-0-10">Selected Frame</p>
                            </Col>

                        </Row>
                    </Col>

                </Row>

            </Row>
        </>
    );
}

export default AlbumCameoSidebar;