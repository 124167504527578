import { Select, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import style from "../Template.module.css";
import clientAPI from "../../../../api/api";
import { useFetching } from "../../../../hoc/fetchingHook";

const SelectPrice = () => {

    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);

    const handleItemSelect = (newSelectedItem) => {
        setSelectedItem(newSelectedItem);
    };

    const [getSizesList, getSizesLoading, getSizesError] = useFetching(async () => {
        const { data: res } = await clientAPI.getProductObjectSizes(5, "SIZE");
        if (res) {
            const body = res.Print;
            const newData = body.map((item) => ({
                id: item.ID,
                name: `${item.width} x ${item.height}`,
            }));
            setData(newData);
        }
    });

    useEffect(() => {
        getSizesList();
    }, []);

    return (
      <div>
          <Form.Item
            label="Print Size"
            name="size_id"
             rules={[
                 {
                     required: true,
                     message: "Please choose Print Size",
                 },
             ]}
          >
              <Select
                className={style.selectedPrice}
                placeholder="Select Print Size"
                value={selectedItem}
                onChange={handleItemSelect}
              >
                  {getSizesLoading ? (
                    <Select.Option className={style.selectedOption} key="loading" value="loading">
                        <Spin />
                    </Select.Option>
                  ) : (
                    data.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        className={style.selectedOption}
                      >
                          {item.name}
                      </Select.Option>
                    ))
                  )}
              </Select>
          </Form.Item>
      </div>
    );
};

export default SelectPrice;
