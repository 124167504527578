import {  HomeOutlined,} from "@ant-design/icons";

export const labItems = [
  {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: 'Dashboard',
  },
  {
      key: 'Lab1',
      icon: <HomeOutlined />,
      label: 'Lab1',
  },
  {
      key: 'Lab2',
      icon: <HomeOutlined />,
      label: 'Lab2',
  },
  {
      key: 'Lab3',
      icon: <HomeOutlined />,
      label: 'Lab3',
  },
  {
      key: 'Lab4',
      icon: <HomeOutlined />,
      label: 'Lab4',
  },
  {
      key: 'Lab5',
      icon: <HomeOutlined />,
      label: 'lab5',
  },
  

];