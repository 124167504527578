import axios from "axios";

const token = localStorage.getItem("token");


const request = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "https://dev.studioseye.com/api/",
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});


export default request;
