import { createRoot } from 'react-dom/client';
import React, { useRef,useState,useEffect } from "react";
import HTMLFlipBook from 'react-pageflip';
import { v4 as uuidv4 } from 'uuid';
import CommentArea from "../../Utils/CommentArea";
import {Button, Col,Row} from "antd";


function MyBook({ selectedCover, showCameo, textProperties,  setActivePage,setLastPage}) {

    const flipBookRef = useRef(null);
    const [showAllComments, setShowAllComments] = useState(false);
    const [areCommentsVisible, setAreCommentsVisible] = useState(false);
    const [showCovers, setShowCovers] = useState(false);
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            const divElement = divRef.current;
            const parentElement = divElement.parentElement;
            const rect = divElement.getBoundingClientRect();
            const parentRect = parentElement.getBoundingClientRect();


        }
    }, [divRef]);

    const toggleCovers = () => {
        setShowCovers(!showCovers);
    }
    const isLastPage = () => {
        if (flipBookRef.current) {
            const currentPageIndex = flipBookRef.current.pageFlip().getCurrentPageIndex();
            const totalPages = flipBookRef.current.pageFlip().getPageCount();
            return currentPageIndex === totalPages - 1;  // Indices are usually 0-based
        }
        return false;
    };
    const nextPage = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipNext();
        }
    };

    const prevPage = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipPrev();
        }
    };
    const handleOnFlip = (e) => {
        console.log(isLastPage());
        if(e.data || e.data === 0){
            setActivePage(e.data);
            setLastPage(isLastPage());
        }
    };



    const toggleComments = () => {
        setAreCommentsVisible(!areCommentsVisible);
        if (areCommentsVisible) {
            handleHideAllComments();
        } else {
            handleShowAllComments();
        }
    };

    const handleShowAllComments = () => {
        let comments = document.getElementsByClassName('message-orange');
        for(let i = 0; i < comments.length; i++) {
            comments[i].style.display = 'block';
        }
        setShowAllComments(true)
    };

    const handleHideAllComments = () => {
        let comments = document.getElementsByClassName('message-orange');
        for(let i = 0; i < comments.length; i++) {
            comments[i].style.display = 'none';
            setShowAllComments(false)
        }
    };



    const cancelComment = (dataId) => {
        const commentContainer = commentContainers[dataId];
        if (commentContainer) {
            commentContainer.parentNode.removeChild(commentContainer);
            delete commentContainers[dataId];
        }
    };

    const saveComment = (e) => {
     
    };

    const generateUniqueId = () => {
        // Generate a unique ID using your preferred method
        // For example, using the `uuid` package as mentioned earlier
        const uniqueId = uuidv4();
        return uniqueId;
    };
    const commentContainers = {};
    const handlePageClick = (pageIndex, clickEvent) => {
        const commentId = generateUniqueId();
        const dataId = `${pageIndex}_${commentId}`;
        // console.log(pageIndex);
        if (flipBookRef.current && flipBookRef.current.pageFlip()) {
            const containerRect = flipBookRef.current.pageFlip().block.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;
            const clickedX = clickEvent.pageX - containerRect.left;
            const clickedY = clickEvent.pageY - containerRect.top;

            let clickPercentX = 0;
            let clickPercentY = 0;
            if(pageIndex % 2 === 0){
                clickPercentX = (clickedX / (containerWidth / 2)) * 100;
                clickPercentY = (clickedY / containerHeight) * 100;

            }else{

            let clickCurrentX = (containerWidth - clickedX);
                clickPercentX = 100 - ((clickCurrentX / (containerWidth / 2)) * 100);
                clickPercentY = (clickedY / containerHeight) * 100;
            }
            const pageDiv = clickEvent.currentTarget;
            const commentContainer = document.createElement('div');
            commentContainer.setAttribute('data-id', dataId);
            commentContainer.className = 'comment-area';
            createRoot(commentContainer).render(
                <CommentArea
                    clickPercentX={clickPercentX}
                    clickPercentY={clickPercentY}
                    dataId={dataId}
                    cancelComment={cancelComment}
                    saveComment={saveComment}
                    showAllComments={showAllComments}
                />,
            );
            commentContainer.addEventListener('click', (event) => {
                event.stopPropagation(); // Prevent event propagation to handlePageClick
            });
            const pageWidth = pageDiv.offsetWidth;
            const pageHeight = pageDiv.offsetHeight;
            const commentAreaWidth = 200; // Adjust the width of the comment area as needed
            const commentAreaHeight = 130; // Adjust the width of the comment area as needed
            if (clickPercentX + (commentAreaWidth / pageWidth) * 100 > 100) {
                clickPercentX = 100 - (commentAreaWidth / pageWidth) * 100;
            }

            if (clickPercentY + (commentAreaHeight / pageHeight) * 100 > 100) {
                clickPercentY = 100 - (commentAreaHeight / pageHeight) * 100;
            }


            commentContainer.style.left = `${clickPercentX}%`;
            commentContainer.style.top = `${clickPercentY}%`;

            pageDiv.appendChild(commentContainer);
            commentContainers[dataId] = commentContainer;
        }
    };
    const getRatioStyle = (ratio) => {
        switch(ratio) {
            case '5x5':
                return 'ratio-5x5';
            case '5x7':
                return 'ratio-5x7';
            case '8x10':
                return 'ratio-8x10';
            case '10x8':
                return 'ratio-10x8';
            default:
                return 'ratio-5x5';
        }
    }

    const getTextPositionClass = (type) => {
        switch (type) {
            case 'CENTER':
                return 'center-stamping';
            case 'BOTTOM-RIGHT':
                return 'bottom-right-stamping';
            case 'BOTTOM-CENTER':
                return 'bottom-center-stamping';
            default:
                return '';
        }
    }


    const albumClass ='';// getRatioStyle('5x5');
    return (
        <Row>
        <Col className={`p50 ${albumClass}`} lg={24} md={24} span={24}  style={{ display: 'flex', justifyContent: 'center' }}>
            {!showCovers ?
                (
            <HTMLFlipBook
                ref={flipBookRef}
                showCover={true}
                size="stretch"
                width={100}
                height={100}
                maxShadowOpacity={0}
                className="flip-book-main"
                useMouseEvents={false}
                onFlip={(e) => handleOnFlip(e)}
            >
                {/*onClick={(e) => handlePageClick(1, e)}*/}
                {/*START Front Cover*/}
                <div  className="page" data-density="hard">
                    <img
                        src={selectedCover}
                        alt="Cover Image"
                        className="album-left-cover-image"
                    />
                    {showCameo === 'type1' && <div className="cameo-type-one">
                        <img
                            src={"http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"}
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />

                    </div>}
                    {showCameo === 'type2' && <div className="cameo-type-two">
                        <img
                            src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />
                    </div>}
                    {showCameo === 'type3' && <div className="cameo-type-tree">
                        <img
                            src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />
                    </div>}
                    {showCameo === 'type4' && <div className="cameo-type-four">
                        <img
                            src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />
                    </div>}
                    {showCameo === 'type5' && <div className="cameo-type-five">
                        <img
                            src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />
                    </div>}
                    {showCameo === 'type6' && <div className="cameo-type-six">
                        <img
                            src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />
                    </div>}
                    {showCameo === 'type7' && <div className="cameo-type-seven">
                        <img
                            src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                            alt="Cover Image"
                            className="album-left-cameo-image"
                        />
                    </div>}


                    <div
                        ref={divRef}
                        className={`stamping-content ${getTextPositionClass(textProperties.position.type)}`}
                        style={{
                            color: textProperties.color,
                            fontSize: textProperties.fontSize,
                            fontFamily: textProperties.fontFamily,
                            fontStyle: textProperties.fontStyle
                        }}
                    >
                        {textProperties.content}

                    </div>
                </div>
                {/*END Front COVER*/}
                {/*START ENDLEAVES*/}
                <div onClick={(e) => handlePageClick(2, e)} className="page" data-density="hard">
                    <img
                        src="https://cdn.dragdrop.design/album/assets/endleaf/white_textured_endleaves.jpg"
                        alt="Cover Image"
                        className="album-left-page-image"
                        style={{
                             backgroundImage: `url(${selectedCover})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </div>
                <div onClick={(e) => handlePageClick(3, e)} className="page" data-density="hard">
                    <img
                        src="https://cdn.dragdrop.design/album/assets/endleaf/white_textured_endleaves.jpg"
                        alt="Cover Image"
                        className="album-right-page-image"
                        style={{
                             backgroundImage: `url(${selectedCover})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </div>
                {/* END ENDLEAVES*/}

                <div onClick={(e) => handlePageClick(4, e)} className="page page-content" data-density="hard">
                    <img
                        src="http://tricolorimage.com/wp-content/uploads/2015/04/2.jpg"
                        alt="Cover Image"
                        className="album-left-page-image"
                        style={{
                             backgroundImage: `url(${selectedCover})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </div>

                <div onClick={(e) => handlePageClick(5, e)} className="page page-content" data-density="hard">
                    <img
                        src="http://tricolorimage.com/wp-content/uploads/2015/04/2-1.jpg"
                        alt="Cover Image"
                        className="album-right-page-image"
                        style={{
                             backgroundImage: `url(${selectedCover})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </div>


                <div onClick={(e) => handlePageClick(6, e)} className="page page-content" data-density="hard">
                    <img
                        src="http://tricolorimage.com/wp-content/uploads/2015/04/3-2.jpg"
                        alt="Cover Image"
                        className="album-left-page-image"
                        style={{
                             backgroundImage: `url(${selectedCover})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </div>
                <div onClick={(e) => handlePageClick(7, e)} className="page page-content" data-density="hard">
                    <img
                        src="http://tricolorimage.com/wp-content/uploads/2015/04/3-1.jpg"
                        alt="Cover Image"
                        className="album-right-page-image"
                        style={{
                            backgroundImage: `url(${selectedCover})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </div>

                {/*START Back Cover*/}
                <div onClick={(e) => handlePageClick(8, e)} className="page" data-density="hard">
                    <img
                        src={selectedCover}
                        alt="Cover Image"
                        className="album-front-right-cover-image"
                    />
                </div>
                {/*END back COVER*/}
            </HTMLFlipBook>
            ) :
            (
                <>
            <HTMLFlipBook
                size="stretch"
                width={100}
                height={100}
                maxShadowOpacity={0}
                className="flip-book-main"
                useMouseEvents={false}
            >
                {/*START Front Cover*/}
                <div className="page" data-density="hard">
                    <img
                        src={selectedCover}
                        alt="Cover Image"
                        className="album-front-left-cover-image2"
                    />
                </div>
                {/*END Front COVER*/}

                {/*START Back Cover*/}
                <div className="page" data-density="hard">
                    <div style={{
                        left:0,
                        height:"100%",
                        width:"10%",
                        backgroundImage: `url(${selectedCover})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }} className="corner">
                    </div>
                    <img
                        src={selectedCover}
                        alt="Cover Image"
                        className="album-front-right-cover-image2"
                    />
                </div>
                {/*END back COVER*/}

            </HTMLFlipBook>
                </>
                )}
            </Col>

                <Col className="p50" lg={24} md={24} span={24}  style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button className="mt5 album-btn" type="primary" onClick={prevPage}>
                        Previous
                    </Button>
                    <Button className="mt5 mrl15 album-btn" type="primary" onClick={nextPage}>
                        Next
                    </Button>
                    <Button className="mt5 mrl15 album-btn" type="primary" onClick={toggleComments}>
                        {areCommentsVisible ? 'Hide All Comments' : 'Show All Comments'}
                    </Button>
                    <Button className="mt5 mrl15 album-btn" type="primary" onClick={toggleCovers}>
                        {!showCovers ? 'Show Front and Back' : 'Front Page'}
                    </Button>


                </Col>
        </Row>
    )
}


export default MyBook;