export const menuHelper = {
    getParentLabel(items, childKey){
        for (const item of items) {
            if (item.key === childKey) {
                return item.label;
            }
            if (item.children) {
                const parentLabel = menuHelper.getParentLabel(item.children, childKey);
                if (parentLabel) {
                    return item.label;
                }
            }
        }
        return null;
    },
    getMenuLabel(items, childKey){
        for (const item of items) {
            if (item.key === childKey) {
                return item.label;
            }
            if (item.children) {
                const parentLabel = menuHelper.getMenuLabel(item.children, childKey);
                if (parentLabel) {
                    return parentLabel;
                }
            }
        }
        return null;
    },
}