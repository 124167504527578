import React, {useEffect, useRef, useState} from 'react';
import {Col, Collapse, Row, Table, Tour} from 'antd';
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientAPI from "../../../../../api/api";
import {useHelperTab} from "../../../../../context/HelperTabContext";

const CreatePackage = ({pdfId,helperShow,setHelperShow,setRefNext,setRefPrevious,contractList}) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const [packages, setPackages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedRowOffer, setSelectedRowOffer] = useState([]);
    const [priceListData, setPriceListData] = useState([]);
    const [totalSum, setTotalSum] = useState(0);

    const { helperTab } = useHelperTab();
    const [open, setOpen] = useState(helperTab);
    useEffect(() => {
        if (typeof setHelperShow === 'function') {
            if (!open) { setHelperShow(false); }
        }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }
    }, [helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    useEffect(() => {
        setRefPrevious(ref2);
        setRefNext(ref3);
    }, [ref2,ref3]);
    const steps= [
        {
            title: 'Select Packages From Dropdown Menu ',
            description: 'This dropdown menu is a crucial tool for selecting packages while drafting a contract. It offers a comprehensive list of available packages that you can include in your contract. These packages might vary based on the services or products offered and can cater to different needs and preferences',
            target: () => ref1.current,
        },{
            title: 'Previous to Contract Selection',
            description: 'This dropdown menu is a crucial tool for contract while drafting a contract. It offers a comprehensive list of available packages that you can include in your contract. These packages might vary based on the services or products offered and can cater to different needs and preferences',
            target: () => ref2.current,
        },
        {
            title: 'Proceed to Terms and Conditions ',
            description: 'The "Next" button in this context is specifically designed to take you to the Terms and Conditions step of your process. After completing the current section, which might involve contract details, package selection, or terms and conditions, clicking this button signifies that you are ready to move forward to the next crucial phase: setting up the Payment Schedule',
            target: () => ref3.current,
        },

    ];

    const [getPackages, addLoading, addError] = useFetching(async () => {
        const {data: res} = await clientAPI.getPackageList();
        if (res) {
            setPackages(res);
        }
    });

    const calculateTotalSum = () => {
        const sum = priceListData.reduce((acc, priceListData) => acc + priceListData.total, 0);
        setTotalSum(sum.toFixed(2));
        localStorage.setItem('contract_total',sum.toFixed(2))
    };

    const [getPCategory, catLoading, catError] = useFetching(async () => {
        const {data: res} = await clientAPI.getPackageCategory();
        if (res) {
            setCategories(res);
        }
    });

    const columns = [
        {
            title: 'Package Name',
            dataIndex: 'package_name',
            key: 'package_name',
        },
        {
            title: 'Package Items',
            dataIndex: 'title_name',
            key: 'title_name',
            align:'center',
            render: (_, record) => (
                <span>
        {record.size_data && record.size_data.map(item => (
            <div style = {{borderBottom:'1px solid #6d6868' }} key={item.id}>{item.title_name}  {item.name}</div>
        ))}
      </span>
            ),
        },
        {
            title:'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align:'center',
            render: (_, record) => (
                <span>
        {record.size_data && record.size_data.map(item => (
            <div style = {{borderBottom:'1px solid #6d6868'}} key={item.id}>{item.quantity}</div>
        ))}
      </span>
            ),
        },

    ];
    const onChange = (selectedRowKeys, selectedRows, categoryId) => {
        // Update selected rows for the current category
        const newSelectedRowOffer = { ...selectedRowOffer, [categoryId]: selectedRowKeys };
        setSelectedRowOffer(newSelectedRowOffer);

        // Aggregate selections from all categories
        const newPriceListData = categories.reduce((acc, category) => {
            const selectedIds = newSelectedRowOffer[category.id] || [];
            const selectedItems = packages.filter(pkg => selectedIds.includes(pkg.id));
            return [...acc, ...selectedItems];
        }, []);

        setPriceListData(newPriceListData);
    };


    const categoriesWithPackages = categories.map(selectedCategory => {
        const data = packages.filter(item => item.package_category.includes(selectedCategory.id));

        const rowSelection = {
            selectedRowKeys: selectedRowOffer[selectedCategory.id] || [],
            onChange: (selectedRowKeys, selectedRows) => {
                onChange(selectedRowKeys, selectedRows, selectedCategory.id);
            },
        };

        return {
            key: selectedCategory.id,
            label: `${selectedCategory.name} (${data.length})`,
            showArrow: !!data.length,
            children: data.length > 0 ? (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                    rowKey={(record) => record.id}
                />
            ) : null
        };
    });


    useEffect(() => {
        getPackages();
        getPCategory();
    }, []);



    useEffect(() => {
        const storedData = localStorage.getItem('contract_packages');
        if (pdfId !== undefined) {
            const dataContractList = contractList;
            const selectedContract = dataContractList.find(item => item.contract_data.id === pdfId);

            if (selectedContract) {
                const contractPackages = selectedContract.contract_packages || [];

                setPriceListData(contractPackages)

            }
        }else if(storedData) {
            const parsedData = JSON.parse(storedData);
            const selectedMapping = {};
            let allSelectedItems = [];

            // Use a Set to track which items have been added
            const addedItemIds = new Set();

            categories.forEach(category => {
                const categoryData = parsedData.filter(item => {
                    return item.package_category.includes(category.id) && !addedItemIds.has(item.id);
                });

                if (categoryData.length > 0) {
                    selectedMapping[category.id] = categoryData.map(item => item.id);
                    categoryData.forEach(item => addedItemIds.add(item.id));
                    allSelectedItems = [...allSelectedItems, ...categoryData];
                }
            });

            setSelectedRowOffer(selectedMapping);
            setPriceListData(allSelectedItems);
        }
    }, [packages, categories]);




    useEffect(() => {
        // Only update localStorage if priceListData is not empty
        if (priceListData && priceListData.length > 0) {
            localStorage.setItem('contract_packages', JSON.stringify(priceListData));
            calculateTotalSum();
        }
    }, [priceListData]);



    return (
        <>

            <div className={'text-center mt-4'}>
                <h3 className={'text-uppercase mb-4 header-title'}>Choose Packages</h3>
            </div>
            <div className={'mt-4 album-notifications-select'} >
                <Row>
                <Col ref={ref1} lg={12} md={12} span={24}>
                {categoriesWithPackages.map(catItem => (
                    <Col key={`cat-item-${catItem.key}`} lg={24} md={24} span={24} className="mt-1">
                        <Collapse
                            collapsible={!!catItem.children ? '' : 'disabled'}
                            items={[catItem]}
                            accordion
                            onChange={onChange}
                        />
                    </Col>

                ))}
               </Col>
                <Col lg={12} md={12} span={24}>
                    <Table
                        rowKey={(record) => record.id}
                        className="priceListTable"
                        columns={columns}
                        dataSource={priceListData}
                        pagination={false}
                        style={{ maxHeight: '300px', overflowY: 'auto' }}
                    />
                    <div style={{marginTop: '16px', fontWeight: 'bold'}}>
                        Total in $
                        <span style={{ width: "100px",textAlign:"center",marginLeft:"10px", color:'#000' }}>{totalSum} </span>
                    </div>
                </Col>
                </Row>
            </div>
            {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
        </>
    );
};

export default CreatePackage;
