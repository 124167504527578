import React, { useState, useEffect,useRef } from 'react';
import {Checkbox, Col, Form, Row, Tour} from 'antd';
import { usePolicyContext } from '../../../../../context/PolicyContext';
import 'react-quill/dist/quill.snow.css';
import clientAPI from '../../../../../api/api';
import LoadingSpin from "../../../../Utils/LoadingSpin";
import styles from './Contract.module.css'
import {useHelperTab} from "../../../../../context/HelperTabContext";


const OurPolicy = ({setRefNext,setRefPrevious}) => {
  const isWindowWidthGreaterThan577 = window.innerWidth > 577;
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [editorHtml, setEditorHtml] = useState('');
  const [submittedContent, setSubmittedContent] = useState('');
  const {policyData, setPolicyData } = usePolicyContext();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const { data: res } = await clientAPI.showStudioTerms();
      if (res) {
        setLoading(false);
        setData({
          id: res.id,
          name: res.name,
          content: res.content,
        });
        setEditorHtml(res.content);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: data.name
    });
  }, [data, form]);

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      const updatedData = {
        name: values.policy,
        content: editorHtml
      };
      setSubmittedContent(editorHtml);
      setPolicyData(updatedData);
    });
  };

  const { helperTab } = useHelperTab();
  const [open, setOpen] = useState(helperTab);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  useEffect(() => {
    setRefPrevious(ref2);
    setRefNext(ref3);
  }, [ref2,ref3]);
  const steps= [
    {
      title: 'Terms and Conditions',
      description: 'This feature provides you with the flexibility to update and modify the Terms and Conditions as per your current requirements and policies. To access and change your Terms and Conditions, simply navigate to the settings menu located on the left side of your interface',
      target: () => ref1.current,
    },{
      title: 'Previous to Package Selection',
      description: 'This dropdown menu is a crucial tool for selecting packages while drafting a contract. It offers a comprehensive list of available packages that you can include in your contract. These packages might vary based on the services or products offered and can cater to different needs and preferences',
      target: () => ref2.current,
    },
    {
      title: 'Proceed to Payment Schedule ',
      description: 'The "Next" button in this context is specifically designed to take you to the Payment Schedule step of your process. After completing the current section, which might involve contract details, package selection, or terms and conditions, clicking this button signifies that you are ready to move forward to the next crucial phase: setting up the Payment Schedule',
      target: () => ref3.current,
    },
  ];


  if (loading) {
    return (
      <LoadingSpin>
        <div>Loading...</div>
      </LoadingSpin>
    );
  }
  return (
    <div className="mt-4">
      <div className="text-center">
        <h3 className="text-uppercase mb-4 header-title">Terms and Conditions</h3>
      </div>
      <Form
        ref={formRef}
        form={form}
        style={{ margin:'0 auto', background:'#fff',  padding:'20px'}}
        name="ReactQuill"
        size="large"
        layout="vertical"
      >
        <Row justify="space-between" className="mb-4">
          <Col span={24}>
            <Form.Item  name="name" initialValue={data?.content}>
              <p>{data?.name}</p>

            </Form.Item>
          </Col>
        </Row>
        <Row ref={ref1} justify="space-between" className="mb-4">
          <Col span={24}>
            <Form.Item name="content" initialValue={data?.content} >
              <div className={styles.ourPolicy}>
                <div dangerouslySetInnerHTML={{ __html: data?.content }} />
              </div>
            </Form.Item>

            <Form.Item
              name="checked"
              valuePropName="checked"
            >
             <span style={{padding:'10px'}} > <Checkbox>I agree with your Terms and Conditions</Checkbox></span>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
    </div>

  );
};

export default OurPolicy;
