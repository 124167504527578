import React, { useState,useEffect } from 'react';
import {Button, Checkbox, Col, Input, message, Row, Space, Table} from 'antd';
import Container from 'react-bootstrap/Container';
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {useNavigate} from 'react-router';
import {useFetching} from "../../../../../hoc/fetchingHook";
import clientsAPI from "../../../../../api/api";


const AlbumsEdit = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [data, setData] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [fromValue, setFromValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentId, setCurrentId] = useState(1);
  const [text, setText] = useState("");
  const [checked, setChecked] = useState("NO");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const columns = [
    {
      title: 'Size',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Album from / to prices',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (_, record, index) => renderInputs(_, record, index),
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      align: 'center',
      render: (_, record) => (
        <>
          <Row>
            <Col span={24}>
              {!record.isNew && (
                <PlusSquareOutlined className='my_ant_icons_table fz-40 ' onClick={() => handleAddRow(record.name,record.key,record.id,record.dataIndex)} />

              )}
            </Col>
            <Col span={24}>
              {record.isNew && !selectedRowKeys.includes(record.key) && (
                <MinusSquareOutlined  className='my_ant_icons_table fz-40' onClick={() => handleDeleteRow(record.key)} />
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const [getSizesList, getSizesLoading, getSizesError] = useFetching(async () => {
    const { data: res } = await clientsAPI.getProductObjectSizes(2, "SIZE");
    if (res) {
      const sizesData = res["Flat Cards"];
      setSizes([...sizesData]);

      const initialData = sizesData.map((item, index) => ({
        key: index,
        id: item.ID,
        name:`${item.width} x ${item.height}`,
        type: { from: '', to: '', price: '', discountPrice: '' },
        isNew: false,
      }));

      setData(initialData);
    }
  });

  const fetchSizesAndDetails = async () => {
    try {
      const sizesRes = await clientsAPI.getProductObjectSizes(2, 'SIZE');
      const headerRes = JSON.parse(localStorage.getItem('albumData'));

      if (sizesRes.data && headerRes) {
        setText(headerRes.name);
        setCheckboxChecked(headerRes.is_default === 'YES');

        const selectedIds = headerRes.albumData.map((item) => item.itemID);
        console.log(selectedIds,'headerRes.albumData')
        setSelectedRowKeys(selectedIds);
        setChecked(headerRes.is_default);
        const sizesData = sizesRes.data["Flat Cards"];
        setSizes(sizesData)
        console.log(sizesData,'sizesData')
        console.log(headerRes,'headerRes')
        const size = sizesData.find((size) => size.ID === selectedIds.itemID);
        return {
          id: size.itemID,
          name: `${size.width} x ${size.height}`,
          from: size.from.toString(),
          to: size.to.toString(),
          price: size.price.toString(),
          isFromAPI: true,

        };
      }
    } catch (error) {
      console.error('Error fetching sizes and details', error);
    }
  };

    useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        await getSizesList();
      } catch (error) {
        console.error('Error fetching sizes', error);
      } finally {
        setLoading(false);
      }
    }
    localStorage.setItem('card_type','1');
      fetchSizesAndDetails();
    fetchData();
  }, []);

  const handleInputChange = (key, columnIndex, field, value) => {
    setData((prevData) =>
      prevData.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            type: {
              ...row.type,
              [field]: value,
            },
          };
        }
        return row;
      })
    );
  };

  const renderInputs = (text, record, columnIndex) => {
    const fromValue = parseFloat(record?.type?.from || '') || '';
    const toValue = parseFloat(record?.type?.to || '') || '';
    const priceValue = parseFloat(record?.type?.price || '') || '';
    const discountPriceValue = parseFloat(record?.type?.discountPrice || '' || '') || '';
    const isInvalid = toValue !== '' && fromValue >= toValue;

    const error = () => {
      message.error(`Cannot enter a value less than ${fromValue}`);
    };

    return (
      <Row>
        <Col span={6}>
          <Input
            value={fromValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'from', e.target.value)}
            placeholder="From"
            type="number"
            style={{ padding: '5px 0', width: '70%', textAlign: 'center', borderColor: isInvalid ? 'red' : '', }}

          />
        </Col>
        <Col span={6}>
          <Input
            value={toValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'to', e.target.value)}
            placeholder="To"
            type="number"
            style={{ padding: '5px 0', width: '70%', textAlign: 'center' }}
            onClick={isInvalid ? null : error}
          />
        </Col>
        <Col span={6}>
          <Input
            value={priceValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'price', e.target.value)}
            placeholder="Price"
            type="number"
            style={{ padding: '5px 0', width: '70%', textAlign: 'center' }}
          />
        </Col>
        <Col span={6}>
          <Input
            value={discountPriceValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'discountPrice', e.target.value)}
            placeholder="Discount Price"
            type="number"
            style={{ padding: '5px 0', width: '70%', textAlign: 'center' }}

          />
        </Col>
      </Row>
    );
  };

  const handleAddRow = (selectedSize, currentIndex, id) => {
    const newData = [...data];

    const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
      if (item.id === id) {
        return index;
      }
      return lastIndex;
    }, -1);

    const newKey = lastIndexWithSameId === -1 ? currentIndex + 1 : newData[lastIndexWithSameId].key + 1;

    const newRow = {
      key: newKey,
      id: id,
      name: selectedSize,
      type: { from: '', to: '', price: '', discountPrice: '' },
      isNew: true,
    };

    newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);

    const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);
    const typeTo = parseFloat(previousRow.type?.to);

    newData.forEach((item, index) => {
      item.key = index;
      if (previousRow && index === lastIndexWithSameId + 1) {
        newData[index].type.from = typeTo + 1;
      }
    });

    setFromValue(typeTo + 1);

    setData(newData);
  };

  const handleDeleteRow = (rowKey) => {
    if (data.length <= 1) {
      return;
    }
    const newData = data.filter((item) => item.key !== rowKey);
    setData(newData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleSubmit = async () => {

    const albumData = data.filter((item) => selectedRowKeys.includes(item.key))
      .map((item) => ({
        name: item.name,
        itemID: item.id,
        type: {
          from: item.type?.from || '',
          to: item.type?.to || '',
          price: item.type?.price || '',
          discountPrice: item.type?.discountPrice || '',
        },
      }));

    const albumDataBD = {
      id: currentId,
      is_default: checked,
      name: text,
      albumData: albumData,
    };
    setCurrentId(currentId+1)
    try {
      setData(albumDataBD);

      console.log(albumDataBD, 'albumDataBD');
      // localStorage.setItem('albumData', JSON.stringify(albumDataBD));
      // navigate('/studio/albums_list');
      window.location.reload()
    } catch (err) {
      console.error("Error creating card:", err);
    }
  };

  useEffect(() => {
    checkboxChecked ?  setChecked("YES") :  setChecked("NO")
  }, [checkboxChecked]);

  const handleChecked = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleInputChangeText = (e) => {
    setText(e.target.value);
  };

  return (
    <div>
      <Row>
        <Space  className='spane'>
          <label className="textInput">Price list name</label>
          <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
          <Checkbox className="default_checkbox" checked={checkboxChecked}
                    onChange={() => handleChecked()}>
            <div className="default_text">Check if you want set your default price list</div>
          </Checkbox>
        </Space>
      </Row>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.key}
      />
      <Container className={'text-center'}>
        <Button onClick={handleSubmit} type="primary" htmlType="submit" className="send-email-btn">
          Submit
        </Button>
      </Container>
    </div>
  );
};

export default AlbumsEdit;