const CHECK_USER_TYPE = 'CHECK_USER_TYPE';
const SET_USER_INFO = 'SET_USER_INFO';
let initialState = {
    userData:{},
}


const dashboardReducer = (state = initialState ,action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return { ...state, userInfo: action.userInfo }
        default:
            return state;
    }
}

export const setUserInfoAC = (userData) => ({type:SET_USER_INFO,userData: userData});

export const initStateFromParams = (params) =>(dispatch) =>{
    dispatch(setUserInfoAC(params.userInfo));
}


export default dashboardReducer;

