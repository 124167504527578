import { useFetching } from "./fetchingHook";
import React, { useEffect, useState } from 'react';
import clientAPI from "../api/api";

const IsAuth = () => {
    const [authUser, setAuthUser] = useState(false);
    const [checkIsAuth] = useFetching(async () => {
        const response = await clientAPI.checkIsAuth();
        if (response.data && response.data.message === "success") {
            setAuthUser(true);
        } else {
            setAuthUser(false);
        }
    });

    useEffect(() => {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("user_id");
        const user_type = localStorage.getItem("user_type");
        if (token && user_id && user_type) {
            checkIsAuth();
        } else {
            setAuthUser(false);
        }
    }, []);

    return authUser;
};

export default IsAuth;