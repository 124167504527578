import React, { useEffect, useState } from 'react';
import {Col,Form, Row, Select, Spin } from "antd";
import style from "./CreateFolder.module.css";
import clientAPI from "../../../../api/api";

const SelectedAlbumPackage = ({setSizeId}) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);


    const handleItemSelect = (newSelectedItem) => {
        const size_id= data.find((item) => item.name === newSelectedItem)?.id || null;
        setSizeId(size_id);
        setSelectedItem(newSelectedItem);
    };

    const fetchSizesAndDetails = async () => {
        try {
            const sizesRes = await clientAPI.getProductObjectSizes(1, 'SIZE');
            if (sizesRes.data) {
                const sizesBody = sizesRes.data.Album;
                if (sizesBody) {
                    setLoading(false);
                    const combinedData = sizesBody.map((size) => ({
                        id: size.ID,
                        name: `${size.width} x ${size.height}`,
                        ID: size.ID
                    }));
                    setData(combinedData);
                    return combinedData;
                }
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSizesAndDetails();
    }, []);

    return (
      <Row justify="space-between" className='mb10'>
          <Col span={24} className='mb10'>
                <Form.Item name="selected_size" label="Size" rules={[{ required: true }]} >
                    <Select
                        className={style.selectedAlbum}
                        onChange={handleItemSelect}
                        placeholder="Select your Album Size"
                        value={selectedItem}
                    >
                        {loading ? (
                            <Select.Option className={style.selectedOption} key="loading" value="loading">
                                <Spin />
                            </Select.Option>
                        ) : (
                            data.map((detail) => (
                                <Select.Option style={{ textAlign: "center" }} key={detail.ID} value={detail.name}>
                                    {detail.name}
                                </Select.Option>
                            ))
                        )}
                    </Select>
                </Form.Item>
          </Col>
      </Row>
    );
};

export default SelectedAlbumPackage;
