import React, {useState,useEffect} from 'react';
import clientAPI from "../../../api/api";
import MoreInfo from "../../Utils/MoreInfo";
import EditPassword from '../../Utils/EditPassword';
import LoadingSpin from "../../Utils/LoadingSpin";
import Container from "react-bootstrap/Container";
import { Button, Col, Form, Input, message, Row, Select} from 'antd';
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFetching } from "../../../hoc/fetchingHook";
import { handleTagChange, validateMessages } from '../../Utils/isFunction';

const EditStudio = () => {

    const [data, setData] = useState();
    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };

    const [updateUserInfo, isUserLoading, UserError] = useFetching(async (current_user_id, values) => {
        const {data: res} = await clientAPI.updateUserInfo(current_user_id, values);
        if (res) {
            const currentPath = '/labs/studio_list';
            navigate(currentPath);
            window.location.reload();
        }
    });

    const onFinish = (values) => {
        const current_user_id = localStorage.getItem('studio_id');
        updateUserInfo(current_user_id,values);

    };

    const [fetchStudioData, isDataLoading, dataError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getStudioByID(studio_id);
        if (res) {
            setData(res);
        }
    });

    useEffect(() => {
        const studio_id = localStorage.getItem('studio_id');
        fetchStudioData(studio_id);

    }, []);


    if(isDataLoading){
        return (
            <>
                <LoadingSpin />
            </>
        );
    }else{
        return(
            <>
                <div className={'text-center mt-4'}>
                    <h3 className={'text-uppercase mb-4 header-title'}>Edit Studio</h3>
                </div>
                <div className={'mt-4 album-notifications-select'}>
                    <Form
                        form={form}
                        name="validate_other"
                        onFinish={onFinish}
                        size={"large"}
                        layout="vertical"
                        style={{
                            maxWidth: 600,
                            margin: "auto"
                        }}
                        validateMessages={validateMessages}
                        initialValues={data}
                    >
                        <Form.Item
                            name="studio_name"
                            label="Studio Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Enter tags (email addresses)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter at least one email address.',
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                onChange={handleTagChangee}
                                style={{width: '100%'}}
                                placeholder="Enter your (email addresses)"
                            />
                        </Form.Item>

                        <Row>
                            <Col lg={24} md={24} span={24}>
                                <Form.Item
                                    name="username"
                                    label="Username"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="space-between">
                            <Col lg={11} md={11} span={24}>
                                <Form.Item
                                    name="firstname"
                                    label="Firstname"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col lg={11} md={11} span={24}>
                                <Form.Item
                                    name="lastname"
                                    label="Lastname"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Col className={"collapse-client"} lg={24} md={24} span={24}>
                            <EditPassword />
                        </Col>
                        <Col className={"collapse-client"} lg={24} md={24} span={24}>
                            <a onClick={() => { setExpand(!expand);}}>
                                <DownOutlined rotate={expand ? 180 : 0} /> More Details
                            </a>
                        </Col>
                        {expand && (
                            <MoreInfo />
                        )}
                        <Container className={'text-center'}>
                            <Button type="primary" htmlType="submit" className='send-email-btn'>
                                Update
                            </Button>
                        </Container>
                    </Form>
                </div>
            </>
        )
    }




};
export default EditStudio;