import { createAsyncThunk } from "@reduxjs/toolkit";
import { setFavoritePrivate, setFolderDataList, setImages, setShopingCard } from "./galleryHeaderSlice";
import request from "../../../../../service";


const user_id = localStorage.getItem("user_id");
const studio_id = localStorage.getItem("studio_id");
const client_id = localStorage.getItem("client_id");
const event_id = localStorage.getItem("event_id");
const current_user_id = localStorage.getItem("current_user_id");
const user_type = localStorage.getItem('user_type')
const event_name = localStorage.getItem('eventName')


const GetGalleryImages = createAsyncThunk(
  "get/gallery/images",
  async ({ offset, }, { dispatch }) => {
    try {
      const response = await request.post("get_event_images", {
        event_id,
        user_id,
        offset,
        take: 50,
      });
      dispatch(setImages(response.data));
      return response.data;
    } catch (error) {
      console.error("Failed to fetch images:", error);
      throw error;
    }
  }
);


const GetGalleryFavoritePrivate = createAsyncThunk(
  "get/gallery/favorite/private",
  async (_, { dispatch }) => {
    const folderTypes = ["Favorite", "Private"];

    const responses = await Promise.all(
      folderTypes.map((folder_type) =>
        request.post("get_favorite", {
          folder_type,
          client_id,
          event_id,
          user_id,
          studio_id,
        })
      )
    );

    responses.forEach((response, index) => {
      dispatch(
        setFavoritePrivate({
          folder_type: folderTypes[index],
          data: response.data,
        })
      );
    });
  }
);


const AddGalleryFavorite = createAsyncThunk(
  "add/gallery/favorite",
  async ({type,method,id }, { rejectWithValue }) => {
    try {
      const response = await request.post("add_favorite", {
     
        folder_type: type,
        method_mode: method,
        client_id,
        event_id,
        user_id,
        studio_id,
        image_id:id
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : "Something went wrong"
      );
    }
  }
);

const GetShopingCard = createAsyncThunk(
  "get/shopping/card",
  async (_, { dispatch }) => {  
    const response = await request.post("shopping_cart_get", {
      user_id,
      status: 0,
      current_user_id,
     
      ShoppingCartInfo:{
        user_type,
        event_id,
        client_id,
        studio_id
      }

    });
     
      
      dispatch(setShopingCard(response.data.data))
    return response.data; 
  }
);


 const GetFolderImages = createAsyncThunk(
  "folder/images",
  async (_, { dispatch }) => {
    const response = await request.post("get_event_folders_images", {
      client_id,
      event_id,
      user_id,
      studio_id,
    });
    dispatch(setFolderDataList(response.data));
  }
);


export {
  GetGalleryImages,
  GetGalleryFavoritePrivate,
  AddGalleryFavorite,
  GetShopingCard,
  GetFolderImages

}