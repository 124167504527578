// PasswordVisibilityToggle.js
import React, { useState } from 'react';
import './Login.css'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const PasswordVisibilityToggle = ({ onToggle }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
    onToggle && onToggle(!visible);
  };

  return (
    <span className="password-toggle" onClick={toggleVisibility}>
      {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
    </span>
  );
};

export default PasswordVisibilityToggle;
