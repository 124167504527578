import React, {useEffect, useRef, useState} from 'react';
import { Table, Col, Form, Input,Row, Select, Collapse, InputNumber,Button, Space, Divider, Tour, Modal} from "antd";
import Container from "react-bootstrap/Container";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import styles from "../Contract/ContractAdd/Contract.module.css";
import {useContractContext} from "../../../../context/ContactContext";
import PriceDefault from "./PriceDefault";
import {useNavigate} from "react-router-dom";
import AlbumPackage from "./AlbumPackage";
import CardPackage from "./CardPackage";
import {showDeleteConfirm, sortColumns} from "../../../Utils/isFunction";
import Downloads from "./Downloads";
import clientAPI from "../../../../api/api";
import {useFetching} from "../../../../hoc/fetchingHook";
import UnitDefault from "./UnitDefault";
import style from "../../../Clients/Gallery/CreateFolder/CreateFolder.module.css";
import getColumnSearchProps from "../../../Utils/getColumnSearchProps";
import TextArea from "antd/es/input/TextArea";
import {useHelperTab} from "../../../../context/HelperTabContext";

let index = 0;
const {Panel} = Collapse;
const AddPackage = ({setHelperShow,helperShow}) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const { helperTab } = useHelperTab();

    const [open, setOpen] = useState(helperTab);
    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }
    }, [helperShow]);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    const steps = [
        {
            title: 'Select Type',
            description: 'Select Type" feature offers two distinct choices for configuring your offerings: "Package" and "Add-On". This functionality allows for greater flexibility and customization in how you structure and price your services or products.' +
                'When you select \'Package\', you are working with a predefined set of services or products bundled together.\n' +
                'This option also allows you to associate existing add-ons with the package. These add-ons can enhance the package, offering additional value to the client.\n' +
                'The package is priced as a whole, incorporating the value of both the core package and any selected add-ons.\n' +
                'Selecting \'Add-On\':\n' +
                '\n' +
                'Choosing \'Add-On\' lets you specify individual services or products that can be added to a package.\n' +
                'Here, you have the option to set two prices: the \'Total Price\' and the \'Add-On Price\':\n' +
                'Total Price: This is the standalone price of the add-on if purchased separately.\n' +
                'Add-On Price: This is a special, usually discounted price for the add-on when it is bought in conjunction with a package.\n' +
                'For instance, if an add-on has a Total Price of $200 but an Add-On Price of $100, clients who purchase it along with a package will only pay the Add-On Price of $100, offering them a compelling incentive to opt for the package deal',
            target: () => ref1.current,
        },
        {
            title: 'Package Category',
            description: 'The "Package Category" feature allows for efficient categorization and organization of your packages. This tool is essential for streamlining the selection process, whether you are setting up a new package or categorizing existing services' +
                'Selecting from Existing Categories:\n' +
                '\n' +
                'Utilize the dropdown menu to view and choose from a list of pre-existing package categories.\n' +
                'These categories might include types such as "Weddings", "Corporate Events", "Birthdays", etc., depending on your service range.\n' +
                'Selecting an existing category helps in quickly associating the package with a specific service type or client need, ensuring it is easily searchable and correctly grouped.\n' +
                'Adding a New Category:\n' +
                '\n' +
                'If the package you are creating or updating does not fit into any existing category, the dropdown menu also provides an option to add a new category.\n' +
                'This feature ensures that you can continuously expand and adapt your category list to accommodate new types of packages or evolving services.\n' +
                'Adding a new category helps in keeping your offerings organized and up-to-date, making it easier for clients to find exactly what they are looking for',
            target: () => ref2.current,
        },
        {
            title: 'Setting Image Limits',
            description: 'Customization for Different Packages:\n' +
                '\n' +
                'The number of images can vary between packages, allowing you to offer a range of options to suit different client needs and price points. For example, a basic package might include 10 images, while a premium package offers 30.\n' +
                'This customization enables you to tailor your packages more precisely and cater to a diverse clientele.',
            target: () => ref3.current,
        },
        {
            title: 'Add ',
            description: 'Services:\n' +
                '\n' +
                'Here, you can select specific services such as photography, videography, etc. For instance, you might choose a photographer and specify the duration of service, like \'2 hours of photography\'.\n' +
                'This option allows for precise allocation of service resources and clear client expectations.\n' +
                'Print:\n' +
                '\n' +
                'In the Print category, you can select different sizes and types of print materials. This could range from small prints like \'4x6\' to larger formats.\n' +
                'It offers clients a variety of choices to fit their preferences for physical photo prints.\n' +
                'Card:\n' +
                '\n' +
                'This option lets you choose different aspects of card production, including the card type, paper type, and size.\n' +
                'It’s ideal for creating customized invitation cards, thank you cards, and other similar products.\n' +
                'Album:\n' +
                '\n' +
                'For albums, the selection can include the size of the album, the type of cover, and the number of pages.\n' +
                'This allows for complete customization of photo albums to suit different tastes and requirements.\n' +
                'Downloads:\n' +
                '\n' +
                'This category might include options for digital downloads, like high-resolution images or video files.\n' +
                'It caters to clients who prefer digital copies of their photographs or videos.\n' +
                'Units:\n' +
                '\n' +
                'In Units, you can select from options like photo frames, canvases, or other physical units that house or display photographs.\n' +
                'This offers additional products that complement the primary services.',
            target: () => ref4.current,
        },
        {
            title: 'Save and Personalize',
            description: 'Save and Personalize Your Price List: Once you\'ve finalized your pricing, hit \'Save\' to add these details to your price list. This action locks in your carefully selected prices. After saving, you have the flexibility to assign different items from this list to specific clients. This feature allows for tailored pricing strategies, ensuring that each client receives a customized selection that suits their needs and your business goals. It\'s all about giving you control over your pricing, client by client.',
            target: () => ref5.current,
        },
    ];
    const {Option} = Select;
    const formRef = useRef(null);
    const [form] = Form.useForm();
    const {formData, setFormData} = useContractContext();
    const [data, setData] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [lastId, setLastId] = useState(1000);
    const [categories, setCategories] = useState([]);
    const [nameCategory, setNameCategory] = useState('');
    const inputRef = useRef(null);
    const [totalChangedManually, setTotalChangedManually] = useState(false);
    const [manualTotalValue, setManualTotalValue] = useState(null);
    const [printDefaultId, setPrintDefaultId] = useState(null);
    const [unitDefaultId, setUnitDefaultId] = useState(null);
    const [printType, setPrintType] = useState(1);
    const [unitType, setUnitType] = useState(2);
    const [packageType, setPackageType] = useState('Package');
    const [specialOffers, setSpecialOffers] = useState([]);
    const [selectedRowOffer, setSelectedRowOffer] = useState([]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let imagePositions = [...Array(20).keys()].map((_, index) => {
        let value = (index + 1).toString();
        return {
            label: value,
            value: value,
        };
    });
    imagePositions.push({ label: "Unlimited", value: "Unlimited" });
    const [getStudioDefaultPrint, printDefaultLoading, printDefaultError] = useFetching(async (printType) => {
        const {data: res} = await clientAPI.getPrintPriceStudioDefault(printType);
        if (res) {
            setPrintDefaultId(res.id);
        }
    });

    const [removePkgCat, removeLoading, removeError] = useFetching(async (cat_id) => {
        const {data: res} = await clientAPI.removePackageCategory(cat_id);
        if (res) {
            console.log(res);
        }
    });

    const [getStudioDefaultUnits, unitDefaultLoading, unitDefaultError] = useFetching(async (unitType) => {
        const {data: res} = await clientAPI.getPrintPriceStudioDefault(unitType);
        if (res) {
            setUnitDefaultId(res.id);
        }
    });

    const [getPCategory, catLoading, catError] = useFetching(async () => {
        const {data: res} = await clientAPI.getPackageCategory();
        if (res) {
            setCategories(res);
        }
    });

  const [getPAddOn, addLoading, addError] = useFetching(async () => {
        const {data: res} = await clientAPI.getPackageAddOn();
        if (res) {
            setSpecialOffers(res);
        }
    });


    const [savePackage, packageLoading, packageError] = useFetching(async (pkg) => {
        const {data: res} = await clientAPI.savePackage(pkg);
        if (res) {
            navigate('/studio/list_package')
            window.location.reload();
        }
    });

    const onNameCategoryChange = (event) => {
        setNameCategory(event.target.value);
    };


    const addItemCategory = async (e) => {
        if (nameCategory.trim() === '') {
            return;
        }

        try {
            // API call to add a new category
            const { data: newCategory } = await clientAPI.addPackageCategory(nameCategory);

            setCategories([...categories, newCategory]);
            setNameCategory('');
        } catch (error) {
            console.error("Error adding category:", error);
        } finally {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    };


    const handleTotalValueChange = (value) => {
        const parsedValue = parseFloat(value) || 0.00;
        setManualTotalValue(parsedValue);
        setTotalChangedManually(true);
    };

    const navigate = useNavigate()
    const handleAddButtonClick = () => {
        let newId = formData.size_data?.length > 0 ? Math.max(...formData.size_data.map(item => item.id)) + 1 : 1;
        const existingIds = new Set(formData?.size_data?.map(item => item.item_id));
        let newItemId = lastId + 1;
        while (existingIds.has(newItemId)) {
            newItemId++;
        }
        setLastId(newItemId);

        const newItem = {
            id: newId++,
            type: 0,
            title_name: selectedType,
            item_id: newItemId,
            name:form.getFieldValue('typeDescription'),
            quantity: 1,

        };
        if (formData?.size_data) {
            const zeroCostItem = formData.size_data.find(item => item.type === 0 && item.cost === 0);
            if (zeroCostItem) {
                newItem.initialCost = zeroCostItem.initialCost;
            }
            const updatedFormData = {
                ...formData,
                size_data: [...formData.size_data, newItem],
            };
            setFormData(updatedFormData);
        }
        else{
            formData.size_data = [];
            const updatedFormData = {
                ...formData,
                size_data: [...formData.size_data, newItem],
            };
            setFormData(updatedFormData);
        }
    };

    const handleQuantityChange = (event, id) => {
        const newQuantity = event;

        const updatedData = formData.size_data.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    quantity: newQuantity,
                };
            }
            return item;
        });

        setData(updatedData);

        const updatedFormData = {
            ...formData,
            size_data: updatedData,
        };

        setFormData(updatedFormData);
    };


    const handleDelete = (id,) => {
        showDeleteConfirm(id ,'item').then((result) => {
            if (result) {
                const updatedSizeData = formData.size_data.filter((e) => e.id !== id);
                const updatedFormData = {
                    ...formData,
                    size_data: updatedSizeData,
                };
                setFormData(updatedFormData);
            } else {
            }
        })
    }

    const handleDeleteCategory = (id) => {
        showDeleteConfirm(id ,'item').then((result) => {
            if (result) {
                removePkgCat(id);
                const updatedCatData = categories.filter((e) => e.id !== id);
                setCategories(updatedCatData);
            }
        })
    }



    const deleteEmptyData = (data) => {
        const filteredData = {};

        for (const [key, value] of Object.entries(data)) {
            if (value.length > 0) {
                filteredData[key] = value;
            }
        }
        if (Object.keys(filteredData).length === 0) {
            return null;
        }
        return filteredData;
    }

    const ServicePackege = () => {
        const [items1, setItems1] = useState(['Photographer']);
        const [name, setName] = useState('');
        const handleSelectChange = (value) => {
            setSelectedType(value);
        };
        const addItem = (e) => {
            e.preventDefault();
            setItems1([...items1, name || `New item ${index++}`]);
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        };
        const onNameChange = (event) => {
            setName(event.target.value);
        };
        return (
            <div>
                <Col span={24} >
                 <Form.Item name="type" label="Service" rules={[{required: true,}]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare(
                                (optionB?.label ?? '').toLowerCase()
                            )
                        }
                        allowClear
                        className="Select_Delete"
                        onChange={handleSelectChange}
                        placeholder="Type"
                        style={{
                            width:"100%"
                        }}

                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                    <Input
                                        placeholder="Please enter item"
                                        ref={inputRef}
                                        value={name}
                                        onChange={onNameChange}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                        Add Service
                                    </Button>
                                </Space>
                            </>
                        )}
                        options={items1.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                    />

                </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Service Description:" name="typeDescription">
                        <Input className={styles.contractInput}/>
                    </Form.Item>
                </Col>

                <Col span={24} className='mt-4'>
                    <Form.Item name="referredBy">
                        <Button
                            type="primary"
                            // htmlType="submit"
                            className='formBtn send-email-btn'
                            onClick={handleAddButtonClick}
                        > Add
                        </Button>
                    </Form.Item>
                </Col>

            </div>
        );
    };

    const handleCreateButtonClick = () => {
        const newPackageInfo = {
            package_type: packageType,
            package_name: form.getFieldValue('packageName'),
            package_category: form.getFieldValue('packageCategory'),
            max_image_pos: form.getFieldValue('maxImagePos'),
            description: form.getFieldValue('description'),
            total:manualTotalValue !== null ? manualTotalValue.toFixed(2) : '',
            total_add_on:form.getFieldValue('totalAddOn'),
            size_data: formData && formData.size_data ? formData.size_data : null,
            package_category_offers: deleteEmptyData(selectedRowOffer),

        };

        savePackage(newPackageInfo);

    };

    const categoryCol = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            width: 200,
            render: (_, record) => (
                <div>
                    {record.name}
                </div>
            ),
        },
        {
            key: 'actions',
            title: 'Delete',
            width: 80,
            render: (_, record) => (
                <DeleteOutlined
                    className="my_ant_icons_table"
                    onClick={() => handleDeleteCategory(record.id)}
                />
            ),
        }
    ]
    const columns = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            width: 200,
            render: (_, record) => (
                <div>
                    {record.title_name + "  " + record.name}
                </div>
            ),
        },
        {
            title: 'Count',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 50,
            render: (_, record) => (
                <div>
                    <InputNumber
                        style={{width: "50px"}}
                        min={1}
                        value={record.quantity}
                        onChange={(e) => handleQuantityChange(e, record.id, record)}
                    />
                </div>
            ),
        },
        {
            key: 'actions',
            title: 'Actions',
            width: 80,
            render: (_, record) => (
                <DeleteOutlined
                    className="my_ant_icons_table"
                    onClick={() => handleDelete(record.id)}
                />
            ),
        }
    ];

    const columnsOffer = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            width: 200,
            render: (_, record) => (
                <div>
                    {record.package_name}
                </div>
            ),
        },
        {
            title: 'Add-on Items',
            dataIndex: 'title_name',
            key: 'title_name',
            ...getColumnSearchProps("title_name"),
            sorter: sortColumns('title_name'),
            sortDirections: ['descend', 'ascend'],
            align:'center',
            render: (_, record) => (
                <span>
        {record.size_data && record.size_data.map(item => (
            <div style = {{borderBottom:'1px solid #6d6868' }} key={item.id}>{item.title_name}  {item.name}</div>
        ))}
      </span>
            ),
        },
    ];
    const items = [
        {
            key: '1',
            label: 'Services',
            children: <ServicePackege/>,

        },
        {
            key: '2',
            label: 'Print',
            children: <PriceDefault product_type="1" parent_id={printDefaultId} form={form}/>
        },
        {
            key: '3',
            label: 'Card',
            children: <CardPackage parent_id={printDefaultId} form={form}/>
        },
        {
            key: '4',
            label: 'Album',
            children: <AlbumPackage form={form} />
        },
        {
            key: '5',
            label: 'Downloads',
            children: <Downloads form={form}/>
        },
        {
            key: '6',
            label: 'Units',
            children: <UnitDefault product_type="2" parent_id={unitDefaultId} form={form}/>
        },
    ];

    const categoriesWithSpecialOffers = categories.map(selectedCategory => {
        const data = specialOffers.filter(item => item.package_category.includes(selectedCategory.id));
        return {
            key: selectedCategory.id,
            label: selectedCategory.name,
            showArrow: !!data.length,
            children: data.length > 0 ? <Table
                rowSelection={{
                    onChange: (newSelectedRowOffer) => {
                        setSelectedRowOffer({ ...selectedRowOffer, [selectedCategory.id]: newSelectedRowOffer });
                    },
                }}
                className="categoriesWithSpecialOffersTable"
                columns={columnsOffer}
                dataSource={data} pagination={false}
                style={{maxHeight: '300px', overflowY: 'auto'}}
                rowKey={(record) => record.id}
                key={(record) => record.id}
            /> : null
        };
    }) || [];

    useEffect(() => {
        getStudioDefaultPrint(printType);
        getStudioDefaultUnits(unitType);
        setFormData({});
        getPCategory();
        getPAddOn();
    }, []);

    const onChange = (key) => {
        console.log(key);
    };
    return (
        <>
            <div className={'text-center mt-4'}>
                <h3 className={'text-uppercase mb-4 header-title'}> Add Package </h3>
                <div className={'mt-4 album-notifications-select'} >
                    <Form
                        initialValues={{ packageType: 'Package' }}
                        ref={formRef}
                        form={form}
                        name="validate_other"
                        onFinish={(values) => {  return false; }}
                        size="large"
                        layout="vertical"
                        style={{
                            maxWidth: 1300,
                            margin: 'auto',
                        }}
                    >
                        <Row justify="space-between">
                            <Col lg={9} md={9} span={24} >
                                <span ref={ref1}>
                                    <Form.Item name="packageType" label="Package Type" rules={[{required: true}]}>
                                        <Select
                                            className={style.selectedCard}
                                            showSearch
                                            onChange={setPackageType}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "").toLowerCase().localeCompare(
                                                    (optionB?.label ?? "").toLowerCase()
                                                )
                                            }
                                            value={packageType}
                                        >
                                            {[
                                                {
                                                    label: "Package",
                                                    value: "Package",
                                                },
                                                {
                                                    label: "Add-on",
                                                    value: "Add-on",
                                                },
                                            ].map((option) => (
                                                <Select.Option key={option.value} value={option.value}
                                                               className={style.selectedOption}>
                                                    {option.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </span>
                                <span ref={ref2}>
                                    <Form.Item name="packageCategory" label="Package Category" rules={[{required: true}]}>
                                        <Select
                                          mode={"multiple"}
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            (option?.label ?? '').includes(input)
                                          }
                                          filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare(
                                              (optionB?.label ?? '').toLowerCase()
                                            )
                                          }
                                          allowClear
                                          className="Select_Delete"
                                          onChange={(value) => setSelectedCategories(value)}
                                          placeholder="Package Category"
                                          style={{
                                              width:"100%"
                                          }}
                                          dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', }}/>
                                                <Space style={{ padding: '0 8px 4px', }} >
                                                    <Input
                                                      placeholder="Please enter category name"
                                                      ref={inputRef}
                                                      value={nameCategory}
                                                      onChange={onNameCategoryChange}
                                                    />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={addItemCategory}>
                                                        Add New Category
                                                    </Button>
                                                </Space>
                                            </>
                                          )}
                                          options={categories.map((item) => ({
                                              label: item.name,
                                              value: item.id,
                                          }))}
                                        />

                                    </Form.Item>
                                    <div className={"w100"}><a className={"remove_category_link"} onClick={showModal}>Remove Category</a></div>
                                </span>


                                <Form.Item label="Package Name:" name="packageName">
                                    <Input className={styles.contractInput}/>
                                </Form.Item>
                                <span ref={ref3}>
                                    <Form.Item name="maxImagePos" label="Image Positions" rules={[{required: true}]}>
                                    <Select
                                        className={style.selectedCard}
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "").toLowerCase().localeCompare(
                                                (optionB?.label ?? "").toLowerCase()
                                            )
                                        }

                                    >
                                        {imagePositions.map((option) => (
                                            <Select.Option key={option.value} value={option.value}
                                                           className={style.selectedOption}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                </span>
                                <Form.Item name="description" label="Description">
                                    <TextArea rows={4} />
                                </Form.Item>

                                <Col lg={24} md={24} span={24} className="mt-4" ref={ref4}>
                                    <Collapse items={items}  accordion onChange={onChange} />
                                </Col>
                            </Col>

                            <Col lg={14} md={14} span={24}>
                                <Col span={24} className='mt-4'>

                                    <Table
                                        className="priceListTable"
                                        columns={columns}
                                        dataSource={Array.isArray(formData?.size_data) ? formData.size_data.map((item) => ({
                                            ...item,
                                            key: item.id
                                        })) : []} pagination={false}
                                        style={{maxHeight: '300px', overflowY: 'auto'}}
                                    />
                                    {packageType === 'Package' ? (
                                    <>
                                        <h5 className={'mb-3'}>Special offers</h5>
                                        {categoriesWithSpecialOffers.map(catItem => (
                                            <Col key={`cat-item-${catItem.key}`} lg={24} md={24} span={24} className="mt-1">
                                                <Collapse
                                                    collapsible={!!catItem.children ? '' : 'disabled'}
                                                    items={[catItem]}
                                                    accordion
                                                    onChange={onChange}
                                                />
                                            </Col>
                                        ))}
                                    </>
                                        ) : null}
                                    <Container className={'text-end'}>
                                        <div style={{marginTop: '16px', fontWeight: 'bold'}}>
                                            Total:
                                            <Input
                                                style={{ width: "130px",marginLeft: '16px',textAlign:"center" }}
                                                type="text"
                                                value={manualTotalValue !== null ? manualTotalValue : ''}
                                                onChange={(e) => handleTotalValueChange(e.target.value)}
                                            />
                                        </div>

                                        {packageType === 'Add-on' ? (
                                            <Form.Item name="totalAddOn" rules={[{required: true}]}>
                                                <div style={{marginTop: '16px', fontWeight: 'bold'}}>
                                                    Add-on Total:
                                                    <Input
                                                        style={{ width: "130px",marginLeft: '16px',textAlign:"center" }}
                                                        type="text"
                                                    />
                                                </div>
                                            </Form.Item>
                                        ) : null}

                                    </Container>

                                    <Container className={'text-end'}>
                                        <Button
                                          ref={ref5}
                                            type="primary"
                                            // htmlType="submit"
                                            className='formBtn send-email-btn'
                                            onClick={handleCreateButtonClick}
                                        > Create
                                        </Button>
                                    </Container>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Table
                        className="priceListTable"
                        columns={categoryCol}
                        dataSource={categories.map((item) => ({
                            ...item,
                            key: item.id
                        }))}
                        pagination={false}
                        style={{maxHeight: '300px', overflowY: 'auto'}}
                    />
                </Modal>
                {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
            </div>
        </>
    );
};

export default AddPackage;