const GET_STUDIOS = 'GET_STUDIOS';

let initialState = {
    studios:[
        {id:1,name:"s1"},
        {id:2,name:"s2"},
        {id:3,name:"s3"}
    ]
}


const studioReducer = (state = initialState,action) => {
    switch (action.type) {
        case GET_STUDIOS:
            return {

            }
        default:
            return state;
    }
}


export default studioReducer;

