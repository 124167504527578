import React, {useRef, useState,useEffect} from 'react';
import Container from "react-bootstrap/Container";
import clientAPI from "../../../../api/api";
import MoreInfo from "../../../Utils/MoreInfo";
import { useNavigate } from "react-router-dom";
import { useFetching } from "../../../../hoc/fetchingHook";
import {Button, Col, Form, Input, Row, Select, Modal, Tooltip, Typography, message, Tour} from 'antd';
import { SolutionOutlined, DownOutlined } from "@ant-design/icons";
import { generateRandomUsername, handleGenerateUnamePass, handleTagChange, validateMessages } from '../../../Utils/isFunction';

const AddContact = ({helperShow,setHelperShow}) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;

    const { Option } = Select;
    const [isTypeSchool, setIsTypeSchool] = useState(false);
    const [form] = Form.useForm();
    const currentPath = '/studio/';
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [expand, setExpand] = useState(false);
    const navigate = useNavigate();
    const randomUsername = generateRandomUsername(8);
    const handleClick = () => { handleGenerateUnamePass(form) }
    const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };

    const [open, setOpen] = useState(helperShow);

    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }

    }, [helperShow]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);


    const steps = [
        {
            title: 'Choose Client Type: Client / School',
            description: 'This option allows you to select the type of client you are setting up: either a "Client" or a "School". Each type has distinct features tailored to meet specific needs.\n' +
                '\n' +
                'Client: Choosing \'Client\' is ideal for individual clients or businesses. In this mode, clients can create and manage events, providing them with a comprehensive toolset for event planning and coordination. This option is suited for clients who require personalized event management solutions.\n' +
                '\n' +
                'School: Selecting \'School\' activates a unique QR-based functionality. This is particularly useful for educational institutions. In this mode, the system is geared towards handling bulk data, like student or event information, typically provided by the school in an Excel file. The system processes this data to generate QR codes for each entry. These QR codes serve as a quick and efficient way for schools to access the system or specific information within it. Once the QR codes are generated, schools can easily log in to the system using the QR link, streamlining the process and enhancing user convenience',
            target: () => ref1.current,
        },
        {
            title: 'Email Addresses',
            description: 'This section allows you to add multiple email addresses. You can enter one or more email addresses as needed. Each email address should be entered separately to ensure accurate communication and record-keeping',
            target: () => ref2.current,
        },
        {
            title: 'Generate Username & Password',
            description: 'Click this button to automatically generate a unique username and password. This feature is designed to provide you with secure and randomized credentials, ensuring enhanced security for your account',
            target: () => ref3.current,
        },
        {
            title: 'More Information',
            description: 'Use the "More Details" option to provide comprehensive information about your client. This section is designed to capture detailed contact information, ensuring that you have all the necessary data for effective communication and service delivery',
            target: () => ref4.current,
        },
        {
            title: 'Create Client',
            description: 'he "Create" button is your final step in submitting information or finalizing the process you are working on. When you click this button, it triggers the system to save and process all the data you\'ve entered',
            target: () => ref5.current,
        },
    ];


    const [createClient, isPostsLoading, postError] = useFetching(async (values) => {
        const { data: res } = await clientAPI.createClient(values);
        if (res) {
            localStorage.setItem('client_id',res.message);
            setOpenSuccessModal(true);
        }
    });

    function onTypeChange(e){
        e === "1" ? setIsTypeSchool(true) : setIsTypeSchool(false)
    }
    const onFinish = (values) => {
        createClient(values);
    };
    const onFinishSuccess= (values) => {
        setOpenSuccessModal(false);
    };

    const handleOk = () => {
        setOpenSuccessModal(false);
        navigate(currentPath + 'add_event');
        window.location.reload();
    };
    const handleCancel = () => {
        setOpenSuccessModal(false);
        navigate(currentPath + 'list_client');
        window.location.reload();
    };
    
    return(
        <>
            <div className={'text-center mt-4'}>
                <h3 className={'text-uppercase mb-4 header-title'}>Add Client / School</h3>
            </div>
            <div className={'mt-4 album-notifications-select'}>
            <Form
                form={form}
                name="validate_other"
                onFinish={onFinish}
                size={"large"}
                layout="vertical"
                style={{
                    maxWidth: 600,
                    margin: "auto"
                }}
                validateMessages={validateMessages}
                initialValues={{state: 'New York'}}
            >
                <div ref={ref1}>
                <Form.Item
                    name="type"
                    label="Client Type"
                    rules={[ {required: true }]}
                >
                    <Select placeholder="select client type" onChange={onTypeChange}>
                        <Option value="2">Client</Option>
                        <Option value="1">School</Option>
                    </Select>
                </Form.Item>
                </div>
                <div ref={ref2}>
                <Form.Item
                    name="email"
                    label="Enter tags (email addresses)"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter at least one email address.',
                            type: 'array',
                        },
                    ]}
                >
                    <Select
                        mode="tags"
                        onChange={handleTagChangee}
                        style={{ width: '100%' }}
                        placeholder="Enter your (email addresses)"
                    />
                </Form.Item>
                </div>

                <Row justify='space-between'>
                    <Col sm={21} xs={20}>
                        <Form.Item
                          name="username"
                          label="Username"
                          rules={[ {required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col sm={2} xs={3}  ref={ref3}>
                        <Tooltip title="Generate Username and Password">
                            <Typography.Link onClick={handleClick}>
                                <SolutionOutlined className={"generate-uname-pass"}/>
                            </Typography.Link>
                        </Tooltip>

                    </Col>
                </Row>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password value={randomUsername}/>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password value={randomUsername}/>
                </Form.Item>
                {isTypeSchool && (
                    <Form.Item
                        name="school_name"
                        label="School name"
                        rules={[ {required: true }]}
                    >
                        <Input/>
                    </Form.Item>
                )}
                {!isTypeSchool && (
                    <Row  justify="space-between">
                        <Col lg={11} md={11} span={24}>
                            <Form.Item
                                name="firstname"
                                label="Firstname"
                                rules={[ {required: true }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col lg={11} md={11} span={24}>
                            <Form.Item
                                name="lastname"
                                label="Lastname"
                                rules={[ {required: true }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Col ref={ref4} className={"collapse-client"} lg={24} md={24} span={24}>
                    <a onClick={() => { setExpand(!expand);}}>
                        <DownOutlined rotate={expand ? 180 : 0} /> More Details
                    </a>
                </Col>
                {expand && ( <MoreInfo />)}
                <Container ref={ref5} className={'text-center'}>
                    <Button type="primary" htmlType="submit"  className='formBtn send-email-btn' >
                        Create
                    </Button>
                    <Modal
                        title="Client successfully created"
                        centered
                        open={openSuccessModal}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        className={'success-modal'}
                    >
                        <Form
                            name="validate_other"
                            onFinish={onFinishSuccess}
                            layout="vertical"
                            style={{ maxWidth: 600 }}
                        >
                            <p className={'add-event-question'}>Do you want to add Event?</p>
                        </Form>
                    </Modal>
                </Container>
            </Form>
                {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
            </div>
        </>
    )

};
export default AddContact;