import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../../../../service";
import { setCreditStudio, setOrderID } from "./mainCheckoutSlice";

const user_id = localStorage.getItem('user_id');
const current_user_id = localStorage.getItem('current_user_id');

const ShoppingCardStore = createAsyncThunk(
    "shopping/card/store",
    async({shoppingCartData,shoppingCardInfo})=>{
      
        const response = await request.post("shopping_cart_store",{
            user_id,
            current_user_id,
            shoppingCartData,
            shoppingCardInfo,
           
        })
        // console.log(response.data)
    }
)


const GetCreditOwner = createAsyncThunk(
    "get/credit/owner",
    async ({ ownerID }, { dispatch, rejectWithValue }) => {
    
        try {
            const response = await request.post("get_credit_owner_id", {
                ownerID,
                user_id,
            });

            dispatch(setCreditStudio(response.data));
            return response.data;
        } catch (error) {
          
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


const GetOrderID =createAsyncThunk(
    "get/order/id",
    async({shoppingCartInfo},{rejectWithValue,dispatch}) =>{
        try {
            const response = await request.post("get_order_id", {
                shoppingCartInfo,
                user_id,
            });

     

            dispatch(setOrderID(response.data))
           
            return response.data;
        } catch (error) {
          
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export{
    ShoppingCardStore,
    GetCreditOwner,
    GetOrderID
}