import React,{ useEffect, useState } from 'react';
import isAuth from "../../hoc/IsAuth";
import AddContact from "./ClientSidebar/Contacts/AddContact";
import StudioTable from "./StudioTable"; //clent list
import Logout from "../Logout/Logout";
import LoadingSpin from "../Utils/LoadingSpin";
import useCheckUserType from "../../hoc/checkUserType";
import Buttons from '../Utils/Buttons';
import {Route, useNavigate} from "react-router-dom";
import { useParams } from "react-router";
import { studioItems} from './StudioItems';
import { menuHelper } from "../../hoc/menuHoc";
import {Breadcrumb, Button, Col, Layout, Menu, Row, theme} from 'antd';
import { HomeOutlined, LeftCircleFilled, LogoutOutlined, RightCircleFilled, RollbackOutlined } from "@ant-design/icons";
import { labItems } from './LabItems';
import { stringToBool } from '../Utils/stringToBool';
import Album from '../Clients/Album/Album';
import { useWindowSize } from '../Utils/WindowSize';

import EditContact from './ClientSidebar/Contacts/EditContact';
import TextPolicy from "./ClientSidebar/Settings/TextPolicy";

import AddEvent from "./ClientSidebar/Events/AddEvent";
import EventList from "./ClientSidebar/Events/EventList";
import EditEvent from "./ClientSidebar/Events/EditEvent";

import AddStaff from "../Lab/Staff/AddStaff";
import ListStaff from "../Lab/Staff/ListStaff";
import EditStaff from "../Lab/Staff/EditStaff";

import AddVenue from "./ClientSidebar/Venue/AddVenue";
import ListVenue from "./ClientSidebar/Venue/ListVenue";
import EditVenue from "./ClientSidebar/Venue/EditVenue";

import AddPhotographer from "./ClientSidebar/Photographers/AddPhotographer";
import PhotographersList from "./ClientSidebar/Photographers/PhotographersList";
import EditPhotographer from "./ClientSidebar/Photographers/EditPhotographer";

import AddPriceStudio from "./ClientSidebar/Price/Prints/AddPriceStudio";
import ListPriceStudio from "./ClientSidebar/Price/Prints/ListPriceStudio";
import EditPriceStudio from "./ClientSidebar/Price/Prints/EditPriceStudio";

import AddFlatCardsStudio from "./ClientSidebar/Price/Cards/FlatCards/AddFlatCardsStudio";
import ListFlatCardsStudio from "./ClientSidebar/Price/Cards/FlatCards/ListFlatCardsStudio";
import EditFlatCardsStudio from "./ClientSidebar/Price/Cards/FlatCards/EditFlatCardsStudio";

import AddFoldedAccordionStudio from "./ClientSidebar/Price/Cards/FoldedAccordion/AddFoldedAccordionStudio";
import ListFoldedAccordionStudio from "./ClientSidebar/Price/Cards/FoldedAccordion/ListFoldedAccordionStudio";
import EditFoldedAccordionStudio from "./ClientSidebar/Price/Cards/FoldedAccordion/EditFoldedAccordionStudio";

import AddFoldOverStudio from "./ClientSidebar/Price/Cards/FoldOver/AddFoldOverStudio";
import ListFoldOverStudio from "./ClientSidebar/Price/Cards/FoldOver/ListFoldOverStudio";
import EditFoldOverStudio from "./ClientSidebar/Price/Cards/FoldOver/EditFoldOverStudio";

import AddPhotoCardsStudio from "./ClientSidebar/Price/Cards/PhotoCards/AddPhotoCardsStudio";
import ListPhotoCardsStudio from "./ClientSidebar/Price/Cards/PhotoCards/ListPhotoCardsStudio";
import EditPhotoCardsStudio from "./ClientSidebar/Price/Cards/PhotoCards/EditPhotoCardsStudio";

import AddAlbum from "./ClientSidebar/Price/Albums/AddAlbum";
import AlbumsList from "./ClientSidebar/Price/Albums/AlbumsList";
import AlbumsEdit from "./ClientSidebar/Price/Albums/AlbumsEdit";

import AddUnitsStudio from "./ClientSidebar/Price/Units/AddUnitsStudio";
import ListUnitsStudio from "./ClientSidebar/Price/Units/ListUnitsStudio";
import EditUnitsStudio from "./ClientSidebar/Price/Units/EditUnitsStudio";

import Completed from "./ClientSidebar/Monitoring/Zip/Completed";
import InProcess from "./ClientSidebar/Monitoring/Zip/InProcess";


import Contract from './ClientSidebar/Contract/ContractAdd/Contract';
import ContractList from './ClientSidebar/Contract/ContractList/ContractList';

import AddPackage from "./ClientSidebar/Package/AddPackage";
import ListPackage from "./ClientSidebar/Package/ListPackage";
import EditPackage from "./ClientSidebar/Package/EditPackage";

import './ClientSidebar/Price/Prints/Price.css'
import './StudioDashboard.css'
import {useFetching} from "../../hoc/fetchingHook";
import clientsAPI from "../../api/api";

import CreateGroup from "../Lab/Background/CreateGroup";
import BackgroundList from "../Lab/Background/BackgroundList";
import UploadBackground from "../Lab/Background/UploadBackground";
import EditBackground from "../Lab/Background/EditBackground";
import {HelperTabProvider} from "../../context/HelperTabContext";
import SwitchHelperMode from "../Utils/SwitchHelperMode";
import HelperShow from "../Utils/HelperShow";
import ClientCreditList from "./ClientSidebar/ClientCredit/ClientCreditList";
import ClientCreditAdd from "./ClientSidebar/ClientCredit/ClientCreditAdd";
import OrderDetails from "../Cart/Checkout/OrderDetails";
import Orders from "./Orders/Orders";

const { Header, Sider, Content } = Layout;
const user_id = parseInt(localStorage.getItem('user_id'));
const studio_id = parseInt(localStorage.getItem('studio_id'));
let goToLab = {};
if(user_id !== studio_id || user_id===studio_id){
  goToLab = {
    key: 'go_to_lab',
    icon: <RollbackOutlined />,
    label: 'Go To Lab',
  };
}else{
  goToLab = {
    key: 'list_client',
    icon: <HomeOutlined />,
    label: 'Home',
  };
}
const navLabItems = [
  goToLab,
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
  }
]

const navStudioItems = [
  {
    key: 'list_client',
    icon: <HomeOutlined />,
    label: 'Home',
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
  }
]

const Sidebar = (props) => {
  const [helperShow, setHelperShow] = useState(false);
  const currentUserType = useCheckUserType();
  const { token: { colorBgContainer } } = theme.useToken();
  const navigate = useNavigate();
  const [activeStudio, setActiveStudio] = useState(stringToBool(localStorage.getItem('studio_mode')));
  const currentPath = '/studio/';
  const [itemsB, setItemsB] = useState([]);
  const [selectedKey, setSelectedKey] = useState('/');
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [navItmes, setNavItmes] = useState([]);
  const isUserAuthenticated = isAuth();
  const windowSize = useWindowSize();
  const PREFIX = "https://studioseye.com/pricing/upload/";
  const [studioInfo, setStudioInfo] = useState();

  const [getStudioInfo, isGetStudioInfo, studioIndoError] = useFetching(async () => {
    const res  = await clientsAPI.getStudioInfoByToken();
    //   const res  = await clientsAPI.getClientInfoByID(studio_id);
    if (res) {
      setStudioInfo(res.data);
    }
  });

  const showBreadcrumbs = (items, e) => {
    const parentMenu = menuHelper.getParentLabel(items,e);
    const clickedItem = menuHelper.getMenuLabel(items,e);
    let bread;
    if(clickedItem === "Dashboard"){
      bread = [
        { title: 'Dashboard', href: '/studio/dashboard' },
      ];
    }else if (clickedItem === parentMenu){
      bread = [
        { title: 'Dashboard', href: '/studio/dashboard' },
        { title: clickedItem, href: `/studio/${e}` },
      ];
    }else{
      bread = [
        { title: 'Dashboard', href: '/studio/dashboard' },
        { title: parentMenu},
        { title: clickedItem, href: `/studio/${e}` },
      ];
    }
    setItemsB(bread);
  }

  const handleMenuClick = (e) => {
    if(e.key === 'go_to_lab'){
      localStorage.setItem('user_type',"LAB");
      const currentPath2 = '/labs/studio_list';
      navigate(currentPath2);
    }else{
      setSelectedKey(e.key);
      showBreadcrumbs(items,e.key);
      navigate(currentPath+e.key);
    }
  };

  const chengeStudioMode = () => {
    setActiveStudio(!activeStudio);
    localStorage.setItem('studio_mode',!activeStudio);
    if(stringToBool(localStorage.getItem('studio_mode')) === true){
      setItems(labItems);
      setNavItmes(navLabItems);
    }else{
      setItems(studioItems);
      setNavItmes(navStudioItems);
    }
  };

  const renderConditionalContent = () => {
    if (currentUserType === 'LAB') {
      if (windowSize.width < 768) {
        return (
          <Buttons
            activeStudio={activeStudio}
            chengeStudioMode={chengeStudioMode}
            studioModeLabel="Lab"
            clientModeLabel="Studio"
          />
        );
      } else if (windowSize.width > 768) {
        return (
          <Buttons
            activeStudio={activeStudio}
            chengeStudioMode={chengeStudioMode}
            studioModeLabel="Lab mode"
            clientModeLabel="Studio mode"
          />
        );
      }
    }

    return null;
  };

  let { id } = useParams();

  useEffect(() => {
    setActiveStudio(stringToBool(localStorage.getItem('studio_mode')));
    if(stringToBool(localStorage.getItem('studio_mode')) === true && currentUserType === 'LAB'){
      setItems(labItems);
      setNavItmes(navLabItems);
    }else{
      setItems(studioItems);
      setNavItmes(navStudioItems);
    }
    if(id){
      setSelectedKey(id);
      showBreadcrumbs(items,id);
    }else if(props.page){
      setSelectedKey(props.page);
      showBreadcrumbs(items,props.page);
      navigate(currentPath+props.page);
    }
    getStudioInfo()
  }, []);

  useEffect(() => {
    if(windowSize.width < 768){
      setCollapsed(true);
    }else if(windowSize.width > 768) {
      setCollapsed(false);
    }

  }, [windowSize,items,navItmes]);

  if(!isUserAuthenticated && (currentUserType !== "LAB" || currentUserType !== "STUDIO")){
    return <>
      <LoadingSpin/>
    </>
  }else{
    return (
     <HelperTabProvider>
      <Layout style={{ minHeight: '100vh', }}>
        <Header className="header">
          <Row justify='space-between'>
            <Col xs={5} sm={3}>
              <div className="logo">
                {studioInfo && (
                  <img src={PREFIX + studioInfo.companyLogo} alt="Studio Logo" style={{ background: '#e6f4ff'}}/>
                )}
              </div>
            </Col>
            <Col>
             <div className="colapseButtonContainer">
           <Button className="colapseButton"
                   type="text"
                   icon={collapsed ? <RightCircleFilled className="colapseButtonColor"/> : <LeftCircleFilled className="colapseButtonColor"/>}
                   onClick={() => setCollapsed(!collapsed)}
           />
         </div>
            </Col>
           <Col >
             <div className="studioModeContainer">
               {renderConditionalContent()}
             </div>
           </Col>
            <Col  className="helperMode" span={4}>
             <div>
               {/*<SwitchHelperMode/>*/}
               <HelperShow helperShow={helperShow} setHelperShow={setHelperShow}/>
             </div>
           </Col>
            <Col xs={8} sm={9}>
           <Menu  className="top_menu"
                  onClick={handleMenuClick}
                  selectedKeys={[selectedKey]}
                  defaultSelectedKeys={['studio_clients']}
                  defaultOpenKeys={['studio_clients']}
                  theme="light"
                  mode="horizontal"
                  items={navItmes}/>
         </Col>
          </Row>
        </Header>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed} className='labDashbordMenu' >
            <Menu
              onClick={handleMenuClick}
              selectedKeys={[selectedKey]}
              mode="inline"
              style={{ height: '100%', borderRight: 0}}
              items={items}
            />
          </Sider>
          <Layout className='labDashbordLayout' >
            <Row justify='space-between' className='mt-4 mb-4'>
              <Col>
                <Breadcrumb items={itemsB} />
              </Col>
              <Col >
                <div>
                  {studioInfo && ( <>
                      ID: <b>{studioInfo.id}</b>, Company Name: <b>{studioInfo.companyName}</b>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Content
              className='labDashbordContent'
              style={{ minHeight: 280 }}
            >
              {selectedKey === 'dashboard' && <StudioTable />}
              {selectedKey === 'album' && <Album/>}
              {selectedKey === 'logout' && <Logout/>}
              {selectedKey === 'terms_conditions' && <TextPolicy />}

              {selectedKey === 'add_client' && <AddContact helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'list_client' && <StudioTable  studioInfo={studioInfo}/>}
              {selectedKey === 'edit_client' && <EditContact/>}

              {selectedKey === 'add_event' && <AddEvent helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'list_event' && <EventList/>}
              {selectedKey === 'edit_event' && <EditEvent/>}

              {selectedKey === 'add_ph' && <AddPhotographer helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'list_ph' && <PhotographersList/>}
              {selectedKey === 'edit_ph' && <EditPhotographer/>}

              {selectedKey === 'staff_add' && <AddStaff helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'staff_list' && <ListStaff/>}
              {selectedKey === 'staff_edit' && <EditStaff/>}

              {selectedKey === 'price_add' && <AddPriceStudio helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'price_list' && <ListPriceStudio/>}
              {selectedKey === 'price_edit' && <EditPriceStudio/>}

              {selectedKey === 'units_add' && <AddUnitsStudio helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'units_list' && <ListUnitsStudio/>}
              {selectedKey === 'units_edit' && <EditUnitsStudio/>}

              {selectedKey === 'venue_add' && <AddVenue helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'venue_list' && <ListVenue/>}
              {selectedKey === 'venue_edit' && <EditVenue/>}


              {selectedKey === 'albums_add' && <AddAlbum helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'albums_list' && <AlbumsList/>}
              {selectedKey === 'album_edit' && <AlbumsEdit/>}

              {selectedKey === 'completed' && <Completed/>}
              {selectedKey === 'in_process' && <InProcess/>}

              {selectedKey === 'contract' && <Contract helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'contract_list' && <ContractList />}

              {selectedKey === 'add_package' && <AddPackage helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'list_package' && <ListPackage />}
              {selectedKey === 'edit_package' && <EditPackage />}

              {selectedKey === 'flat_cards_add' && <AddFlatCardsStudio helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'flat_cards_list' && <ListFlatCardsStudio/>}
              {selectedKey === 'flat_cards_edit' && <EditFlatCardsStudio/>}

              {selectedKey === 'folded_accordion_add' && <AddFoldedAccordionStudio/>}
              {selectedKey === 'folded_accordion_list' && <ListFoldedAccordionStudio/>}
              {selectedKey === 'folded_accordion_edit' && <EditFoldedAccordionStudio/>}

              {selectedKey === 'fold_over_add' && <AddFoldOverStudio helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'fold_over_list' && <ListFoldOverStudio/>}
              {selectedKey === 'fold_over_edit' && <EditFoldOverStudio/>}

              {selectedKey === 'photo_cards_add' && <AddPhotoCardsStudio helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'photo_cards_list' && <ListPhotoCardsStudio/>}
              {selectedKey === 'photo_cards_edit' && <EditPhotoCardsStudio/>}

              {selectedKey === 'background_create_group' && <CreateGroup helperShow={helperShow} setHelperShow={setHelperShow}/>}
              {selectedKey === 'background_group_list' && <BackgroundList/>}
              {selectedKey === 'background_add' && <UploadBackground/>}
              {selectedKey === 'background_edit' && <EditBackground/>}

              {selectedKey === 'order_list' && <Orders />}

              {/*{selectedKey === 'credit_card_add' && <CreditCard/>}*/}
              {/*{selectedKey === 'list_credit_card' && <CreditList />}*/}


              {selectedKey === 'client_credit_list' && <ClientCreditList />}
              {selectedKey === 'client_credit_add' && <ClientCreditAdd />}

            </Content>
          </Layout>
        </Layout>
      </Layout>
    </HelperTabProvider>
    );
  }
}
export default Sidebar;