import {
  UsergroupAddOutlined,
  ReadOutlined,
  HomeOutlined,
  PictureOutlined,
} from "@ant-design/icons";

export const phStudioItems = [
  {
      key: 'home',
      icon: <HomeOutlined />,
      label: 'Home',
  }, {
      key: 'staffStudioItems',
      icon: <UsergroupAddOutlined />,
      label: 'staffStudioItems1',

    },
  {
      key: 'staffStudioItems',
      icon: <ReadOutlined />,
      label: 'staffStudioItems2',
  },
  {
      key: 'staffStudioItems',
      icon: <PictureOutlined />,
      label: 'staffStudioItems3',
  },

]