import React,{useEffect, useState } from 'react';
import Logout from "../Logout/Logout";
import LoadingSpin from "../Utils/LoadingSpin";
import useCheckUserType from "../../hoc/checkUserType";
import triColorLogo from "../../Images/TRICOLOR CENTER.png";
import isAuth from "../../hoc/IsAuth";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { menuHelper } from "../../hoc/menuHoc";
import { items } from "./LeftSidebarItems";
import { useWindowSize } from '../Utils/WindowSize';
import { HomeOutlined, LeftCircleFilled, RightCircleFilled,LogoutOutlined } from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, theme, Button, Row, Col} from 'antd';

import StudioAdd from "../Studios/StudioAdd/StudioAdd";
import LabStudioList from "./LabStudiList";
import EditStudio from "../Studios/StudioEdit/StudioEdit";


import AddStaff from "./Staff/AddStaff";
import ListStaff from "./Staff/ListStaff";
import EditStaff from "./Staff/EditStaff";

import AddVenue from "./Venue/AddVenue";
import ListVenue from "./Venue/ListVenue";
import EditVenue from "./Venue/EditVenue";

import ListPriceLab from "./Price/Prints/ListPriceLab";
import AddPriceLab from "./Price/Prints/AddPriceLab";
import EditPriceLab from "./Price/Prints/EditPriceLab";

import AddFlatCardsLab from "./Price/Cards/FlatCards/AddFlatCardsLab";
import ListFlatCardsLab from "./Price/Cards/FlatCards/ListFlatCardsLab";
import EditFlatCardsLab from "./Price/Cards/FlatCards/EditFlatCardsLab";

import AddFoldedAccordionLab from "./Price/Cards/FoldedAccordion/AddFoldedAccordionLab";
import ListFoldedAccordionLab from "./Price/Cards/FoldedAccordion/ListFoldedAccordionLab";
import EditFoldedAccordion from "./Price/Cards/FoldedAccordion/EditFoldedAccordionLab";

import AddFoldOverLab from "./Price/Cards/FoldOver/AddFoldOverLab";
import ListFoldOverLab from "./Price/Cards/FoldOver/ListFoldOverLab";
import EditFoldOverLab from "./Price/Cards/FoldOver/EditFoldOverLab";

import PhotoCardLab from "./Price/Cards/PhotoCards/AddPhotoCardLab";
import ListPhotoCardsLab from "./Price/Cards/PhotoCards/ListPhotoCardsLab";
import EditPhotoCardsLab from "./Price/Cards/PhotoCards/EditPhotoCardsLab";

import AddAlbumLab from "./Price/Albums/AddAlbumLab";
import ListAlbumLab from "./Price/Albums/ListAlbumLab";
import EditAlbumLab from "./Price/Albums/EditAlbumLab";

import AddUnitsLab from "./Price/Units/AddUnitsLab";
import EditUnitsLab from "./Price/Units/EditUnitsLab";
import ListUnitsLab from "./Price/Units/ListUnitsLab";
import CreateGroup from "./Background/CreateGroup";
import BackgroundList from "./Background/BackgroundList";
import UploadBackground from "./Background/UploadBackground";
import EditBackground from "./Background/EditBackground";
import UploadTemplate from "./Template/UploadTemplate/UploadTemplate";
import CreateGroupTemplates from "./Template/CreateGroupTemplates";
import ListTemplates from "./Template/ListTemplates";
import {HelperTabProvider} from "../../context/HelperTabContext";
import SwitchHelperMode from "../Utils/SwitchHelperMode";
import HelperShow from "../Utils/HelperShow";

import StudioCreditList from "./StudioCredit/StudioCreditList";
import StudioCreditAdd from "./StudioCredit/StudioCreditAdd";





const { Header, Content, Sider } = Layout;
const items1 = [{
    key: 'studio_list',
    icon: <HomeOutlined />,
    label: 'Home',
},
    {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
},
]



const Lab = (props) => {

    const currentUserType = useCheckUserType();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [helperShow, setHelperShow] = useState(false);
    const navigate = useNavigate();
    const currentPath = '/labs/';
    const [itemsB, setItemsB] = useState([]);
    const [selectedKey, setSelectedKey] = useState('/');
    const [collapsed, setCollapsed] = useState(false);
    const isUserAuthenticated = isAuth();
    const windowSize = useWindowSize();

    const showBreadcrumbs = (items, e) => {
        const parentMenu = menuHelper.getParentLabel(items,e);
        const clickedItem = menuHelper.getMenuLabel(items,e);
        let bread;
        if(clickedItem === "Dashboard"){
            bread = [
                { title: 'Dashboard', href: '/labs/dashboard' },
            ];
        }else if (clickedItem === parentMenu){
            bread = [
                { title: 'Dashboard', href: '/labs/dashboard' },
                { title: clickedItem, href: `/labs/${e}` },
            ];
        }else{
            bread = [
                { title: 'Dashboard', href: '/labs/dashboard' },
                { title: parentMenu},
                { title: clickedItem, href: `/labs/${e}` },
            ];
          }
        setItemsB(bread);
    }

    const handleMenuClick = (e) => {
        setSelectedKey(e.key);
        showBreadcrumbs(items,e.key);
        navigate(currentPath+e.key);
    };
    let { id } = useParams();

    useEffect(() => {
        if(id){
            setSelectedKey(id);
            showBreadcrumbs(items,id);

        }else if(props.page){
            setSelectedKey(props.page);
            showBreadcrumbs(items,props.page);
            navigate(currentPath+props.page);
        }
    }, []);

    useEffect(() => { 
        if(windowSize.width < 768){
            setCollapsed(true);
        }else if(windowSize.width > 768) {
            setCollapsed(false);
        }
    }, [windowSize]);

    if(!isUserAuthenticated || currentUserType !== 'LAB'){
        return <>
            <LoadingSpin/>
        </>
    }else{
    return (
      <HelperTabProvider>
        <Layout style={{
            minHeight: '100vh',
        }}>
            <Header className="header">
                <Row justify='space-between'>
                    <Col span={3}>
                        <div className="logo">
                            <img src={triColorLogo} alt="tricolor-icon"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="colapseButtonContainer">
                            <Button className="colapseButton"
                                    type="text"
                                    icon={collapsed ? <RightCircleFilled className="colapseButtonColor"/> : <LeftCircleFilled className="colapseButtonColor"/>}
                                    onClick={() => setCollapsed(!collapsed)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="helperMode">
                            {/*<SwitchHelperMode/>*/}
                            <HelperShow helperShow={helperShow} setHelperShow={setHelperShow}/>
                        </div>
                    </Col>
                    <Col span={9}>
                        <Menu
                          className="top_menu"
                          onClick={handleMenuClick}
                          selectedKeys={[selectedKey]}
                          theme="light"
                          mode="horizontal"
                          defaultSelectedKeys={['2']}
                          items={items1}
                        />
                    </Col>
                </Row>




            </Header>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed} className='labDashbordMenu'>
                    <Menu
                        onClick={handleMenuClick}
                        selectedKeys={[selectedKey]}
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        style={{ height: '100%', borderRight: 0, }}
                        items={items}
                    />
                </Sider>
                <Layout  className='labDashbordLayout'  >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                        items={itemsB}
                    />
                    <Content  className='labDashbordContent'  >
                        {selectedKey === 'logout' && <Logout/>}
                        {selectedKey === 'studio_list' && <LabStudioList/>}
                        {selectedKey === 'studio_add' && <StudioAdd helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'edit_studio' && <EditStudio/>}
                        {selectedKey === 'staff_add' && <AddStaff helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'staff_list' && <ListStaff/>}
                        {selectedKey === 'staff_edit' && <EditStaff/>}

                        {selectedKey === 'venue_add' && <AddVenue helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'venue_list' && <ListVenue/>}
                        {selectedKey === 'venue_edit' && <EditVenue/>}

                        {selectedKey === 'price_add' && <AddPriceLab helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'price_list' && <ListPriceLab/>}
                        {selectedKey === 'price_edit' && <EditPriceLab/>}

                        {selectedKey === 'units_add' && <AddUnitsLab helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'units_list' && <ListUnitsLab/>}
                        {selectedKey === 'units_edit' && <EditUnitsLab/>}

                        {selectedKey === 'flat_cards_add' && <AddFlatCardsLab helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'flat_cards_list' && <ListFlatCardsLab/>}
                        {selectedKey === 'flat_cards_edit' && <EditFlatCardsLab/>}

                        {selectedKey === 'folded_accordion_add' && <AddFoldedAccordionLab />}
                        {selectedKey === 'folded_accordion_list' && <ListFoldedAccordionLab/>}
                        {selectedKey === 'folded_accordion_edit' && <EditFoldedAccordion/>}

                        {selectedKey === 'fold_over_add' && <AddFoldOverLab helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'fold_over_list' && <ListFoldOverLab/>}
                        {selectedKey === 'fold_over_edit' && <EditFoldOverLab/>}

                        {selectedKey === 'photo_cards_add' && <PhotoCardLab helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'photo_cards_list' && <ListPhotoCardsLab/>}
                        {selectedKey === 'photo_cards_edit' && <EditPhotoCardsLab/>}

                        {selectedKey === 'album_add' && <AddAlbumLab helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'album_list' && <ListAlbumLab/>}
                        {selectedKey === 'album_edit' && <EditAlbumLab/>}

                        {selectedKey === 'background_add' && <UploadBackground/>}
                        {selectedKey === 'background_create_group' && <CreateGroup helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'background_group_list' && <BackgroundList/>}
                        {selectedKey === 'background_edit' && <EditBackground/>}

                        {selectedKey === 'templates_add' && <UploadTemplate helperShow={helperShow} setHelperShow={setHelperShow}/>}
                        {selectedKey === 'studio_credit_list' && <StudioCreditList />}
                        {selectedKey === 'studio_credit_add' && <StudioCreditAdd />}

                    </Content>
                </Layout>
            </Layout>
        </Layout>
      </HelperTabProvider>
    );
    }
};
export default Lab;
