



import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { Button, Space, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import styles from '../Gallery.module.css';


const AllImageDownloader = ({ selectedImages, selectionState, galleryInfo }) => {
  const IMG_SERVER = 'https://studioseye.com';
  const H_THUMBNAILS = '/H_THUMBNAILS/';
  const THUMBNAILS = '/THUMBNAILS/';
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownObj, setDropdownObj] = useState({});



  const downloadSelectedImagesH = async () => {
    const obj = {
      ...selectionState,
      ...galleryInfo,
    };
    setDropdownObj(obj);

    
    setDropdownOpen(false);
  };

  const downloadSelectedImagesM = async () => {
    const zip = new JSZip();

    await Promise.all(
      selectedImages.map(async (img) => {
        if (img) {
          const downloadUrl = IMG_SERVER + img.IMAGE_LINK_PATH + THUMBNAILS + img.IMAGE_NAME;
          const response = await fetch(downloadUrl);
          const blob = await response.blob();
          zip.file(`${img.ID}_${img.IMAGE_NAME}`, blob);
        }
      })
    );

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.target = '_blank';
    downloadLink.download = 'images.zip';
    downloadLink.click();
    setDropdownOpen(false);
  };

  // useEffect(() => {
  //   if (dropdownOpen) {
  //     const timer = setTimeout(() => {
  //       setDropdownOpen(false);
  //     }, 10000); // 10 seconds
  //     return () => clearTimeout(timer);
  //   }
  // }, [dropdownOpen]);

 

  return (
    <>
      <Tooltip title="Download All Selected Images">
        <Button className={styles.buttonIcon} onClick={() => setDropdownOpen(!dropdownOpen)}>
          <CloudDownloadOutlined /> <span className={styles.CompareTextButton}>Download</span>
        </Button>
      </Tooltip>
      {dropdownOpen && (
        <div className="DropdownOpenBlock DropdownImages">
          <Space direction="vertical">
            <Button onClick={downloadSelectedImagesH} className={styles.buttonIcon}>
              <CloudDownloadOutlined /> <span className={styles.CompareTextButton}>Download H Images</span>
            </Button>
            <Button onClick={downloadSelectedImagesM} className={styles.buttonIcon}>
              <CloudDownloadOutlined /> <span className={styles.CompareTextButton}>Download M Images</span>
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};

export default AllImageDownloader;
