import React, {useEffect, useState}  from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faInfo, faHeart, faCartPlus, faToggleOff} from '@fortawesome/free-solid-svg-icons'
import './GalleryNavbar.css';
import {Button} from "antd";
import {stringToBool} from "../../Utils/stringToBool";

const alertfnC = () => {
    localStorage.clear();
    window.location.href = '/';
}


const GalleryNavbar = (props) => {
    const [activeStudio, setActiveStudio] = useState(true);
    const [studioIndicator, setStudioIndicator] = useState(stringToBool(localStorage.getItem('studio_mode')));

    const handleClickStudio = (event) => {
        setActiveStudio(!activeStudio);
        localStorage.setItem('studio_mode',!activeStudio);
        props.setStudioMode(!activeStudio);
    };
    useEffect(() => {
        setStudioIndicator(localStorage.getItem('studio_mode'));
        setActiveStudio(stringToBool(localStorage.getItem('studio_mode')));



    }, []);

    useEffect(() => {
            console.log(studioIndicator);
    }, [studioIndicator]);


    return (
        <div className="gallery first-fixed-header">
            <Navbar expand="lg">
                <Container className={'justify-content-lg-center justify-content-md-center justify-content-inherit gallery-navbar-container'}>
                    <Nav.Link href="/gallery">
                        <FontAwesomeIcon icon={faHouse} className={'font-icon'}/>
                        <span className={'text-uppercase'}>Home</span>
                    </Nav.Link>
                    <Nav.Link href="/info">
                        <FontAwesomeIcon icon={faInfo} className={'font-icon'}/>
                        <span className={'text-uppercase'}>Info</span>
                    </Nav.Link>
                    <Nav.Link href="/favorites">
                        <FontAwesomeIcon icon={faHeart} className={'font-icon'}/>
                        <span className={'text-uppercase'}>Favorites</span>
                    </Nav.Link>
                    <Nav.Link href='/cart'>
                        <FontAwesomeIcon icon={faCartPlus} className={'font-icon'}/>
                        <span className={'text-uppercase'}>Cart</span>
                    </Nav.Link>

                    <Nav.Link onClick={alertfnC}>
                        <FontAwesomeIcon icon={faToggleOff} className={'font-icon'}/>
                        <span className={'text-uppercase'}>Logout</span>
                    </Nav.Link>
                    <Button className={'studio-mode'}
                            onClick={(e) => { activeStudio ? e.preventDefault() : handleClickStudio()}}
                            style={{ backgroundColor: activeStudio ? "#007A3D" : "#929292" }}>Studio mode</Button>
                    <Button className={'client-mode'}   onClick={(e) => { !activeStudio ? e.preventDefault() : handleClickStudio()}} style={{ backgroundColor: activeStudio ? "#929292" : "#007A3D" }}>Client mode</Button>
                </Container>
            </Navbar>
        </div>
    );
};

export default GalleryNavbar;