// ImageDownloaderButton.js
import React from 'react';
import styles from "../Gallery.module.css";
import {DownloadOutlined} from "@ant-design/icons";

const ImageDownloaderButton = ({ image, IMG_SERVER, H_THUMBNAILS }) => {
  const downloadImage = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = IMG_SERVER + image.IMAGE_LINK_PATH + H_THUMBNAILS + image.IMAGE_NAME;
    downloadLink.target = "_blank";
    downloadLink.download = image.IMAGE_NAME;
    downloadLink.click();
  };

  return (
    <button className={styles.inButton} onClick={() => downloadImage()}>
      <DownloadOutlined />
    </button>
  );
};

export default ImageDownloaderButton;
