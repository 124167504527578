import React, { useState } from 'react';
import { Button, Card, Layout, Affix } from 'antd';
import './AlbumMain.css';
import ScrollableContainer from "../Album/ScrollableContainer";
import BookCoverStyle from "./BookCoverStyle";
import SpreadStyle from "./SpreadStyle";
import GalleryScrollHorizontal from "../GalleryScrollHorizontal/GalleryScrollHorizontal";
import DropZoneContext from '../../../context/DropZoneContext';
const { Header, Content, Footer, Sider } = Layout;

function AlbumMain() {
    const [imageDropZones, setImageDropZones] = useState({});
    const [isDraggingElem, setIsDraggingElem] = useState(false);
    const [currentSpread, setCurrentSpread] = useState([]);
    const pages = [
        { label: 'Cover', component:  <BookCoverStyle/> },
        { label: 'Endleaf', component:  <SpreadStyle spreadType={1}/> },
        { label: 'Spread - 1', component:  <SpreadStyle spreadType={2}/> },
        { label: 'Spread - 2', component:  <SpreadStyle spreadType={2}/> },
        { label: 'Spread - 3', component:  <SpreadStyle spreadType={2}/> },
        { label: 'Spread - 4', component:  <SpreadStyle spreadType={2}/> },
        { label: 'Spread - 5', component:  <SpreadStyle spreadType={2}/> },
        { label: 'Spread - 6', component:  <SpreadStyle spreadType={2}/> },


        // ... Add as many pages as you need
    ];
    return (
        <DropZoneContext.Provider value={{
            imageDropZones,
            setImageDropZones,
            isDraggingElem,
            setIsDraggingElem
        }}>
        <Layout className="album-layout">
            <Layout style={{ marginRight: '300px' }}> {/* Set marginRight to the width of the sidebar */}
                <Header>
                    <div className="header-content">
                        <ScrollableContainer pages={pages} setCurrentSpread={setCurrentSpread}/>
                    </div>
                </Header>
                <Content>
                    <div className="album-content">
                        <div className="album_content_size">
                        {/*<BookCoverStyle/>*/}
                            {currentSpread.component}
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div className="card-footer-content">
                        <GalleryScrollHorizontal/>
                    </div>
                </Footer>
            </Layout>

            {/* Fixed Right Sidebar using Sider */}
            <Affix offsetTop={0} style={{ position: 'absolute', right: 0, height: '100vh' }}>
                <Sider width={300} style={{height: '100vh', background: '#fff', padding: '20px' }}> {/* Set width to the desired sidebar width */}
                    <h3>{`Settings for ${currentSpread.label}`}</h3>
                    <Button>6x6"</Button>
                    <Button>Manage 2 spreads</Button>
                    {/* Settings related content goes here */}
                </Sider>
            </Affix>
        </Layout>
     </DropZoneContext.Provider>
    );
}

export default AlbumMain;
