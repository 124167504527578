import React,{useEffect, useState,} from 'react';
import clientAPI from "../../../../../api/api";
import getColumnSearchProps from '../../../../Utils/getColumnSearchProps'
import { useFetching } from "../../../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { sortColumns, showDeleteConfirm, onChangeTable } from '../../../../Utils/isFunction'
import {Progress, Space, Table} from 'antd';
import { LoginOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";


const InProcess = () => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });

  const [getCompletedList, isCompletedLoading, completedError] = useFetching(async () => {
    const { data: res } = await clientAPI.getInProccessListZip();
    if (res) {
      console.log(res, "res data getCompletedLISTZIP");
      const modifiedData = res.map((item) => ({
        ...item,
        size: item.size,
        current_offset: item.current_offset,
      }));

      console.log('modifiedData:', modifiedData);

      setData(modifiedData);
      setTableParams({
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: 20,
          total:modifiedData.length,
        },
      });
    }
  });


  const formatSize = (bytes) => {
    const gb = bytes / (1024 * 1024 * 1024); // Конвертируем байты в гигабайты
    return `${gb.toFixed(3)} GB`;
  };


  useEffect(() => {
    getCompletedList();
    const interval = setInterval(    getCompletedList, 5 * 60 * 1000); // Fetch every 5 minutes
    return () => {
      clearInterval(interval); // Clear the interval on unmount to prevent memory leaks
    };
  }, []);

  const handleTableChange = onChangeTable(tableParams, setTableParams);

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      ...getColumnSearchProps("size"),
      sorter: sortColumns("size"),
      sortDirections: ['descend', 'ascend'],
      render: (size) => formatSize(size), // Use the formatSize function to render the formatted size
    },
    {
      title: 'Uploaded ',
      dataIndex: 'uploaded ',
      key: 'uploaded ',
      ...getColumnSearchProps('uploaded '),
      sorter: sortColumns('uploaded '),
      sortDirections: ['descend', 'ascend'],
      render: (current_offset, record) => {
        console.log(record,"record")
        const {size} = record;
        const percentage = (current_offset / size) * 100;
        return( <>
          <p>{formatSize(current_offset)}</p>
          <Progress percent={percentage}/>
        </>)
      },
    }
  ];
  if(isCompletedLoading){
    return (
        <>
          <Table
              className="table-striped-rows studio-table-rows"
              columns={columns}
              dataSource={data}
              loading={isCompletedLoading}
              pagination={tableParams.pagination}
          />
        </>
    );
  }else{
    return(
        <>
          <Table
              className="table-striped-rows studio-table-rows"
              pagination={tableParams.pagination}
              loading={isCompletedLoading}
              rowKey= "id"
              columns={columns}
              dataSource={data}
              onChange={handleTableChange}
              scroll={{ x: 100 }}
          />
        </>
    );
  }
};
export default InProcess;