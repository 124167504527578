import React, { useState,useEffect } from 'react';
import {Button, Col, Input, Row, Table, message, Space, Checkbox} from 'antd';
import Container from "react-bootstrap/Container";
import {PlusSquareOutlined,MinusSquareOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router';
import {showDeleteConfirmPrice} from "../../../Utils/isFunction";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientsAPI from "../../../../api/api";
import LoadingSpin from "../../../Utils/LoadingSpin";


const EditAlbumLab = (id) => {

  const navigate = useNavigate();
  const [selectionType, setSelectionType] = useState('checkbox');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sizes, setSizes] = useState([]);
  const current_user_id = localStorage.getItem('user_id');
  const [fromValue, setFromValue] = useState(0)
  const [inputNameValue, setInputNameValue] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [checked, setChecked] = useState("NO");
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);


  localStorage.setItem('current_user_id', current_user_id);

  const [createCard, isLoading, error] = useFetching(async (cardData) => {
    const { data: res } = await clientsAPI.createCardPrice(cardData);
    if (res) {
      navigate('/labs/album_list');
      window.location.reload();
    }
  });

  const fetchSizesAndDetails = async () => {

    const groupByRowId = (arrayOfArrays) => {
      console.log(arrayOfArrays);
      return arrayOfArrays.reduce((acc, array) => {
        if (Array.isArray(array)) { // Check if it's an array
          console.log(array);
          array.forEach(item => {
            const rowId = item.row_id;
            if (!acc[rowId]) {
              acc[rowId] = {
                id: item.item_id,
                itemID: item.id,
                row_id: rowId,
                items: [],
              };
            }
            acc[rowId].items.push(item);
          });
        } else {
          console.warn("Encountered a non-array element:", array);
        }
        return acc;
      }, {});
    };

    try {
      const sizesRes = await clientsAPI.getProductObjectSizes(1, 'SIZE');
      if (sizesRes.data) {
        const detailsRes2 = await clientsAPI.getEditedCardById();
        if (detailsRes2.data) {
          const sizesData = sizesRes.data["Album"];
          setSizes(sizesData);
          setInputNameValue(detailsRes2.data.header.name);
          setDefaultCheck(detailsRes2.data.header.default_checked === 'YES');
          // Group data by row_id
          const groupedData = groupByRowId(detailsRes2.data.details);
          const apiTypes = {};
          const apiSizes = [];
          // Loop through each key in groupedData
          for (let key in groupedData) {
            const apiItemTypes = groupedData[key].items;
            apiSizes.push(groupedData[key].itemID);

            // Loop through each item in items array
            for (let item of apiItemTypes) {
              apiTypes[item.item_type] = true;

            }
          }

          setCheckedRowKeys(apiSizes);


          // Process each group
          let i = 0;
          const transformedData = Object.values(groupedData).map(group => {
            ++i

            const size = sizesData.find(size => size.ID === group.id);
            console.log(group.items);
            if (size) {
              return  {
                key: i,
                id: group.id,
                itemID: group.itemID,
                size: `${size.width} x ${size.height}`,
                items: group.items.map(detail => ({
                  [detail.item_type]: {
                    from: detail.item_from.toString(),
                    to: detail.item_to.toString(),
                    price: detail.item_price.toString(),
                    discountPrice: detail.item_discountPrice.toString(),
                    isFromAPI: true,
                  },
                })),
                isNew: true,
              };


            }
          }).filter(item => item !== null);

          const normalizeTransformedData = (transformedData) => {
            return transformedData.map(item => {
              if (item.items) {
                const normalizedItems = item.items.reduce((acc, detail) => {
                  const [itemType, itemValue] = Object.entries(detail);
                  acc[itemType] = itemValue;
                  return acc;
                }, {});
                return { ...item, items: [normalizedItems] };
              }
              return null; // Return null for items that are undefined
            }).filter(item => item !== null);
          };



          const initialDataSize = sizesData.map((item, index) => ({
            key: index+1000,
            id: item.ID,
            size: `${item.width} x ${item.height}`,
            items: { from: '', to: '', price: '', isFromAPI: false },
            isNew: false,
          }));
          const apiData =  normalizeTransformedData(transformedData)
          const combinedData = [...initialDataSize, ...apiData];
          const sortedData = combinedData.sort((a, b) => a.id - b.id);
          setData(sortedData);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching sizes and details', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('card_type','1');
    fetchSizesAndDetails();
  }, []);

  useEffect(() => {
    console.log(checkedRowKeys);
    console.log(data);
    defaultCheck ? setChecked("YES") : setChecked("NO")
  }, [data,checkedRowKeys,defaultCheck]);

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 100,
    },
    {
      title: 'Album from / to prices',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      render: (_, record,index) => renderInputs(_, record, index),
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      width: 20,
      render: (_, record) => (
        <>
          <Row>
            <Col span={24}>
              {!record.isNew && (
                <PlusSquareOutlined className='my_ant_icons_table fz-40 ' onClick={() => handleAddRow(record.size,record.key,record.id,record.dataIndex)} />

              )}
            </Col>
            <Col span={24}>
              {record.isNew && (
                <MinusSquareOutlined  className='my_ant_icons_table fz-40' onClick={() => handleDeleteRow(record.key)} />
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleInputChange = (key, columnKey, typeKey, field, value) => {
    setData((prevData) => {
      return prevData.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            items: [
              {
                ...row.items,
                [columnKey]: {
                  ...(row.items?.[columnKey] || {}),
                  [field]: value,
                },
              },
            ],
          };
        }
        return row;
      });
    });
  }

  const renderInputs = (text, record, columnIndex) => (
    <Row>
      <Col span={6}>
        <Input
          value={record.type[columnIndex]?.from || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'from', e.target.value)}
          placeholder="From"
          type="number"
          style={{ padding: '5px 0', width: '70%', textAlign: 'center' }}
        />
      </Col>
      <Col span={6}>
        <Input
          value={record.type[columnIndex]?.to || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'to', e.target.value)}
          placeholder="To"
          type="number"
          style={{ padding: '5px 0',width: '70%',textAlign:'center' }}
        />
      </Col>
      <Col span={6}>
        <Input
          value={record.type[columnIndex]?.price || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'price', e.target.value)}
          placeholder="Price"
          type="number"
          style={{ padding: '5px 0', width: '70%', textAlign:'center' }}
        />
      </Col>
      <Col span={6}>
        <Input
          value={record.type[columnIndex]?.discountPrice || ''}
          onChange={(e) => handleInputChange(record.key, columnIndex, 'discountPrice', e.target.value)}
          placeholder="Discount Price"
          type="number"
          style={{ padding: '5px 0', width: '70%', textAlign:'center' }}
        />
      </Col>
    </Row>
  );

  const handleAddRow = (selectedSize, currentIndex, id) => {
    const newData = [...data];
    const newKey = `${id}-${Date.now()}`;
    const newRow = {
      key: newKey,
      id: id,
      size: selectedSize,
      items:{ from:  '', to: '', price: '', isFromAPI: false },
      isNew: true,
    };

    const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
      if (item.id === id) {
        return index;
      }
      return lastIndex;
    }, -1);

    newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);
    const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);

    if (previousRow !== undefined) {
      const typeTo = parseFloat(previousRow.items.to);

      if (previousRow) {
        newRow.items.from = typeTo + 1;
      }
      setFromValue(typeTo + 1);

    }

    setData(newData);
  };

  const handleDeleteRow = (rowKey) => {
    showDeleteConfirmPrice(id).then((result) => {
      if (result) {
        console.log("User clicked Yes");

        const rowToDelete = data.find((item) => item.key === rowKey);
        if (rowToDelete) {
          if (data.length <= 1) {
            return;
          }
          const newData = data.filter((item) => item.key !== rowKey);
          const updatedData = newData.map((item, index) => ({ ...item, key: index }));
          setData(updatedData);
        }
      } else {
        console.log("User clicked No");
      }
    });
  }

  const rowSelection = {
    selectedRowKeys: checkedRowKeys,
    onChange: (selectedRowKeys) => {
      setCheckedRowKeys(selectedRowKeys);
    },
  };
  const handleSubmit = async () => {
    const edited_card_id = localStorage.getItem('edited_card');
    const sizeInfoData = data.filter((item) => checkedRowKeys.includes(item.itemID) || checkedRowKeys.includes(item.key))
      .map((item) => {
        const selectedTypes = Object.keys(item.items)
          .filter((typeKey) => item.items[typeKey]?.from !== '' && item.items[typeKey]?.to !== '' && item.items[typeKey]?.price !== ''&& item.items[typeKey]?.discountPrice !== '')
          .map((typeKeys) => ({
            from: item.items[typeKeys]?.from,
            to: item.items[typeKeys]?.to,
            price: item.items[typeKeys]?.price,
            discountPrice: item.items[typeKeys]?.discountPrice,
          }));
        return {
          itemId: item.id,
          size: item.size,
          cardName: selectedTypes,
        };
      });

    const newCardData  = {
      mainCardType : 1,
      edited_card_id:edited_card_id,
      default_check : checked,
      priceName: inputNameValue,
      sizeInfoData: sizeInfoData
    };

    console.log(newCardData);
    try {
      createCard(newCardData);
    } catch (err) {
      console.error("Error creating card:", err);
    }
  };

  const handleInputNameChange = (e) => {
    const newValue = e.target.value;
    setInputNameValue(newValue);
  };

  if (loading) {
    return (
      <LoadingSpin>
        <div>Loading...</div>
      </LoadingSpin>
    );
  }

  return (
    <div>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>Edite Album Lab</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        <div style={{ margin: 16}}  >
          <Row>
            <Space className='spane'>
              <label className='textInput'>Add price Name</label>
              <Input
                type="text"
                className="input_price"
                value={inputNameValue}
                onChange={handleInputNameChange}
              />
              <Checkbox className="default_checkbox" checked={defaultCheck}
                        onChange={() => setDefaultCheck(!defaultCheck)}>
                <div className="default_text">Check if you want set your default price list</div>
              </Checkbox>
            </Space>

          </Row>
        </div>
        <Table
          className='priceTable'
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.itemID ? record.itemID : record.key}
          pagination={false}
        />
        <Container className={'text-center'}>
          <Button onClick={handleSubmit } type="primary" htmlType="submit"  className='send-email-btn formBtn' >
            Update
          </Button>
        </Container>
      </div>
    </div>
  );
};


export default EditAlbumLab;


