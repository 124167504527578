import {  Modal  } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import React from 'react'
const { confirm } = Modal;

export const showDeleteConfirm = (id,custom) => {
  return new Promise((resolve, reject) => {
    confirm({
      title: ` Are you sure to delete this ${custom} item?`,
      icon: <ExclamationCircleFilled/>,
      content: `If you delete the ${custom}, you will lose ...`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  })
}

export const showDeleteConfirmPrice = (id) => {
  return new Promise((resolve, reject) => {
    confirm({
      title: 'Are you sure to delete your price list item?',
      icon: <ExclamationCircleFilled />,
      content: 'If you delete this row, you can lose ...',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        resolve(true);  // Resolve the promise with true when user clicks "Yes"
      },
      onCancel() {
        resolve(false);  // Resolve the promise with false when user clicks "No"
      },
    });
  });
}



export const sortColumns = (dataIndex) => ((a, b) => {
  if (a[dataIndex] == null && b[dataIndex] == null) {
    return 0;
  } else if (a[dataIndex] == null) {
    return -1;
  } else if (b[dataIndex] == null) {
    return 1;
  }
  return a[dataIndex].localeCompare(b[dataIndex]);
})

export const onChangeTable = (tableParams, setTableParams) => (pagination, filters, sorter, extra) => {

  let params = {};

  if (pagination) {
    params = { ...params, pagination };
  }

  if (sorter) {
    params = { ...params, sorter };
  }

  if (filters) {
    let searchFilters = {};
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (value && value[0]) {
        searchFilters = { ...searchFilters, [key]: value[0] };
      }
    });
    params = { ...params, filters: searchFilters };
  }
  
  setTableParams(params);
  
  if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
    // Additional logic to handle page size change
    // setData([]);
  }
};


export const handleGenerateUnamePass = (form) => {
  const username = generateRandomUsername(6);
  const password = generateRandomUsername(8);
  form.setFieldsValue({
    username: username,
    password: password,
    confirm: password,
  });
};

export const generateRandomUsername = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
export const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const handleTagChange = (selectedTags, form, message) => {
  const validTags = selectedTags.filter(isValidEmail);
  if (validTags.length !== selectedTags.length) {
    message.error('Please enter valid email addresses for all tags.');
  }
  form.setFieldsValue({ email: validTags });
};
