import React from 'react';
import EditCardsStudio from "../EditCardsStudio";

const EditFlatCardsStudio = () => {

  const nameCards = "Flat Cards";
  const navigate_link = '/Studio/flat_cards_list';
  const mainCardType = 1;

  return (
    <div>
      <EditCardsStudio nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};



export default EditFlatCardsStudio;


