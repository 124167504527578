import React, { useEffect, useState } from 'react';
import { Col, Form, Radio, Row, Select, Spin } from "antd";
import style from "../Template.module.css";
import clientAPI from "../../../../api/api";

const SelectCard = ({ selectType }) => {
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [templatePosition, setTemplatePosition] = useState('front');

    const fetchSizesAndDetails = async (selectedType) => {
        setLoading(true);
        try {
            let productID = 1; // Assuming "Flat Cards" have an ID of 1
            switch (selectedType) {
                case 'Fold Over': productID = 3; break;
                case 'Flat Cards': productID = 1; break;
                case 'Photo Cards': productID = 4; break;
                // Add more cases as necessary
            }
            const sizesRes = await clientAPI.getProductObjectSizes(2, 'SIZE');
            const defSizes = await clientAPI.getStudioCardDefailtByType(productID);

            if (defSizes.data && defSizes.data.item_ids && Array.isArray(defSizes.data.item_ids) && defSizes.data.item_ids.length > 0) {
                const sizesBody = sizesRes.data[selectedType];
                const sizesMap = sizesBody.reduce((acc, size) => {
                    acc[size.ID] = size;
                    return acc;
                }, {});

                const combinedData = defSizes.data.item_ids.reduce((acc, item) => {
                    const size = sizesMap[item];
                    if (size) {
                        acc.push({
                            id: item,
                            name: `${size.CAT_TEXT}`,
                            width: `${size.width}`,
                            height: ` ${size.height}`,
                            price: `${size.price}`,
                        });
                    }
                    return acc;
                }, []);
                setData(combinedData);
                setLoading(false);
                return defSizes.data;
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching sizes and details', error);
        }

    };

    useEffect(() => {
        if (selectedType) {
            fetchSizesAndDetails(selectedType);
        }
    }, [selectedType]);



    const handleTemplatePosition = (e) => {
        const templatePosition = e.target.value;
        setTemplatePosition(templatePosition);
    };

    return (
      <div>
          <Row justify="space-between" className='mb-10'>
              <Col span={12} >
                  <Form.Item
                    name="cards_type"
                    label="Card Type"
                    rules={[{ required: true }]}
                  >
                      <Select
                        className={style.selectedPrice}
                        onChange={setSelectedType}
                        placeholder="Select Card Type"
                        value={selectedType}
                      >
                          {[
                              {
                                  label: "Fold Over",
                                  value: "Fold Over",
                              },
                              {
                                  label: "Flat Cards",
                                  value: "Flat Cards",
                              },
                              {
                                  label: "Photo Cards",
                                  value: "Photo Cards",
                              },
                          ].map((option) => (
                            <Select.Option key={option.value} value={option.value}
                                           className={style.selectedOption}>
                                {option.label}
                            </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>
              </Col>
              <Col span={11} >
                  <Form.Item name="size_id" label="Card Size" rules={[{ required: true }]}>
                      <Select
                        placeholder="Select Card Size"
                        className={style.selectedPrice}
                        onChange={setType}
                        value={type}
                      >
                          {loading && selectedType ? (
                            <Select.Option className={style.selectedOption} key="loading" value="loading">
                                <Spin />
                            </Select.Option>
                          ) : (
                            data.map((detail) => (
                              <Select.Option
                                className={style.selectedOption}
                                key={detail.id}
                                value={detail.id}
                              >
                                  {detail.name}
                              </Select.Option>
                            ))
                          )}
                      </Select>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify='space-between' className='mb-10'>
              <Col span={24} >
                  <Form.Item
                    name="templatePosition"
                    label="Template Position"
                    rules={[{ required: true }]}
                  >
                      <Radio.Group
                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                        onChange={handleTemplatePosition}
                        value={templatePosition}
                      >
                          {selectedType === 'Fold Over' && (
                            <>
                                <Radio value="front-back">Front - Back</Radio>
                                <Radio value="inside">Inside</Radio>

                            </>
                          )}
                          {selectedType === 'Flat Cards' && (
                            <>
                                <Radio value="front">Front</Radio>
                                <Radio value="back">Back</Radio>
                            </>
                          )}
                          {selectedType === 'Photo Cards' && (
                            <>
                                <Radio value="front">Front</Radio>
                            </>
                          )}
                      </Radio.Group>
                  </Form.Item>

              </Col>
          </Row>


      </div>
    );
};

export default SelectCard;
