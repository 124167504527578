import React, {useEffect, useRef, useState} from 'react';
import getColumnSearchProps from '../../../Utils/getColumnSearchProps'
import {sortColumns, onChangeTable, showDeleteConfirm} from '../../../Utils/isFunction'
import {Space, Table, Tour} from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../../../../hoc/fetchingHook";
import clientAPI from "../../../../api/api";

const ListPackage = () => {

  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });
  const navigate = useNavigate();
  const handleTableChange = onChangeTable(tableParams, setTableParams);

  const [getPackage, packageLoading, packageError] = useFetching(async () => {
    const {data: res} = await clientAPI.getPackageByStudioId();
    if (res) {
      // const dataWithIds = res.map((item, index) => ({ ...item, id: index + 1 }));
      setData(res);
    }
  });

  useEffect(() => {
    getPackage();
  }, []);


  useEffect(() => {
    localStorage.setItem('packageList', JSON.stringify(data));
  }, [data]);


  const handleDelete = (id) => {

    showDeleteConfirm(id, 'Package').then((result) => {
      if (result) {
        const updatedData = data.filter(item => item.id !== id);
        setData(updatedData);
      }
      })
    }

  const handleEdit = (record) => {
    localStorage.setItem('package_edit', JSON.stringify(record));
    localStorage.setItem('selected_package', JSON.stringify(record.id));
    navigate('/studio/edit_package');
    window.location.reload();
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'index',
      key: 'index',
      align:'center',
      render: (_, record, index) => (
          <span>
      {index + 1}
    </span>
      ),
    },
    {
      title: 'Package Category',
      dataIndex: 'package_category',
      key: 'package_category',
      align: 'center',
      ...getColumnSearchProps("package_category"),
      sorter: sortColumns('package_category'),
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => (
          <span>
            {record.package_category && Object.values(record.package_category).map((item, index) => (
                <div key={index} style={{ borderBottom: '1px solid #6d6868' }}>{item}</div>
            ))}
          </span>
      ),
    },

    {
      title: 'Package Name',
      dataIndex: 'package_name',
      key: 'package_name',
      textAlign:'center',
      ...getColumnSearchProps("package_name"),
      sorter: sortColumns('package_name'),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Package Type',
      dataIndex: 'package_type',
      key: 'package_type',
      textAlign:'center',
      ...getColumnSearchProps("package_type"),
      sorter: sortColumns('package_type'),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Package Items',
      dataIndex: 'title_name',
      key: 'title_name',
      ...getColumnSearchProps("title_name"),
      sorter: sortColumns('title_name'),
      sortDirections: ['descend', 'ascend'],
      align:'center',
      render: (_, record) => (
          <span>
        {record.size_data && record.size_data.map(item => (
            <div style = {{borderBottom:'1px solid #6d6868' }} key={item.id}>{item.title_name}  {item.name}</div>
        ))}
      </span>
      ),
    },
    {
      title: 'Count',
      dataIndex: 'quantity',
      key: 'quantity',
      ...getColumnSearchProps("quantity"),
      sorter: sortColumns('quantity'),
      sortDirections: ['descend', 'ascend'],
      align:'center',
      render: (_, record) => (
          <span>
        {record.size_data && record.size_data.map(item => (
            <div style = {{borderBottom:'1px solid #6d6868'}} key={item.id}>{item.quantity}</div>
        ))}
      </span>
      ),
    },

    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      render: (_, record) => (
          <span style={{whiteSpace: 'pre-line'}}>
            {record.description}
          </span>
      ),
    },

    {
      key: 'total',
      title: 'Total',
      dataIndex: 'total',
      align:'center',
      render: (text) => {
        const parsedValue = parseFloat(text);
        // Check if the parsed value is a number before calling toFixed()
        if (!isNaN(parsedValue)) {
          return parsedValue.toFixed(2);
        }
        return text; // return original text if it's not a valid number
      }
    },
    {
      title: 'Edit',
      dataIndex: 'studio_edit',
      key: 'studio_edit',
      align:'center',
      render: (_, record) => (
        <Space wrap>
          <EditOutlined className="my_ant_icons_table" onClick={() => handleEdit(record)} />
        </Space>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'studio_delete',
      key: 'studio_delete',
      align:'center',
      render: (_, record) => (
        <Space wrap>
          <DeleteOutlined className="my_ant_icons_table" onClick={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];


  return(
    <>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}>  Package List</h3>
      </div>
      <Table
        className="table-striped-rows studio-table-rows"
        pagination={tableParams.pagination}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
        bordered={true}
        onChange={handleTableChange}
        scroll={{ x: 100 }}
      />
    </>

  );
}


export default ListPackage;