import React from 'react';
import { Row, Col, Space, Typography } from 'antd';
const { Text } = Typography;

function AlbumSidebarHeader() {
    return (
        <Row gutter={16}>
            <Col span={12}>
                <h3>Album</h3>
            </Col>
                <Col className="album-header-text" span={12}>
                    <Text  type="secondary">$240.00</Text>
                </Col>

        </Row>
    );
}

export default AlbumSidebarHeader;