import React, {useState,useEffect} from "react";
import clientAPI from "../../../api/api";
import { Modal, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {showDeleteConfirm, sortColumns} from "../../Utils/isFunction";
import StudioCreditAdd from "./StudioCreditAdd";
import {useFetching} from "../../../hoc/fetchingHook";
import getColumnSearchProps from "../../Utils/getColumnSearchProps";


const StudioCreditList = () => {

  const [modalCredit, setModalCredit] = useState(false);
  const [creditStudioData, setCreditStudioData]=useState([])
  const [selectedCreditId, setSelectedCreditId] = useState(null);

  const [getUserCredit, isCreditLoading, creditError] = useFetching(async () => {
    const res = await clientAPI.getCreditListByUser();
    if (res) {
      setCreditStudioData(res.data.credits)
    }
  });

  useEffect(() => {
    getUserCredit();
  }, []);

  const handleDelete = (id) => {
    showDeleteConfirm(id, 'Credit ').then((result) => {
      if (result) {
        setCreditStudioData((prevData) => {
          const updatedData = prevData.filter((item) => item.id !== id);
          localStorage.setItem('creditStudioData', JSON.stringify(updatedData))
          return updatedData;
        });
      } else {
        console.log('NO');
      }
    });
  };

  const handleEdit = (id) => {
    const editItem = creditStudioData.filter((item) => item.id === id);
    setSelectedCreditId(editItem)
    setModalCredit(true);
  };
  const handleCancel = () => {
   setModalCredit(false)
  };
  const columns = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      align: 'center',
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
      render: (_, record) => ( record.id ),
    },
    {
      title: 'Credit line applied',
      dataIndex: 'credit_total_amount',
      key: 'credit_total_amount',
      align: 'center',
      sorter: (a, b) => a.credit_total_amount - b.credit_total_amount,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) =>  `$ ${(record.credit_total_amount / 100).toFixed(2)} `
    },
    {
      title: 'Credit line applied',
      dataIndex: 'credit_amount_applied',
      align: 'center',
      sorter: (a, b) => a.credit_amount_applied - b.credit_amount_applied,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) =>  `$ ${(record.credit_amount_applied / 100).toFixed(2)} `
   },
    {
      title: 'Credit balance',
      dataIndex: 'credit_balance',
      align: 'center',
      sorter: (a, b) => a.credit_balance - b.credit_balance,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => `$ ${(record.credit_balance / 100).toFixed(2)}`
    },


    {
      title: 'Studio',
      dataIndex: 'user_id',
      align: 'center',
      sorter: (a, b) => a.user_id - b.user_id,
      ...getColumnSearchProps("user_id"),
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => (record.user_id),
    },
    {
      title: 'Payment Type',
      dataIndex: 'payment_type',
      align: 'center',
      sorter: (a, b) => a.payment_type - b.payment_type,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        let paymentTypeText = '';
        switch (record.payment_type) {
          case 1:
            paymentTypeText = 'Credit';
            break;
          case 2:
            paymentTypeText = 'Cash';
            break;
          case 3:
            paymentTypeText = 'Check';
            break;
          case 4:
            paymentTypeText = 'Adjustment';
            break;
          default:
            paymentTypeText = 'Unknown';
        }

        return (
            <>
              <span>{paymentTypeText}</span>
            </>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      align: 'center',
      key: (record) => record.id,
      render: (_, record) => (
        <EditOutlined className="my_ant_icons_table" onClick={() => handleEdit(record.id)} />
      ),
    },
    {
      key: 'delete',
      title: 'Delete',
      align: 'center',
      render: (_, record) => (
        <DeleteOutlined
          className="my_ant_icons_table"
          onClick={() => handleDelete(record.id)}
        />
      ),
    },
  ];
    return (
        <>
            <div className={'text-center'}>
                <h3 className={'text-uppercase mb-4 header-title'}>Studio Credit</h3>
            {isCreditLoading ? (
                  <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={creditStudioData}
                    loading={isCreditLoading}

                  />
            ) : (
              <Table
                className="table-striped-rows studio-table-rows"
                pagination={true}
                loading={isCreditLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={creditStudioData}
                scroll={{ x: 100 }}
              />

            )}
            </div>


          <Modal
            centered
            open={modalCredit}
            onCancel={handleCancel}
            cancelButtonProps
            footer={false}
          >
            <StudioCreditAdd
              selectedCreditId={selectedCreditId}
              setModalCredit={setModalCredit}
             />
          </Modal>
        </>
    );
};

export default StudioCreditList;