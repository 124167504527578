import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Dropdown, message, Tooltip } from 'antd';
import {
    HeartFilled,
    HeartOutlined,
    ShoppingCartOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
    MinusCircleOutlined,
    LockOutlined,
    ExpandOutlined
} from '@ant-design/icons';
import styles from '../Gallery.module.css';
import ImageDownloaderButton from './ImageDownloaderButton';
import ShareButton from './ShareButton';
import { useNavigate } from 'react-router-dom';
import { showDeleteConfirm } from "../../../Utils/isFunction";
import { AddGalleryFavorite } from '../GalleryHeader/store/thunk';
import { useDownloadImages, useFavoriteImages, useFolderDataList, useIsSelectedAll, usePrivateImages, useSelectedImages } from '../GalleryHeader/store/selector';
import { useDispatch } from 'react-redux';
import { changeDownloadType, setDownloadImages, toggleFavorite, toggleSelected } from '../GalleryHeader/store/galleryHeaderSlice';

const IMG_SERVER = 'https://studioseye.com';
const H_THUMBNAILS = '/H_THUMBNAILS/';
const THUMBNAILS = '/THUMBNAILS/';

const ImageItem = ({
    index,
    img,
    currentImage,
    openLightbox,
    imageSources,
    selectedFolder,
    handlePlusCircle,
    handleMenuClick,
    removeFolderImageFnc,
    selectedBg,
}) => {

    const navigate = useNavigate();
    const [items, setItems] = useState();
    const [hover, setHover] = useState(false);
    const favoriteImages = useFavoriteImages()
    const selectedImages = useSelectedImages()
    const privateImages = usePrivateImages()
    const folderDataList = useFolderDataList()
    const dispatch = useDispatch()

    const isAllChecked = useIsSelectedAll()

    const downloadImages = useDownloadImages()


    useEffect(() => {
        if (isAllChecked) {
            dispatch(changeDownloadType({ type: 1 }))
        }
        else {
            dispatch(changeDownloadType({ type: 2 }))
        }


    }, [isAllChecked])

   


    const imageSelection = useCallback(
        (img) => {

            if (!isAllChecked) {
                dispatch(setDownloadImages({ img, type: 1 }))
            }
            else {
                dispatch(setDownloadImages({ img, type: 2 }))
            }


            dispatch(toggleSelected(img));
        },
        [dispatch, isAllChecked]
    );









    const menu = { items };



    const handelImageChange = (img) => {
        const imagesForOrder = {
            ...img,
            selectedBg
        }
        localStorage.setItem('ImagesForOrder', JSON.stringify(imagesForOrder));
        navigate('/prints');
    }

    const addToFavorite = ({ id, img }) => {
        const isFavorite = favoriteImages.some((image) => image.ID === id);
        dispatch(toggleFavorite({ img }))
        if (isFavorite) {
            dispatch(AddGalleryFavorite({ type: "Favorite", method: "REMOVE", id }));
        } else {
            dispatch(AddGalleryFavorite({ type: "Favorite", method: "ADD", id }));
        }

    }


    const addToPrivate = (index, id, img) => {
        // setPrivateImages((prevPrivates) => {
        //     const newPrivates = [...prevPrivates];
        //     const isPrivate = newPrivates.some(image => image.ID === img.ID);
        //     if (isPrivate) {
        //         showDeleteConfirm(img.ID, 'Private ').then((result) => {
        //             if (result) {
        //                 const updatedPrivates = prevPrivates.filter((image) => image.ID !== img.ID);
        //                 setPrivateImages(updatedPrivates);
        //                 addFavorite(img, 'Private', 'REMOVE')
        //             }
        //         });
        //     } else {
        //         addFavorite(img, 'Private', 'ADD')
        //         newPrivates.push(img);
        //     }
        //     return newPrivates;
        // });
    }

    useEffect(() => {
        if (folderDataList.length > 0) {
            const itemsC = folderDataList.map((item) => {
                let label = '';
                let quantity = 0;
                const labelParts = [
                    item.folder_name, item.myown_folder ? item.myown_folder : null,
                    (item.selectcard_type !== '0' && item.selectcard_type !== 0) ? `${item.selectcard_type} ` : ' ',
                    (item.selected_size !== '0' && item.selected_size !== 0) ? `${item.selected_size} ` : ' ',
                ];
                label = labelParts.filter(Boolean).join(' ');
                quantity = ' ( ' + (item.images.length) + ' / ' + item.quantity + ')';
                label = label + quantity;
                return {
                    label,
                    key: item.id,
                    quantity,
                    onClick: () => handleMenuClick(item),
                };
            });
            setItems(itemsC);
        }


    }, [folderDataList, handleMenuClick]);

    const deleteImageFromData = async (id) => {
        const result = await showDeleteConfirm(id, 'Image');
        if (result) {

            // removeImage(id);
        } else {
            console.log('Deletion cancelled');
        }
    };


    return (
        <div
            style={selectedBg ? { backgroundImage: `url(${selectedBg})` } : {}}
            className={styles.imageContainer}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <img
                key={img.ID}
                id={img.ID}
                src={`${IMG_SERVER}${img.IMAGE_LINK_PATH}${THUMBNAILS}${img.IMAGE_NAME}`}
                alt={img.IMAGE_NAME}
                loading="lazy"
                onClick={() => openLightbox(img.ID)}
            />

            {hover && (
                <div className={styles.hoverEffect}>
                    {/* Действие "Избранное" */}
                    <div className={favoriteImages.some(image => image.ID === img.ID) ? styles.favoriteActionsActive : styles.favoriteActions}>
                        <button id={img.ID} onClick={() => addToFavorite({ id: img.ID, img })} className={styles.inButton}>
                            {favoriteImages.some(image => image.ID === img.ID) ? (
                                <Tooltip title="Disliked image">
                                    <HeartFilled className={styles.favoriteButton} />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Liked image">
                                    <HeartOutlined className={styles.inButton} />
                                </Tooltip>
                            )}
                        </button>
                    </div>

                    {folderDataList && folderDataList.length > 0 && (
                        <Dropdown
                            menu={{
                                items: folderDataList.map(item => ({
                                    label: `${item.folder_name} ${item?.selected_size} (${item.images.length})`,
                                    key: item.id,
                                    onClick: () => handleMenuClick(item)
                                }))
                            }}
                            trigger={['click']}
                        >
                            <div className={styles.AddActions}>
                                <Tooltip title="Add image to Folder">
                                    <span>
                                        <PlusCircleOutlined onClick={() => handlePlusCircle(img)} />
                                    </span>
                                </Tooltip>
                            </div>
                        </Dropdown>
                    )}


                    {selectedFolder && Array.isArray(selectedFolder.images) && (
                        <div className={styles.removeFolderImage}>
                            <Tooltip title="Delete image from Folder">
                                <button className={styles.inButton} id={img.ID} onClick={() => removeFolderImageFnc(img.ID, selectedFolder.id)}>
                                    <MinusCircleOutlined />
                                </button>
                            </Tooltip>
                        </div>
                    )}


                    <div
                        className={!!selectedImages.includes(id => id === img.ID) ? styles.selectedActionsActive : styles.selectedActions}
                    >
                        <Checkbox
                            className={styles.inButton}
                            style={{ marginLeft: '5px' }}
                            checked={
                                (isAllChecked && !downloadImages.exclude.some((image) => image.ID === img.ID)) ||
                                (!isAllChecked && downloadImages.include.some((image) => image.ID === img.ID))
                            }
                            onChange={() => imageSelection(img)}
                        />


                    </div>


                    <Tooltip title="Choose this image">
                        <div className={privateImages.some(image => image.ID === img.ID) ? styles.LeftPrivateActive : styles.LeftPrivate}>
                            <LockOutlined id={img.ID} onClick={() => addToPrivate(index, img.ID, img)} className={styles.inButton} />
                        </div>
                    </Tooltip>


                    <div className={styles.DownWrapper}>
                        <div className={styles.buttonActions}>
                            <Tooltip title="Expand this image">
                                <button className={styles.inButton}>
                                    <ExpandOutlined key={img.ID} id={img.ID} onClick={() => openLightbox(img.ID)} />
                                </button>
                            </Tooltip>
                            <Tooltip title="Share this image">
                                <button className={styles.inButton}>
                                    <ShareButton initialImageUrl={imageSources[currentImage]} />
                                </button>
                            </Tooltip>
                            <Tooltip title="Buy this image">
                                <button
                                    className={styles.inButton}
                                    key={img.ID}
                                    onClick={() => handelImageChange(img)}
                                >
                                    <ShoppingCartOutlined />
                                </button>
                            </Tooltip>
                            <Tooltip title="Delete this image from data">
                                <button className={styles.inButton} onClick={() => deleteImageFromData(img.ID)}>
                                    <DeleteOutlined />
                                </button>
                            </Tooltip>
                            <Tooltip title="Download this image">
                                <span>
                                    <ImageDownloaderButton image={img} IMG_SERVER={IMG_SERVER} H_THUMBNAILS={H_THUMBNAILS} />
                                </span>
                            </Tooltip>
                        </div>
                        <div className={styles.imageNameActions}>
                            <Tooltip title="Image name">
                                <button className={styles.inButton}>
                                    <p className={styles.imageName}>{img.IMAGE_NAME}</p>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageItem;