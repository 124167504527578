import { useDrag } from "react-dnd";
import { useContext, useEffect, useState } from "react";
import DropZoneContext from "../../../context/DropZoneContext";

const DraggableImage = ({ id, src, img_path, img_name }) => {
    const { imageDropZones, setImageDropZones, setIsDraggingElem } = useContext(DropZoneContext);
    const isDropped = Object.values(imageDropZones).includes(id);
    const [isDragging, setIsDragging] = useState(false);

    const [{ isDragging: dragIsDragging }, drag] = useDrag({
        type: "image",
        item: () => {
            setIsDragging(true);
            setIsDraggingElem(true);
            return { id, img_path, img_name };
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                setImageDropZones(prev => ({
                    ...prev,
                    [dropResult.name]: item
                }));
            }
            setIsDragging(false);
            setIsDraggingElem(false);
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        const handleTouchStart = () => {
            setIsDragging(true);
            setIsDraggingElem(true);
        };

        const handleTouchMove = (e) => {
            e.preventDefault(); // Prevent scrolling
        };

        const handleTouchEnd = () => {
            setIsDragging(false);
            setIsDraggingElem(false);
        };

        const element = document.getElementById(`draggable-image-${id}`);
        if (element) {
            element.addEventListener('touchstart', handleTouchStart);
            element.addEventListener('touchmove', handleTouchMove);
            element.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            if (element) {
                element.removeEventListener('touchstart', handleTouchStart);
                element.removeEventListener('touchmove', handleTouchMove);
                element.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [id, setIsDraggingElem]);

    return (
      <img
        id={`draggable-image-${id}`}
        ref={drag}
        src={src}
        alt={img_name} // It's good practice to include an alt attribute
        style={{
            opacity: isDragging ? 0.5 : 1,
            border: isDropped ? '2px solid #204998' : 'none'
        }}
        className="selectedImage_img"
      />
    );
};

export default DraggableImage;
