import {Button, Col, Input, Modal, Row, Space, Table, Tour} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {useFetching} from "../../../hoc/fetchingHook";
import clientAPI from "../../../api/api";
import {Checkbox} from 'antd';
import {useNavigate} from 'react-router';
import LoadingSpin from "../../Utils/LoadingSpin";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import './background.css'
import {showDeleteConfirm} from "../../Utils/isFunction";
import {useHelperTab} from "../../../context/HelperTabContext";

const CreateGroup = ({helperShow,setHelperShow}) => {
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const { helperTab } = useHelperTab();

    const [open, setOpen] = useState(helperTab);
    useEffect(() => {
        if (!open) { setHelperShow(false); }
    }, [open]);

    useEffect(() => {
        if (helperShow) { setOpen(true);  }
    }, [helperShow]);
    const ref1 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);


    const steps = [
        {
            title: 'Background Group Title',
            description: 'Please enter the name of your Background Group in the designated field. Should you require this background group to be the default for future use, ensure to mark the accompanying checkbox. This action will establish the entered background  as the standard selection across the platform.',
            target: () => ref1.current,
        },

        {
            title: 'Background Customizer',
            description: 'The Background Customizer is a versatile tool designed to enhance the visual appeal of your presentations or client interfaces. With this feature, you have the freedom to select and apply different background themes that best fit the context or aesthetic preference for your client interactions. Here’s how you can use it:\n' +
                '\n' +
                'Selecting a Background: Browse through a wide range of background options varying in colors, patterns, and themes. Whether you\'re looking for something professional, playful, or specific to a certain occasion, you\'ll find a background that suits your needs.\n' +
                '\n' +
                'Customizing with a Name: For a more personalized touch, you can also add a name or title to the selected background. This could be the name of your client, your company, or the specific event you are organizing. It adds a unique and tailored aspect to your interface or presentation.\n' +
                '\n' +
                'Preview and Apply: Before finalizing, you\'ll have the option to preview how your chosen background and name customization will appear. This ensures that everything looks just right. Once satisfied, apply your selections with a simple click, and the new background will be set.',
            target: () => ref3.current,
        },

        {
            title: 'Save and Personalize',
            description: 'Save and Personalize Your Background Group',
            target: () => ref5.current,
        },
    ];

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editedData, setEditedData] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [text, setText] = useState("");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [checked, setChecked] = useState("NO");
    const [previewImage, setPreviewImage] = useState({
        name:'',
        path:'',
    });


    const studio_id = localStorage.getItem('studio_id');
    const lab_id = localStorage.getItem('user_id');

    if (studio_id && studio_id.trim() !== "") {
        localStorage.setItem('current_user_id', studio_id);
    }else {
        localStorage.setItem('current_user_id', lab_id);

    }

    const [getBackgroundList, backgroundLoading, backgroundError] = useFetching(async (price_list_id) => {
        const {data: res} = await clientAPI.getBackgroundsByUserId();
        if (res) {
            setData(res);
        }
    });

    const [saveBackgroundCheckSuccess, backgroundCheckLoading, backgroundCheckError] = useFetching(async (obj) => {
        const {data: res} = await clientAPI.checkBackgrounds(obj);
        if (res) {
            let currentPath = '';
            const user_type = localStorage.getItem('user_type');
            if(user_type === "LAB"){
                currentPath = '/labs/background_group_list';
            }else if(user_type === "STUDIO"){
                currentPath = '/studio/background_group_list';
            }
            navigate(currentPath);
            window.location.reload();
        }
    });

    const handleCancel = () => setPreviewOpen(false);
    const handleInputChangeText = (e) => {
        setText(e.target.value);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        // Determine which rows were added or removed
        const addedKeys = newSelectedRowKeys.filter(key => !selectedRowKeys.includes(key));
        const removedKeys = selectedRowKeys.filter(key => !newSelectedRowKeys.includes(key));

        // Update editedData based on added or removed rows
        setEditedData(prevState => {
            let newState = { ...prevState };

            // Add newly selected rows to editedData
            addedKeys.forEach(key => {
                const record = data.find(item => item.id === key);
                if (record) {
                    newState[key] = record;
                }
            });


            removedKeys.forEach(key => {
                delete newState[key];
            });

            return newState;
        });
    };


    const handleChecked = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const inputChange = (event, record) => {
        const updatedValue = event.target.value;
        // Check if the row is selected
        if (selectedRowKeys.includes(record.id)) {
            setEditedData(prevState => ({
                ...prevState,
                [record.id]: {
                    ...record,
                    name: updatedValue
                }
            }));
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSave = async () => {
        const obj = {
            background_group: editedData,
            is_default: checked,
            name: text
        };
        console.log(obj);
        saveBackgroundCheckSuccess(obj);
    };

    const handleChange = (rec) => {
        setPreviewOpen(true);
        setPreviewImage({
            name:rec.name,
            path:`/background/${rec.path}`,
        });
    };


    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
            width: 400,
            align: 'center',
            render: (text, record) => {
                const editedValue = editedData[record.id]?.name;
                const isRowSelected = selectedRowKeys.includes(record.id);
                return (
                    <Input
                      style={{width:'70%'}}
                        value={editedValue || text}
                        onChange={e => inputChange(e, record)}
                        disabled={!isRowSelected} // Disable the input if the row is not selected
                    />
                );
            }
        },
        {
            title: 'Background',
            dataIndex: 'path',
            key: "path",
            align: 'center',
            render: (path,record) => (
                <img
                    onClick={e => handleChange(record)}
                    src={`/background/${path}`}
                    alt="Background"
                    style={{width: '70px', height: '70px'}} // Optional: set width and height if you want
                />
            ),
        },
    ];
    const user_type = localStorage.getItem('user_type');

    if (user_type === "LAB") {
        columns.push({
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            align: 'center',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined className="my_ant_icons_table" onClick={() => deleteItem(record.id)}/>
                </Space>
            ),
        });
    }
    useEffect(() => {
        getBackgroundList();
    }, []);

    useEffect(() => {
        checkboxChecked ? setChecked("YES") : setChecked("NO")
    }, [checkboxChecked]);

    const deleteItem = (id) => {
        showDeleteConfirm(id, 'Background').then((result) => {
            if (result) {
                console.log('yes')
            } else {
                console.log('NO')
            }
        });
    };
    const headerTitle = user_type === "STUDIO" ? "STUDIO" : "LAB";
    return (
        <>
            <Modal open={previewOpen} title={previewImage.name} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage.path}
                />
            </Modal>
            <div className="text-center mt-4">
                <h3 className="text-uppercase mb-4 header-title">Background List {headerTitle}</h3>
            </div>

            <div style={{margin: 16}} className='inputshHader'>
                <Row ref={ref1}>
                    <Col  md={24} span={24}>
                        <label className="textInput">Background group</label>
                        <Input value={text} type="text" className="input_price" onChange={handleInputChangeText}/>
                    </Col>
                    <Col md={12} span={24}>
                        <Checkbox className="default_checkbox" checked={checkboxChecked}
                                  onChange={() => handleChecked()}>
                            <div className="default_text">Check if you want set your default Group</div>
                        </Checkbox>
                    </Col>
                    <Col  md={12} span={24} className='btnHeader'>
                        <Button ref={ref5}  loading={backgroundCheckLoading} className="btn_price btn_save background__button-save"
                                st="true" onClick={handleSave}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className={"for-ref-left"} ref={ref3}></div>

            <Table
                className="table-striped-rows studio-table-rows background_Table"
                rowSelection={rowSelection}
                loading={backgroundLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                key={(record) => record.id}
            />

            {isWindowWidthGreaterThan577 && <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>}
        </>
    );
};

export default CreateGroup;
