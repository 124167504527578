import React from 'react';
import { Card } from 'antd';
import './ScrollableContainer.css';

const ScrollableContainer = ({ pages,setCurrentSpread }) => {
    return (
        <div className="scroll-container">
            {pages.map((page, index) => (
                <Card
                    onClick={() => setCurrentSpread(page)}
                    key={index}
                    hoverable
                    style={{ width: 260, display: 'flex', border: 'none', boxShadow: 'none' }}
                >
                    <div className="album-component">{page.component}</div>
                    <div className="page-label">{page.label}</div>
                </Card>
            ))}
        </div>
    );
};

export default ScrollableContainer;