import React, {useEffect, useState} from 'react';
import {Button, Col, Form,  Row, Select,  Spin} from "antd";
import clientAPI from "../../../../api/api";
import {useContractContext} from "../../../../context/ContactContext";
import {useFetching} from "../../../../hoc/fetchingHook";


const CardPackage = ({parent_id,form}) => {

    const [type, setType] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [lastId, setLastId] = useState(0);
    const {formData, setFormData} = useContractContext();
    const [selectedType, setSelectedType] = useState(1);
    const [selectedPaperType, setSelectedPaperType] = useState('');

    const [getStudioDefaultCard, studioDefaultCardLoading, studioDefaultCardError] = useFetching(async () => {
        const {data: res} = await clientAPI.getStudioCardDefailtByType(selectedType);
        if (res) {
            // const dataWithIds = res.map((item, index) => ({ ...item, id: index + 1 }));
            // setData(dataWithIds);
        }
    });


    const fetchSizesAndDetails = async (selectedType) => {
        form.setFieldsValue({ card_size: undefined });
        setLoading(true);
        try {
            let productName = 'Flat Cards';
            let productID = 1;
            switch (selectedType) {
                case 3:
                    productName = 'Fold Over';
                    productID = 3;
                    break;
                case 1:
                    productName = 'Flat Cards';
                    productID = 1;
                    break;
                case 4:
                    productName = 'Photo Cards';
                    productID = 4;
                    break;
                default:
                    break;
            }
            const sizesRes = await clientAPI.getProductObjectSizes(2, 'SIZE');
            const defSizes = await clientAPI.getStudioCardDefailtByType(selectedType)
            if (sizesRes.data && sizesRes.data[productName] && sizesRes.data[productName].length > 0) {
                if (defSizes.data && defSizes.data.item_ids && Array.isArray(defSizes.data.item_ids) && defSizes.data.item_ids.length > 0) {
                    const sizesBody = sizesRes.data[productName];
                    console.log(defSizes.data.item_ids);
                    console.log(productName);
                    console.log(sizesBody);

                    // Create an object for quick ID lookup
                    const sizesMap = sizesBody.reduce((acc, size) => {
                        acc[size.ID] = size;
                        return acc;
                    }, {});

                    const combinedData = defSizes.data.item_ids.reduce((acc, item) => {
                        const size = sizesMap[item]; // Find the corresponding size by ID
                        if (size) {
                            acc.push({
                                id: item,
                                name: `${size.width} x ${size.height}`,
                            });
                        }
                        return acc;
                    }, []);

                    console.log(combinedData);
                    setData(combinedData);
                    setLoading(false);
                    return defSizes.data;
                } else {
                    console.error('defSizes.data.item_ids is not an array or is empty');
                }
            } else {
                console.error('sizesRes.data is not structured as expected');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching sizes and details', error);
        }

    };

    useEffect(() => {
        if (selectedType) {
            fetchSizesAndDetails(selectedType);
        }
    }, [parent_id, selectedType]);


    useEffect(() => {
        getStudioDefaultCard()
    }, []);
    const handleAddClick = () => {
        let title_name = "Card"
        let newId = 1;
        let newItemId = lastId + 1;
        const existingIds = new Set(formData?.size_data?.map(item => item.item_id));
        while (existingIds.has(newItemId)) {
            newItemId++;
        }
        setLastId(newItemId);
        if (formData.size_data && Array.isArray(formData.size_data)) {
            newId = formData.size_data.length > 0 ? Math.max(...formData.size_data.map(item => item.id)) + 1 : 1;
        }

        const newData = {
            id: newId,
            item_id: newItemId,
            name: type + "  " + selectedType + " " + selectedPaperType,
            quantity: 1,
            title_name: title_name,
            type: 0,
            card_type:selectedType,
            paper_type:selectedPaperType
        };
        const updatedSizeData = [...(formData.size_data || []), newData];
        const updatedFormData = {...formData, size_data: updatedSizeData};
        setFormData(updatedFormData);
    };
    return (
        <div>
            <Row justify="space-between">
                <Col span={11}>
                    <Form.Item name="cards_type" label="Card Type" rules={[{ required: true }]}>
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            onChange={setSelectedType}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "").toLowerCase().localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            value={selectedType}
                        >
                            {[
                                {
                                    label: "Fold Over",
                                    value: 3,
                                },
                                {
                                    label: "Flat Cards",
                                    value: 1,
                                },
                                {
                                    label: "Photo Cards",
                                    value: 4,
                                },
                            ].map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item name="card_size" label="Card Size" rules={[{required: true}]}>
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            value={type} // Control the value with the 'type' state
                            onChange={setType}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "").toLowerCase().localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                            }
                        >
                            {loading && selectedType ? (
                                <Select.Option style={{ textAlign: "center" }} key="loading" value="loading">
                                    <Spin />
                                </Select.Option>
                            ) : (
                                data.map((detail) => (
                                    <Select.Option
                                        style={{ textAlign: "center" }}
                                        key={detail.id}
                                        value={detail.name}
                                    >
                                        {detail.name}
                                    </Select.Option>
                                ))
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col span={24}>
                    <Form.Item name="paper_type" label="Paper Type" rules={[{ required: true }]}>
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            onChange={setSelectedPaperType}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "").toLowerCase().localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            value={selectedType} // Set the value based on selectedType
                        >
                            {[
                                {
                                    label: "Standard",
                                    value: "standard",
                                },
                                {
                                    label: "Linen",
                                    value: "linen",
                                },

                                {
                                    label: "Pearl",
                                    value: "Pearl",
                                },

                            ].map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="card_referredBy">
                <Button
                    type="primary"
                    htmlType="submit"
                    className='formBtn send-email-btn'
                    onClick={handleAddClick}
                > Add
                </Button>
            </Form.Item>
        </div>
    );
};

export default CardPackage;