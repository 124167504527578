import React from 'react';
import EditCardsLab from "../EditCardsLab";

const EditFoldOverLab = () => {

  const nameCards = "Fold Over";
  const navigate_link = '/labs/fold_over_list';
  const mainCardType = 3;

  return (
    <div>
      <EditCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};


export default EditFoldOverLab;


