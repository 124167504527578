import React, {useEffect, useState} from "react";
import {Button, Form, Input, Select, Spin} from "antd";
import { useNavigate } from "react-router-dom";
import {useFetching} from "../../../../hoc/fetchingHook";
import dataApi from "../../../../api/api";
import clientAPI from "../../../../api/api";
const ClientCreditAdd = ({ selectedCreditId,setModalCredit }) => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;
    const [credit,setCredit]= useState(0.00)
    const [creditStudio,setCreditStudio]= useState('')
    const [clients,setClient]= useState([''])
    const [creditData,setCreditData]= useState([])
    const [selectedClient, setSelectedClient] = useState('');
    const studio_id = localStorage.getItem("studio_id");


    // I need  Credit balance  for this studio from lab
    const [getUserCredit, isCreditLoading, creditError] = useFetching(async () => {
        const res = await clientAPI.getCreditListByUser();
        if (res) {
            const credits = res.data.credits;
            const allUsers = credits.find((user) => user.user_id.toString() === studio_id.toString());
            setCreditStudio(parseFloat(allUsers.credit_balance/100).toFixed(2));
        }
    });

    const [getClients, isClientsLoading, clientsError] = useFetching(async (studio_id) => {
        const {data: res} = await dataApi.getClientListByStudioIdToken(studio_id);
        if (res) {
            setClient(res.client_list)
        }
    })
    //Create
    const [storeUserCredit, isUserLoading,UserError] = useFetching(async (data) => {

        const res = await clientAPI.storeCredit(data);
        if (res) {
        }

    });
    const onFinish = (obj) => {
        const creditDataItem = {
            credit_amount_applied:  0,
            credit_balance:  0,
            ...obj
        };
        console.log(creditDataItem,'creditDataItem')
        setCreditData(creditDataItem);
    };

    useEffect(() => {
        getClients(studio_id)
        getUserCredit()
    }, [studio_id]);

    const hendelSubmit = async () => {
        try {
            if (selectedCreditId) {
                await form.validateFields();
                console.log('I need Update API')
                setModalCredit(false)
            } else {
                await form.validateFields();
                storeUserCredit(creditData);
                navigate("/studio/client_credit_list");
                window.location.reload();
            }
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const onChangeCredit = (e) => {
        const value = e.target.value;
        setCredit(value);
    };

    const onBlurCredit = () => {
        const formattedValue = parseFloat(credit).toFixed(2);
        form.setFieldsValue({
            credit_total_amount: formattedValue || '0.00',
        });
    };

    const onChangeClient = (value, option) => {
        setSelectedClient(option.label);
    };

    useEffect(() => {
        try {
               if(selectedCreditId){
                const selectedCreditCard = selectedCreditId[0];

                   let paymentTypeText = '';
                   switch (2) {

                       case 1:
                           paymentTypeText = 'Credit';
                           break;
                       case 2:
                           paymentTypeText = 'Cash';
                           break;
                       case 3:
                           paymentTypeText = 'Check';
                           break;
                       case 4:
                           paymentTypeText = 'Adjustment';
                           break;
                       default:
                           paymentTypeText = 'Unknown';
                   }

                   const selectUserById = clients.filter((item) => item.id === selectedCreditCard.user_id)[0];

                if (selectedCreditCard && selectUserById) {
                    form.setFieldsValue({
                        credit_total_amount: (selectedCreditCard.credit_total_amount / 100).toFixed(2) || '',
                        selected_user: `(${selectUserById.id}) ${selectUserById.name}` || '',
                        payment_type: paymentTypeText || '',
                        notes: selectedCreditCard.notes || '',
                    });
                }
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);

        }
    }, [form, selectedCreditId]);


    return (
      <>
          <div className={'text-center mt-4'}>
              {selectedCreditId?(<h3 className={'text-uppercase mb-4 header-title'}>Edit Credit for Client</h3>):(
                <h3 className={'text-uppercase mb-4 header-title'}>Add Credit for Client</h3>
              )}
          </div>
          <div className={"mt-4 album-notifications-select"}>
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                className="form"
                style={{
                    maxWidth: 500,
                    margin: "auto"
                }}
              >
                  <Form.Item
                    labelCol={{ span: 19 }}
                    wrapperCol={{ span: 5 }}
                    className="mt-4"
                    label="Available credit line "
                  >
                      <span>$ {creditStudio}</span>
                  </Form.Item>
                      <Form.Item
                    className="mt-4"
                    name="credit_total_amount"
                    label="Credit"
                    rules={[
                        { required: true, message: 'Please enter the applied credit amount!' },
                        {
                            validator(_, value) {
                                const maxValue = (parseFloat(creditStudio)).toFixed(2);
                                if (parseFloat(value) > maxValue) {
                                    return Promise.reject(`Cannot apply more than available credit: $${maxValue}`);
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                  >
                      <Input
                        suffix="$"
                        type="text"
                        value={credit}
                        onChange={onChangeCredit}
                        onClick={(e) => (e.target.value = '')}
                        onBlur={onBlurCredit}
                        placeholder="0.00"
                      />
                  </Form.Item>
                  <Form.Item
                    className="mt-4"
                    name="selected_user"
                    label="Client"
                    rules={[{ required: true }]}
                  >
                      <Select
                        showSearch
                        placeholder="Search to Client"
                        optionFilterProp="children"
                        onChange={onChangeClient}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        filterSort={(optionB, optionA) =>
                          (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={
                            isClientsLoading
                              ? [{ label: <Spin />, value: "loading" }]
                              : clients.map((client) => ({
                                  value: client.id,
                                  label: `(${client.id}) ${client.name}`,
                              }))
                        }
                        value={selectedClient}
                      >
                      </Select>
                  </Form.Item>

                  <Form.Item
                    className="mt-4"
                    name="payment_type"
                    label="Type"
                    rules={[{ required: true }]}
                  >
                      <Select
                        placeholder="Choose starting type"
                        allowClear
                        onTouchStart={(e) => e.stopPropagation()}
                      >
                          <Option value="1">Credit</Option>
                          <Option value="2">Cash</Option>
                          <Option value="3">Check</Option>
                          <Option value="4">Adjustment</Option>
                      </Select>
                  </Form.Item>

                  <Form.Item
                    className="mt-4"
                    name="notes"
                    label="Notes"
                  >
                      <TextArea rows={4} placeholder="Enter your notes" />
                  </Form.Item>
                  <div className='text-center mt-4'>
                      <Button
                        onClick={hendelSubmit}
                        type="primary"
                        htmlType="submit"
                        className={'ant-btn-set'}
                      >
                          {selectedCreditId ? "Update" : 'Save'}
                      </Button>
                  </div>

              </Form>
          </div>
      </>
    );
}

export default ClientCreditAdd;
