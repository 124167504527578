import {clientsAPI} from "../api/api";

const GET_CLIENTS = 'GET_CLIENTS';
const SET_CLIENTS = 'SET_CLIENTS';
const IS_SHOW = 'IS_SHOW';

let initialState = {
    studioID:78,
    clients: [],
    isShow:false
}


const clientReducer = (state = initialState,action) => {
    switch (action.type) {
        case IS_SHOW:
            return { ...state, isShow: action.isShow }
        case GET_CLIENTS:
            return { ...state, clients: action.clients }
        case SET_CLIENTS:
            return { ...state, clients: action.clients }
        default:
            return state;
    }
}


export const setClientsAC = (clientsList) => ({type:SET_CLIENTS,clients: clientsList});
export const getClientsAC = (clientsList) => ({type:GET_CLIENTS,clients: clientsList});
export const showAC = () => ({type:IS_SHOW,isShow: true});

export const getClientAPI = (studioID) => (dispatch) =>{
    clientsAPI.getClients(studioID).then(response => {
        dispatch(setClientsAC(response.data));
        dispatch(showAC);
    }
    )
}


export default clientReducer;

