import React, { useEffect, useState } from 'react';
import clientAPI from "../../../../api/api";
import dayjs from 'dayjs';
import LoadingSpin from "../../../Utils/LoadingSpin";
import Container from "react-bootstrap/Container";
import { Col, Form, Row, Select, DatePicker, Input, Spin, Button } from 'antd';
import { useFetching} from "../../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { validateMessages } from '../../../Utils/isFunction';

const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select time!',
        },
    ],
}
const options = [
    {
        key: 'sub3',
        value: 'Anniversary',
        label: 'Anniversary',
    },
    {
        key: 'sub10',
        value: 'Bar Mitzvah',
        label: 'Bar Mitzvah',
    },
    {
        key: 'sub2',
        value: 'Bat Mitzvah',
        label: 'Bat Mitzvah',
    },
    {
        key: 'sub4',
        value: 'Birthday',
        label: 'Birthday',
    },
    {
        key: 'sub6',
        value: 'Baptism',
        label: 'Baptism',
    },
    {
        key: 'sub9',
        value: 'Dance',
        label: 'Dance',
    },
    {
        key: 'sub5',
        value: 'Engagement Photos',
        label: 'Engagement Photos',
    },
    {
        key: 'sub7',
        value: 'Graduation',
        label: 'Graduation',
    },
    {
        key: 'sub8',
        value: 'School',
        label: 'School',
    },
    {
        key: 'sub1',
        value: 'Wedding',
        label: 'Wedding',
    },

    {
        key: 'sub11',
        value: 'Other',
        label: 'Other',
    },
];
const dateFormat = 'MM-DD-YYYY';

const EditEvent = () => {

    const [clientListData, setClientListData] = useState([]);
    const [phListData, setPhListData] = useState([]);
    const [venueListData, setVenueListData] = useState([]);
    const [data, setData] = useState();
    const studio_id = localStorage.getItem('studio_id');
    const event_id = localStorage.getItem('event_id');
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [createEvent, isEventLoading, eventError] = useFetching(async (values) => {
        const { data: res } = await clientAPI.updateEvent(values);
        if (res) {
            let currentPath = '/studio/list_event';
            navigate(currentPath);
            window.location.reload();
        }
    });

    const [fetchClientList, isPostsLoading, postError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getClientListByStudioID(studio_id);
        if (res) {
            setClientListData(res);
        }
    });

    const [fetchPhList, isPhLoading, phError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getPhListByStudioID(studio_id);
        if (res) {
            setPhListData(res);
        }
    });

    const [fetchVenueList, isVenueLoading, venueError] = useFetching(async (studio_id) => {
        const { data: res } = await clientAPI.getVenueListByStudioID(studio_id);
        if (res) {
            setVenueListData(res);
        }
    });

    const [fetchStaffData, isDataLoading, dataError] = useFetching(async (event_id) => {
        const {data: res} = await clientAPI.getEventData(event_id);
        if (res) {
            res.event_date = dayjs(res.event_date, dateFormat)
            setData(res);
        }
    });

    useEffect(() => {
        fetchClientList(studio_id);
        fetchPhList(studio_id);
        fetchVenueList(studio_id);
        fetchStaffData(event_id);
    }, []);

    const onFinish = (fieldsValue) => {
        const values = {
            ...fieldsValue,
            'event_date': fieldsValue['event_date'].format('MM-DD-YYYY'),
        };
        console.log(values);
        createEvent(values);
    };
    if (isDataLoading) {
        return (
            <>
                <LoadingSpin />
            </>
        );
    } else {
        return (
            <>
                <div className={'text-center mt-4'}>
                    <h3 className={'text-uppercase mb-4 header-title'}>Edit event</h3>
                </div>
                <div className={'mt-4 album-notifications-select'}>
                    <Form
                        form={form}
                        size={"large"}
                        name="validate_other"
                        onFinish={onFinish}
                        layout="vertical"
                        style={{
                            maxWidth: 600,
                            margin: "auto"
                        }}
                        validateMessages={validateMessages}
                        initialValues={data}
                    >
                        <Form.Item
                            name="client_id"
                            label="Select Client"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {isPostsLoading && <Spin/>}
                            {!isPostsLoading && (
                                <Select
                                    showSearch
                                    placeholder="Search to Client"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={clientListData.map((client) => ({
                                        value: client.value,
                                        label: client.label
                                    }))}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            name="photographer_id"
                            label="Select Photographer"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {isPhLoading && <Spin/>}
                            {!isPhLoading && (
                                <Select
                                    mode="multiple"
                                    placeholder="Search to Photographer"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={phListData.map((ph) => ({value: ph.value, label: ph.label}))}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            name="venue_id"
                            label="Select Venue"
                        >
                            {isVenueLoading && <Spin/>}
                            {!isVenueLoading && (
                                <Select
                                    mode="multiple"
                                    placeholder="Search to Venue"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={venueListData.map((ven) => ({value: ven.value, label: ven.label}))}
                                />
                            )}
                        </Form.Item>
                        <Row justify="space-between">
                            <Col lg={8} md={8} span={24}>
                                <Form.Item
                                    name="event_name"
                                    label="Event Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={8} span={24}>
                                <Form.Item
                                    name="event_type"
                                    label="Event Type"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Search to Type"
                                        optionFilterProp="children"
                                        options={options}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={6} md={6} span={24}>
                                <Form.Item name="event_date" label="Event Date" {...config} className={'date-picker'}>
                                    <DatePicker value="2023-05-17" format="MM-DD-YYYY"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Container className={'text-center'}>
                            <Button type="primary" htmlType="submit"  className='send-email-btn' >
                                Update
                            </Button>
                        </Container>
                    </Form>
                </div>
            </>
        )
    }
}
export default EditEvent;