import React, { useState } from 'react';
import { Tooltip, Button, Modal } from 'antd';
import 'react-18-image-lightbox/style.css';
import styles from '../Gallery.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import './GalleryCompareSplit.css';

const GalleryCompareSplit = ({ selectedImages }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leftImageIndex, setLeftImageIndex] = useState(0);
  const [rightImageIndex, setRightImageIndex] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const IMG_SERVER = 'https://studioseye.com';
  const H_THUMBNAILS = 'H_THUMBNAILS/';
  const THUMBNAILS = 'THUMBNAILS/';

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDragStart = (index) => {
    setIsDragging(true);
    if (index === leftImageIndex) {
      setLeftImageIndex(leftImageIndex === selectedImages.length - 1 ? leftImageIndex - 1 : leftImageIndex);
    } else if (index === rightImageIndex) {
      setRightImageIndex(rightImageIndex === 0 ? rightImageIndex + 1 : rightImageIndex);
    }
  };

  function generateNormalizedURL(url) {
    const normalizedURL = encodeURI(url);
    const fullyEncodedURL = normalizedURL.replace(/\(/g, '%28').replace(/\)/g, '%29');
    return fullyEncodedURL;
  }

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleImageSelection = (index) => {
    if (isDragging) {
      if (leftImageIndex === index) {
        setLeftImageIndex(index === selectedImages.length - 1 ? index - 1 : index + 1);
      } else if (rightImageIndex === index) {
        setRightImageIndex(index === 0 ? index + 1 : index - 1);
      }
    } else {
      setRightImageIndex(index);
    }
  };

  return (
    <>
      <Tooltip title="Choose only two images for compare images split">
        <Button onClick={showModal} className={styles.buttonIcon}>
          <svg id="Capa_1"
               enableBackground="new 0 0 512 512"
               viewBox="10 70 512 512"
               xmlns="http://www.w3.org/2000/svg"
               width="27px" height="25px"
               fill='#fff'
          >
            <g>
              <path d="m512 151.893h-241v-30h-30v90h30v-30h211v270h-211v-30h-30v90h30v-30h241z"/>
              <path d="m0 151.893h30v30h-30z"/>
              <path d="m60 151.893h30v30h-30z"/>
              <path d="m120 151.893h30v30h-30z"/>
              <path d="m180 151.893h30v30h-30z"/>
              <path d="m0 211.893h30v30h-30z"/>
              <path d="m0 271.893h30v30h-30z"/>
              <path d="m0 331.893h30v30h-30z"/>
              <path d="m0 391.893h30v30h-30z"/>
              <path d="m0 451.893h30v30h-30z"/>
              <path d="m60 451.893h30v30h-30z"/>
              <path d="m120 451.893h30v30h-30z"/>
              <path d="m180 451.893h30v30h-30z"/>
              <path d="m241 241.893h30v30h-30z"/>
              <path d="m241 301.893h30v30h-30z"/>
              <path d="m241 361.893h30v30h-30z"/>
            </g>
          </svg>
          <span className={styles.CompareTextButtun}>Compare Split</span>
        </Button>
      </Tooltip>

      <Modal open={isModalOpen} footer={false} onCancel={handleCancel} className={styles.ModalImage}>
        <div className="scrollSplit">
          <InfiniteScroll dataLength={selectedImages.length} height={150}>
            {selectedImages.map((img, index) => (
              <div
                className="scrollSplit_div"
                key={index}
                onMouseDown={() => handleDragStart(index)}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
                onClick={() => handleImageSelection(index)}
              >
                <img
                  className="scrollSplit_img"
                  id={img.ID}
                  alt={img.IMAGE_NAME}
                  src={generateNormalizedURL(IMG_SERVER + img.IMAGE_LINK_PATH + THUMBNAILS + img.IMAGE_NAME)}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
        {selectedImages.length >= 2 && (
          <div className='Compare'>
            <div className='CompareContent'>
              <img
                className='CompareImage'
                src={IMG_SERVER + selectedImages[leftImageIndex].IMAGE_LINK_PATH + H_THUMBNAILS + selectedImages[leftImageIndex].IMAGE_NAME}
                alt="Left Image"
              />
            </div>
            <div className='CompareContent'>
              <img
                className='CompareImage'
                src={IMG_SERVER + selectedImages[rightImageIndex].IMAGE_LINK_PATH + H_THUMBNAILS + selectedImages[rightImageIndex].IMAGE_NAME}
                alt="Right Image"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default GalleryCompareSplit;
