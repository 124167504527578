import image1 from '../../../Images/01-C48721.jpg';
import image2 from '../../../Images/02-CE2491.jpg';
import image3 from '../../../Images/02-SVBright-Red1.jpg';
import image4 from '../../../Images/03-CE4801.jpg';
import image5 from '../../../Images/08-CA8901.jpg';
import image6 from '../../../Images/04-CB9211.jpg';
import image7 from '../../../Images/03-SVBlack.jpg';
import image8 from '../../../Images/06-SECobalt1.jpg';
import image9 from '../../../Images/08-SEEbony1.jpg';
import image10 from '../../../Images/09-CB9151.jpg';
import image11 from '../../../Images/10-M07261.jpg';
import image12 from '../../../Images/11-PB0021.jpg';
import image13 from '../../../Images/15-C49021.jpg';
import image14 from '../../../Images/21-BLACK1.jpg';
import image15 from '../../../Images/23-M7341.jpg';
import image16 from '../../../Images/24-C46751.jpg';
import image17 from '../../../Images/ModernBook.jpg';
import image18 from '../../../Images/modern-album-Slider1.jpg';
import image19 from '../../../Images/cover.jpg';
import image20 from '../../../Images/acrilic.jpg';
import image21 from '../../../Images/photowrap.jpg';
import image22 from '../../../Images/cover1.png';
import image23 from '../../../Images/cover2.png';
import image24 from '../../../Images/wrapphoto.jpg';
import image25 from '../../../Images/cover7.png';
import image26 from '../../../Images/cover3.png';
import image27 from '../../../Images/cover4.png';
import image28 from '../../../Images/cover5.png';
import image29 from '../../../Images/cover6.png';
import image30 from '../../../Images/cover7.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    { id: 1, src: image1, alt: 'Image 1' },
    { id: 2, src: image2, alt: 'Image 2' },
    { id: 3, src: image3, alt: 'Image 3' },
    { id: 4, src: image4, alt: 'Image 4' },
    { id: 5, src: image5, alt: 'Image 5' },
    { id: 6, src: image6, alt: 'Image 6' },
    { id: 7, src: image7, alt: 'Image 7' },
    { id: 8, src: image8, alt: 'Image 8' },
    { id: 9, src: image9, alt: 'Image 9' },
    { id: 10, src: image10, alt: 'Image 10' },
    { id: 11, src: image11, alt: 'Image 11' },
    { id: 12, src: image12, alt: 'Image 12' },
    { id: 13, src: image13, alt: 'Image 13' },
    { id: 14, src: image14, alt: 'Image 14' },
    { id: 15, src: image15, alt: 'Image 15' },
    { id: 16, src: image16, alt: 'Image 16' },
    { id: 17, src: image17, alt: 'Image 17' },
    { id: 18, src: image18, alt: 'Image 18' },
    { id: 19, src: image19, alt: 'Image 19' },
    { id: 20, src: image20, alt: 'Image 20' },
    { id: 21, src: image21, alt: 'Image 21' },
    { id: 22, src: image22, alt: 'Image 22' },
    { id: 23, src: image23, alt: 'Image 23' },
    { id: 24, src: image24, alt: 'Image 24' },
    { id: 25, src: image25, alt: 'Image 25' },
    { id: 26, src: image26, alt: 'Image 26' },
    { id: 27, src: image27, alt: 'Image 27' },
    { id: 28, src: image28, alt: 'Image 28' },
    { id: 29, src: image29, alt: 'Image 29' },
    { id: 30, src: image30, alt: 'Image 30' },
];