import React,{useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import MoreInfo from '../../Utils/MoreInfo'
import clientAPI from "../../../api/api";
import EditPassword from '../../Utils/EditPassword';
import LoadingSpin from "../../Utils/LoadingSpin";
import { useFetching } from "../../../hoc/fetchingHook";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { DownOutlined } from "@ant-design/icons";
import { handleTagChange, validateMessages } from '../../Utils/isFunction';


const EditStaff = () => {

    const [form] = Form.useForm();
    const [data, setData] = useState();
    const [expand, setExpand] = React.useState(false);
    const navigate = useNavigate();
    const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };

    const [updateUserInfo, isUserLoading, UserError] = useFetching(async (current_user_id, values) => {
        const {data: res} = await clientAPI.updateUserInfo(current_user_id, values);
        if (res) {
            let currentPath = '';
            const user_type = localStorage.getItem('user_type');
            if(user_type === "LAB"){
                currentPath = '/labs/staff_list';
            }else if(user_type === "STUDIO"){
                currentPath = '/studio/staff_list';
            }
            navigate(currentPath);
            window.location.reload();
        }
    });

    const onFinish = (values) => {
        const current_user_id = localStorage.getItem('staff_id');
        updateUserInfo(current_user_id,values);
    };

    const [fetchStaffData, isDataLoading, dataError] = useFetching(async (staff_id) => {
        const {data: res} = await clientAPI.getStaffByParentID(staff_id);
        if (res) {
            setData(res);
        }
    });

    useEffect(() => {
        const staff_id = localStorage.getItem('staff_id');
        fetchStaffData(staff_id);
    }, []);


    if (isDataLoading) {
        return (
            <>
                <LoadingSpin />
            </>
        );
    } else {
        return (
            <>
                <div className={'text-center mt-4'}>
                    <h3 className={'text-uppercase mb-4 header-title'}>Edit Staff</h3>
                </div>
                <div className={'mt-4 album-notifications-select'}>
                    <Form
                        form={form}
                        name="register"
                        size={"large"}
                        onFinish={onFinish}
                        layout="vertical"
                        style={{
                            maxWidth: 600,
                            margin: "auto"
                        }}
                        validateMessages={validateMessages}
                        initialValues={data}
                        scrollToFirstError
                    >
                        <Form.Item
                            name="email"
                            label="Enter tags (email addresses)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter at least one email address.',
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                onChange={handleTagChangee}
                                style={{width: '100%'}}
                                placeholder="Enter your (email addresses)"
                            />
                        </Form.Item>
                        <Row>
                            <Col lg={24} md={24} span={24}>
                                <Form.Item
                                    name="username"
                                    label="Username"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="space-between">
                            <Col lg={11} md={11} span={24}>
                                <Form.Item
                                    name="firstname"
                                    label="Firstname"
                                    rules={[{required: true,},]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col lg={11} md={11} span={24}>
                                <Form.Item
                                    name="lastname"
                                    label="Lastname"
                                    rules={[{required: true,},]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Col className={"collapse-client"} lg={24} md={24} span={24}>
                        <EditPassword />
                        </Col>
                        <Col className={"collapse-client"} lg={24} md={24} span={24}>
                            <a onClick={() => {
                                setExpand(!expand);
                            }}>
                                <DownOutlined rotate={expand ? 180 : 0}/> More Details
                            </a>
                        </Col>

                        {expand && (
                            <MoreInfo/>
                        )}

                        <Container className={'text-center'}>
                            <Button type="primary" htmlType="submit" className='send-email-btn'>
                                Update
                            </Button>
                        </Container>

                    </Form>
                </div>
            </>
        );
    }
}
export default EditStaff;
