import React from 'react';
import { Checkbox } from 'antd';

const CheckedColumn = ({ text, size, checked, onChange }) => {
  const checkboxChange = (e) => {
    const newChecked = e.target.checked;
    onChange(newChecked);
  };

  return (
    <div style={{ display: 'flex', textAlign: 'center' }}>
      <Checkbox
        style={{ marginRight: '15px', marginTop:'-13px'}}
        name={text}
        onChange={checkboxChange}
        checked={checked || false} 
      />
      <p>{text}</p>
    </div>
  );
};

export default CheckedColumn;

