import React, { useState,useEffect } from 'react';
import {Button, Col, Input, Row, Table, message, Space, Checkbox} from 'antd';
import Container from "react-bootstrap/Container";
import {PlusSquareOutlined,MinusSquareOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router';
import clientsAPI from "../../../../api/api";
import {useFetching} from "../../../../hoc/fetchingHook";
import {showDeleteConfirmPrice} from "../../../Utils/isFunction";

const AddAlbumLab = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [data, setData] = useState([]);
  const [sizes, setSizes] = useState([]);
  const current_user_id = localStorage.getItem('user_id');
  const [fromValue, setFromValue] = useState(0)
  const [inputNameValue, setInputNameValue] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [checked, setChecked] = useState("NO");

  localStorage.setItem('current_user_id', current_user_id);

  const [createCard, isLoading, error] = useFetching(async (cardData) => {
    const { data: res } = await clientsAPI.createCardPrice(cardData);
    if (res) {
      console.log(res)
      navigate('/labs/album_list');
      window.location.reload();

    }
  });

  const [getSizesList, getSizesLoading, getSizesError] = useFetching(async () => {
    const { data: res } = await clientsAPI.getProductObjectSizes(1, "SIZE");
    if (res) {
      const sizesData = res["Album"];
      setSizes([...sizesData]);

      const initialData = sizesData.map((item, index) => ({
        key: index,
        id: item.ID,
        size: `${item.width} x ${item.height}`,
        type: [{ from: '', to: '', price: '',discountPrice:'', isFromAPI: true}],
        isNew: false,
      }));

      setData(initialData);
    }

  });

  const getSizes = () => {
    getSizesList()
  };

  useEffect(() => {
    defaultCheck ? setChecked("YES") : setChecked("NO")
    getSizes();
  }, [defaultCheck]);


  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      align: 'center',
    },
    {
      title: 'Album from / to prices',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (_, record,index) => renderInputs(_, record, index),
    },
    {
      title: 'Add/ Remove',
      dataIndex: 'add',
      key: 'add',
      align: 'center',
      render: (_, record) => (
        <>
          <Row>
            <Col span={24}>
              {!record.isNew && (
                <PlusSquareOutlined className='my_ant_icons_table fz-40 ' onClick={() => handleAddRow(record.size,record.key,record.id,record.dataIndex)} />

              )}
            </Col>
            <Col span={24}>
              {record.isNew && (
                <MinusSquareOutlined  className='my_ant_icons_table fz-40' onClick={() => handleDeleteRow(record.key)} />
              )}
            </Col>
          </Row>


        </>
      ),
    },
  ];

  const handleInputChange = (key, columnIndex, field, value) => {

    const newData = [...data];

    const rowIndex = newData.findIndex((row) => row.key === key);

    if (rowIndex !== -1) {
      const newType = { ...newData[rowIndex].type };

      newType[columnIndex] = { ...newType[columnIndex], [field]: value };

      newData[rowIndex] = {
        ...newData[rowIndex],
        type: newType,
      };

      setData(newData);
    }
  };


  const renderInputs = (text, record, columnIndex) => {
    const fromValue = parseFloat(record.type[columnIndex]?.from) || '';
    const toValue = parseFloat(record.type[columnIndex]?.to) || '';
    const priceValue = parseFloat(record.type[columnIndex]?.price) || '';
    const discountPriceValue = parseFloat(record.type[columnIndex]?.discountPrice) || '';

    const isInvalid = toValue !== '' && fromValue >= toValue;

    const error = () => {
      message.error(`Cannot enter a value less than ${fromValue}`);
    };

    return (
      <Row justify={"space-between"}>
        <Col xl={5} span={24}>
          <Input
            value={fromValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'from', e.target.value)}
            placeholder="From"
            type="number"
            style={{
              padding: '5px 0',
              textAlign: 'center',
              width: '70%',
              borderColor: isInvalid ? 'red' : '',
            }}
          />

        </Col>
        <Col xl={5} span={24}>
          <Input
            value={toValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'to', e.target.value)}
            placeholder="To"
            type="number"
            style={{padding: '5px 0', width: '70%', textAlign: 'center'}}
            onClick={isInvalid ? null : error}
          />
        </Col>
        <Col xl={5} span={24}>
          <Input
            value={priceValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'price', e.target.value)}
            placeholder="Price"
            type="number"
            style={{padding: '5px 0', width: '70%', textAlign: 'center'}}

          />
        </Col>

        <Col span={5}>
          <Input
            value={discountPriceValue}
            onChange={(e) => handleInputChange(record.key, columnIndex, 'discountPrice', e.target.value)}
            placeholder="Discount Price"
            type="number"
            style={{padding: '5px 0', width: '70%', textAlign: 'center',}}
          />
        </Col>
      </Row>
    );

  }

  const handleAddRow = (selectedSize, currentIndex, id) => {
    const newData = [...data];

    const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
      if (item.id === id) {
        return index;
      }
      return lastIndex;
    }, -1);

    const newKey =
      lastIndexWithSameId === -1 ? currentIndex + 1 : newData[lastIndexWithSameId].key + 1;

    const newRow = {
      key: newKey,
      id: id,
      size: selectedSize,
      type: [{ from: '', to: '', price: '', isFromAPI: false }],
      isNew: true,
    };

    // Initialize the type properties of the new row with default values
    newRow.type.forEach((typeItem) => {
      typeItem.from = '';
      typeItem.to = '';
      typeItem.price = '';
      typeItem.discountPrice = '';
      typeItem.isFromAPI = false;
    });

    newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);
    const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);

    if (previousRow) {
      const lastIndex = newRow.type.length - 1;
      const typeTo = parseFloat(previousRow.type[lastIndex]?.to);

      if (!isNaN(typeTo)) {
        newRow.type[lastIndex].from = typeTo + 1;
        setFromValue(typeTo + 1);
      }
    }

    newData.forEach((item, index) => {
      item.key = index;
    });

    setData(newData);
  };


  // const handleAddRow = (selectedSize, currentIndex, id) => {
  //   const newData = [...data];
  //
  //   const lastIndexWithSameId = newData.reduce((lastIndex, item, index) => {
  //     if (item.id === id) {
  //       return index;
  //     }
  //     return lastIndex;
  //   }, -1);
  //
  //   const newKey = lastIndexWithSameId === -1 ? currentIndex + 1 : newData[lastIndexWithSameId].key + 1;
  //
  //   const newRow = {
  //     key: newKey,
  //     id: id,
  //     size: selectedSize,
  //     type: [{ from:  '', to: '', price: '',discountPrice:'', isFromAPI: false }],
  //     isNew: true,
  //   };
  //   newData.splice(lastIndexWithSameId === -1 ? currentIndex + 1 : lastIndexWithSameId + 1, 0, newRow);
  //   const previousRow = newData.find((row) => row.id === id && row.key === lastIndexWithSameId);
  //   const linenTo = parseFloat(previousRow.type[0]?.to);
  //
  //   console.log(linenTo,'linenTo')
  //
  //   newData.forEach((item, index) => {
  //     item.key = index;
  //     if (previousRow && index === lastIndexWithSameId + 1 ) {
  //       newData[index].type.from = linenTo+1;
  //
  //     }
  //
  //   });
  //
  //   setFromValue(linenTo + 1)
  //   console.log(fromValue,'fromValue')
  //
  //   setData(newData);
  // };


  const handleDeleteRow = (rowKey) => {
    showDeleteConfirmPrice(rowKey).then((result) => {
      if (result) {
        console.log('Yes')
        const rowToDelete = data.find((item) => item.key === rowKey);

        if (rowToDelete ) {
          if (data.length <= 1) {
            return;
          }
          const newData = data.filter((item) => item.key !== rowKey);
          const updatedData = newData.map((item, index) => ({ ...item, key: index }));
          setData(updatedData);
        } else {
          console.log('No')
        }
      }
    })
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };




  const handleSubmit = async () => {
    const sizeInfoData = data
      .filter((item) => selectedRowKeys.includes(item.key))
      .map((item) => {

          const typeInfo = item.type.map((typeItem) => ({
            itemId: item.id,
            size: item.size,
            from: typeItem.from,
            to: typeItem.to,
            price: typeItem.price,
          }));
          return typeInfo;
      })

    const newCardData = {
      default_check: checked,
      priceName: inputNameValue,
      sizeInfoData: sizeInfoData,
    };
    console.log('newCardData:', newCardData);

    // createCard(newCardData);
  };




  const handleInputNameChange = (e) => {
    const newValue = e.target.value;
    setInputNameValue(newValue);
  };

  return (
    <div>
      <div className={'text-center mt-4'}>
        <h3 className={'text-uppercase mb-4 header-title'}> Add Album Lab</h3>
      </div>
      <div className={'mt-4 album-notifications-select'}>
        <div style={{ margin: 16}}  >
          <Row>
            <Space className='spane'>
              <label >Add price Name</label>
              <Input
                type="text"
                className="input_price"
                value={inputNameValue}
                onChange={handleInputNameChange}
              />
              <Checkbox className="default_checkbox" checked={defaultCheck}
                        onChange={() => setDefaultCheck(!defaultCheck)}>
                <div className="default_text">Check if you want set your default price list</div>
              </Checkbox>
              <Button onClick={handleSubmit } type="primary" htmlType="submit"  className='formBtn' >
                Save
              </Button>
            </Space>

          </Row>
        </div>
        {getSizesLoading?
          ( <Table
            className='priceTable'
            columns={columns}
            loading={getSizesLoading}
          />):(  <Table
            className='priceTable'
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.key}
            pagination={false}
            loading={getSizesLoading}
            scroll={{ x: 100 }}
          />)}
        <Container className={'text-center'}>
          <Button onClick={handleSubmit } type="primary" htmlType="submit"  className='send-email-btn formBtn' >
            Save
          </Button>
        </Container>
      </div>
    </div>
  );
};


export default AddAlbumLab;


