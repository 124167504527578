 import {Breadcrumb, Layout, Menu, theme, Button, Row, Col} from 'antd';
import React,{useEffect, useState } from 'react';
import isAuth from "../../hoc/IsAuth";
import GalleryList from "./Gallery/GalleryList";
import Logout from "../Logout/Logout";
import LoadingSpin from "../Utils/LoadingSpin";
import useCheckUserType from "../../hoc/checkUserType";
import Buttons from "../Utils/Buttons";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { menuHelper } from "../../hoc/menuHoc";
import { clientItems } from "./LeftSidebarClientItems";
import { studioItems } from "./LeftSidebarStudioItems";
import { stringToBool } from '../Utils/stringToBool';
import { HomeOutlined, LeftCircleFilled, RightCircleFilled, LogoutOutlined, RollbackOutlined} from '@ant-design/icons';
import { useWindowSize } from '../Utils/WindowSize';
import {useFetching} from "../../hoc/fetchingHook";
import clientsAPI from "../../api/api";
import SwitchHelperMode from "../Utils/SwitchHelperMode";
import {HelperTabProvider} from "../../context/HelperTabContext";
 import ClientStudioInfo from "./Gallery/StudioClientInfo";
 import HelperShow from "../Utils/HelperShow";
 import './ClientDashbord.css'
 import styles from "./Gallery/Gallery.module.css";
 import {ClientChat} from "../HelpChat/Chat";
const { Header, Content, Sider } = Layout;

let goToStudio = {};
const user_id = parseInt(localStorage.getItem('user_id'));
const studio_id = parseInt(localStorage.getItem('studio_id'));
if(user_id !== studio_id || user_id===studio_id){
    goToStudio = {
        key: 'go_to_studio',
        icon: <RollbackOutlined />,
        label: 'Go To Studio',
    };
}else{
    goToStudio = {
        key: '/',
        icon: <HomeOutlined />,
        label: 'Home',
    };
}

const navClientItems = [{
    key: 'gallery_list',
    icon: <HomeOutlined />,
    label: 'Home',
},
    {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
}
]

const navStudioItems = [
    goToStudio,
    {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: 'Logout',
    }
]

const Client = (props) => {
    const [helperShow, setHelperShow] = useState(false);
    const currentUserType = useCheckUserType();
    const {  token: { colorBgContainer } } = theme.useToken();
    const isUserAuthenticated = isAuth();
    const navigate = useNavigate();
    const currentPath = '/client/';
    const [itemsB, setItemsB] = useState([]);
    const [selectedKey, setSelectedKey] = useState('/');
    const [collapsed, setCollapsed] = useState(false);
    const [items, setItems] = useState([]);
    const [navItmes, setNavItmes] = useState([]);
    const [activeStudio, setActiveStudio] = useState(stringToBool(localStorage.getItem('studio_mode')));
    const windowSize = useWindowSize();
    const [studioInfo, setStudioInfo] = useState();
    const [clientInfo, setClientSInfo] = useState();

    const PREFIX = "https://studioseye.com/pricing/upload/";

    const client_id =localStorage.getItem('client_id',)
    const [getStudioInfo, isGetStudioInfo, studioIndoError] = useFetching(async (id) => {
        const res  = await clientsAPI.getClientInfoByID(id);
        if (res) {
            setStudioInfo(res.data.studioInfo);
            setClientSInfo(res.data.client_info);
        }
    });
    useEffect(() => {
        getStudioInfo(client_id)
    },[client_id])

    const chengeStudioMode = (event) => {
        setActiveStudio(!activeStudio);
        localStorage.setItem('studio_mode',!activeStudio);
        if(stringToBool(localStorage.getItem('studio_mode')) === true){
            setItems(studioItems);
            setNavItmes(navStudioItems);
        }else{
            setItems(clientItems);
            setNavItmes(navClientItems);
        }
    };

    const showBreadcrumbs = (items, e) => {
        const parentMenu = menuHelper.getParentLabel(items,e);
        const clickedItem = menuHelper.getMenuLabel(items,e);
        let bread;
        if(clickedItem === "Dashboard"){
            bread = [
                { title: 'Dashboard', href: '/client/dashboard' },
            ];
        }else if (clickedItem === parentMenu){
            bread = [
                { title: 'Dashboard', href: '/client/dashboard' },
                { title: clickedItem, href: `/client/${e}` },
            ];
        }else{
            bread = [
                { title: 'Dashboard', href: '/client/dashboard' },
                { title: parentMenu},
                { title: clickedItem, href: `/client/${e}` },
            ];
        }
        setItemsB(bread);
    }
    const handleMenuClick = (e) => {
        if(e.key === 'go_to_studio'){
            localStorage.setItem('user_type',"STUDIO");
            setActiveStudio(activeStudio);
            localStorage.setItem('studio_mode',!activeStudio);
            const currentPath2 = '/studio/list_client';

            navigate(currentPath2);
        }else{
        setSelectedKey(e.key);
        showBreadcrumbs(items,e.key);
        navigate(currentPath+e.key);
        }
    };

    const renderConditionalContent = () => {
        if (currentUserType === 'STUDIO' || (currentUserType === 'LAB')) {
            return (
                <Buttons
                    activeStudio={activeStudio}
                    chengeStudioMode={chengeStudioMode}
                    studioModeLabel="Studio mode"
                    clientModeLabel="Client mode"
                />
            );
        } else {
            return null;
        }
    };

    let { id } = useParams();
    useEffect(() => {
        setActiveStudio(stringToBool(localStorage.getItem('studio_mode')));
        if(stringToBool(localStorage.getItem('studio_mode')) === true){
            setItems(studioItems);
            setNavItmes(navStudioItems);
        }else{
            setItems(clientItems);
            setNavItmes(navClientItems);
        }
        if(id){
            setSelectedKey(id);
            showBreadcrumbs(items,id);
        }else if(props.page){
            setSelectedKey(props.page);
            showBreadcrumbs(items,props.page);
            navigate(currentPath+props.page);
        }
    }, []);

    useEffect(() => { 
    }, [items,navItmes,windowSize]);

    useEffect(() => { 
       if(windowSize.width < 768){
           setCollapsed(true);
       }else if(windowSize.width > 768) {
           setCollapsed(false);
       }
    }, [windowSize]);


    if(!isUserAuthenticated){
        return <>
            <LoadingSpin/>
        </>
    }else{
        return (
         < HelperTabProvider>
            <Layout style={{
                minHeight: '100vh',
            }}>
                <Header className="header">
                    <Row justify='space-between'>
                        <Col span={3}>
                            <div className="logo">
                                {studioInfo && (
                                  <img src={PREFIX + studioInfo.studio_logo} alt="Studio Logo" style={  { background: '#e6f4ff'}}/>
                                )}
                            </div>
                        </Col>
                        <Col span={1}  className='colapseButtonContainer'>
                                <Button
                                    className="colapseButton"
                                    type="text"
                                    icon={collapsed ? <RightCircleFilled className="colapseButtonColor"/> : <LeftCircleFilled className="colapseButtonColor"/>}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                        </Col>
                        <Col>
                            {renderConditionalContent()}
                        </Col>
                        <Col >
                            <div className="helperMode  helperModeClient">
                                {/*<SwitchHelperMode/>*/}
                                <HelperShow helperShow={helperShow} setHelperShow={setHelperShow}/>
                            </div>
                        </Col>
                        <Col xl={4} span={5}>
                            <Menu className="top_menu"
                                  onClick={handleMenuClick}
                                  selectedKeys={[selectedKey]}
                                  theme="light"
                                  mode="horizontal"
                                  defaultSelectedKeys={['2']}
                                  items={navItmes} />
                        </Col>
                    </Row>
                </Header>
                <Layout>
                    <Sider trigger={null} collapsible collapsed={collapsed}>
                        <Menu
                            onClick={handleMenuClick}
                            selectedKeys={[selectedKey]}
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                                borderRight: 0,
                            }}
                            items={items}
                        />
                    </Sider>
                    <Layout
                        style={{
                            padding: '0 24px 24px',
                        }}
                    >
                        <Row justify='space-between' className='mt-4 mb-4'>
                            <Col>
                                <Breadcrumb
                                  items={itemsB}
                                />
                            </Col>
                            <Col  className='studioInfo'>
                                <Row className={styles.Row} justify='start'>
                                    {studioInfo && clientInfo && (<ClientStudioInfo studioInfo={studioInfo} clientInfo={clientInfo}/>)}
                                </Row>
                            </Col>
                        </Row>
                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                background: colorBgContainer,
                            }}
                        >

                            {selectedKey === 'gallery_list' && <GalleryList activeStudio={activeStudio}/>}
                            {selectedKey === 'logout' && <Logout/>}
                            {clientInfo && (
                              <ClientChat clinetInfo={clientInfo} clientId={clientInfo?.client_id}/>
                            )}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
         </HelperTabProvider>
        );
    }
};
export default Client;
