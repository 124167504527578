import React, {useRef, useState,useEffect} from 'react';
import Header from '../Header/Header';
import MainImage from './mainImage/MainImage';
import SelectImage from './selectImage/SelectImage';
import SelectSize from './selectSize/SelectSize';
import {Checkbox, Col, Input, Row, Tour} from 'antd';
import './Main.css';
import {useFetching} from "../../../hoc/fetchingHook";
import clientsAPI from "../../../api/api";
import clientAPI from "../../../api/api";
import {HelperTabProvider, useHelperTab} from "../../../context/HelperTabContext";
import {ClientChat} from "../../HelpChat/Chat";



const Prints = () => {
    const [helperShow, setHelperShow] = useState(false);
    const isWindowWidthGreaterThan577 = window.innerWidth > 577;
    const [printImage, setPrintImage] = useState('');
    const [processedData, setProcessedData] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imageID, setImageID] = useState(0);
    const [isSwitchChecked, setIsSwitchChecked] = useState(0)
    const [currentSize, setCurrentSize] = useState({});
    const [inlineResult, setInlineResult] = useState('');
    const [printImageOpen, setPrintImageOpen] = useState(false);
    const [textValues, setTextValues] = useState('');
    const [holdToText, setHoldToText] = useState(true)
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [productType, setProductType] = useState('Print')
    const [studioInfo, setStudioInfo] = useState();
    const [bgImages, setBgImages] = useState([]);
    const [selectedBg, setSelectedBg] = useState(null);
    const [updateStoredMessagesFlag, setUpdateStoredMessagesFlag] = useState(false);
    const { TextArea } = Input;
    const updateStoredMessages = () => {
        setUpdateStoredMessagesFlag((prev) => !prev);
    };


    useEffect(() => {
        if (helperShow) { setHelperShow(true);  }
    }, [helperShow]);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const client_id =localStorage.getItem('client_id')



    const onHoldToText = (holdToText) => {
        setHoldToText(!holdToText)
    };
    const onChangeTextarea = (e) => {
        const textValues = e.target.value
        setTextValues(textValues)
    };

    const [getBackgrounds, isBgLoading, bgError] = useFetching(async (studio_id) => {
        try {
            if(studio_id){
                const { data: res } = await clientAPI.getClientBackgroundByStudioId(studio_id);
                if (res?.length > 0) {
                    setBgImages(res);
                }
            }
        } catch (error) {
            console.error(error);
        }
    });




    const [getStudioInfo, isGetStudioInfo, studioIndoError] = useFetching(async (id) => {
        const res  = await clientsAPI.getClientInfoByID(id);
        if (res) {
            setStudioInfo(res.data);
        }
    });


    useEffect(() => {
        getBackgrounds(studioInfo?.client_info.studio_id);
    }, [studioInfo?.client_info.studio_id]);

    useEffect(() => {
        setTimeout(() => {
            if (holdToText && !isAddedToCart) {
                setTextValues('');
            }
        }, 1000);
    }, [holdToText, isAddedToCart]);




    const steps = [
        {
            title: 'Select Size',
            description: 'choose your print size',
            target: () => ref1.current,
        },
        {
            title: 'Choose Image',
            description: 'choose your image for print.',
            target: () => ref2.current,
        },
        {
            title: 'Adjust Crop or change Portrait / Landscape',
            description: 'Click to see other actions.',
            target: () => ref3.current,
        },
        {
            title: 'Set Quantity and Add to Cart',
            description: 'Click to see other actions.',
            target: () => ref4.current,
        },
    ];


    useEffect(() => {
        getStudioInfo(client_id)
    },[client_id])

    return (
      <HelperTabProvider>
      <Row>
          <Col  span={24} className="border-line-bottom">
              <Header studioInfo = {studioInfo?.studioInfo}  helperShow={helperShow} setHelperShow={setHelperShow} />
          </Col>
          <Col ref={ref2} xl={6} lg={6} md={6} span={24}>
              <SelectImage
                setPrintImageOpen={setPrintImageOpen}
                imageID = {imageID}
                setImageID = {setImageID}
                setInlineResult={setInlineResult}
                setPrintImage={setPrintImage}
                printImage={printImage}
                printImageOpen={printImageOpen}
                productType={productType}
                setProductType={setProductType}
                studioInfo = {studioInfo}
                bgImages = {bgImages}
                setSelectedBg = {setSelectedBg}
                updateStoredMessagesFlag={updateStoredMessagesFlag}

              />
          </Col>
          <Col ref={ref3} xl={12} lg={18} md={18} span={24}  className='main'>
              <MainImage
                setPrintImage={setPrintImage}
                imageID = {imageID}
                setImageID = {setImageID}
                inlineResult={inlineResult}
                setInlineResult={setInlineResult}
                printImage={printImage}
                currentSize={currentSize}
                setProcessedData={setProcessedData}
                processedData={processedData}
                imageSize={imageSize}
                setImageSize={setImageSize}
                isSwitchChecked={isSwitchChecked}
                setIsSwitchChecked={setIsSwitchChecked}
                newEditor={true}
                printImageOpen={printImageOpen}
                selectedBg = {selectedBg}
                setSelectedBg = {setSelectedBg}
                updateStoredMessagesFlag={updateStoredMessagesFlag}
                setUpdateStoredMessagesFlag={setUpdateStoredMessagesFlag}
              />
              <Row justify='start' className='centered'>
                  <Col span={10} >
                      <Checkbox className="setCheck" onChange={onHoldToText}>Hold Text for Next Image</Checkbox>
                  </Col>
                  <hr />
                  <Col span={13}>
                      <TextArea rows={2} onChange={onChangeTextarea} value={textValues} />
                  </Col>
              </Row>
          </Col>
          <Col xl={6} lg={24} md={24} span={24}>
              <SelectSize
                productType={productType}
                imageID = {imageID}
                setImageID = {setImageID}
                setQuantity={setQuantity}
                quantity={quantity}
                inlineResult={inlineResult}
                printImage={printImage}
                currentSize={currentSize}
                setCurrentSize={setCurrentSize}
                setProcessedData={setProcessedData}
                processedData={processedData}
                imageSize={imageSize}
                setImageSize={setImageSize}
                isSwitchChecked={isSwitchChecked}
                setIsSwitchChecked={setIsSwitchChecked}
                newEditor={true}
                textValues={textValues}
                holdToText={holdToText}
                setHoldToText={setHoldToText}
                selectedBg={selectedBg}
                isAddedToCart={isAddedToCart}
                setIsAddedToCart={setIsAddedToCart}
                ref1={ref1}
                ref4={ref4}
                onChangeTextarea={onChangeTextarea}
                updateStoredMessages={updateStoredMessages}
              />
          </Col>
          {studioInfo?.client_info && (
            <ClientChat clinetInfo={studioInfo?.client_info} clientId={client_id}/>
          )}
          {isWindowWidthGreaterThan577 && <Tour open={helperShow} onClose={() => {setHelperShow(!helperShow)}} steps={steps}/>}
      </Row>
      </HelperTabProvider>
    );
};

export default Prints;
