import React, { useState, } from 'react';
import {Col, Form, Input, message, Row, Select, Modal, Button, Checkbox, Tooltip, Typography} from 'antd';
import {
  generateRandomUsername,
  handleGenerateUnamePass,
  handleTagChange,
  validateMessages
} from "../../../../Utils/isFunction";
import styles from './Contract.module.css'
import Container from "react-bootstrap/Container";
import {DownOutlined, SolutionOutlined} from "@ant-design/icons";
import MoreInfo from "../../../../Utils/MoreInfo";

const AddVenue = ({ onNewVenue } ) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [venueValues, setVenueValues] = useState({});
  const [currentId, setCurrentId] = useState(1);
  const [expand, setExpand] = React.useState(false);
  const randomUsername = generateRandomUsername(8);
  const handleClick = () => { handleGenerateUnamePass(form) }
  const handleTagChangee = (selectedTags) => { handleTagChange(selectedTags, form, message) };



  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const valuesId = {
        venueId: currentId,
        ...values,
      }
      setVenueValues(valuesId);
      setCurrentId(currentId + 1);
      if (onNewVenue && typeof onNewVenue === 'function') {
        onNewVenue(valuesId);
      }
      form.resetFields();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Validation error:', error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>

      <Button
        onClick={showModal}
        type="primary"
        className={styles.contractBtn}
      >
        +
      </Button>
      <Modal open={isModalOpen} footer={false} onCancel={handleCancel} >
        <div className={'text-center mt-4'}>
          <h3 className={'text-uppercase mb-4 header-title'}>Venue Information</h3>
        </div>
        <div className={'mt-4 album-notifications-select'}>
          <Form
            form={form}
            name="venueInfo"
            size={"large"}

            layout="vertical"
            style={{
              maxWidth: 600,
              margin: "auto"
            }}
            validateMessages={validateMessages}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label="Enter tags (email addresses)"
            >
              <Select
                mode="tags"
                onChange={handleTagChangee}
                style={{ width: '100%' }}
                placeholder="Enter your (email addresses)"
              />
            </Form.Item>
            <Row justify='space-between'>
              <Col sm={21} xs={20}>
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col sm={2} xs={3}  >
                <Tooltip title="Generate Username and Password">
                  <Typography.Link onClick={handleClick}>
                    <SolutionOutlined className={"generate-uname-pass"}/>
                  </Typography.Link>
                </Tooltip>

              </Col>
            </Row>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password  value={randomUsername}/>
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password  value={randomUsername}/>
            </Form.Item>

            <Row justify="space-between">
              <Col lg={24} md={24} span={24}>
                <Form.Item
                  name="venueName"
                  label="Venue Name"
                  rules={[{ required: true,},]}
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Col className={"collapse-client"} lg={24} md={24} span={24}>
              <a onClick={() => { setExpand(!expand);}}>
                <DownOutlined rotate={expand ? 180 : 0} /> More Details
              </a>
            </Col>
            {expand && (
              <MoreInfo />
            )}
          </Form>
        </div>

        <Container className="text-end mt-4 mb-2">
          <Button htmlType="button" type="primary"  style={{marginRight:'15px'}} className={styles.contractBtn}  onClick={handleOk} >
            Save
          </Button>
          <Button htmlType="button" type="primary" className={styles.contractBtn}  onClick={handleCancel} >
            Cancel
          </Button>
        </Container>

      </Modal>
    </>
  )
}

export default AddVenue;