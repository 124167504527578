import React from 'react';
import {createRoot} from 'react-dom/client';
import {DndProvider} from 'react-dnd'; // Import DndProvider
import {HTML5Backend} from 'react-dnd-html5-backend'; // Import HTML5Backend
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import store from './redux/store-conf';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {CartProvider} from "./context/CartContext";
import {PriceContextProvider} from "./context/PriceContext";
import {PolicyProvider} from "./context/PolicyContext";
import {ContractProvider} from "./context/ContactContext";

const Root = ({store}) => (
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>
);

createRoot(document.getElementById('root')).render(
    <DndProvider backend={HTML5Backend}>
        <CartProvider>
            <PriceContextProvider>
                <ContractProvider>
                    <PolicyProvider>
                        <Root store={store}/>
                    </PolicyProvider>
                </ContractProvider>
            </PriceContextProvider>
        </CartProvider>
    </DndProvider>,
);

reportWebVitals();