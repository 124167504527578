import React, { useContext, useEffect, useState } from 'react';
import './ShoppingCart.css';
import { Col, Modal, Row } from 'antd';
import MainImage from '../Clients/Prints/mainImage/MainImage';
import { CartContext } from '../../context/CartContext';
import AddButton from "../Clients/AddButton/AddButton";

const EditorCart = ({ selectedItemIndex, setSelectedItemIndex,isEditorCartOpen }) => {
  console.log()


  const { cart, setCart } = useContext(CartContext);
  const [formData, setFormData] = useState({
    image: '',
    selectedBg: '',
    quantity: 0,
    currentSize: {},
    imageSize: { width: 0, height: 0 },
    switcher: false,
    processedData: null,
    textValues: '',
  });



  useEffect(() => {
    if (selectedItemIndex !== null) {
      const selectedItem = cart[selectedItemIndex];
      if (selectedItem) {
        setFormData({ ...selectedItem });
      }
    }
  }, [selectedItemIndex, cart]);

  const handleModalClose = () => {
    setSelectedItemIndex(null);
  };
  const updateCartData = () => {
    // This function should update the cart with the edited values
    const updatedCart = [...cart];
    updatedCart[selectedItemIndex] = { ...updatedCart[selectedItemIndex], ...formData };
    setCart(updatedCart);
    handleModalClose();
  };

  return (
      <Modal
          title="Edit Shopping Cart"
          open={selectedItemIndex !== null}
          onCancel={handleModalClose}
          footer={null}
          className="modalEdit"
      >
        <Row>
          <Col span={24}>
            <MainImage
                printImage={formData.image}
                currentSize={formData.currentSize}
                setProcessedData={(data) => setFormData({ ...formData, processedData: data })}
                processedData={formData.processedData}
                isSwitchChecked={formData.switcher}
                setIsSwitchChecked={(checked) => setFormData({ ...formData, switcher: checked })}
                imageSize={formData.imageSize}
                setImageSize={(size) => setFormData({ ...formData, imageSize: size })}
                selectedBg={formData.selectedBg}
                newEditor={false}
            />
          </Col>
          <Col span={24}>
            <AddButton
              quantity={formData.quantity}
              updateCartData={updateCartData}
              printImage={formData.image}
              currentSize={formData.currentSize}
              processedData={formData.processedDataI}
              isEditorCartOpen={isEditorCartOpen}
              newEditor={false}
            />
          </Col>
        </Row>
      </Modal>
  );
};

export default EditorCart;