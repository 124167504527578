import React from 'react';
import AddCardsLab from "../AddCardsLab";

const AddPhotoCardLab = () => {

  const nameCards = "Photo Cards";
  const navigate_link = '/labs/photo_cards_list';
  const mainCardType = 4;

  return (
    <div>
      <AddCardsLab nameCards={nameCards} navigate_link={navigate_link} mainCardType={mainCardType} />
    </div>
  );
};

export default AddPhotoCardLab;


