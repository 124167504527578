
    import React, {useEffect, useState} from 'react';
    import clientAPI from "../../../../api/api";
    import style from "./CreateFolder.module.css";
    import {Col, Form, Row, Select, Spin} from 'antd';


    const SelectUnitDefault = ({setSizeId,setSizePrice}) => {
        const [loading, setLoading] = useState(true);
        const [data, setData] = useState([]);
        const [selectedItem, setSelectedItem] = useState({});

        const handleItemSelect = (selectedItem) => {
            const size_id= data.find((item) => item.name === selectedItem)?.id || null;
            const size_price= data.find((item) => item.name === selectedItem)?.price|| null;
            setSizeId(size_id);
            setSizePrice(size_price);
            setSelectedItem(selectedItem);
        };

        const fetchSizesAndDetails = async () => {
            try {
                const {data: sizesRes} = await clientAPI.getProductObjectSizes(22, 'SIZE');
                const headerRes2 = await clientAPI.getPrintPriceStudioDefault(2);
                if (sizesRes &&  headerRes2.data) {
                    const  detailsRes2 = await clientAPI.getPrintPriceDetails(headerRes2.data.id);
                    if (detailsRes2.data && headerRes2) {
                        const sizesBody = sizesRes.Units;
                        setLoading(false);
                        const nonEmptyDetails2 = detailsRes2.data.filter((detail) => detail.price);
                        const combinedData = nonEmptyDetails2.map((detail2) => {
                            const size = sizesBody.find((size) => size.ID === detail2.item_id);
                            return {
                                id: detail2.item_id,
                                name: size ? `${size.width} x ${size.height}` : null,
                                ID:size ? size.ID : null,
                                price:size ? parseFloat(detail2.price).toFixed(2) : null
                            };
                        });

                        const filteredCombinedData = combinedData.filter((item) => item.name !== null);
                        setData(filteredCombinedData);
                        setLoading(false);
                        return detailsRes2.data;
                    };
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching sizes and details', error);
            }
        };

        useEffect(() => {
            fetchSizesAndDetails();
        }, []);

        return (
          <Row justify="space-between">
              <Col span={24} className='mb10'>
                  <Form.Item label="Selected Size:" name="selected_size">
                      <Select
                        className={style.selectedPrice}
                        placeholder="Select items"
                        value={selectedItem.selected_size}
                        onChange={handleItemSelect}
                      >
                          {loading ? (
                            <Select.Option className={style.selectedOption} key="loading" value="loading">
                                <Spin />
                            </Select.Option>
                          ) : (
                            data.map((item) => (
                              <Select.Option
                                key={item.id}
                                value={item.name}
                                className={style.selectedOption}
                              >
                                  {item.name}
                              </Select.Option>
                            ))
                          )}
                      </Select>
                  </Form.Item>
              </Col>
          </Row>
        );
    };
    export default SelectUnitDefault;
