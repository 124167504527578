import React, { useEffect, useState, useContext } from 'react';
import { animated } from "@react-spring/web";
import { Row, Col, Button, Tooltip, Space, Checkbox, Input, Dropdown, Modal, message, Pagination, List } from 'antd';
import {
  HeartFilled,
  HeartOutlined,
  LockOutlined,
  RollbackOutlined,
  ShoppingCartOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import CreateFolder from "./CreateFolder/CreateFolder";
import ShoppingCart from "../../Cart/ShoppingCart";
import { CartContext } from "../../../context/CartContext";
import LogoutButton from "../LogoutButton";
import GalleryCompareSplit from "./UtilsGallery/GalleryCompareSplit";
import AllImageDownloader from "./UtilsGallery/AllImageDownloader";
import styles from './Gallery.module.css';
import ClientStudioInfo from "./StudioClientInfo";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemCreateFolder1 from "./CreateFolder/ItemCreateForder1";
import { useFavoriteImages, usePrivateImages, useSelectedImages, useTotalImages, useIsSelectedAll, useFolderDataList, useImages } from './GalleryHeader/store/selector';
import { useDispatch } from 'react-redux';
import { selectedAllImages, setFavoritePrivate, toggleSelected, toggleSelectedAll } from './GalleryHeader/store/galleryHeaderSlice';


const GalleryHeader = ({

  navbarAnimation,

  setFilteredImage,
  goGalleryPage,
  favoriteOpen,
  setFavoriteOpen,
  privateOpen,
  setPrivateOpen,
  currentPage,
  handlePageChange,
  handleMenuClickHeader,
  bgImages,
  studioInfo,
  setSelectedBg,
}) => {

  const { Search } = Input;
  const PREFIX = "https://studioseye.com/pricing/upload/";
  const { cart, totalQuantity } = useContext(CartContext);


  const [modalVisible, setModalVisible] = useState(false);
  const [openBackgroundModal, setOpenBackgroundModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [items, setItems] = useState();
  const [clientInfo, setClientSInfo] = useState();
  const studio_mode = localStorage.getItem('studio_mode') || '';


  const dispatch = useDispatch()
  const total = useTotalImages()
  const favoriteImages = useFavoriteImages()
  const privateImages = usePrivateImages()
  const selectedImages = useSelectedImages()
  const isSelectedAll = useIsSelectedAll();
  const folderDataList = useFolderDataList()
  const images = useImages()



  // useEffect(() => {
  //   if (isSelectedAll) {
   
  //     dispatch(toggleSelectedAll(images))
  //   }

  // }, [isSelectedAll, images])



  const menu = { items, };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };




  const handleSelectAllChange = () => {
    dispatch(selectedAllImages());

  };



  const handleCartClick = () => {
    setModalVisible(true);
  };

  const handleBackgroundClick = () => {
    setOpenBackgroundModal(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setOpenBackgroundModal(false);
  };


  const onFavorite = () => {
    dispatch(setFavoritePrivate({
      folder_type: "Favorite",
      data: selectedImages
    }))


    dispatch(toggleSelected([]))
  }
  const onPrivate = () => {
    dispatch(setFavoritePrivate({
      folder_type: "Private",
      data: selectedImages
    }))


    dispatch(toggleSelected([]))
  }


  useEffect(() => {
    setClientSInfo(studioInfo?.client_info)
  }, [studioInfo?.client_info.studio_id]);

  useEffect(() => {
    const filteredImages = images.filter(img =>
      img.IMAGE_NAME.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredImage(filteredImages);
  }, [searchQuery, images]);




  useEffect(() => {
    if (folderDataList.length > 0) {

      const itemsC = folderDataList.map((item) => {
        let label = '';
        let quantity = 0;
        const labelParts = [
          item.folder_name, item.myown_folder ? item.myown_folder : null,
          item.selectcard_type !== '0' && item.selectcard_type !== 0
            ? `${item.selectcard_type} `
            : ' ',
          item.selected_size !== '0' && item.selected_size !== 0
            ? `${item.selected_size} `
            : ' ',
        ];
        label = labelParts.filter(Boolean).join(' ');
        quantity = ' ( ' + item.images.length + ' / ' + item.quantity + ')';
        label = label + quantity;
        return {
          label,
          key: item.id,
          quantity,
          onClick: () => handleMenuClickHeader(item),
        };
      });

      const additionalButtons = [
        {
          label: 'Add to Favorite',
          icon: <PlusCircleOutlined />,
          key: 'button1',
          onClick: () => onFavorite(),
          className: styles.buttonIcon,
          style: { color: 'white' },
        },
        {
          label: 'Add to Private',
          key: 'button2',
          icon: <PlusCircleOutlined />,
          onClick: () => onPrivate(),
          className: styles.buttonIcon,
          style: { color: 'white' },
        },
      ];
      const updatedItems = [...additionalButtons, ...itemsC];
      setItems(updatedItems);
    }
  }, [folderDataList, handleMenuClickHeader]);





  return (
    <div className={styles.fixedHeader}>
      <animated.div style={navbarAnimation} className={styles.client_header}>
        <Row justify='space-between' className={styles.Row}>
          <Col span={3} lg={2} >
            <div className={styles.GalleryLogo}>
              {studioInfo && (<img src={PREFIX + studioInfo.studioInfo.studio_logo} alt="Studio Logo" />)}
            </div>
          </Col>
          <Col style={{ display: 'flex' }}>
            <Tooltip title="Selected all Photos">
              <Checkbox
                className='galleryCheked'
                checked={isSelectedAll}
                onChange={handleSelectAllChange}
              />
            </Tooltip>

            <Tooltip title="All Photos">
              <Button
                // onClick={() => {
                //   setSelectedFolder([]);
                //   setPrivateOpen(false);
                //   setFavoriteOpen(false);
                // }}
                className={styles.galleryBtn}
                style={{ color: isSelectedAll ? "#327dc3" : "initial" }}
              >
                <span className={styles.CompareText}>All Photos ( {total} )</span>
              </Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Create Folder">
              <CreateFolder
                folderDataList={folderDataList}

                icon={<PlusCircleOutlined />}
                buttonText="Create Folder"
              />
            </Tooltip>
          </Col>
          <Col >
            <Tooltip title="All private images">
              <Button className={privateOpen ? styles.galleryBtnActive : styles.galleryBtn} onClick={() => {
                setPrivateOpen(!privateOpen)
                setFavoriteOpen(false)
              }}>
                <LockOutlined />
                <div className={styles.favoriteWrapper}>
                <span className={styles.CompareText}>Private</span>
                <span className={styles.privateCount}>{privateImages.length}</span>
                  </div>
           
              </Button>
            </Tooltip>
          </Col >
          <Col >
            <Tooltip title="All favorite images">
              <Button className={favoriteOpen ? styles.galleryBtnActive : styles.galleryBtn} onClick={() => {
                setFavoriteOpen(!favoriteOpen)
                setPrivateOpen(false)
              }}>
                {favoriteImages.length > 0 ? (
                  <HeartFilled className={styles.favoriteButton} />
                ) : (
                  <HeartOutlined className={styles.inButtonHeader} />
                )}
                <div className={styles.favoriteWrapper}>
                <span className={styles.CompareText}>Favorite</span>
                <span className={styles.favoriteCount}>{favoriteImages.length}</span>
                </div>
             
              </Button>
            </Tooltip>
          </Col>
          <Col >
            <Tooltip title="To back Galleries">
              <Button onClick={() => goGalleryPage()} className={styles.galleryBtn}>
                <RollbackOutlined />
                <span className={styles.CompareText}> Galleries List</span>
              </Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Shopping Cart">
              <Button onClick={handleCartClick} className={styles.cartIcon}>
                <ShoppingCartOutlined className={styles.cartIconSvg} />

                <div className={styles.favoriteWrapper}>
                <span className={styles.totalQuantity}> {totalQuantity}</span>
                </div>
              
              </Button>
            </Tooltip>
          </Col>
          <Col >
            <LogoutButton />
          </Col>
        </Row>
      </animated.div>
      <animated.div style={navbarAnimation} className={styles.client_icon}>
        <Row justify='space-between' >
          <Col>
            <Row className={styles.Row} justify='start'>
              {studio_mode === 'true' ? <span className={styles.galleryBtnClientMode}>Studio mode</span> : <span className={styles.galleryBtnClientMode}>Client mode</span>}
              {studioInfo && clientInfo && (<ClientStudioInfo studioInfo={studioInfo.studioInfo} clientInfo={clientInfo} />)}
            </Row>
          </Col>
          <Col className={styles.colNone}>
            <Space direction="horizontal" className={styles.client_right}>
              <Tooltip title="Selected Backgrounds for School">
                <Button className={styles.buttonIcon} onClick={handleBackgroundClick}>
                  <PlusCircleOutlined /> <span className={styles.CompareTextButtun}>Backgrounds</span>
                </Button>
              </Tooltip>
              {selectedImages.length > 1 ? <GalleryCompareSplit selectedImages={selectedImages} className={styles.colNone} /> : null}
            </Space>
          </Col>
        </Row>
      </animated.div>
      <animated.div style={navbarAnimation} className={`${styles.client_icon} ${styles.colNone}`}>
        <Row justify='space-between' style={{ alignItems: 'center' }}>
          <Col>
            <ItemCreateFolder1
              folderDataList={folderDataList}


            />
          </Col>
          <Col>
            <Search
              allowClear
              placeholder="Search by image name"
              value={searchQuery}
              onChange={handleInputChange}
              style={{ width: '100%' }}
              className='deleteInput'
            />
          </Col>
          <Col>
            <Pagination
              showSizeChanger
              className='galleryPagination'
              defaultCurrent={1}
              current={currentPage}
              total={total}
              pageSize={50}
              onChange={(page, take) => handlePageChange(page, take)}
            />
          </Col>
          <Col>
            <Space direction="horizontal" className={styles.client_right}>
              {selectedImages.length > 1 && <AllImageDownloader selectedImages={selectedImages} />}
              {selectedImages.length > 1 ? (
                <Dropdown menu={menu} trigger={['click']} >
                  <Tooltip title="All selected Images add to Folder">
                    <Button className={styles.buttonIcon}>
                      <PlusCircleOutlined /> <span className={styles.CompareTextButtun}>Add to <b>{isSelectedAll ? total : selectedImages.length}</b> images</span>
                    </Button>
                  </Tooltip>
                </Dropdown>
              ) : null}
            </Space>
          </Col>
        </Row>
      </animated.div>

      <animated.div style={navbarAnimation} className={`${styles.client_icon} ${styles.colBlock}`}>
        <Row justify='space-between' style={{ alignItems: 'center' }}>
          
          <Col>
            <ItemCreateFolder1
              folderDataList={folderDataList}

            // setSelectedFolder={setSelectedFolder}
            />
          </Col>
          <Space direction="horizontal" className={styles.client_right}>

            <Col>
              {selectedImages.length > 1 ? (
                <Dropdown menu={menu} trigger={['click']}   >
                  <Tooltip title="All selected Images add to Folder">
                    <Button className={styles.buttonIcon} >
                      <PlusCircleOutlined />
                      <span className={styles.CompareTextButtun}>Add to <b>{selectedImages.length}</b> images</span>
                      <span>Add to</span>
                    </Button>
                  </Tooltip>
                </Dropdown>
              ) : null}
            </Col>
            <Col> {selectedImages.length > 1 && <GalleryCompareSplit selectedImages={selectedImages} />}</Col>
            <Col>
              {selectedImages.length > 1 && <AllImageDownloader selectedImages={selectedImages} />}
            </Col>
            <Col>
              <Tooltip title="Selected Backgrounds for School">
                <Button className={styles.buttonIcon} onClick={handleBackgroundClick}>
                  <PlusCircleOutlined /> <span className={styles.CompareTextButtun}>Backgrounds</span>
                </Button>
              </Tooltip>
            </Col>
          </Space>
        </Row>
      </animated.div>
      <Modal
        title='Shopping Cart'
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        className='modalCart'
        style={{ top: '0', right: '0', marginRight: '0px' }}
      >
        <ShoppingCart cart={cart} totalQuantity={totalQuantity} />
      </Modal>

      <Modal
        title='Backgrounds'
        open={openBackgroundModal}
        onCancel={handleModalClose}
        footer={null}
        className='modalCart'
        style={{ top: '0', right: '0', marginRight: '0px' }}
      >
        <InfiniteScroll
          className="scrollableDiv"
          dataLength={bgImages}
          scrollableTarget="scrollablePanel"
          height={500}
        >
          <List
            grid={{
              gutter: 1,
              column: 4,
            }}
            dataSource={bgImages}
            renderItem={({ id, path, name }) => (
              <div
                className="selectedImage_div"
                key={id}
              >
                <img
                  onClick={() => setSelectedBg(`/background/${path}`)}
                  src={`/background/${path}`}
                  className="selectedImage_img"
                  alt=""
                />
              </div>
            )}
          />
        </InfiniteScroll>
      </Modal>
    </div>
  );
};

export default GalleryHeader;
