import React, { useEffect, useState } from "react";
import { Collapse, List, Select } from 'antd';
import InfiniteScroll from "react-infinite-scroll-component";
import clientsAPI from "../../../api/api";
import { useFetching } from "../../../hoc/fetchingHook";
import DraggableImage from "./DraggbleImage";
import {ProductType} from "../ProductType/ProductType";
import {useWindowSize} from '../../Utils/WindowSize'


const GalleryScrollHorizontal = () => {
  const [images, setImages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [folderDataList, setFolderDataList] = useState([]);
  const [folderImages, setFolderImages] = useState([]);
  const IMG_SERVER = 'https://studioseye.com';
  const H_THUMBNAILS = 'H_THUMBNAILS/';
  const THUMBNAILS = 'THUMBNAILS/';
  const [productType, setProductType] = useState('Card')
  const windowSize = useWindowSize();
  const gridConfig = windowSize.width <= 577
    ? {
      gutter: 1,
      column: 5,
    }
    : windowSize.width <= 768
      ?{
        gutter: 1,
        column: 6,
      }
    : windowSize.width <= 1200
      ? {
        gutter: 1,
        column: 8,
      }
      : {
        gutter: 1,
        column: 2,
      };

  const heightScroll = windowSize.width <= 1200 ? 180: 400
  const generateNormalizedURL = (url) => {
    const normalizedURL = encodeURI(url);
    return normalizedURL.replace(/\(/g, '%28').replace(/\)/g, '%29');
  };

  const [getFoldersImages, isFoldersLoading, foldersError] = useFetching(async () => {
    try {
      const { data: res } = await clientsAPI.getEventFoldersImages();
      if (res?.length > 0) {
        const filteredRes = res.filter(item => item.folder_name === "Card" || item.folder_name === "Other");
        setFolderDataList(filteredRes);
      }
    } catch (error) {
      foldersError();
      console.error(error);
    }
  });


  const loadMoreData = () => {
    getGalleryImages(offset, 5);
  };

  const [getGalleryImages, isGalleryLoading, galleryError] = useFetching(
    async (offset, take) => {
      try {
        const { data: res } = await clientsAPI.getEventImagesByEventID(
          offset,
          take
        );
        if (res) {
          if (res.items.length === 0) {
            setHasMore(false);
          } else {
            setImages((prevImages) => [...prevImages, ...res.items]);
            setOffset((prevOffset) => prevOffset + res.items.length);
            setTotal(res.total);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  );

  const handleMenuSelect = (value) => {
    setSelectedMenuItem(value);
  };

  useEffect(() => {
    const selectedItem = folderDataList.find((item) => item.id === Number(selectedMenuItem));
    if (selectedItem) {
      setFolderImages(selectedItem.images);
    }
  }, [selectedMenuItem]);

  const generateMenuItems = () => {
    return folderDataList.map((item) => ({
      key: item.id.toString(),
      label: (
        <div>
          {`${item.folder_name} `}
          {(item.selectcard_type !== '0' && item.selectcard_type !== 0) ? `${item.selectcard_type} ` : ' '}
          {(item.selected_size !== '0' && item.selected_size !== 0) ? `${item.selected_size} ` : ' '}
          {item.quantity ? " ( " : null}
          <span style={{ color: item.images.length > item.quantity ? 'red' : 'inherit' }}>
                        {item.quantity ? item.images.length : null}
                    </span>
          {item.quantity ? ` / ${item.quantity} )` : null}
        </div>
      ),
    }));
  };

  useEffect(() => {
    getGalleryImages(offset, 8);
    getFoldersImages();
  }, []);
  const items = [
    {
      key: '1',
      label: 'Product Type',
      children: <ProductType selectedProducts={productType}  productType= {productType}setProductType={setProductType} />,
    },
    {
      key: '2',
      label: 'Select Gallery Images',
      children:    <InfiniteScroll
        className="gallery-scroll"
        dataLength={images.length}
        next={() => loadMoreData()}
        hasMore={hasMore}
        height={heightScroll}
      >
        <List
          grid={gridConfig}
          dataSource={images}
          renderItem={img => (
            <div className="selectedImage" key={img.ID}>
              <DraggableImage
                id={img.ID}
                img_path={generateNormalizedURL(IMG_SERVER + img.IMAGE_LINK_PATH + H_THUMBNAILS + img.IMAGE_NAME)}
                img_name={img.IMAGE_NAME}
                src={generateNormalizedURL(IMG_SERVER + img.IMAGE_LINK_PATH + THUMBNAILS + img.IMAGE_NAME)}
              />
            </div>
          )}
        />
      </InfiniteScroll>,
    },
    {
      key: '3',
      label: 'Select Folder Card  Images',
      children:<>
        <Select className='selectFolderImages' onSelect={handleMenuSelect} value={selectedMenuItem} placeholder="Select an item">
          {generateMenuItems().map((menuItem) => (
            <Select.Option key={menuItem.key} onClick={menuItem.onClick}>
              {menuItem.label}
            </Select.Option>
          ))}
        </Select>
        <InfiniteScroll
          className="gallery-scroll"
          dataLength={folderImages.length}
          next={() => loadMoreData()}
          hasMore={hasMore}
          height={heightScroll}
        >
          <List
            grid={gridConfig}
            dataSource={folderImages}
            renderItem={img => (
              <div className="selectedImage" key={img.ID}>
                <DraggableImage
                  id={img.ID}
                  img_path={generateNormalizedURL(IMG_SERVER + img.IMAGE_LINK_PATH + H_THUMBNAILS + img.IMAGE_NAME)}
                  img_name={img.IMAGE_NAME}
                  src={generateNormalizedURL(IMG_SERVER + img.IMAGE_LINK_PATH + THUMBNAILS + img.IMAGE_NAME)}
                />
              </div>
            )}
          />
        </InfiniteScroll>
      </>,
    },
  ];


  return (
     <Collapse items={items} ghost accordion defaultActiveKey={['2']} expandIconPosition="end" />
  );
};

export default GalleryScrollHorizontal;
