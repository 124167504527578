import React, { useState, useRef, useEffect } from 'react';
import {Drawer, FloatButton, Input, Upload, Space, Badge, Card, Row, Col} from "antd";
import {
  QuestionCircleOutlined,
  SendOutlined,
  LinkOutlined,
  WechatOutlined,
  DownloadOutlined,
  SkypeOutlined, MailOutlined, QrcodeOutlined,WhatsAppOutlined
} from "@ant-design/icons";
import './Chat.css';

const ClientContext = React.createContext();

const StudioContext = React.createContext();

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUploadFile = (file) => {
  return true;
};


export const Chat = ({
                       clientContext,
                       studioContext,
                       clientId,
                     }) => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [file, setFile] = useState({});
  const messagesEndRef = useRef(null);
  const studio_id = localStorage.getItem('studio_id') || '';

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const submitMessage = () => {
    if (currentMessage.trim() !== '' || file){
      const newMessage = {
        type: 'question',
        content: currentMessage,
        timestamp: new Date(),
        file: file,
        studioId: clientContext.studio_id,
        clientName: clientContext.firstname + ' ' + clientContext.lastname,
        isRead: false,
      };
      const updatedMessages = {
        ...messages,
        [clientId]: messages[clientId] ? [...messages[clientId], newMessage] : [newMessage],
      };

      setMessages(updatedMessages);
      setCurrentMessage('');
      setFile({})
    }
  };

  const submitAnswer = () => {
    if (currentAnswer.trim() !== ''  || file){
      const newAnswer = {
        type: 'answer',
        content: currentAnswer,
        timestamp: new Date(),
        file: file,
        studioId: studio_id,
        isRead: false,
      };

      const storedMessages = JSON.parse(localStorage.getItem('chatMessages')) || {};
      const clientMessages = storedMessages[clientId] || [];
      const updatedClientMessages = [...clientMessages, newAnswer];
      const updatedMessages = {
        ...storedMessages,
        [clientId]: updatedClientMessages,
      };

      localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
      setMessages(updatedMessages);

      setCurrentAnswer('');
      setFile({});
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitMessage();
    }
  };

  const handleKeyPressAnswer = (e) => {
    if (e.key === 'Enter') {
      submitAnswer();
    }
  };

  const DownloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangeFile = (info) => {
    if (info.file && info.file.status === "uploading") {
      getBase64(info.file.originFileObj, (url) => {
        const infoFile = {
          url,
          ...info.file
        };
        setFile(infoFile);
      });
    }
  };

  useEffect(() => {
    const storedMessages = localStorage.getItem('chatMessages');
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages[clientId]]);


  useEffect(() => {
    scrollToBottom();
    if (clientContext && messages[clientId] && messages[clientId].length > 0) {
      const lastAnswerIndex = messages[clientId].findLastIndex(message => message.type === 'answer');
      const newClientMessages = messages[clientId].slice(lastAnswerIndex + 1).filter(message => message.type === 'question' && !message.isRead);
      const updatedClientMessages = {
        ...JSON.parse(localStorage.getItem('newClientMessagesCount') || '{}'),
        [clientId]: newClientMessages.length,
      };
      localStorage.setItem('newClientMessagesCount', JSON.stringify(updatedClientMessages));
    }

    if (studioContext && messages[clientId] && messages[clientId].length > 0) {
      const lastQuestionIndex = messages[clientId].findLastIndex(message => message.type === 'question');

      const newStudioAnswers = messages[clientId].slice(lastQuestionIndex + 1).filter(message => message.type === 'answer' && !message.isRead);
      const updatedStudioAnswers = {
        ...JSON.parse(localStorage.getItem('newStudioAnswersCount') || '{}'),
        [clientId]: newStudioAnswers.length,
      };
      localStorage.setItem('newStudioAnswersCount', JSON.stringify(updatedStudioAnswers));
    }
  }, [messages, clientContext, studioContext, clientId]);

  return (
    <StudioContext.Provider value={{ messages, setMessages }}>
      <ClientContext.Provider value={{ messages, setMessages }}>
            <div className="help-chat-container">
              <div className="chat-messages">
                {messages[clientId] && messages[clientId].length > 0 ?(
                  messages[clientId].map((message, index) => (
                  <div key={`message-${index}`} className={`chat-message ${message.type}`}>
                    <span className='answer'>
                           {message.type === 'answer' && (
                             <Badge.Ribbon text ="STUDIO"  className="ClinetInfo Ribbon" color="cyan" placement='start'>
                               <Card style={{padding: '24px 0 10px 0'}}>
                                 <div className="answer-content">
                                   {message.content && <span>{message.content}</span>}
                                   {message.file && Object.keys(message.file).length > 0 && (
                                     <div className='file'>
                                           {message.file && message.file.type && message.file.type.startsWith("image/") ? (
                                             <img src={message.file.url} alt="Uploaded" style={{
                                             width:'100%', height:'100%',    objectFit:' contain',
                                             }}/>
                                           ) : (
                                             <span>{message.file.name}</span>
                                           )}
                                       <DownloadOutlined onClick={() => DownloadFile(message.file)}/>
                                  </div>
                                   )}
                                 </div>
                                 <span className='timestamp'>{new Date(message.timestamp).toLocaleString()}</span>
                               </Card>
                             </Badge.Ribbon>
                           )}
                    </span>
                    <span className='question'>
                    {message.type === 'question' &&  (
                        <Badge.Ribbon text ={`Client: ${message.clientName}`} className="ClinetInfoMessage ClinetInfo" color="magenta"  placement='end'>
                          <Card style={{padding: '24px 0 10px'}}>
                            <Space className="message-contents">
                            {message.content && <span>{message.content}</span>}
                            {message.file && Object.keys(message.file).length > 0 && (
                              <div className='file'>
                                <DownloadOutlined onClick={() => DownloadFile(message.file)}/>
                                {message.file && message.file.type && message.file.type.startsWith("image/") ? (
                                  <img src={message.file.url} alt="Uploaded" style={{  width:'100%', height:'100%',objectFit:' contain' }}
                                  />
                                ) : (
                                  <span>{message.file.name}</span>
                                )}
                              </div>
                            )}
                          </Space>

                            <span className='timestamp'>{new Date(message.timestamp).toLocaleString()}</span>
                          </Card>
                        </Badge.Ribbon>
                    )}
                    </span>
                  </div>
                ))):(<div style={{textAlign:'center', fontSize: '12px', color:'#999999'}}>There are no conversations yet...</div>)}
                <div ref={messagesEndRef} />
              </div>
              <div className="chat-input">
                  {clientContext &&
                    <Input
                      type="text"
                      value={currentMessage}
                      onChange={(e) => setCurrentMessage(e.target.value)}
                      suffix={
                        <>
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUploadFile}
                            onChange={handleChangeFile}
                          >
                            <LinkOutlined />
                          </Upload>
                          <SendOutlined disabled={!currentMessage.trim()} onClick={submitMessage} className='my_ant_icons_table'/>
                        </>
                      }
                      onKeyPress={handleKeyPress}
                    />}

                  {studioContext && <Input
                      type="text"
                      value={currentAnswer}
                      onChange={(e) => setCurrentAnswer(e.target.value)}
                      suffix={
                        <>
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUploadFile}
                            onChange={handleChangeFile}
                          >
                            <LinkOutlined />
                          </Upload>
                          <SendOutlined  disabled={!currentAnswer.trim()} onClick={submitAnswer} className='my_ant_icons_table'/>
                        </>
                      }
                      onKeyPress={handleKeyPressAnswer}
                    />}

                <div>
                  {file && file.type && file.type.startsWith("image/") ? (
                    <span style={{ width: '50px' }}>
                      <img src={file.url} alt="Uploaded" style={{
                        maxWidth: '100%',
                        maxHeight: '50px'
                      }}/>
                    </span>
                  ) : (
                    <span>{file && file.name}</span>
                  )}
                </div>


              </div>
        </div>
      </ClientContext.Provider>
    </StudioContext.Provider>
  );
};

export const StudioChat = ({studioInfo,clinetID,record}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  const onClose = () => {
    setIsChatOpen(false);
  };
  return (
    <>
      <span>
       <WechatOutlined
          className="my_ant_icons_table"
            type="primary"
            style={{ right: 24, zIndex: 1000 }}
            onClick={toggleChat}
          />

      </span>
      <Drawer
        title={`Client: (${record.id}) ${record.name}`}
        width={500}
        onClose={onClose}
        open={isChatOpen}
      >
        <Chat
          studioContext={studioInfo}
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
          clientId={clinetID}
        />
      </Drawer>
    </>
  );
};

export const ClientChat = ({ clinetInfo,clientId}) => {

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [newMessagesCount, setNewMessagesCount] = useState('');

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const onClose = () => {
    setIsChatOpen(false);
  };

  useEffect(() => {
    const storedNewMessagesCount = JSON.parse(localStorage.getItem('newStudioAnswersCount')) || {};
    const studioAnswers = storedNewMessagesCount[clientId] || [];
    setNewMessagesCount(studioAnswers);
  }, [])

  const sendMessageToSkype = () => {
    window.open('https://join.skype.com/invite/Ne4R16bwQjh3');
  };

  const sendMessageToWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=+79037734342&text=YourMessageHere');
  };

  const sendEmail = () => {
    window.open('mailto:someone@example.com?subject=YourSubjectHere&body=YourMessageHere');
  };

  const scanQRCode = () => {
  };
  return (
    <>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type="primary"
        style={{ right: 6,bottom: "8%", }}
        onClick={toggleChat}
        badge={{count: newMessagesCount,overflowCount: 9999}}
      />
      <Drawer
        title="Enter your message..."
        width={500}
        onClose={onClose}
        open={isChatOpen}
      >
        <Row justify='space-around'>
          <Col span={24}> <p>You can find us on social networks and ask questions</p></Col>
          <Col><SkypeOutlined className='byBut ChatIcon' onClick={sendMessageToSkype}/></Col>
          <Col><WhatsAppOutlined className='byBut ChatIcon' onClick={sendMessageToWhatsApp}/></Col>
          <Col><MailOutlined className='byBut ChatIcon' onClick={sendEmail}/></Col>
          <Col><QrcodeOutlined className='byBut ChatIcon' onClick={scanQRCode}/></Col>
        </Row>
        <Chat
          clientContext={clinetInfo}
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
          clientId={clientId}
        />
      </Drawer>
    </>
  );
};




