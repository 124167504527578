import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../context/CartContext';
import { Table, Button, Row, Col, notification, Space } from 'antd';
import { CheckCircleTwoTone, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './ShoppingCart.css';
import EditorCart from './EditorCart';
import Quantity from '../Utils/Quantity';
import MainCheckout from "./Checkout/MainCheckout/container/MainCheckout";
import CardPreview from "./CardPreview";
import { useFetching } from "../../hoc/fetchingHook";
import clientAPI from "../../api/api";
import ImageRenderer from "../Utils/ImageRenderer";
import CardEdit from "./CardEdit";

const ShoppingCart = () => {
  const { cart, setCart, removeFromCart, totalQuantity, } = useContext(CartContext);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [editCard, setEditCard] = useState(null);
  const [shoppingCartInfo, setShoppingCartInfo] = useState({
    user_id: localStorage.getItem('user_id'),
    user_type: localStorage.getItem('user_type'),
    current_user_id: localStorage.getItem('current_user_id'),
    event_id: localStorage.getItem('event_id'),
    client_id: localStorage.getItem('client_id'),
    studio_id: localStorage.getItem('studio_id'),
    event_name: localStorage.getItem('eventName'),
    owner_id: localStorage.getItem('studio_mode') === 'true' ? localStorage.getItem('studio_id') : localStorage.getItem('client_id'),
    studio_mode: localStorage.getItem('studio_mode') || '',
  });

  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const setQuantity = (key, newQuantity) => {
    const updatedCart = [...cart];
    updatedCart[key].quantity = newQuantity;
    setCart(updatedCart);
  }
  const [storeShoppingCart, cartLoading, cartError] = useFetching(async (shoppingCartData, shoppingCardInfo) => {
    try {
      if (shoppingCartData) {
        const { data: res } = await clientAPI.storeShoppingCart(shoppingCartData, shoppingCardInfo);

      }
    } catch (error) {
      console.error(error);
    }
  });




  const columns = [
    {
      title: '',
      dataIndex: 'num',
      width: 2,
    },
    {
      title: 'Image',
      dataIndex: 'original_image_back',
      render: (_, record) => {
        if (record?.productType?.includes('Card') && record?.cardObject) {
          return <CardPreview cardObject={record.cardObject} />;
        }
        return <ImageRenderer image={record.original_image_back} record={record} />;
      },
      width: 400,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (_, record) => (
        <div>
          <Quantity
            className='cartPlus'
            setQuantity={(newQuantity) => setQuantity(record.key, newQuantity)}
            quantity={record.quantity}
          />
        </div>
      ),
      width: 100,
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      render: (_, record) => (
        <Row>
          {record.productType ? (<Col span={24}>{`${record.productType} - ${record.size}`} </Col>) : ''}
          {/*{record.checkedValues &&  (*/}
          {/*  <Space direction='vertical'>*/}
          {/*    <span key={item}><CheckCircleTwoTone twoToneColor="#52c41a" /> {item}</span>*/}
          {/*    ))}*/}
          {/*  </Space>*/}
          {/*)}*/}
          {record.retouch && record.retouch.length > 0 ? (<Col span={24}><CheckCircleTwoTone twoToneColor="#52c41a" />  Retouch </Col>) : ''}
          {record.textValues ? (<Col span={24}>{`${record.textValues}`} </Col>) : null}
          {record.selectCardType ? (<Col span={24}>{`${record.selectCardType}`} </Col>) : ''}
          {record.verticalHorizontal ? (<Col span={24}>{`${record.verticalHorizontal}`} </Col>) : ''}
        </Row>
      ),
      width: 400,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: 70,
    },

    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      width: 100,
    },
    {
      title: 'Actions',
      render: (_, record) => {

        return (
          <div>
            {record.productType === 'Card' && (
              <>
                <Button onClick={() => setEditCard(record.key)} className='cartEdit mb5'>
                  <EditOutlined /> Edit Card
                </Button>
              </>
            )}

            {record.productType === 'Album' && (
              <>
                {/*<Button onClick={() => editAlbum(record.key)} className='cartEdit mb5'>*/}
                {/*  <EditOutlined /> Edit Album*/}
                {/*</Button>*/}
                {/* Other specific actions for Albums */}
              </>
            )}

            {record.productType === 'Print' && (
              <>
                <Button onClick={() => setSelectedItemIndex(record.key)} className='cartEdit mb5'>
                  <EditOutlined /> Edit
                </Button>
              </>
            )}

            {record.productType === 'Unit' && (
              <>
                {/*<Button onClick={() => editUnit(record.key)} className='cartEdit mb5'>*/}
                {/*  <EditOutlined /> Edit Unit*/}
                {/*</Button>*/}
                {/* Other specific actions for Units */}
              </>
            )}

            <Button onClick={() => removeFromCart(record.key)} className='cartDelete' danger>
              <DeleteOutlined /> Remove
            </Button>
          </div>
        )

      },


      width: 40,
    },
  ];

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));

    const current_cart = JSON.parse(localStorage.getItem('cart') || '[]');
    const newData = current_cart.map((item, index) => {
      let itemPrice;
      if (item.price) {
        itemPrice = parseFloat(item.price);
      } else if (item.currentSize && item.currentSize.price) {
        itemPrice = parseFloat(item.currentSize.price);
      } else if (item.templateForm && item.templateForm.price) {
        itemPrice = parseFloat(item.templateForm.price);
      } else {
        itemPrice = 0; // Default to 0 if none of the above conditions are met
      }

      let price = itemPrice; // Initialize price with the default itemPrice
      if (item.productType?.includes('Card') && item.cardObject && item.filteredObject?.options.length > 0) {
        const priceOption = item.filteredObject.options.find(option => item.quantity >= option.from && item.quantity <= option.to);
        if (priceOption) {
          price = parseFloat(priceOption.price);
        }
      }

      return {
        num: index + 1,
        key: index,
        image: item.image ? item.image : item.printImage,
        original_image: item.original_image,
        processedData: item.processedData ? item.processedData : '',
        selectedBg: item.selectedBg,
        quantity: item.quantity,
        productType: item.productType,
        size: `${item.currentSize?.width || ''} x ${item.currentSize?.height || ''}`,
        currentSize: {
          width: `${item.currentSize?.width || ''}`,
          height: `${item.currentSize?.height || ''}`,
        },
        price: price.toFixed(2),
        totalPrice: (price * item.quantity).toFixed(2),
        textValues: item.textValues ? item.textValues : '',
        retouch: item.retouch ? item.retouch : '',
        switcher: item.switcher,
        selectCardType: item.templateForm ? item.templateForm.product_type : '',
        verticalHorizontal: item.templateForm ? item.templateForm.template_pos : '',
        cardObject: item.cardObject,
        filteredObject: item.filteredObject,
      };
    });

    setData(newData);
    const newTotalPrice = newData.reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0).toFixed(2);
    setTotalPrice(newTotalPrice);
  }, [cart, cart.quantity]);




  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: 'Successfully',
      description: 'Data added successfully.',
    });
  };
  const saveOrder = () => {
    try {
      storeShoppingCart(data, shoppingCartInfo);
      openNotificationWithIcon('success');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };



 
  return (

    <>
      <Table className='scrollableDiv'
        dataSource={data}
        columns={columns}
        scroll={{ x: 100 }}
        pagination={false}
        style={{ height: '80%' }}
      />
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <span style={{ marginRight: '20px' }}>
          Total Items: <strong>{totalQuantity}</strong>
        </span>
        <span>
          Total Price: <strong>${totalPrice}</strong>
        </span>
      </div>
      <Row justify='space-between' style={{ marginTop: '20px' }}>
        <Col>         <span>
          {

            data.length ?
              (
                <>
                  <MainCheckout cartData={data}
                    shoppingCartInfo={shoppingCartInfo}
                    totalPrice={totalPrice}
                    setCart={setCart}
                    setData={setData}

                  />

                  <Button className="main-checkout-button" onClick={saveOrder}>
                    Save and order later
                  </Button>
                </>
              )
              : ""
          }



        </span></Col>

        <Col>
          <Button className="main-image-button" onClick={() => removeFromCart('all')}>
            Clear Cart
          </Button>
        </Col>
      </Row>
      {selectedItemIndex !== null && (
        <EditorCart
          selectedItemIndex={selectedItemIndex}
          setSelectedItemIndex={setSelectedItemIndex}
          isEditorCartOpen={true}
          setEditorCartOpen={setSelectedItemIndex}
        />
      )}
      {editCard !== null && (
        <CardEdit
          editCard={editCard}
          setEditCard={setEditCard}
          isEditorCartOpen={true}
          setEditorCartOpen={editCard}
        />
      )}
    </>
  );
};

export default ShoppingCart;
